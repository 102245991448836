const item = [
  {
    코드: 1,
    이름: "낡은 글라디우스",
    종류: 1,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 5,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1302014,
  },
  {
    코드: 2,
    이름: "신문지 칼",
    종류: 1,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1302024,
  },
  {
    코드: 3,
    이름: "하늘색 우산",
    종류: 1,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 6,
    마력: 6,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1302017,
  },
  {
    코드: 4,
    이름: "검",
    종류: 1,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 7,
    마력: 7,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1302000,
  },
  {
    코드: 5,
    이름: "돼지치기 막대",
    종류: 1,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1302132,
  },
  {
    코드: 6,
    이름: "연두색 우산",
    종류: 1,
    "필요 레벨": 8,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 10,
    직업: "공용",
    공격속도: 4,
    공격력: 6,
    마력: 19,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 40,
    imgCode: 1302027,
  },
  {
    코드: 7,
    이름: "카알 대검",
    종류: 1,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1302007,
  },
  {
    코드: 8,
    이름: "사브르",
    종류: 1,
    "필요 레벨": 15,
    필요STR: 40,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 14,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1302183,
  },
  {
    코드: 9,
    이름: "바이킹소드",
    종류: 1,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 18,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1302184,
  },
  {
    코드: 10,
    이름: "바이킹소드",
    종류: 1,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 3,
    공격력: 23,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1302022,
  },
  {
    코드: 11,
    이름: "일룬",
    종류: 1,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1302185,
  },
  {
    코드: 12,
    이름: "연보라 우산",
    종류: 1,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 21,
    마력: 21,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1302028,
  },
  {
    코드: 13,
    이름: "글라디우스",
    종류: 1,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 26,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1302186,
  },
  {
    코드: 14,
    이름: "메이플 소드",
    종류: 1,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 36,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 20,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1302211,
  },
  {
    코드: 15,
    이름: "커틀러스",
    종류: 1,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1302187,
  },
  {
    코드: 16,
    이름: "붉은 채찍",
    종류: 1,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 15,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1302013,
  },
  {
    코드: 17,
    이름: "화염의 카타나",
    종류: 1,
    "필요 레벨": 38,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 50,
    마력: 50,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 17,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 20851,
    imgCode: 1302063,
  },
  {
    코드: 18,
    이름: "광선채찍",
    종류: 1,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 42,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 15,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1302049,
  },
  {
    코드: 19,
    이름: "노란색 우산",
    종류: 1,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 34,
    마력: 34,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1302016,
  },
  {
    코드: 20,
    이름: "베이지 우산",
    종류: 1,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 36,
    마력: 36,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1302029,
  },
  {
    코드: 21,
    이름: "메이플 켈트 소드",
    종류: 1,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 3,
    공격력: 46,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1302208,
  },
  {
    코드: 22,
    이름: "메이플 베릴 브링어",
    종류: 1,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 32,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1302169,
  },
  {
    코드: 23,
    이름: "영웅의 글라디우스",
    종류: 1,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 54,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1302015,
  },
  {
    코드: 24,
    이름: "칠성검",
    종류: 1,
    "필요 레벨": 48,
    필요STR: 4,
    필요DEX: 4,
    필요INT: 4,
    필요LUK: 4,
    직업: "공용",
    공격속도: 4,
    공격력: 40,
    마력: 38,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 0,
    판매비용: 53084,
    imgCode: 1302031,
  },
  {
    코드: 25,
    이름: "코라",
    종류: 1,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 46,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1302190,
  },
  {
    코드: 26,
    이름: "쥬얼 쿠아다라",
    종류: 1,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 42,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1302051,
  },
  {
    코드: 27,
    이름: "빨강 우산",
    종류: 1,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 48,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1302025,
  },
  {
    코드: 28,
    이름: "네오코라",
    종류: 1,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1302190,
  },
  {
    코드: 29,
    이름: "메이플 글로리 소드",
    종류: 1,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 63,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1302209,
  },
  {
    코드: 30,
    이름: "레드 카타나",
    종류: 1,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 3,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1302053,
  },
  {
    코드: 31,
    이름: "검정 우산",
    종류: 1,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 60,
    마력: 60,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1302026,
  },
  {
    코드: 32,
    이름: "메이플 래티넘 브링어",
    종류: 1,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 53,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1302170,
  },
  {
    코드: 33,
    이름: "메이플 파이롭 소드",
    종류: 1,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 3,
    공격력: 72,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1302142,
  },
  {
    코드: 34,
    이름: "아츠",
    종류: 1,
    "필요 레벨": 80,
    필요STR: 170,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 65,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1302250,
  },
  {
    코드: 35,
    이름: "레전드 메이플 브링어",
    종류: 1,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 63,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1302192,
  },
  {
    코드: 36,
    이름: "프라우테",
    종류: 1,
    "필요 레벨": 90,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 73,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1302251,
  },
  {
    코드: 37,
    이름: "스파타",
    종류: 1,
    "필요 레벨": 100,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 81,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1302056,
  },
  {
    코드: 38,
    이름: "드래곤 카라벨라",
    종류: 1,
    "필요 레벨": 110,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1302059,
  },
  {
    코드: 39,
    이름: "엑서큐서너스",
    종류: 1,
    "필요 레벨": 120,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 89,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1302081,
  },
  {
    코드: 40,
    이름: "저스티스 메이플 브링어",
    종류: 1,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 97,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1302200,
  },
  {
    코드: 41,
    이름: "반 레온 세이버",
    종류: 1,
    "필요 레벨": 130,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 93,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 1302193,
  },
  {
    코드: 42,
    이름: "아가레스 블러디 피어",
    종류: 1,
    "필요 레벨": 140,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 97,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1302153,
  },
  {
    코드: 43,
    이름: "라이온하트 커틀러스",
    종류: 1,
    "필요 레벨": 150,
    필요STR: 420,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 101,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1302152,
  },
  {
    코드: 44,
    이름: "우트가르드 세이버",
    종류: 1,
    "필요 레벨": 160,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 105,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 45,
    이름: "파프니르 미스틸테인",
    종류: 1,
    "필요 레벨": 170,
    필요STR: 480,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 109,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 46,
    이름: "앱솔랩스 세이버",
    종류: 1,
    "필요 레벨": 180,
    필요STR: 510,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 47,
    이름: "아케인셰이드 세이버",
    종류: 1,
    "필요 레벨": 190,
    필요STR: 540,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 117,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 48,
    이름: "제네시스 세이버",
    종류: 1,
    "필요 레벨": 200,
    필요STR: 570,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 120,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 49,
    이름: "나무 야구 방망이",
    종류: 2,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 19,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1402009,
  },
  {
    코드: 50,
    이름: "목검",
    종류: 2,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 5,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1402119,
  },
  {
    코드: 51,
    이름: "환목검",
    종류: 2,
    "필요 레벨": 15,
    필요STR: 35,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1402120,
  },
  {
    코드: 52,
    이름: "양손검",
    종류: 2,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 19,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1402121,
  },
  {
    코드: 53,
    이름: "클러디",
    종류: 2,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 24,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1402122,
  },
  {
    코드: 54,
    이름: "알루미늄 야구 방망이",
    종류: 2,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 27,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1402010,
  },
  {
    코드: 55,
    이름: "왕푸",
    종류: 2,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 29,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1402123,
  },
  {
    코드: 56,
    이름: "하이랜더",
    종류: 2,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 33,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1402124,
  },
  {
    코드: 57,
    이름: "쟈드",
    종류: 2,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 38,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1402125,
  },
  {
    코드: 58,
    이름: "메이플 베릴 베다",
    종류: 2,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1402106,
  },
  {
    코드: 59,
    이름: "호검",
    종류: 2,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 47,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1402126,
  },
  {
    코드: 60,
    이름: "그리스",
    종류: 2,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1402127,
  },
  {
    코드: 61,
    이름: "메이플 소울 로헨",
    종류: 2,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 71,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1402068,
  },
  {
    코드: 62,
    이름: "그륜힐",
    종류: 2,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 66,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1402128,
  },
  {
    코드: 63,
    이름: "메이플 래티넘 베다",
    종류: 2,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 63,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1402107,
  },
  {
    코드: 64,
    이름: "메이플 파이롭 로헨",
    종류: 2,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 81,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1402085,
  },
  {
    코드: 65,
    이름: "레전드 메이플 베다",
    종류: 2,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 73,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1402129,
  },
  {
    코드: 66,
    이름: "그레이트 로헨",
    종류: 2,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 76,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1402175,
  },
  {
    코드: 67,
    이름: "청운검",
    종류: 2,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 76,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1402004,
  },
  {
    코드: 68,
    이름: "라 투핸더",
    종류: 2,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1402016,
  },
  {
    코드: 69,
    이름: "참마도",
    종류: 2,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 100,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1402005,
  },
  {
    코드: 70,
    이름: "참화도",
    종류: 2,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 94,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1402035,
  },
  {
    코드: 71,
    이름: "드래곤 클레이모어",
    종류: 2,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 99,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1402036,
  },
  {
    코드: 72,
    이름: "니플하임",
    종류: 2,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 104,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1402046,
  },
  {
    코드: 73,
    이름: "저스티스 메이플 베다",
    종류: 2,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 110,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1402138,
  },
  {
    코드: 74,
    이름: "반 레온 스워드",
    종류: 2,
    "필요 레벨": 130,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 108,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 1402091,
  },
  {
    코드: 75,
    이름: "아가레스 블러디 투핸더",
    종류: 2,
    "필요 레벨": 140,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1402096,
  },
  {
    코드: 76,
    이름: "라이온하트 배틀시미터",
    종류: 2,
    "필요 레벨": 150,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 118,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1402095,
  },
  {
    코드: 77,
    이름: "우트가르드 투핸드소드",
    종류: 2,
    "필요 레벨": 160,
    필요STR: 470,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 122,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 78,
    이름: "파프니르 페니텐시아",
    종류: 2,
    "필요 레벨": 170,
    필요STR: 500,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 127,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 79,
    이름: "앱솔랩스 브로드세이버",
    종류: 2,
    "필요 레벨": 180,
    필요STR: 530,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 132,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 80,
    이름: "아케인셰이드 투핸드소드",
    종류: 2,
    "필요 레벨": 190,
    필요STR: 560,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 136,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 81,
    이름: "제네시스 투핸드소드",
    종류: 2,
    "필요 레벨": 200,
    필요STR: 590,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 140,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 82,
    이름: "손 도끼",
    종류: 3,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 7,
    마력: 7,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1312004,
  },
  {
    코드: 83,
    이름: "양날 도끼",
    종류: 3,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1312000,
  },
  {
    코드: 84,
    이름: "전투 도끼",
    종류: 3,
    "필요 레벨": 15,
    필요STR: 40,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 14,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1312001,
  },
  {
    코드: 85,
    이름: "콘트라 엑스",
    종류: 3,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 18,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1312016,
  },
  {
    코드: 86,
    이름: "미스릴 도끼",
    종류: 3,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1312003,
  },
  {
    코드: 87,
    이름: "파이어 잭",
    종류: 3,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 26,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1312005,
  },
  {
    코드: 88,
    이름: "당커",
    종류: 3,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1312083,
  },
  {
    코드: 89,
    이름: "블루 카운터",
    종류: 3,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 34,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1312007,
  },
  {
    코드: 90,
    이름: "메이플 베릴 어비스",
    종류: 3,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 34,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1312068,
  },
  {
    코드: 91,
    이름: "훌라후프",
    종류: 3,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1312012,
  },
  {
    코드: 92,
    이름: "벅",
    종류: 3,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 42,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1312085,
  },
  {
    코드: 93,
    이름: "호크헤드",
    종류: 3,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1312086,
  },
  {
    코드: 94,
    이름: "메이플 스틸 엑스",
    종류: 3,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 3,
    공격력: 63,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1312046,
  },
  {
    코드: 95,
    이름: "미하일",
    종류: 3,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1312087,
  },
  {
    코드: 96,
    이름: "메이플 래티넘 어비스",
    종류: 3,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 52,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1312069,
  },
  {
    코드: 97,
    이름: "메이플 파이롭 엑스",
    종류: 3,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 3,
    공격력: 74,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1312056,
  },
  {
    코드: 98,
    이름: "레전드 메이플 어비스",
    종류: 3,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 62,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1312098,
  },
  {
    코드: 99,
    이름: "비펜니스",
    종류: 3,
    "필요 레벨": 90,
    필요STR: 260,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 73,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1312138,
  },
  {
    코드: 100,
    이름: "토마호크",
    종류: 3,
    "필요 레벨": 100,
    필요STR: 290,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 81,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1312030,
  },
  {
    코드: 101,
    이름: "드래곤 엑스",
    종류: 3,
    "필요 레벨": 110,
    필요STR: 320,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1312031,
  },
  {
    코드: 102,
    이름: "버디슈",
    종류: 3,
    "필요 레벨": 120,
    필요STR: 350,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 89,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1312038,
  },
  {
    코드: 103,
    이름: "저스티스 메이플 어비스",
    종류: 3,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 95,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1312106,
  },
  {
    코드: 104,
    이름: "반 레온 엑스",
    종류: 3,
    "필요 레벨": 130,
    필요STR: 380,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 93,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 1312095,
  },
  {
    코드: 105,
    이름: "아가레스 블러디 헤드",
    종류: 3,
    "필요 레벨": 140,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 97,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1312066,
  },
  {
    코드: 106,
    이름: "라이온하트 챔피온 엑스",
    종류: 3,
    "필요 레벨": 150,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 101,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1312065,
  },
  {
    코드: 107,
    이름: "우트가르드 엑스",
    종류: 3,
    "필요 레벨": 160,
    필요STR: 460,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 105,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 108,
    이름: "파프니르 트윈클리버",
    종류: 3,
    "필요 레벨": 170,
    필요STR: 490,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 109,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 109,
    이름: "앱솔랩스 엑스",
    종류: 3,
    "필요 레벨": 180,
    필요STR: 510,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 110,
    이름: "아케인셰이드 엑스",
    종류: 3,
    "필요 레벨": 190,
    필요STR: 540,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 117,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 111,
    이름: "제네시스 엑스",
    종류: 3,
    "필요 레벨": 200,
    필요STR: 570,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 120,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 112,
    이름: "쇠 도끼",
    종류: 4,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 5,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1412001,
  },
  {
    코드: 113,
    이름: "철제 도끼",
    종류: 4,
    "필요 레벨": 15,
    필요STR: 45,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1412078,
  },
  {
    코드: 114,
    이름: "강철 도끼",
    종류: 4,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 19,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1412079,
  },
  {
    코드: 115,
    이름: "양손 도끼",
    종류: 4,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 24,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1412080,
  },
  {
    코드: 116,
    이름: "버크",
    종류: 4,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 29,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1412081,
  },
  {
    코드: 117,
    이름: "니암",
    종류: 4,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 33,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1412082,
  },
  {
    코드: 118,
    이름: "버드빌",
    종류: 4,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 38,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1412083,
  },
  {
    코드: 119,
    이름: "메이플 투핸디드 엑스",
    종류: 4,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 52,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1412043,
  },
  {
    코드: 120,
    이름: "메이플 베릴 아발란체",
    종류: 4,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1412067,
  },
  {
    코드: 121,
    이름: "라이징",
    종류: 4,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 47,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1412084,
  },
  {
    코드: 122,
    이름: "샤이닝",
    종류: 4,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1412085,
  },
  {
    코드: 123,
    이름: "메이플 데몬엑스",
    종류: 4,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 71,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1412044,
  },
  {
    코드: 124,
    이름: "메이플 래티넘 아발란체",
    종류: 4,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 63,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1412068,
  },
  {
    코드: 125,
    이름: "크로노",
    종류: 4,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 66,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1412086,
  },
  {
    코드: 126,
    이름: "메이플 파이롭 배틀엑스",
    종류: 4,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 81,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1412055,
  },
  {
    코드: 127,
    이름: "헬리오스",
    종류: 4,
    "필요 레벨": 80,
    필요STR: 220,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 76,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1412124,
  },
  {
    코드: 128,
    이름: "레전드 메이플 아발란체",
    종류: 4,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 73,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1412087,
  },
  {
    코드: 129,
    이름: "클로니안 엑스",
    종류: 4,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1412125,
  },
  {
    코드: 130,
    이름: "타바르",
    종류: 4,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 94,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1412021,
  },
  {
    코드: 131,
    이름: "드래곤 배틀엑스",
    종류: 4,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 99,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1412026,
  },
  {
    코드: 132,
    이름: "저스티스 메이플 아발란체",
    종류: 4,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 110,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 133,
    이름: "타바르진",
    종류: 4,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 104,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 134,
    이름: "반 레온 배틀엑스",
    종류: 4,
    "필요 레벨": 130,
    필요STR: 390,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 108,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 135,
    이름: "아가레스 블러디 기간틱",
    종류: 4,
    "필요 레벨": 140,
    필요STR: 420,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1412066,
  },
  {
    코드: 136,
    이름: "라이온하트 배틀엑스",
    종류: 4,
    "필요 레벨": 150,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 118,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1412065,
  },
  {
    코드: 137,
    이름: "우트가르드 투핸드엑스",
    종류: 4,
    "필요 레벨": 160,
    필요STR: 480,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 122,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 138,
    이름: "파프니르 배틀클리버",
    종류: 4,
    "필요 레벨": 170,
    필요STR: 510,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 127,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 139,
    이름: "앱솔랩스 브로드엑스",
    종류: 4,
    "필요 레벨": 180,
    필요STR: 540,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 132,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 140,
    이름: "아케인셰이드 투핸드엑스",
    종류: 4,
    "필요 레벨": 190,
    필요STR: 570,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 136,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 141,
    이름: "제네시스 투핸드엑스",
    종류: 4,
    "필요 레벨": 200,
    필요STR: 600,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 140,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 142,
    이름: "몽둥이",
    종류: 5,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 7,
    마력: 7,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1322005,
  },
  {
    코드: 143,
    이름: "쇠파이프",
    종류: 5,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1322006,
  },
  {
    코드: 144,
    이름: "철근 해머",
    종류: 5,
    "필요 레벨": 10,
    필요STR: 35,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/마법사",
    공격속도: 5,
    공격력: 11,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1322032,
  },
  {
    코드: 145,
    이름: "가죽 핸드백",
    종류: 5,
    "필요 레벨": 15,
    필요STR: 20,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1322007,
  },
  {
    코드: 146,
    이름: "사각 강철 야삽",
    종류: 5,
    "필요 레벨": 15,
    필요STR: 20,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 5,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1322010,
  },
  {
    코드: 147,
    이름: "빨간 벽돌",
    종류: 5,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1322012,
  },
  {
    코드: 148,
    이름: "메이스",
    종류: 5,
    "필요 레벨": 15,
    필요STR: 20,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/마법사",
    공격속도: 5,
    공격력: 14,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1322113,
  },
  {
    코드: 149,
    이름: "강철 메이스",
    종류: 5,
    "필요 레벨": 20,
    필요STR: 25,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/마법사",
    공격속도: 5,
    공격력: 19,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1322114,
  },
  {
    코드: 150,
    이름: "007 가방",
    종류: 5,
    "필요 레벨": 20,
    필요STR: 25,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 20,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1322008,
  },
  {
    코드: 151,
    이름: "뚜러",
    종류: 5,
    "필요 레벨": 25,
    필요STR: 30,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 25,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1322009,
  },
  {
    코드: 152,
    이름: "퓨전 메이스",
    종류: 5,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/마법사",
    공격속도: 5,
    공격력: 23,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1322115,
  },
  {
    코드: 153,
    이름: "세계의 돼지도감",
    종류: 5,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 27,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1322031,
  },
  {
    코드: 154,
    이름: "워해머",
    종류: 5,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 27,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1322116,
  },
  {
    코드: 155,
    이름: "프라이팬",
    종류: 5,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 29,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1322027,
  },
  {
    코드: 156,
    이름: "막대 사탕",
    종류: 5,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 29,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1322003,
  },
  {
    코드: 157,
    이름: "호스맨즈",
    종류: 5,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 31,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1322117,
  },
  {
    코드: 158,
    이름: "젝커",
    종류: 5,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 36,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1322118,
  },
  {
    코드: 159,
    이름: "메이플 베릴 스타시커",
    종류: 5,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 37,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1322099,
  },
  {
    코드: 160,
    이름: "너클메이스",
    종류: 5,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 44,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1322119,
  },
  {
    코드: 161,
    이름: "검은색 튜브",
    종류: 5,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 46,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1322021,
  },
  {
    코드: 162,
    이름: "타무스",
    종류: 5,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 53,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1322120,
  },
  {
    코드: 163,
    이름: "구명 튜브",
    종류: 5,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 55,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1322025,
  },
  {
    코드: 164,
    이름: "메이플 해버크 해머",
    종류: 5,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 67,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1322074,
  },
  {
    코드: 165,
    이름: "도깨비 방망이",
    종류: 5,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 59,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1322033,
  },
  {
    코드: 166,
    이름: "스튬",
    종류: 5,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 61,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1322121,
  },
  {
    코드: 167,
    이름: "파노 튜브",
    종류: 5,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 4,
    공격력: 63,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1322026,
  },
  {
    코드: 168,
    이름: "메이플 래티넘 스타시커",
    종류: 5,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1322101,
  },
  {
    코드: 169,
    이름: "모던스튬",
    종류: 5,
    "필요 레벨": 75,
    필요STR: 215,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 66,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 316406,
    imgCode: 1322020,
  },
  {
    코드: 170,
    이름: "메이플 파이롭 해머",
    종류: 5,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 4,
    공격력: 76,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1322084,
  },
  {
    코드: 171,
    이름: "골든해머",
    종류: 5,
    "필요 레벨": 80,
    필요STR: 230,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 70,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1322183,
  },
  {
    코드: 172,
    이름: "레전드 메이플 스타시커",
    종류: 5,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 66,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1322138,
  },
  {
    코드: 173,
    이름: "루인해머",
    종류: 5,
    "필요 레벨": 90,
    필요STR: 245,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 78,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1322184,
  },
  {
    코드: 174,
    이름: "배틀해머",
    종류: 5,
    "필요 레벨": 100,
    필요STR: 260,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 87,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1322045,
  },
  {
    코드: 175,
    이름: "드래곤 메이스",
    종류: 5,
    "필요 레벨": 110,
    필요STR: 275,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 91,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1322052,
  },
  {
    코드: 176,
    이름: "알라르간도",
    종류: 5,
    "필요 레벨": 120,
    필요STR: 290,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 96,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 177,
    이름: "반 레온 해머",
    종류: 5,
    "필요 레벨": 130,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 100,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 178,
    이름: "아가레스 블러디 해머",
    종류: 5,
    "필요 레벨": 140,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 104,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1322097,
  },
  {
    코드: 179,
    이름: "라이온하트 배틀해머",
    종류: 5,
    "필요 레벨": 150,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 108,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1322096,
  },
  {
    코드: 180,
    이름: "우트가르드 해머",
    종류: 5,
    "필요 레벨": 160,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 181,
    이름: "파프니르 골디언해머",
    종류: 5,
    "필요 레벨": 170,
    필요STR: 470,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 117,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 182,
    이름: "앱솔랩스 비트해머",
    종류: 5,
    "필요 레벨": 180,
    필요STR: 500,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 121,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 183,
    이름: "아케인셰이드 해머",
    종류: 5,
    "필요 레벨": 190,
    필요STR: 530,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 126,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 184,
    이름: "제네시스 해머",
    종류: 5,
    "필요 레벨": 200,
    필요STR: 560,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 130,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 185,
    이름: "나무 망치",
    종류: 6,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 6,
    공격력: 13,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1422079,
  },
  {
    코드: 186,
    이름: "강철구 망치",
    종류: 6,
    "필요 레벨": 15,
    필요STR: 45,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1422080,
  },
  {
    코드: 187,
    이름: "곡괭이",
    종류: 6,
    "필요 레벨": 20,
    필요STR: 20,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 5,
    공격력: 20,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1512000,
  },
  {
    코드: 188,
    이름: "사각 망치",
    종류: 6,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 20,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1422081,
  },
  {
    코드: 189,
    이름: "몽키 스패너",
    종류: 6,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 6,
    공격력: 25,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1422082,
  },
  {
    코드: 190,
    이름: "미스릴 모울",
    종류: 6,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1422083,
  },
  {
    코드: 191,
    이름: "사케",
    종류: 6,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1422011,
  },
  {
    코드: 192,
    이름: "빅해머",
    종류: 6,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 35,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1422084,
  },
  {
    코드: 193,
    이름: "타이탄",
    종류: 6,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1422085,
  },
  {
    코드: 194,
    이름: "메이플 빅 모울",
    종류: 6,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 41,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1422046,
  },
  {
    코드: 195,
    이름: "메이플 베릴 토르",
    종류: 6,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 43,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1422069,
  },
  {
    코드: 196,
    이름: "골든 모울",
    종류: 6,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1422086,
  },
  {
    코드: 197,
    이름: "플루튬",
    종류: 6,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 60,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1422087,
  },
  {
    코드: 198,
    이름: "메이플 벨제트",
    종류: 6,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 75,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1422047,
  },
  {
    코드: 199,
    이름: "메이플 래티넘 토르",
    종류: 6,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 68,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1422070,
  },
  {
    코드: 200,
    이름: "호프만",
    종류: 6,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 70,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1422088,
  },
  {
    코드: 201,
    이름: "메이플 파이롭 모울",
    종류: 6,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 7,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1422057,
  },
  {
    코드: 202,
    이름: "크롬",
    종류: 6,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 80,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1422126,
  },
  {
    코드: 203,
    이름: "레전드 메이플 토르",
    종류: 6,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 78,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1422089,
  },
  {
    코드: 204,
    이름: "레오마이트",
    종류: 6,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 90,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1422127,
  },
  {
    코드: 205,
    이름: "골든 스미스해머",
    종류: 6,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 100,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1422027,
  },
  {
    코드: 206,
    이름: "드래곤 플레임",
    종류: 6,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 105,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1422028,
  },
  {
    코드: 207,
    이름: "벨로체",
    종류: 6,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 110,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 208,
    이름: "저스티스 메이플 토르",
    종류: 6,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 209,
    이름: "반 레온 투핸드해머",
    종류: 6,
    "필요 레벨": 130,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 115,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 210,
    이름: "아가레스 블러디모울",
    종류: 6,
    "필요 레벨": 140,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 120,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1422067,
  },
  {
    코드: 211,
    이름: "라이온하트 기간틱모울",
    종류: 6,
    "필요 레벨": 150,
    필요STR: 460,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 125,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1422066,
  },
  {
    코드: 212,
    이름: "우트가르드 투핸드해머",
    종류: 6,
    "필요 레벨": 160,
    필요STR: 490,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 130,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 213,
    이름: "파프니르 라이트닝어",
    종류: 6,
    "필요 레벨": 170,
    필요STR: 520,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 135,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 214,
    이름: "앱솔랩스 브로드해머",
    종류: 6,
    "필요 레벨": 180,
    필요STR: 550,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 140,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 215,
    이름: "아케인셰이드 투핸드해머",
    종류: 6,
    "필요 레벨": 190,
    필요STR: 580,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 145,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 216,
    이름: "제네시스 투핸드해머",
    종류: 6,
    "필요 레벨": 200,
    필요STR: 610,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 150,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 217,
    이름: "창",
    종류: 7,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 6,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1432107,
  },
  {
    코드: 218,
    이름: "포크 창",
    종류: 7,
    "필요 레벨": 15,
    필요STR: 15,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 6,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1432108,
  },
  {
    코드: 219,
    이름: "물고기 작살",
    종류: 7,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 21,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1432109,
  },
  {
    코드: 220,
    이름: "죽창",
    종류: 7,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1432132,
  },
  {
    코드: 221,
    이름: "펌프킨 스피어",
    종류: 7,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 27,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1432013,
  },
  {
    코드: 222,
    이름: "삼지창",
    종류: 7,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 29,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1432110,
  },
  {
    코드: 223,
    이름: "나카마키",
    종류: 7,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 33,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1432111,
  },
  {
    코드: 224,
    이름: "제코",
    종류: 7,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 38,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1432112,
  },
  {
    코드: 225,
    이름: "메이플 스피어",
    종류: 7,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 52,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1432058,
  },
  {
    코드: 226,
    이름: "낚싯대",
    종류: 7,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 45,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1432039,
  },
  {
    코드: 227,
    이름: "메이플 베릴 파르파티",
    종류: 7,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1432095,
  },
  {
    코드: 228,
    이름: "장팔사모",
    종류: 7,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 47,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1432113,
  },
  {
    코드: 229,
    이름: "십자창",
    종류: 7,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1432114,
  },
  {
    코드: 230,
    이름: "메이플 베리트 스피어",
    종류: 7,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 72,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1432059,
  },
  {
    코드: 231,
    이름: "스페판",
    종류: 7,
    "필요 레벨": 70,
    필요STR: 220,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 66,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1432115,
  },
  {
    코드: 232,
    이름: "메이플 래티넘 파르파티",
    종류: 7,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 64,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1432096,
  },
  {
    코드: 233,
    이름: "메이플 파이롭 스피어",
    종류: 7,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 5,
    공격력: 83,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1432075,
  },
  {
    코드: 234,
    이름: "레전드 메이플 파르파티",
    종류: 7,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 73,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1432117,
  },
  {
    코드: 235,
    이름: "호진공창",
    종류: 7,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 76,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1432152,
  },
  {
    코드: 236,
    이름: "페어프로즌",
    종류: 7,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1432153,
  },
  {
    코드: 237,
    이름: "피나카",
    종류: 7,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 94,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1432030,
  },
  {
    코드: 238,
    이름: "드래곤 팔티잔",
    종류: 7,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 99,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1432038,
  },
  {
    코드: 239,
    이름: "알슈피스",
    종류: 7,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 104,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 240,
    이름: "저스티스 메이플 파르파티",
    종류: 7,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 111,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 241,
    이름: "반 레온 스피어",
    종류: 7,
    "필요 레벨": 130,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 108,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 242,
    이름: "아가레스 블러디 스피어",
    종류: 7,
    "필요 레벨": 140,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1432087,
  },
  {
    코드: 243,
    이름: "라이온하트 푸스키나",
    종류: 7,
    "필요 레벨": 150,
    필요STR: 460,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 118,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1432086,
  },
  {
    코드: 244,
    이름: "우트가르드 스피어",
    종류: 7,
    "필요 레벨": 160,
    필요STR: 490,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 122,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 245,
    이름: "파프니르 브류나크",
    종류: 7,
    "필요 레벨": 170,
    필요STR: 520,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 127,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 246,
    이름: "앱솔랩스 피어싱 스피어",
    종류: 7,
    "필요 레벨": 180,
    필요STR: 550,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 132,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 247,
    이름: "아케인셰이드 스피어",
    종류: 7,
    "필요 레벨": 190,
    필요STR: 580,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 136,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 248,
    이름: "제네시스 스피어",
    종류: 7,
    "필요 레벨": 200,
    필요STR: 610,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 140,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 249,
    이름: "스카이 스노우보드",
    종류: 8,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 13,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1442012,
  },
  {
    코드: 250,
    이름: "냉동참치",
    종류: 8,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 55,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1442039,
  },
  {
    코드: 251,
    이름: "폴암",
    종류: 8,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 7,
    공격력: 13,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1442144,
  },
  {
    코드: 252,
    이름: "아쿠아 스노우보드",
    종류: 8,
    "필요 레벨": 12,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 16,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 207,
    imgCode: 1442013,
  },
  {
    코드: 253,
    이름: "노란 대걸레",
    종류: 8,
    "필요 레벨": 13,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 7,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 285,
    imgCode: 1442021,
  },
  {
    코드: 254,
    이름: "아이언 볼",
    종류: 8,
    "필요 레벨": 15,
    필요STR: 15,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1442006,
  },
  {
    코드: 255,
    이름: "빨간색 서핑보드",
    종류: 8,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 18,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1442054,
  },
  {
    코드: 256,
    이름: "팬텀",
    종류: 8,
    "필요 레벨": 20,
    필요STR: 20,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 20,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1442146,
  },
  {
    코드: 257,
    이름: "실버 스노우보드",
    종류: 8,
    "필요 레벨": 24,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 26,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3317,
    imgCode: 1442014,
  },
  {
    코드: 258,
    이름: "지저분한 대걸레",
    종류: 8,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/궁수/도적",
    공격속도: 7,
    공격력: 25,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1442147,
  },
  {
    코드: 259,
    이름: "미스릴 폴암",
    종류: 8,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1442148,
  },
  {
    코드: 260,
    이름: "스틸 폴암",
    종류: 8,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1442080,
  },
  {
    코드: 261,
    이름: "도끼 폴암",
    종류: 8,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 35,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1442149,
  },
  {
    코드: 262,
    이름: "골든 스노우보드",
    종류: 8,
    "필요 레벨": 36,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 38,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16796,
    imgCode: 1442015,
  },
  {
    코드: 263,
    이름: "남색 대걸레",
    종류: 8,
    "필요 레벨": 36,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 7,
    공격력: 36,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16796,
    imgCode: 1442023,
  },
  {
    코드: 264,
    이름: "크레센트",
    종류: 8,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 40,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1442150,
  },
  {
    코드: 265,
    이름: "메이플 폴암",
    종류: 8,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 55,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 30,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1442083,
  },
  {
    코드: 266,
    이름: "메이플 베릴 프람",
    종류: 8,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 43,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1442132,
  },
  {
    코드: 267,
    이름: "연두색 서핑보드",
    종류: 8,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 48,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1442055,
  },
  {
    코드: 268,
    이름: "다크 스노우보드",
    종류: 8,
    "필요 레벨": 48,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 53084,
    imgCode: 1442016,
  },
  {
    코드: 269,
    이름: "구룡도",
    종류: 8,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1442151,
  },
  {
    코드: 270,
    이름: "슈퍼 스노우보드",
    종류: 8,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 53,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1442046,
  },
  {
    코드: 271,
    이름: "하늘색 서핑보드",
    종류: 8,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 58,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1442056,
  },
  {
    코드: 272,
    이름: "방천극",
    종류: 8,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 60,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1442152,
  },
  {
    코드: 273,
    이름: "블러드 스노우보드",
    종류: 8,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 62,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1442017,
  },
  {
    코드: 274,
    이름: "메이플 카르스탄",
    종류: 8,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 75,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 35,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1442084,
  },
  {
    코드: 275,
    이름: "흰색 대걸레",
    종류: 8,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 7,
    공격력: 65,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1442022,
  },
  {
    코드: 276,
    이름: "황룡도",
    종류: 8,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 70,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1442153,
  },
  {
    코드: 277,
    이름: "메이플 래티넘 프람",
    종류: 8,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 68,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1442133,
  },
  {
    코드: 278,
    이름: "메이플 스노우보드",
    종류: 8,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 90,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1442157,
  },
  {
    코드: 279,
    이름: "메이플 파이롭 핼버드",
    종류: 8,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 6,
    공격력: 87,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 40,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1442104,
  },
  {
    코드: 280,
    이름: "월아산",
    종류: 8,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 80,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1442204,
  },
  {
    코드: 281,
    이름: "레전드 메이플 프람",
    종류: 8,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 78,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1442154,
  },
  {
    코드: 282,
    이름: "핼버드",
    종류: 8,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 90,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1442205,
  },
  {
    코드: 283,
    이름: "보라색 서핑보드",
    종류: 8,
    "필요 레벨": 95,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 100,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 814506,
    imgCode: 1442057,
  },
  {
    코드: 284,
    이름: "제드버그",
    종류: 8,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 100,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1442044,
  },
  {
    코드: 285,
    이름: "드래곤 핼버드",
    종류: 8,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 105,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1442045,
  },
  {
    코드: 286,
    이름: "디에스이라에",
    종류: 8,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 110,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 287,
    이름: "커터문",
    종류: 8,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 120,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1442169,
  },
  {
    코드: 288,
    이름: "저스티스 메이플 프람",
    종류: 8,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 118,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 289,
    이름: "반 레온 핼버드",
    종류: 8,
    "필요 레벨": 130,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 115,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 290,
    이름: "아가레스 블러디 폴암",
    종류: 8,
    "필요 레벨": 140,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 120,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1442117,
  },
  {
    코드: 291,
    이름: "라이온하트 파르티잔",
    종류: 8,
    "필요 레벨": 150,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 125,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1442116,
  },
  {
    코드: 292,
    이름: "우트가르드 핼버드",
    종류: 8,
    "필요 레벨": 160,
    필요STR: 480,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 130,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 293,
    이름: "파프니르 문글레이브",
    종류: 8,
    "필요 레벨": 170,
    필요STR: 510,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 135,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 294,
    이름: "앱솔랩스 핼버드",
    종류: 8,
    "필요 레벨": 180,
    필요STR: 540,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 140,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 295,
    이름: "아케인셰이드 폴암",
    종류: 8,
    "필요 레벨": 190,
    필요STR: 570,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 145,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 296,
    이름: "제네시스 폴암",
    종류: 8,
    "필요 레벨": 200,
    필요STR: 600,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 7,
    공격력: 150,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 297,
    이름: "나무 스태프",
    종류: 9,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 32,
    필요LUK: 13,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 12,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1382132,
  },
  {
    코드: 298,
    이름: "사파이어 스태프",
    종류: 9,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 48,
    필요LUK: 18,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 15,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1382003,
  },
  {
    코드: 299,
    이름: "고목나무 스태프",
    종류: 9,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 62,
    필요LUK: 23,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 19,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1382134,
  },
  {
    코드: 300,
    이름: "위저드 스태프",
    종류: 9,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 78,
    필요LUK: 28,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 24,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1382135,
  },
  {
    코드: 301,
    이름: "독버섯",
    종류: 9,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 10,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 31,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1382015,
  },
  {
    코드: 302,
    이름: "써클윈드 스태프",
    종류: 9,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 92,
    필요LUK: 33,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 29,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1382136,
  },
  {
    코드: 303,
    이름: "페탈 스태프",
    종류: 9,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 108,
    필요LUK: 38,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 33,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1382137,
  },
  {
    코드: 304,
    이름: "메이플 스태프",
    종류: 9,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 43,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1382074,
  },
  {
    코드: 305,
    이름: "홀 스태프",
    종류: 9,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 123,
    필요LUK: 43,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 38,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1382138,
  },
  {
    코드: 306,
    이름: "메이플 라마 스태프",
    종류: 9,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 50,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1382075,
  },
  {
    코드: 307,
    이름: "아크 스태프",
    종류: 9,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 138,
    필요LUK: 48,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 43,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1382030,
  },
  {
    코드: 308,
    이름: "메이플 베릴 사라스",
    종류: 9,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 40,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1382120,
  },
  {
    코드: 309,
    이름: "쏜즈",
    종류: 9,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 168,
    필요LUK: 58,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 52,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1382139,
  },
  {
    코드: 310,
    이름: "메이플 위덤 스태프",
    종류: 9,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 70,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1382076,
  },
  {
    코드: 311,
    이름: "이블윙즈",
    종류: 9,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 198,
    필요LUK: 68,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 61,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1382140,
  },
  {
    코드: 312,
    이름: "메이플 래티넘 사라스",
    종류: 9,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 64,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1382121,
  },
  {
    코드: 313,
    이름: "레이든 스태프",
    종류: 9,
    "필요 레벨": 75,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 228,
    필요LUK: 78,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 71,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 316406,
    imgCode: 1382194,
  },
  {
    코드: 314,
    이름: "메이플 파이롭 스태프",
    종류: 9,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 86,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1382093,
  },
  {
    코드: 315,
    이름: "레전드 메이플 사라스",
    종류: 9,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 73,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1382142,
  },
  {
    코드: 316,
    이름: "케이그",
    종류: 9,
    "필요 레벨": 85,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 258,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 80,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 522006,
    imgCode: 1382195,
  },
  {
    코드: 317,
    이름: "표고버섯",
    종류: 9,
    "필요 레벨": 92,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 40,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 87,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 716392,
    imgCode: 1382016,
  },
  {
    코드: 318,
    이름: "블루마린",
    종류: 9,
    "필요 레벨": 95,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 288,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 90,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 814506,
    imgCode: 1382035,
  },
  {
    코드: 319,
    이름: "드래곤 스태프",
    종류: 9,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 333,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 99,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1382036,
  },
  {
    코드: 320,
    이름: "저스티스 메이플 사라스",
    종류: 9,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 111,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 321,
    이름: "에아스 핸드",
    종류: 9,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 363,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 104,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 322,
    이름: "반 레온 스태프",
    종류: 9,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 383,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 108,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 323,
    이름: "엘리고스 블러디 로드",
    종류: 9,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 400,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 113,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1382105,
  },
  {
    코드: 324,
    이름: "드래곤테일 워스태프",
    종류: 9,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 430,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 118,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1382104,
  },
  {
    코드: 325,
    이름: "우트가르드 스태프",
    종류: 9,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 460,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 122,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 326,
    이름: "파프니르 마나크라운",
    종류: 9,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 490,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 127,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 327,
    이름: "앱솔랩스 스펠링스태프",
    종류: 9,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 510,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 132,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 328,
    이름: "아케인셰이드 스태프",
    종류: 9,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 540,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 136,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 329,
    이름: "제네시스 스태프",
    종류: 9,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 570,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 7,
    공격력: 7,
    마력: 140,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 330,
    이름: "우드 완드",
    종류: 10,
    "필요 레벨": 8,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 10,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 40,
    imgCode: 1372005,
  },
  {
    코드: 331,
    이름: "자쿰의 나뭇가지",
    종류: 10,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 6,
    공격력: 7,
    마력: 43,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1372049,
  },
  {
    코드: 332,
    이름: "하드 우드 완드",
    종류: 10,
    "필요 레벨": 13,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 40,
    필요LUK: 15,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 13,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 285,
    imgCode: 1372108,
  },
  {
    코드: 333,
    이름: "메탈 완드",
    종류: 10,
    "필요 레벨": 18,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 55,
    필요LUK: 20,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 17,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1049,
    imgCode: 1372109,
  },
  {
    코드: 334,
    이름: "아이스 완드",
    종류: 10,
    "필요 레벨": 23,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 70,
    필요LUK: 25,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 21,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2798,
    imgCode: 1372110,
  },
  {
    코드: 335,
    이름: "미스릴 완드",
    종류: 10,
    "필요 레벨": 28,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 85,
    필요LUK: 30,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 25,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6146,
    imgCode: 1372111,
  },
  {
    코드: 336,
    이름: "위저드 완드",
    종류: 10,
    "필요 레벨": 33,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 100,
    필요LUK: 35,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 30,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 11859,
    imgCode: 1372112,
  },
  {
    코드: 337,
    이름: "크리스탈 완드",
    종류: 10,
    "필요 레벨": 38,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 115,
    필요LUK: 40,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 34,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 20851,
    imgCode: 1372012,
  },
  {
    코드: 338,
    이름: "페어리 완드",
    종류: 10,
    "필요 레벨": 38,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 115,
    필요LUK: 40,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 34,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 20851,
    imgCode: 1372113,
  },
  {
    코드: 339,
    이름: "가로등",
    종류: 10,
    "필요 레벨": 41,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 40,
    직업: "마법사",
    공격속도: 5,
    공격력: 7,
    마력: 37,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 28257,
    imgCode: 1372017,
  },
  {
    코드: 340,
    이름: "메이플 베릴 파르마",
    종류: 10,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 35,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1372096,
  },
  {
    코드: 341,
    이름: "크로미",
    종류: 10,
    "필요 레벨": 48,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 145,
    필요LUK: 50,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 42,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 53084,
    imgCode: 1372114,
  },
  {
    코드: 342,
    이름: "우선",
    종류: 10,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 150,
    필요LUK: 53,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 43,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1372011,
  },
  {
    코드: 343,
    이름: "하트 완드",
    종류: 10,
    "필요 레벨": 54,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 5,
    공격력: 7,
    마력: 49,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 85030,
    imgCode: 1372033,
  },
  {
    코드: 344,
    이름: "이블테일러",
    종류: 10,
    "필요 레벨": 58,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 175,
    필요LUK: 60,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 50,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 113164,
    imgCode: 1372115,
  },
  {
    코드: 345,
    이름: "메이플 샤이니 완드",
    종류: 10,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 5,
    공격력: 7,
    마력: 66,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1372056,
  },
  {
    코드: 346,
    이름: "엔젤윙즈",
    종류: 10,
    "필요 레벨": 68,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 205,
    필요LUK: 70,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 58,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 213813,
    imgCode: 1372116,
  },
  {
    코드: 347,
    이름: "메이플 래티넘 파르마",
    종류: 10,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 55,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1372097,
  },
  {
    코드: 348,
    이름: "메이플 파이롭 완드",
    종류: 10,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 5,
    공격력: 7,
    마력: 77,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1372071,
  },
  {
    코드: 349,
    이름: "피닉스 완드",
    종류: 10,
    "필요 레벨": 78,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 235,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 66,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 370150,
    imgCode: 1372163,
  },
  {
    코드: 350,
    이름: "레전드 메이플 파르마",
    종류: 10,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 64,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1372117,
  },
  {
    코드: 351,
    이름: "마기코라스",
    종류: 10,
    "필요 레벨": 88,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 265,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 75,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 599695,
    imgCode: 1372164,
  },
  {
    코드: 352,
    이름: "다이몬의 완드",
    종류: 10,
    "필요 레벨": 98,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 295,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 83,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 922368,
    imgCode: 1372010,
  },
  {
    코드: 353,
    이름: "드래곤 완드",
    종류: 10,
    "필요 레벨": 108,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 325,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 87,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1360488,
    imgCode: 1372032,
  },
  {
    코드: 354,
    이름: "엔릴 티어",
    종류: 10,
    "필요 레벨": 118,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 355,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 91,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1938777,
    imgCode: 0,
  },
  {
    코드: 355,
    이름: "저스티스 메이플 파르마",
    종류: 10,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 98,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 356,
    이름: "반 레온 완드",
    종류: 10,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 385,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 96,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 357,
    이름: "엘리고스 블러디 완드",
    종류: 10,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 415,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 100,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1372085,
  },
  {
    코드: 358,
    이름: "드래곤테일 아크완드",
    종류: 10,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 445,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 104,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1372084,
  },
  {
    코드: 359,
    이름: "우트가르드 완드",
    종류: 10,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 475,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 108,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 360,
    이름: "파프니르 마나테이커",
    종류: 10,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 505,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 113,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 361,
    이름: "앱솔랩스 스펠링완드",
    종류: 10,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 535,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 117,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 362,
    이름: "아케인셰이드 완드",
    종류: 10,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 565,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 121,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 363,
    이름: "제네시스 완드",
    종류: 10,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 595,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 6,
    공격력: 7,
    마력: 125,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 364,
    이름: "가니어",
    종류: 11,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 4,
    공격력: 11,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1472149,
  },
  {
    코드: 365,
    이름: "스틸 티탄즈",
    종류: 11,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 4,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1472150,
  },
  {
    코드: 366,
    이름: "스틸 이고르",
    종류: 11,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 4,
    공격력: 14,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1472151,
  },
  {
    코드: 367,
    이름: "메바",
    종류: 11,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 3,
    공격력: 16,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1472152,
  },
  {
    코드: 368,
    이름: "스틸 가즈",
    종류: 11,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 4,
    공격력: 18,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1472153,
  },
  {
    코드: 369,
    이름: "다크 가디언",
    종류: 11,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 4,
    공격력: 20,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1472013,
  },
  {
    코드: 370,
    이름: "메이플 스론즈",
    종류: 11,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1472093,
  },
  {
    코드: 371,
    이름: "아다만티움 보닌",
    종류: 11,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 4,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1472016,
  },
  {
    코드: 372,
    이름: "메이플 칸데오",
    종류: 11,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 20,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1472094,
  },
  {
    코드: 373,
    이름: "메이플 베릴 체이서",
    종류: 11,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1472136,
  },
  {
    코드: 374,
    이름: "다크 슬레인",
    종류: 11,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 4,
    공격력: 26,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1472046,
  },
  {
    코드: 375,
    이름: "다크 기간틱",
    종류: 11,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 4,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1472047,
  },
  {
    코드: 376,
    이름: "메이플 스칸다",
    종류: 11,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 25,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1472095,
  },
  {
    코드: 377,
    이름: "흑갑충",
    종류: 11,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 4,
    공격력: 34,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1472048,
  },
  {
    코드: 378,
    이름: "메이플 래티넘 체이서",
    종류: 11,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1472137,
  },
  {
    코드: 379,
    이름: "메이플 파이롭 스칸다",
    종류: 11,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 30,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1472111,
  },
  {
    코드: 380,
    이름: "코브라스티어",
    종류: 11,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 230,
    직업: "도적",
    공격속도: 4,
    공격력: 38,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1472199,
  },
  {
    코드: 381,
    이름: "레전드 메이플 체이서",
    종류: 11,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1472159,
  },
  {
    코드: 382,
    이름: "캐스터스",
    종류: 11,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1472200,
  },
  {
    코드: 383,
    이름: "레드 크리븐",
    종류: 11,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 290,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1472053,
  },
  {
    코드: 384,
    이름: "드래곤 퍼플 슬레브",
    종류: 11,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 320,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1472052,
  },
  {
    코드: 385,
    이름: "저스티스 메이플 체이서",
    종류: 11,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 386,
    이름: "람피온",
    종류: 11,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 350,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 387,
    이름: "반 레온 가즈",
    종류: 11,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 380,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 388,
    이름: "핼파스 블러디미스트",
    종류: 11,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 420,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1472123,
  },
  {
    코드: 389,
    이름: "레이븐혼 메탈피스트",
    종류: 11,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 450,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1472122,
  },
  {
    코드: 390,
    이름: "우트가르드 가즈",
    종류: 11,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 480,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 391,
    이름: "파프니르 리스크홀더",
    종류: 11,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 510,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 392,
    이름: "앱솔랩스 리벤지가즈",
    종류: 11,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 540,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 393,
    이름: "아케인셰이드 가즈",
    종류: 11,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 570,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 394,
    이름: "제네시스 가즈",
    종류: 11,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 600,
    직업: "도적",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 395,
    이름: "도루코 대거",
    종류: 12,
    "필요 레벨": 5,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 8,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6,
    imgCode: 1332066,
  },
  {
    코드: 396,
    이름: "후루츠 대거",
    종류: 12,
    "필요 레벨": 8,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 40,
    imgCode: 1332007,
  },
  {
    코드: 397,
    이름: "크리스마스 트리",
    종류: 12,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1332032,
  },
  {
    코드: 398,
    이름: "삼각 자마다르",
    종류: 12,
    "필요 레벨": 12,
    필요STR: 0,
    필요DEX: 25,
    필요INT: 0,
    필요LUK: 25,
    직업: "도적",
    공격속도: 3,
    공격력: 11,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 207,
    imgCode: 1332158,
  },
  {
    코드: 399,
    이름: "삼지 자마다르",
    종류: 12,
    "필요 레벨": 17,
    필요STR: 0,
    필요DEX: 35,
    필요INT: 0,
    필요LUK: 40,
    직업: "도적",
    공격속도: 3,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 835,
    imgCode: 1332159,
  },
  {
    코드: 400,
    이름: "스팅어",
    종류: 12,
    "필요 레벨": 22,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 55,
    직업: "도적",
    공격속도: 3,
    공격력: 19,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2342,
    imgCode: 1332160,
  },
  {
    코드: 401,
    이름: "쌍지단도",
    종류: 12,
    "필요 레벨": 27,
    필요STR: 0,
    필요DEX: 55,
    필요INT: 0,
    필요LUK: 70,
    직업: "도적",
    공격속도: 3,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5314,
    imgCode: 1332161,
  },
  {
    코드: 402,
    이름: "리프 크로",
    종류: 12,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 3,
    공격력: 24,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1332162,
  },
  {
    코드: 403,
    이름: "반월 자마다르",
    종류: 12,
    "필요 레벨": 32,
    필요STR: 0,
    필요DEX: 65,
    필요INT: 0,
    필요LUK: 85,
    직업: "도적",
    공격속도: 3,
    공격력: 26,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10485,
    imgCode: 1332001,
  },
  {
    코드: 404,
    이름: "태극부채",
    종류: 12,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 5,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1332020,
  },
  {
    코드: 405,
    이름: "게파트",
    종류: 12,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 3,
    공격력: 28,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1332163,
  },
  {
    코드: 406,
    이름: "드래곤 토네일",
    종류: 12,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 3,
    공격력: 31,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1332164,
  },
  {
    코드: 407,
    이름: "페트병",
    종류: 12,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 32,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1332021,
  },
  {
    코드: 408,
    이름: "메이플 바그나",
    종류: 12,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 45,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 15,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1332093,
  },
  {
    코드: 409,
    이름: "메이플 베릴 바마나",
    종류: 12,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 32,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1332144,
  },
  {
    코드: 410,
    이름: "신기타",
    종류: 12,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 3,
    공격력: 39,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1332165,
  },
  {
    코드: 411,
    이름: "쌍고검",
    종류: 12,
    "필요 레벨": 53,
    필요STR: 4,
    필요DEX: 4,
    필요INT: 4,
    필요LUK: 4,
    직업: "공용",
    공격속도: 3,
    공격력: 42,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 78904,
    imgCode: 1332029,
  },
  {
    코드: 412,
    이름: "게타",
    종류: 12,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 3,
    공격력: 46,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1332166,
  },
  {
    코드: 413,
    이름: "메이플 다크메이트",
    종류: 12,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 59,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 20,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 167772,
    imgCode: 1332094,
  },
  {
    코드: 414,
    이름: "부채",
    종류: 12,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 3,
    공격력: 51,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1332030,
  },
  {
    코드: 415,
    이름: "칸디네",
    종류: 12,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 3,
    공격력: 53,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1332167,
  },
  {
    코드: 416,
    이름: "메이플 래티넘 바마나",
    종류: 12,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1332145,
  },
  {
    코드: 417,
    이름: "메이플 파이롭 하프문",
    종류: 12,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 67,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 20,
    회피율: 25,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1332114,
  },
  {
    코드: 418,
    이름: "레전드 메이플 바마나",
    종류: 12,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1332168,
  },
  {
    코드: 419,
    이름: "용천권",
    종류: 12,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 210,
    직업: "도적",
    공격속도: 3,
    공격력: 60,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1332208,
  },
  {
    코드: 420,
    이름: "바키트",
    종류: 12,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 230,
    직업: "도적",
    공격속도: 3,
    공격력: 67,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1332209,
  },
  {
    코드: 421,
    이름: "블러드 대거",
    종류: 12,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 250,
    직업: "도적",
    공격속도: 3,
    공격력: 74,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1332052,
  },
  {
    코드: 422,
    이름: "드래곤 크리스",
    종류: 12,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 270,
    직업: "도적",
    공격속도: 3,
    공격력: 78,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1332050,
  },
  {
    코드: 423,
    이름: "킬릭",
    종류: 12,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 290,
    직업: "도적",
    공격속도: 3,
    공격력: 81,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 424,
    이름: "저스티스 메이플 바마나",
    종류: 12,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 89,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 425,
    이름: "반 레온 대거",
    종류: 12,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 400,
    직업: "도적",
    공격속도: 3,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 426,
    이름: "핼파스 블러디 슬레이어",
    종류: 12,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 420,
    직업: "도적",
    공격속도: 3,
    공격력: 89,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1332131,
  },
  {
    코드: 427,
    이름: "레이븐혼 바젤라드",
    종류: 12,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 440,
    직업: "도적",
    공격속도: 3,
    공격력: 92,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1332130,
  },
  {
    코드: 428,
    이름: "우트가르드 대거",
    종류: 12,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 460,
    직업: "도적",
    공격속도: 3,
    공격력: 96,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 429,
    이름: "파프니르 다마스커스",
    종류: 12,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 480,
    직업: "도적",
    공격속도: 3,
    공격력: 99,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 430,
    이름: "앱솔랩스 슬래셔",
    종류: 12,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 500,
    직업: "도적",
    공격속도: 3,
    공격력: 103,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 431,
    이름: "아케인셰이드 대거",
    종류: 12,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 520,
    직업: "도적",
    공격속도: 3,
    공격력: 106,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 432,
    이름: "제네시스 대거",
    종류: 12,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 540,
    직업: "도적",
    공격속도: 3,
    공격력: 110,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 433,
    이름: "필드 대거",
    종류: 13,
    "필요 레벨": 15,
    필요STR: 15,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 14,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1332006,
  },
  {
    코드: 434,
    이름: "넙치검",
    종류: 13,
    "필요 레벨": 20,
    필요STR: 20,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 18,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1332008,
  },
  {
    코드: 435,
    이름: "아이언 대거",
    종류: 13,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1332010,
  },
  {
    코드: 436,
    이름: "카스",
    종류: 13,
    "필요 레벨": 30,
    필요STR: 30,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 26,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1332009,
  },
  {
    코드: 437,
    이름: "베즐러드",
    종류: 13,
    "필요 레벨": 40,
    필요STR: 40,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 34,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1332011,
  },
  {
    코드: 438,
    이름: "차",
    종류: 13,
    "필요 레벨": 50,
    필요STR: 50,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 42,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1332016,
  },
  {
    코드: 439,
    이름: "스네이크",
    종류: 13,
    "필요 레벨": 60,
    필요STR: 60,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1332017,
  },
  {
    코드: 440,
    이름: "메이플 아수라 대거",
    종류: 13,
    "필요 레벨": 77,
    필요STR: 10,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 3,
    공격력: 72,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 20,
    회피율: 25,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1332095,
  },
  {
    코드: 441,
    이름: "금강저",
    종류: 13,
    "필요 레벨": 70,
    필요STR: 70,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1332019,
  },
  {
    코드: 442,
    이름: "카타르",
    종류: 13,
    "필요 레벨": 80,
    필요STR: 80,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 65,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1332022,
  },
  {
    코드: 443,
    이름: "커세어",
    종류: 13,
    "필요 레벨": 90,
    필요STR: 90,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 73,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1332026,
  },
  {
    코드: 444,
    이름: "골드 트윈 나이프",
    종류: 13,
    "필요 레벨": 100,
    필요STR: 100,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 81,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1332051,
  },
  {
    코드: 445,
    이름: "드래곤 칸자르",
    종류: 13,
    "필요 레벨": 110,
    필요STR: 110,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 85,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1332049,
  },
  {
    코드: 446,
    이름: "페스카즈",
    종류: 13,
    "필요 레벨": 120,
    필요STR: 120,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 89,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 447,
    이름: "반 레온 카타르",
    종류: 13,
    "필요 레벨": 130,
    필요STR: 130,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 93,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 448,
    이름: "핼파스 블러디 카타르",
    종류: 13,
    "필요 레벨": 140,
    필요STR: 140,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 97,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 0,
  },
  {
    코드: 449,
    이름: "레이븐혼 카타르",
    종류: 13,
    "필요 레벨": 150,
    필요STR: 150,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 101,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 0,
  },
  {
    코드: 450,
    이름: "우트가르드 카타르",
    종류: 13,
    "필요 레벨": 160,
    필요STR: 160,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 105,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 451,
    이름: "파프니르 카타르",
    종류: 13,
    "필요 레벨": 170,
    필요STR: 170,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 109,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 452,
    이름: "앱솔랩스 카타르",
    종류: 13,
    "필요 레벨": 180,
    필요STR: 180,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 113,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 453,
    이름: "아케인셰이드 카타르",
    종류: 13,
    "필요 레벨": 190,
    필요STR: 190,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 117,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 454,
    이름: "제네시스 카타르",
    종류: 13,
    "필요 레벨": 200,
    필요STR: 200,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사/도적",
    공격속도: 3,
    공격력: 120,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 455,
    이름: "워 보우",
    종류: 14,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1452137,
  },
  {
    코드: 456,
    이름: "합금 활",
    종류: 14,
    "필요 레벨": 15,
    필요STR: 20,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 13,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1452138,
  },
  {
    코드: 457,
    이름: "사냥꾼의 활",
    종류: 14,
    "필요 레벨": 20,
    필요STR: 25,
    필요DEX: 65,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 17,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1452139,
  },
  {
    코드: 458,
    이름: "배틀 보우",
    종류: 14,
    "필요 레벨": 25,
    필요STR: 30,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 20,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1452140,
  },
  {
    코드: 459,
    이름: "라이덴",
    종류: 14,
    "필요 레벨": 30,
    필요STR: 35,
    필요DEX: 95,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 23,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1452141,
  },
  {
    코드: 460,
    이름: "모험가의 발터2000",
    종류: 14,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1452092,
  },
  {
    코드: 461,
    이름: "레드 바이퍼",
    종류: 14,
    "필요 레벨": 35,
    필요STR: 40,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: 27,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1452142,
  },
  {
    코드: 462,
    이름: "메이플 보우",
    종류: 14,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1452077,
  },
  {
    코드: 463,
    이름: "발터2000",
    종류: 14,
    "필요 레벨": 40,
    필요STR: 45,
    필요DEX: 125,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 30,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1452143,
  },
  {
    코드: 464,
    이름: "메이플 헌터스",
    종류: 14,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 7,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1452078,
  },
  {
    코드: 465,
    이름: "메이플 베릴 템페스트",
    종류: 14,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1452125,
  },
  {
    코드: 466,
    이름: "올림푸스",
    종류: 14,
    "필요 레벨": 50,
    필요STR: 55,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 37,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1452144,
  },
  {
    코드: 467,
    이름: "조가지궁",
    종류: 14,
    "필요 레벨": 54,
    필요STR: 58,
    필요DEX: 165,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 85030,
    imgCode: 1452023,
  },
  {
    코드: 468,
    이름: "봉황위궁",
    종류: 14,
    "필요 레벨": 60,
    필요STR: 65,
    필요DEX: 185,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 44,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1452145,
  },
  {
    코드: 469,
    이름: "메이플 칸디바 보우",
    종류: 14,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 0,
  },
  {
    코드: 470,
    이름: "마법 파괴 활",
    종류: 14,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1452018,
  },
  {
    코드: 471,
    이름: "메이플 래티넘 템페스트",
    종류: 14,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1452126,
  },
  {
    코드: 472,
    이름: "레드 힌켈",
    종류: 14,
    "필요 레벨": 70,
    필요STR: 75,
    필요DEX: 205,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1452146,
  },
  {
    코드: 473,
    이름: "메이플 파이롭 보우",
    종류: 14,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 13,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1452100,
  },
  {
    코드: 474,
    이름: "골드 아룬드",
    종류: 14,
    "필요 레벨": 80,
    필요STR: 80,
    필요DEX: 225,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1452014,
  },
  {
    코드: 475,
    이름: "레전드 메이플 템페스트",
    종류: 14,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1452147,
  },
  {
    코드: 476,
    이름: "메투스",
    종류: 14,
    "필요 레벨": 90,
    필요STR: 80,
    필요DEX: 245,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1452192,
  },
  {
    코드: 477,
    이름: "다크 니스록",
    종류: 14,
    "필요 레벨": 100,
    필요STR: 80,
    필요DEX: 265,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1452021,
  },
  {
    코드: 478,
    이름: "드래곤 샤인보우",
    종류: 14,
    "필요 레벨": 110,
    필요STR: 80,
    필요DEX: 285,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1452044,
  },
  {
    코드: 479,
    이름: "엔가우",
    종류: 14,
    "필요 레벨": 120,
    필요STR: 80,
    필요DEX: 305,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 480,
    이름: "저스티스 메이플 템페스트",
    종류: 14,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 481,
    이름: "반 레온 보우",
    종류: 14,
    "필요 레벨": 130,
    필요STR: 80,
    필요DEX: 335,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 482,
    이름: "이포스 블러디 롱보우",
    종류: 14,
    "필요 레벨": 140,
    필요STR: 80,
    필요DEX: 365,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1452112,
  },
  {
    코드: 483,
    이름: "팔콘윙 컴포지트 보우",
    종류: 14,
    "필요 레벨": 150,
    필요STR: 80,
    필요DEX: 395,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1452111,
  },
  {
    코드: 484,
    이름: "우트가르드 보우",
    종류: 14,
    "필요 레벨": 160,
    필요STR: 80,
    필요DEX: 425,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 485,
    이름: "파프니르 윈드 체이서",
    종류: 14,
    "필요 레벨": 170,
    필요STR: 80,
    필요DEX: 455,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 486,
    이름: "앱솔랩스 슈팅보우",
    종류: 14,
    "필요 레벨": 180,
    필요STR: 80,
    필요DEX: 485,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 487,
    이름: "아케인셰이드 보우",
    종류: 14,
    "필요 레벨": 190,
    필요STR: 80,
    필요DEX: 515,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 488,
    이름: "제네시스 보우",
    종류: 14,
    "필요 레벨": 200,
    필요STR: 80,
    필요DEX: 545,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 489,
    이름: "석궁",
    종류: 15,
    "필요 레벨": 12,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 207,
    imgCode: 1462126,
  },
  {
    코드: 490,
    이름: "전투 석궁",
    종류: 15,
    "필요 레벨": 18,
    필요STR: 18,
    필요DEX: 55,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1049,
    imgCode: 1462127,
  },
  {
    코드: 491,
    이름: "발란쉐",
    종류: 15,
    "필요 레벨": 22,
    필요STR: 22,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2342,
    imgCode: 1462128,
  },
  {
    코드: 492,
    이름: "산양 석궁",
    종류: 15,
    "필요 레벨": 28,
    필요STR: 28,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6146,
    imgCode: 1462129,
  },
  {
    코드: 493,
    이름: "모험가의 헤클러",
    종류: 15,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1462077,
  },
  {
    코드: 494,
    이름: "이글크로",
    종류: 15,
    "필요 레벨": 32,
    필요STR: 32,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10485,
    imgCode: 1462004,
  },
  {
    코드: 495,
    이름: "메이플 크로우",
    종류: 15,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1462069,
  },
  {
    코드: 496,
    이름: "헤클러",
    종류: 15,
    "필요 레벨": 38,
    필요STR: 38,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 20851,
    imgCode: 1462131,
  },
  {
    코드: 497,
    이름: "실버 크로우",
    종류: 15,
    "필요 레벨": 42,
    필요STR: 42,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 31116,
    imgCode: 1462132,
  },
  {
    코드: 498,
    이름: "메이플 크로스보우",
    종류: 15,
    "필요 레벨": 43,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 7,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 34188,
    imgCode: 1462070,
  },
  {
    코드: 499,
    이름: "메이플 베릴 발리스타",
    종류: 15,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1462113,
  },
  {
    코드: 500,
    이름: "로우어",
    종류: 15,
    "필요 레벨": 50,
    필요STR: 50,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 37,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1462133,
  },
  {
    코드: 501,
    이름: "골든 크로우",
    종류: 15,
    "필요 레벨": 60,
    필요STR: 60,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 44,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1462134,
  },
  {
    코드: 502,
    이름: "메이플 니샤다",
    종류: 15,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1462071,
  },
  {
    코드: 503,
    이름: "그로스야거",
    종류: 15,
    "필요 레벨": 70,
    필요STR: 70,
    필요DEX: 210,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 50,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1462135,
  },
  {
    코드: 504,
    이름: "메이플 래티넘 발리스타",
    종류: 15,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1462114,
  },
  {
    코드: 505,
    이름: "메이플 파이롭 크로우",
    종류: 15,
    "필요 레벨": 77,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 4,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 13,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 351530,
    imgCode: 1462085,
  },
  {
    코드: 506,
    이름: "다크 샬리트",
    종류: 15,
    "필요 레벨": 80,
    필요STR: 80,
    필요DEX: 240,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: 57,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1462037,
  },
  {
    코드: 507,
    이름: "레전드 메이플 발리스타",
    종류: 15,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1462136,
  },
  {
    코드: 508,
    이름: "카사 크로우",
    종류: 15,
    "필요 레벨": 90,
    필요STR: 80,
    필요DEX: 255,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1462180,
  },
  {
    코드: 509,
    이름: "화이트 네쉐르",
    종류: 15,
    "필요 레벨": 100,
    필요STR: 80,
    필요DEX: 270,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1462015,
  },
  {
    코드: 510,
    이름: "드래곤 샤인크로스",
    종류: 15,
    "필요 레벨": 110,
    필요STR: 80,
    필요DEX: 300,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1462039,
  },
  {
    코드: 511,
    이름: "저스티스 메이플 발리스타",
    종류: 15,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 512,
    이름: "블랙뷰티",
    종류: 15,
    "필요 레벨": 120,
    필요STR: 80,
    필요DEX: 330,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 513,
    이름: "반 레온 크로스보우",
    종류: 15,
    "필요 레벨": 130,
    필요STR: 80,
    필요DEX: 360,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 514,
    이름: "이포스 블러디 크로스보우",
    종류: 15,
    "필요 레벨": 140,
    필요STR: 80,
    필요DEX: 390,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1462100,
  },
  {
    코드: 515,
    이름: "팔콘윙 헤비크로스보우",
    종류: 15,
    "필요 레벨": 150,
    필요STR: 80,
    필요DEX: 420,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1462099,
  },
  {
    코드: 516,
    이름: "우트가르드 크로스보우",
    종류: 15,
    "필요 레벨": 160,
    필요STR: 80,
    필요DEX: 450,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 517,
    이름: "파프니르 윈드윙슈터",
    종류: 15,
    "필요 레벨": 170,
    필요STR: 80,
    필요DEX: 480,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 518,
    이름: "앱솔랩스 크로스보우",
    종류: 15,
    "필요 레벨": 180,
    필요STR: 80,
    필요DEX: 510,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 519,
    이름: "아케인셰이드 크로스보우",
    종류: 15,
    "필요 레벨": 190,
    필요STR: 80,
    필요DEX: 540,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 520,
    이름: "제네시스 크로스보우",
    종류: 15,
    "필요 레벨": 200,
    필요STR: 80,
    필요DEX: 570,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 5,
    공격력: "제보 바람",
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 521,
    이름: "사각 나무 방패",
    종류: 16,
    "필요 레벨": 5,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6,
    imgCode: 1092003,
  },
  {
    코드: 522,
    이름: "냄비 뚜껑",
    종류: 16,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1092008,
  },
  {
    코드: 523,
    이름: "메이플 실드",
    종류: 16,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1092030,
  },
  {
    코드: 524,
    이름: "코-크플레이 방패",
    종류: 16,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1092035,
  },
  {
    코드: 525,
    이름: "우드 버클러",
    종류: 16,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1092095,
  },
  {
    코드: 526,
    이름: "강철 방패",
    종류: 16,
    "필요 레벨": 15,
    필요STR: 40,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1092096,
  },
  {
    코드: 527,
    이름: "미스릴 버클러",
    종류: 16,
    "필요 레벨": 20,
    필요STR: 55,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1092097,
  },
  {
    코드: 528,
    이름: "빨간 삼각 방패",
    종류: 16,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1092098,
  },
  {
    코드: 529,
    이름: "레드 크로스 실드",
    종류: 16,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1092099,
  },
  {
    코드: 530,
    이름: "배틀 실드",
    종류: 16,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1092100,
  },
  {
    코드: 531,
    이름: "미스릴 타워 실드",
    종류: 16,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1092101,
  },
  {
    코드: 532,
    이름: "해골 방패",
    종류: 16,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1092102,
  },
  {
    코드: 533,
    이름: "아다만티움 레전드 실드",
    종류: 16,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1092103,
  },
  {
    코드: 534,
    이름: "메이플 워리어 실드",
    종류: 16,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1092046,
  },
  {
    코드: 535,
    이름: "골드 에이션트 실드",
    종류: 16,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1092104,
  },
  {
    코드: 536,
    이름: "실버 에이션트 실드",
    종류: 16,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1092016,
  },
  {
    코드: 537,
    이름: "골드 아퀼라 실드",
    종류: 16,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1092025,
  },
  {
    코드: 538,
    이름: "골드 칼칸",
    종류: 16,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1092028,
  },
  {
    코드: 539,
    이름: "브론즈 칼칸",
    종류: 16,
    "필요 레벨": 90,
    필요STR: 280,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1092026,
  },
  {
    코드: 540,
    이름: "블루 호플론",
    종류: 16,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1092038,
  },
  {
    코드: 541,
    이름: "그린 호플론",
    종류: 16,
    "필요 레벨": 100,
    필요STR: 310,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1092036,
  },
  {
    코드: 542,
    이름: "블루 드래곤 실드",
    종류: 16,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 0,
    STR: 7,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1092060,
  },
  {
    코드: 543,
    이름: "겔러해드 쉴드",
    종류: 16,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 0,
    STR: 7,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1092042,
  },
  {
    코드: 544,
    이름: "피어리스 카이트 실드",
    종류: 16,
    "필요 레벨": 130,
    필요STR: 420,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 20,
    이동속도: 0,
    STR: 10,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 1092093,
  },
  {
    코드: 545,
    이름: "데이모스 워리어 쉴드",
    종류: 16,
    "필요 레벨": 140,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 10,
    DEX: 10,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1092087,
  },
  {
    코드: 546,
    이름: "미스틱 실드",
    종류: 16,
    "필요 레벨": 22,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 68,
    필요LUK: 22,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 2342,
    imgCode: 1092105,
  },
  {
    코드: 547,
    이름: "에스터 실드",
    종류: 16,
    "필요 레벨": 33,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 100,
    필요LUK: 35,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 11859,
    imgCode: 1092106,
  },
  {
    코드: 548,
    이름: "메이플 매지션 실드",
    종류: 16,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 167772,
    imgCode: 1092045,
  },
  {
    코드: 549,
    이름: "프렐류드",
    종류: 16,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 368,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 20,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 10,
    LUK: 5,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 550,
    이름: "데이모스 세이지쉴드",
    종류: 16,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 420,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 10,
    LUK: 10,
    판매비용: 3841600,
    imgCode: 0,
  },
  {
    코드: 551,
    이름: "주르건 리스트",
    종류: 16,
    "필요 레벨": 22,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 2342,
    imgCode: 1092020,
  },
  {
    코드: 552,
    이름: "님블 리스트",
    종류: 16,
    "필요 레벨": 22,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2342,
    imgCode: 1092019,
  },
  {
    코드: 553,
    이름: "시클루전 리스트",
    종류: 16,
    "필요 레벨": 22,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 2342,
    imgCode: 1092107,
  },
  {
    코드: 554,
    이름: "메이플 시브즈 실드",
    종류: 16,
    "필요 레벨": 64,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 167772,
    imgCode: 1092047,
  },
  {
    코드: 555,
    이름: "피어리스 리스트",
    종류: 16,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 300,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 20,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 10,
    판매비용: 2856100,
    imgCode: 1092094,
  },
  {
    코드: 556,
    이름: "데이모스 다크니스 쉴드",
    종류: 16,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 420,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 10,
    INT: 0,
    LUK: 10,
    판매비용: 3841600,
    imgCode: 1092088,
  },
  {
    코드: 557,
    이름: "메이플 모자",
    종류: 17,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1002508,
  },
  {
    코드: 558,
    이름: "빨간색 머리띠",
    종류: 17,
    "필요 레벨": 5,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6,
    imgCode: 1002014,
  },
  {
    코드: 559,
    이름: "초록색 가죽 모자",
    종류: 17,
    "필요 레벨": 5,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 6,
    imgCode: 1002053,
  },
  {
    코드: 560,
    이름: "검정색 물개모자",
    종류: 17,
    "필요 레벨": 8,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 40,
    imgCode: 1002132,
  },
  {
    코드: 561,
    이름: "흰색 두건",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1002019,
  },
  {
    코드: 562,
    이름: "머리위에 떡 하나",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 4,
    필요DEX: 4,
    필요INT: 4,
    필요LUK: 4,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 100,
    imgCode: 1002798,
  },
  {
    코드: 563,
    이름: "코-크 모자",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 100,
    imgCode: 1002663,
  },
  {
    코드: 564,
    이름: "핑크빈 모자",
    종류: 17,
    "필요 레벨": 13,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 285,
    imgCode: 1003450,
  },
  {
    코드: 565,
    이름: "메탈 기어",
    종류: 17,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1002001,
  },
  {
    코드: 566,
    이름: "군밤장수 모자",
    종류: 17,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1002033,
  },
  {
    코드: 567,
    이름: "흰색 야구모자",
    종류: 17,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1002492,
  },
  {
    코드: 568,
    이름: "빨간색 별 두건",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1002020,
  },
  {
    코드: 569,
    이름: "파란색 삿갓",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 30,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1002090,
  },
  {
    코드: 570,
    이름: "갈색 삿갓",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 3906,
    imgCode: 1002026,
  },
  {
    코드: 571,
    이름: "초록색 삿갓",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1002089,
  },
  {
    코드: 572,
    이름: "머리 위에 떡 둘",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 3906,
    imgCode: 1003266,
  },
  {
    코드: 573,
    이름: "리본돼지 머리띠",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1002441,
  },
  {
    코드: 574,
    이름: "본헬름",
    종류: 17,
    "필요 레벨": 42,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 7,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 31116,
    imgCode: 1002006,
  },
  {
    코드: 575,
    이름: "자쿰의 투구",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 20,
    회피율: 20,
    이동속도: 0,
    STR: 15,
    DEX: 15,
    INT: 15,
    LUK: 15,
    판매비용: 62500,
    imgCode: 1002357,
  },
  {
    코드: 576,
    이름: "라바나 투구",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 10,
    회피율: 10,
    이동속도: 5,
    STR: 18,
    DEX: 18,
    INT: 18,
    LUK: 18,
    판매비용: 240100,
    imgCode: 1003068,
  },
  {
    코드: 577,
    이름: "브론즈 코이프",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 25,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1002043,
  },
  {
    코드: 578,
    이름: "브론즈 캡",
    종류: 17,
    "필요 레벨": 12,
    필요STR: 30,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 207,
    imgCode: 1002039,
  },
  {
    코드: 579,
    이름: "브론즈 풀 헬름",
    종류: 17,
    "필요 레벨": 15,
    필요STR: 45,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1002051,
  },
  {
    코드: 580,
    이름: "브론즈 바이킹 헬름",
    종류: 17,
    "필요 레벨": 20,
    필요STR: 50,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1002059,
  },
  {
    코드: 581,
    이름: "아이언 버거넷 헬름",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1002005,
  },
  {
    코드: 582,
    이름: "주스팅",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 65,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1003316,
  },
  {
    코드: 583,
    이름: "브라운 그레이트 헬멧",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1003321,
  },
  {
    코드: 584,
    이름: "블루 그레이트 헬멧",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1002048,
  },
  {
    코드: 585,
    이름: "레드 듀크",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1002025,
  },
  {
    코드: 586,
    이름: "블루 듀크",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1002093,
  },
  {
    코드: 587,
    이름: "다크 돔",
    종류: 17,
    "필요 레벨": 47,
    필요STR: 110,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 48796,
    imgCode: 1002091,
  },
  {
    코드: 588,
    이름: "실버 크루세이더 헬름",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1002028,
  },
  {
    코드: 589,
    이름: "낡은 미스릴 노르만 헬름",
    종류: 17,
    "필요 레벨": 55,
    필요STR: 130,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1002101,
  },
  {
    코드: 590,
    이름: "낡은 스틸 노르만 헬름",
    종류: 17,
    "필요 레벨": 55,
    필요STR: 130,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1002022,
  },
  {
    코드: 591,
    이름: "레드 오리엔트 헬멧",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1002029,
  },
  {
    코드: 592,
    이름: "블루 오리엔트 헬멧",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1003336,
  },
  {
    코드: 593,
    이름: "실버 플래닛",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1002030,
  },
  {
    코드: 594,
    이름: "미스릴 플래닛",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1002095,
  },
  {
    코드: 595,
    이름: "블루 드래곤 바부타",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 210,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1002339,
  },
  {
    코드: 596,
    이름: "레드 드래곤 바부타",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 210,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1002338,
  },
  {
    코드: 597,
    이름: "레드 그레이스 헬멧",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 240,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1002530,
  },
  {
    코드: 598,
    이름: "다크 그레이스 헬멧",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 240,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1002532,
  },
  {
    코드: 599,
    이름: "블루 그레이스 헬멧",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 240,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1002529,
  },
  {
    코드: 600,
    이름: "블루 니르첸 투구",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 270,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1002378,
  },
  {
    코드: 601,
    이름: "다크 니르첸 투구",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 270,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1002379,
  },
  {
    코드: 602,
    이름: "블루 드래곤 투구",
    종류: 17,
    "필요 레벨": 110,
    필요STR: 300,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1002551,
  },
  {
    코드: 603,
    이름: "휀넬",
    종류: 17,
    "필요 레벨": 120,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 7,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 604,
    이름: "반 레온 배틀헬름",
    종류: 17,
    "필요 레벨": 130,
    필요STR: 360,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 9,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 605,
    이름: "아가레스 블러디 기어",
    종류: 17,
    "필요 레벨": 140,
    필요STR: 390,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 11,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1003177,
  },
  {
    코드: 606,
    이름: "라이온하트 배틀헬름",
    종류: 17,
    "필요 레벨": 150,
    필요STR: 420,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 13,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1003172,
  },
  {
    코드: 607,
    이름: "펜살리르 배틀헬름",
    종류: 17,
    "필요 레벨": 160,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 15,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 608,
    이름: "하이네스 워리어헬름",
    종류: 17,
    "필요 레벨": 170,
    필요STR: 480,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 17,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 609,
    이름: "앱솔랩스 나이트헬름",
    종류: 17,
    "필요 레벨": 180,
    필요STR: 510,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 19,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 610,
    이름: "아케인셰이드 나이트햇",
    종류: 17,
    "필요 레벨": 190,
    필요STR: 540,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 21,
    DEX: 7,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 611,
    이름: "에테르넬 나이트헬름",
    종류: 17,
    "필요 레벨": 200,
    필요STR: 570,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 500,
    MP: 0,
    명중률: 15,
    회피율: 10,
    이동속도: 0,
    STR: 23,
    DEX: 8,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 612,
    이름: "갈색 낡은 고깔 모자",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 32,
    필요LUK: 13,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1002017,
  },
  {
    코드: 613,
    이름: "다크문 고깔모자",
    종류: 17,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 48,
    필요LUK: 18,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1003303,
  },
  {
    코드: 614,
    이름: "초록색 사제의 모자",
    종류: 17,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 62,
    필요LUK: 23,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1002016,
  },
  {
    코드: 615,
    이름: "그린 핀터",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 92,
    필요LUK: 33,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1003317,
  },
  {
    코드: 616,
    이름: "손오공 금 머리띠",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 92,
    필요LUK: 33,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 100,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1002013,
  },
  {
    코드: 617,
    이름: "그린 매티",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 108,
    필요LUK: 38,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1003322,
  },
  {
    코드: 618,
    이름: "브라운 매티",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 108,
    필요LUK: 38,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 1,
    판매비용: 15006,
    imgCode: 1002144,
  },
  {
    코드: 619,
    이름: "다크 매티",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 108,
    필요LUK: 38,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 2,
    판매비용: 15006,
    imgCode: 1002145,
  },
  {
    코드: 620,
    이름: "다크 길티언",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 128,
    필요LUK: 43,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1002154,
  },
  {
    코드: 621,
    이름: "화이트 길티언",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 128,
    필요LUK: 43,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 25600,
    imgCode: 1002155,
  },
  {
    코드: 622,
    이름: "다크 골든서클릿",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 158,
    필요LUK: 53,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1002218,
  },
  {
    코드: 623,
    이름: "오렌지 골든서클릿",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 158,
    필요LUK: 53,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1002217,
  },
  {
    코드: 624,
    이름: "다크 세라피스",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 188,
    필요LUK: 63,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1002246,
  },
  {
    코드: 625,
    이름: "화이트 세라피스",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 188,
    필요LUK: 63,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 4,
    판매비용: 129600,
    imgCode: 1002245,
  },
  {
    코드: 626,
    이름: "다크 키튼서클렛",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 218,
    필요LUK: 73,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1003342,
  },
  {
    코드: 627,
    이름: "블루 키튼서클렛",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 218,
    필요LUK: 73,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 1,
    판매비용: 240100,
    imgCode: 1002253,
  },
  {
    코드: 628,
    이름: "다크 크로스햇",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 248,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1002274,
  },
  {
    코드: 629,
    이름: "그린 크로스햇",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 248,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 3,
    판매비용: 409600,
    imgCode: 1002271,
  },
  {
    코드: 630,
    이름: "흑견랑건",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 278,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1002366,
  },
  {
    코드: 631,
    이름: "녹견랑건",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 278,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 3,
    판매비용: 656100,
    imgCode: 1002363,
  },
  {
    코드: 632,
    이름: "다크 바르햇",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 308,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1002401,
  },
  {
    코드: 633,
    이름: "그린 바르햇",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 308,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 4,
    판매비용: 1000000,
    imgCode: 1002398,
  },
  {
    코드: 634,
    이름: "골드 드래곤 크라운",
    종류: 17,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 338,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 5,
    판매비용: 1464100,
    imgCode: 1002773,
  },
  {
    코드: 635,
    이름: "코럴",
    종류: 17,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 368,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 350,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 6,
    LUK: 5,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 636,
    이름: "반 레온 메이지햇",
    종류: 17,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 398,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 9,
    LUK: 1,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 637,
    이름: "엘리고스 블러디 기어",
    종류: 17,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 428,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 11,
    LUK: 2,
    판매비용: 3841600,
    imgCode: 1003178,
  },
  {
    코드: 638,
    이름: "드래곤테일 메이지샐릿",
    종류: 17,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 458,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 13,
    LUK: 3,
    판매비용: 5062500,
    imgCode: 1003173,
  },
  {
    코드: 639,
    이름: "펜살리르 메이지샐릿",
    종류: 17,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 488,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 15,
    LUK: 4,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 640,
    이름: "하이네스 던위치햇",
    종류: 17,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 518,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 17,
    LUK: 5,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 641,
    이름: "앱솔랩스 메이지크라운",
    종류: 17,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 548,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 19,
    LUK: 6,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 642,
    이름: "아케인셰이드 메이지햇",
    종류: 17,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 578,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 21,
    LUK: 7,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 643,
    이름: "에테르넬 메이지햇",
    종류: 17,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 608,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 500,
    MP: 0,
    명중률: 15,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 23,
    LUK: 8,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 644,
    이름: "검정색 좀도둑 털모자",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1002125,
  },
  {
    코드: 645,
    이름: "블랙 시프 후드",
    종류: 17,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1003305,
  },
  {
    코드: 646,
    이름: "블랙 루즈캡",
    종류: 17,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 20,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1002130,
  },
  {
    코드: 647,
    이름: "다크 티베리안",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1002150,
  },
  {
    코드: 648,
    이름: "다크 가이즈",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 8100,
    imgCode: 1002175,
  },
  {
    코드: 649,
    이름: "블루 가이즈",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1002172,
  },
  {
    코드: 650,
    이름: "다크 버글러",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 15006,
    imgCode: 1003324,
  },
  {
    코드: 651,
    이름: "그린 버글러",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1002178,
  },
  {
    코드: 652,
    이름: "브라운 버글러",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1002179,
  },
  {
    코드: 653,
    이름: "다크 필퍼",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 25600,
    imgCode: 1003329,
  },
  {
    코드: 654,
    이름: "그린 필퍼",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1002183,
  },
  {
    코드: 655,
    이름: "브라운 필퍼",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1002184,
  },
  {
    코드: 656,
    이름: "황 흑일모자",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1002210,
  },
  {
    코드: 657,
    이름: "녹 흑일모자",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1002209,
  },
  {
    코드: 658,
    이름: "다크 하이드후드",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 4,
    판매비용: 129600,
    imgCode: 1002249,
  },
  {
    코드: 659,
    이름: "실버 하이드후드",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 129600,
    imgCode: 1002248,
  },
  {
    코드: 660,
    이름: "브론즈 하이드후드",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 129600,
    imgCode: 1003339,
  },
  {
    코드: 661,
    이름: "블러드 루크",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 240100,
    imgCode: 1003344,
  },
  {
    코드: 662,
    이름: "브라운 루크",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 240100,
    imgCode: 1002281,
  },
  {
    코드: 663,
    이름: "다크 피레타햇",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 3,
    판매비용: 409600,
    imgCode: 1002330,
  },
  {
    코드: 664,
    이름: "레드 피레타햇",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 409600,
    imgCode: 1002329,
  },
  {
    코드: 665,
    이름: "레드 쉐이드햇",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 3,
    판매비용: 656100,
    imgCode: 1002326,
  },
  {
    코드: 666,
    이름: "퍼플 쉐이드햇",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 656100,
    imgCode: 1002325,
  },
  {
    코드: 667,
    이름: "다크 카날 후드",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 4,
    판매비용: 1000000,
    imgCode: 1002383,
  },
  {
    코드: 668,
    이름: "레드 카날 후드",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 4,
    판매비용: 1000000,
    imgCode: 1002382,
  },
  {
    코드: 669,
    이름: "블랙 가리나 후드",
    종류: 17,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 280,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 5,
    판매비용: 1464100,
    imgCode: 1002550,
  },
  {
    코드: 670,
    이름: "차이브",
    종류: 17,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 300,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 5,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 671,
    이름: "반 레온 후드",
    종류: 17,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 330,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 9,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 672,
    이름: "핼파스 블러디 기어",
    종류: 17,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 360,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 11,
    판매비용: 3841600,
    imgCode: 1003180,
  },
  {
    코드: 673,
    이름: "레이븐혼 체이서햇",
    종류: 17,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 390,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 13,
    판매비용: 5062500,
    imgCode: 1003175,
  },
  {
    코드: 674,
    이름: "펜살리르 체이서햇",
    종류: 17,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 420,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 15,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 675,
    이름: "하이네스 어새신보닛",
    종류: 17,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 450,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 17,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 676,
    이름: "앱솔랩스 시프캡",
    종류: 17,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 480,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 19,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 677,
    이름: "아케인셰이드 시프햇",
    종류: 17,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 510,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 7,
    INT: 0,
    LUK: 21,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 678,
    이름: "에테르넬 시프반다나",
    종류: 17,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 540,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 500,
    MP: 0,
    명중률: 15,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 8,
    INT: 0,
    LUK: 23,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 679,
    이름: "초록색 고급 가죽 모자",
    종류: 17,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1003299,
  },
  {
    코드: 680,
    이름: "그린 페더햇",
    종류: 17,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1002114,
  },
  {
    코드: 681,
    이름: "그린 페더윙햇",
    종류: 17,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1003309,
  },
  {
    코드: 682,
    이름: "그린 헌트",
    종류: 17,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1003313,
  },
  {
    코드: 683,
    이름: "그린 네페르",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 30,
    필요DEX: 95,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1003318,
  },
  {
    코드: 684,
    이름: "브라운 네페르",
    종류: 17,
    "필요 레벨": 30,
    필요STR: 30,
    필요DEX: 95,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1002164,
  },
  {
    코드: 685,
    이름: "그린 폴레패더햇",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 35,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1002137,
  },
  {
    코드: 686,
    이름: "브라운 폴레패더햇",
    종류: 17,
    "필요 레벨": 35,
    필요STR: 35,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1002135,
  },
  {
    코드: 687,
    이름: "그린 크라페",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 40,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1003328,
  },
  {
    코드: 688,
    이름: "브라운 크라페",
    종류: 17,
    "필요 레벨": 40,
    필요STR: 40,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1002169,
  },
  {
    코드: 689,
    이름: "녹마로",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1002213,
  },
  {
    코드: 690,
    이름: "청마로",
    종류: 17,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1002211,
  },
  {
    코드: 691,
    이름: "흑궁우",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1002270,
  },
  {
    코드: 692,
    이름: "적궁우",
    종류: 17,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1002267,
  },
  {
    코드: 693,
    이름: "다크 웨어캡",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 220,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1003343,
  },
  {
    코드: 694,
    이름: "베이지 웨어캡",
    종류: 17,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 220,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1002287,
  },
  {
    코드: 695,
    이름: "다크 골드윙캡",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 250,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1002278,
  },
  {
    코드: 696,
    이름: "블루 골드윙캡",
    종류: 17,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 250,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1002275,
  },
  {
    코드: 697,
    이름: "다크 아데스햇",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 280,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1002405,
  },
  {
    코드: 698,
    이름: "레드 아데스햇",
    종류: 17,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 280,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 3,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1002402,
  },
  {
    코드: 699,
    이름: "그린 아르나햇",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 310,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1002408,
  },
  {
    코드: 700,
    이름: "레드 아르나햇",
    종류: 17,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 310,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1002406,
  },
  {
    코드: 701,
    이름: "레드 헌터",
    종류: 17,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 340,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1002547,
  },
  {
    코드: 702,
    이름: "라피드",
    종류: 17,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 370,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 703,
    이름: "반 레온 캡",
    종류: 17,
    "필요 레벨": 130,
    필요STR: 80,
    필요DEX: 400,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 9,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 704,
    이름: "이포스 블러디 기어",
    종류: 17,
    "필요 레벨": 140,
    필요STR: 80,
    필요DEX: 430,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 11,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1003179,
  },
  {
    코드: 705,
    이름: "팔콘윙 센티널캡",
    종류: 17,
    "필요 레벨": 150,
    필요STR: 80,
    필요DEX: 460,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 13,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1003174,
  },
  {
    코드: 706,
    이름: "펜살리르 센티널캡",
    종류: 17,
    "필요 레벨": 160,
    필요STR: 80,
    필요DEX: 490,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 15,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 707,
    이름: "하이네스 레인져베레",
    종류: 17,
    "필요 레벨": 170,
    필요STR: 80,
    필요DEX: 520,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 17,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 708,
    이름: "앱솔랩스 아처후드",
    종류: 17,
    "필요 레벨": 180,
    필요STR: 80,
    필요DEX: 550,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 19,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 709,
    이름: "아케인셰이드 아처햇",
    종류: 17,
    "필요 레벨": 190,
    필요STR: 80,
    필요DEX: 580,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 7,
    DEX: 21,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 710,
    이름: "에테르넬 아처햇",
    종류: 17,
    "필요 레벨": 200,
    필요STR: 80,
    필요DEX: 610,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 500,
    MP: 0,
    명중률: 15,
    회피율: 10,
    이동속도: 0,
    STR: 8,
    DEX: 23,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 711,
    이름: "노가다 목장갑",
    종류: 18,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1082002,
  },
  {
    코드: 712,
    이름: "설빙의 장갑",
    종류: 18,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 2,
    마력: 2,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1082276,
  },
  {
    코드: 713,
    이름: "메이플 가네샤",
    종류: 18,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 8100,
    imgCode: 1082441,
  },
  {
    코드: 714,
    이름: "기계 장갑",
    종류: 18,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1082222,
  },
  {
    코드: 715,
    이름: "무공의 장갑",
    종류: 18,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 5,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 10,
    DEX: 10,
    INT: 10,
    LUK: 10,
    판매비용: 2073600,
    imgCode: 1082393,
  },
  {
    코드: 716,
    이름: "영웅의 장갑",
    종류: 18,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 10,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 15,
    DEX: 15,
    INT: 15,
    LUK: 15,
    판매비용: 2073600,
    imgCode: 1082392,
  },
  {
    코드: 717,
    이름: "주노",
    종류: 18,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1082003,
  },
  {
    코드: 718,
    이름: "강철 반장갑",
    종류: 18,
    "필요 레벨": 15,
    필요STR: 40,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1082346,
  },
  {
    코드: 719,
    이름: "베논",
    종류: 18,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1082351,
  },
  {
    코드: 720,
    이름: "흰 무늬 반장갑",
    종류: 18,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1082356,
  },
  {
    코드: 721,
    이름: "오리할콘 미셀",
    종류: 18,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1082361,
  },
  {
    코드: 722,
    이름: "다크 브리건",
    종류: 18,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1082366,
  },
  {
    코드: 723,
    이름: "다크 너클",
    종류: 18,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1082025,
  },
  {
    코드: 724,
    이름: "골드 브리스트",
    종류: 18,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1082376,
  },
  {
    코드: 725,
    이름: "미스릴 브리스트",
    종류: 18,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1082010,
  },
  {
    코드: 726,
    이름: "다크 클랜치",
    종류: 18,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 2,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1082381,
  },
  {
    코드: 727,
    이름: "다크 허스크",
    종류: 18,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1082386,
  },
  {
    코드: 728,
    이름: "다크 엠페러",
    종류: 18,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 120,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1082117,
  },
  {
    코드: 729,
    이름: "블러드 엠페러",
    종류: 18,
    "필요 레벨": 80,
    필요STR: 270,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 120,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1082116,
  },
  {
    코드: 730,
    이름: "다크 페로치",
    종류: 18,
    "필요 레벨": 90,
    필요STR: 300,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1082130,
  },
  {
    코드: 731,
    이름: "다크 코르뱅",
    종류: 18,
    "필요 레벨": 100,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 180,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1082141,
  },
  {
    코드: 732,
    이름: "블루 드래곤 건틀렛",
    종류: 18,
    "필요 레벨": 110,
    필요STR: 360,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 220,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1082168,
  },
  {
    코드: 733,
    이름: "베르가못",
    종류: 18,
    "필요 레벨": 120,
    필요STR: 390,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 250,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 7,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 734,
    이름: "반 레온 핸드가드",
    종류: 18,
    "필요 레벨": 130,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 1,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 9,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 735,
    이름: "아가레스 블러디건틀렛",
    종류: 18,
    "필요 레벨": 140,
    필요STR: 420,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 2,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 9,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1082300,
  },
  {
    코드: 736,
    이름: "라이온하트 브레이서",
    종류: 18,
    "필요 레벨": 150,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 2,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 12,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 0,
  },
  {
    코드: 737,
    이름: "펜살리르 배틀글러브",
    종류: 18,
    "필요 레벨": 160,
    필요STR: 480,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 13,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 738,
    이름: "타일런트 히아데스 글러브",
    종류: 18,
    "필요 레벨": 170,
    필요STR: 500,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 15,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 739,
    이름: "앱솔랩스 나이트 글러브",
    종류: 18,
    "필요 레벨": 180,
    필요STR: 530,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 4,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 15,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 740,
    이름: "아케인셰이드 나이트글러브",
    종류: 18,
    "필요 레벨": 190,
    필요STR: 560,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 4,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 15,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 741,
    이름: "에테르넬 나이트글러브",
    종류: 18,
    "필요 레벨": 200,
    필요STR: 590,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 5,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 0,
    이동속도: 0,
    STR: 18,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 742,
    이름: "레모나",
    종류: 18,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 48,
    필요LUK: 18,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1082347,
  },
  {
    코드: 743,
    이름: "그린 모리칸",
    종류: 18,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 62,
    필요LUK: 23,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1082021,
  },
  {
    코드: 744,
    이름: "다크 메사나",
    종류: 18,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 78,
    필요LUK: 28,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1082028,
  },
  {
    코드: 745,
    이름: "블랙 루티아",
    종류: 18,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 92,
    필요LUK: 33,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1082362,
  },
  {
    코드: 746,
    이름: "다크 노엘",
    종류: 18,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 108,
    필요LUK: 38,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1082367,
  },
  {
    코드: 747,
    이름: "다크 아르텐",
    종류: 18,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 128,
    필요LUK: 43,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1082064,
  },
  {
    코드: 748,
    이름: "다크 페넌스",
    종류: 18,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 145,
    필요LUK: 50,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1082377,
  },
  {
    코드: 749,
    이름: "다크 마누트",
    종류: 18,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 188,
    필요LUK: 63,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 60,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 1,
    판매비용: 129600,
    imgCode: 1082382,
  },
  {
    코드: 750,
    이름: "다크 로린",
    종류: 18,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 218,
    필요LUK: 73,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 1,
    판매비용: 240100,
    imgCode: 1082387,
  },
  {
    코드: 751,
    이름: "다크 와이즈",
    종류: 18,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 248,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 2,
    판매비용: 409600,
    imgCode: 1082123,
  },
  {
    코드: 752,
    이름: "다크 파쵸네",
    종류: 18,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 278,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 2,
    판매비용: 656100,
    imgCode: 1082134,
  },
  {
    코드: 753,
    이름: "퍼플 시아르",
    종류: 18,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 308,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 4,
    판매비용: 1000000,
    imgCode: 1082154,
  },
  {
    코드: 754,
    이름: "블루 엘리멘탈 글로브",
    종류: 18,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 338,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 5,
    판매비용: 1464100,
    imgCode: 1082164,
  },
  {
    코드: 755,
    이름: "헤르모사",
    종류: 18,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 368,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 5,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 756,
    이름: "반 레온 매이지글로브",
    종류: 18,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 388,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 9,
    LUK: 5,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 757,
    이름: "엘리고스 블러디건틀렛",
    종류: 18,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 400,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 2,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 9,
    LUK: 6,
    판매비용: 3841600,
    imgCode: 1082301,
  },
  {
    코드: 758,
    이름: "드래곤테일 메이지피스트",
    종류: 18,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 430,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 2,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 12,
    LUK: 1,
    판매비용: 5062500,
    imgCode: 1082296,
  },
  {
    코드: 759,
    이름: "펜살리르 메이지글러브",
    종류: 18,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 460,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 3,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 13,
    LUK: 1,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 760,
    이름: "타일런트 헤르메스 글러브",
    종류: 18,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 490,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 3,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 15,
    LUK: 1,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 761,
    이름: "앱솔랩스 메이지글러브",
    종류: 18,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 520,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 4,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 15,
    LUK: 2,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 762,
    이름: "아케인셰이드 메이지글러브",
    종류: 18,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 550,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 4,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 15,
    LUK: 4,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 763,
    이름: "에테르넬 메이지글러브",
    종류: 18,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 580,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 5,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 18,
    LUK: 6,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 764,
    이름: "블랙 듀오",
    종류: 18,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1082349,
  },
  {
    코드: 765,
    이름: "다크 스틸라인",
    종류: 18,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1082354,
  },
  {
    코드: 766,
    이름: "다크 우드쉽",
    종류: 18,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 3906,
    imgCode: 1082039,
  },
  {
    코드: 767,
    이름: "골드 실비아",
    종류: 18,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 8100,
    imgCode: 1082364,
  },
  {
    코드: 768,
    이름: "골드 아르비욘",
    종류: 18,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 15006,
    imgCode: 1082369,
  },
  {
    코드: 769,
    이름: "다크 클리브",
    종류: 18,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 25600,
    imgCode: 1082074,
  },
  {
    코드: 770,
    이름: "황월 장갑",
    종류: 18,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1082066,
  },
  {
    코드: 771,
    이름: "골드 파우",
    종류: 18,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 129600,
    imgCode: 1082384,
  },
  {
    코드: 772,
    이름: "골드 와이어스",
    종류: 18,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 4,
    판매비용: 240100,
    imgCode: 1082389,
  },
  {
    코드: 773,
    이름: "블러드 로버",
    종류: 18,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 120,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 4,
    판매비용: 409600,
    imgCode: 1082120,
  },
  {
    코드: 774,
    이름: "다크 미스트라",
    종류: 18,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 656100,
    imgCode: 1082144,
  },
  {
    코드: 775,
    이름: "다크 아날린",
    종류: 18,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 180,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 5,
    판매비용: 1000000,
    imgCode: 1082138,
  },
  {
    코드: 776,
    이름: "블루 아날린",
    종류: 18,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 3,
    판매비용: 1000000,
    imgCode: 1082135,
  },
  {
    코드: 777,
    이름: "블랙 가리나 글로브",
    종류: 18,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 280,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 6,
    판매비용: 1464100,
    imgCode: 1082167,
  },
  {
    코드: 778,
    이름: "루바브",
    종류: 18,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 300,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 220,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 7,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 779,
    이름: "반 레온 루바브",
    종류: 18,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 330,
    직업: "도적",
    공격속도: 0,
    공격력: 1,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 9,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 780,
    이름: "핼파스 블러디건틀렛",
    종류: 18,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 360,
    직업: "도적",
    공격속도: 0,
    공격력: 2,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 9,
    판매비용: 3841600,
    imgCode: 1082303,
  },
  {
    코드: 781,
    이름: "레이븐혼 체이서글러브",
    종류: 18,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 390,
    직업: "도적",
    공격속도: 0,
    공격력: 2,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 12,
    판매비용: 5062500,
    imgCode: 1082298,
  },
  {
    코드: 782,
    이름: "펜살리르 체이서글러브",
    종류: 18,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 420,
    직업: "도적",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 13,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 783,
    이름: "타일런트 리카온 글러브",
    종류: 18,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 450,
    직업: "도적",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 15,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 784,
    이름: "앱솔랩스 시프글러브",
    종류: 18,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 480,
    직업: "도적",
    공격속도: 0,
    공격력: 4,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 15,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 785,
    이름: "아케인셰이드 시프글러브",
    종류: 18,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 510,
    직업: "도적",
    공격속도: 0,
    공격력: 4,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 15,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 786,
    이름: "에테르넬 시프글러브",
    종류: 18,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 540,
    직업: "도적",
    공격속도: 0,
    공격력: 5,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 18,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 787,
    이름: "아르",
    종류: 18,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1082348,
  },
  {
    코드: 788,
    이름: "그린 디로스",
    종류: 18,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1082353,
  },
  {
    코드: 789,
    이름: "다크 사바타",
    종류: 18,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 75,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1082018,
  },
  {
    코드: 790,
    이름: "블랙 마커",
    종류: 18,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 95,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1082363,
  },
  {
    코드: 791,
    이름: "골드 스케일러",
    종류: 18,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1082368,
  },
  {
    코드: 792,
    이름: "다크 브레이스",
    종류: 18,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1082073,
  },
  {
    코드: 793,
    이름: "다크 윌로우",
    종류: 18,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 165,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 50,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1082378,
  },
  {
    코드: 794,
    이름: "다크 가너",
    종류: 18,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 70,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1082383,
  },
  {
    코드: 795,
    이름: "다크 아이즈",
    종류: 18,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 215,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1082388,
  },
  {
    코드: 796,
    이름: "다크 코든",
    종류: 18,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 240,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1082112,
  },
  {
    코드: 797,
    이름: "다크 아데브",
    종류: 18,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 265,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1082127,
  },
  {
    코드: 798,
    이름: "그린 아르시나",
    종류: 18,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 290,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1082160,
  },
  {
    코드: 799,
    이름: "레드 헌터 글로브",
    종류: 18,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 315,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1082163,
  },
  {
    코드: 800,
    이름: "프레스토",
    종류: 18,
    "필요 레벨": 120,
    필요STR: 80,
    필요DEX: 330,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 250,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 801,
    이름: "반 레온 프레스토",
    종류: 18,
    "필요 레벨": 130,
    필요STR: 80,
    필요DEX: 350,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 1,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 9,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 802,
    이름: "이포스 블러디건틀렛",
    종류: 18,
    "필요 레벨": 140,
    필요STR: 80,
    필요DEX: 380,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 2,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 9,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1082302,
  },
  {
    코드: 803,
    이름: "팔콘윙 센티널글러브",
    종류: 18,
    "필요 레벨": 150,
    필요STR: 80,
    필요DEX: 410,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 2,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 12,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1082297,
  },
  {
    코드: 804,
    이름: "펜살리르 센티널글러브",
    종류: 18,
    "필요 레벨": 160,
    필요STR: 80,
    필요DEX: 440,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 5,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 13,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 805,
    이름: "타일런트 케이론 글러브",
    종류: 18,
    "필요 레벨": 170,
    필요STR: 80,
    필요DEX: 470,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 15,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 806,
    이름: "앱솔랩스 아처글러브",
    종류: 18,
    "필요 레벨": 180,
    필요STR: 80,
    필요DEX: 500,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 4,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 15,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 807,
    이름: "아케인셰이드 아처글러브",
    종류: 18,
    "필요 레벨": 190,
    필요STR: 80,
    필요DEX: 530,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 4,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 10,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 15,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 808,
    이름: "에테르넬 아처글러브",
    종류: 18,
    "필요 레벨": 200,
    필요STR: 80,
    필요DEX: 560,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 5,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 15,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 18,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 809,
    이름: "파란색 고무 장화",
    종류: 19,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1072038,
  },
  {
    코드: 810,
    이름: "검정색 고무신",
    종류: 19,
    "필요 레벨": 11,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 2,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 146,
    imgCode: 1072042,
  },
  {
    코드: 811,
    이름: "갈색 아로아 슈즈",
    종류: 19,
    "필요 레벨": 16,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 655,
    imgCode: 1072048,
  },
  {
    코드: 812,
    이름: "물컹물컹한 신발",
    종류: 19,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 3,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 1600,
    imgCode: 1072634,
  },
  {
    코드: 813,
    이름: "갈색 도로시 구두",
    종류: 19,
    "필요 레벨": 21,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1944,
    imgCode: 1072063,
  },
  {
    코드: 814,
    이름: "빨간색 가죽 구두",
    종류: 19,
    "필요 레벨": 26,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 3,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 4569,
    imgCode: 1072012,
  },
  {
    코드: 815,
    이름: "폰라의 슬리퍼",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 3,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 8100,
    imgCode: 1072275,
  },
  {
    코드: 816,
    이름: "설빙의 부츠",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 7,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072447,
  },
  {
    코드: 817,
    이름: "아이젠",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072171,
  },
  {
    코드: 818,
    이름: "파란색 캔버스화",
    종류: 19,
    "필요 레벨": 31,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 9235,
    imgCode: 1072018,
  },
  {
    코드: 819,
    이름: "메이플 베릴 슈즈",
    종류: 19,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 3,
    INT: 3,
    LUK: 3,
    판매비용: 41006,
    imgCode: 1072521,
  },
  {
    코드: 820,
    이름: "메이플 래티넘 슈즈",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 2,
    STR: 3,
    DEX: 3,
    INT: 3,
    LUK: 3,
    판매비용: 240100,
    imgCode: 1072522,
  },
  {
    코드: 821,
    이름: "메이플 이올렛 슈즈",
    종류: 19,
    "필요 레벨": 79,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 2,
    STR: 4,
    DEX: 4,
    INT: 4,
    LUK: 4,
    판매비용: 389500,
    imgCode: 1072660,
  },
  {
    코드: 822,
    이름: "레전드 메이플 슈즈",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 3,
    STR: 4,
    DEX: 4,
    INT: 4,
    LUK: 4,
    판매비용: 409600,
    imgCode: 1072610,
  },
  {
    코드: 823,
    이름: "브론즈 그리브",
    종류: 19,
    "필요 레벨": 15,
    필요STR: 50,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1072050,
  },
  {
    코드: 824,
    이름: "브라운 미들 래더",
    종류: 19,
    "필요 레벨": 20,
    필요STR: 50,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1072007,
  },
  {
    코드: 825,
    이름: "다크 워 부츠",
    종류: 19,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1072574,
  },
  {
    코드: 826,
    이름: "실버 배틀 그리브",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072579,
  },
  {
    코드: 827,
    이름: "다크 티거",
    종류: 19,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1072584,
  },
  {
    코드: 828,
    이름: "청 진월장화",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1072589,
  },
  {
    코드: 829,
    이름: "황 진월장화",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1072000,
  },
  {
    코드: 830,
    이름: "골드 힐던부츠",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072594,
  },
  {
    코드: 831,
    이름: "에메랄드 힐던부츠",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072132,
  },
  {
    코드: 832,
    이름: "블러드 카멜부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1072149,
  },
  {
    코드: 833,
    이름: "사파이어 카멜부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1072147,
  },
  {
    코드: 834,
    이름: "다크 카젠부츠",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1072604,
  },
  {
    코드: 835,
    이름: "블루 카젠부츠",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1072154,
  },
  {
    코드: 836,
    이름: "다크 리버스부츠",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1072212,
  },
  {
    코드: 837,
    이름: "레드 리버스부츠",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1072210,
  },
  {
    코드: 838,
    이름: "다크 워 그리브",
    종류: 19,
    "필요 레벨": 90,
    필요STR: 300,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1072198,
  },
  {
    코드: 839,
    이름: "다크 크리센트부츠",
    종류: 19,
    "필요 레벨": 100,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1072222,
  },
  {
    코드: 840,
    이름: "그린 크리센트부츠",
    종류: 19,
    "필요 레벨": 100,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1072220,
  },
  {
    코드: 841,
    이름: "블루 드래곤 부츠",
    종류: 19,
    "필요 레벨": 110,
    필요STR: 360,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1072273,
  },
  {
    코드: 842,
    이름: "그라베",
    종류: 19,
    "필요 레벨": 120,
    필요STR: 390,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 843,
    이름: "반 레온 워 부츠",
    종류: 19,
    "필요 레벨": 130,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 6,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 844,
    이름: "아가레스 블러디부츠",
    종류: 19,
    "필요 레벨": 140,
    필요STR: 420,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 6,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1072490,
  },
  {
    코드: 845,
    이름: "라이온하트 배틀부츠",
    종류: 19,
    "필요 레벨": 150,
    필요STR: 450,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 7,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1072485,
  },
  {
    코드: 846,
    이름: "펜살리르 배틀부츠",
    종류: 19,
    "필요 레벨": 160,
    필요STR: 480,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 8,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 847,
    이름: "타일런트 히아데스 부츠",
    종류: 19,
    "필요 레벨": 170,
    필요STR: 510,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 9,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 1072743,
  },
  {
    코드: 848,
    이름: "앱솔랩스 나이트슈즈",
    종류: 19,
    "필요 레벨": 180,
    필요STR: 540,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 12,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 849,
    이름: "아케인셰이드 나이트슈즈",
    종류: 19,
    "필요 레벨": 190,
    필요STR: 570,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 12,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 850,
    이름: "에테르넬 나이트슈즈",
    종류: 19,
    "필요 레벨": 200,
    필요STR: 600,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 15,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 851,
    이름: "갈색 코우치",
    종류: 19,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1072006,
  },
  {
    코드: 852,
    이름: "베이지 니티",
    종류: 19,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 48,
    필요LUK: 18,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1072023,
  },
  {
    코드: 853,
    이름: "블루 쥬얼리 슈즈",
    종류: 19,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 62,
    필요LUK: 23,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1072019,
  },
  {
    코드: 854,
    이름: "옐로우 윈드슈즈",
    종류: 19,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 78,
    필요LUK: 28,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1072073,
  },
  {
    코드: 855,
    이름: "블랙 매직슈즈",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 85,
    필요LUK: 30,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072580,
  },
  {
    코드: 856,
    이름: "블랙 솔트슈즈",
    종류: 19,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 108,
    필요LUK: 38,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 1,
    판매비용: 15006,
    imgCode: 1072091,
  },
  {
    코드: 857,
    이름: "다크 문슈즈",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 128,
    필요LUK: 43,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 1,
    판매비용: 25600,
    imgCode: 1072590,
  },
  {
    코드: 858,
    이름: "레드 문슈즈",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 128,
    필요LUK: 43,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 25600,
    imgCode: 1072114,
  },
  {
    코드: 859,
    이름: "그린 골드윈드슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 158,
    필요LUK: 53,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072143,
  },
  {
    코드: 860,
    이름: "핑크 골드윈드슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 158,
    필요LUK: 53,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1072140,
  },
  {
    코드: 861,
    이름: "블루 앵클부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 188,
    필요LUK: 63,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 1,
    판매비용: 129600,
    imgCode: 1072139,
  },
  {
    코드: 862,
    이름: "핑크 앵클부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 188,
    필요LUK: 63,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 3,
    판매비용: 129600,
    imgCode: 1072136,
  },
  {
    코드: 863,
    이름: "골드 라피스샌들",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 218,
    필요LUK: 73,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 1,
    판매비용: 240100,
    imgCode: 1072605,
  },
  {
    코드: 864,
    이름: "블루 라피스샌들",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 218,
    필요LUK: 73,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 3,
    판매비용: 240100,
    imgCode: 1072157,
  },
  {
    코드: 865,
    이름: "다크 크리시아슈즈",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 248,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 1,
    판매비용: 409600,
    imgCode: 1072179,
  },
  {
    코드: 866,
    이름: "그린 크리시아슈즈",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 248,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 3,
    판매비용: 409600,
    imgCode: 1072177,
  },
  {
    코드: 867,
    이름: "다크 넬리슈즈",
    종류: 19,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 278,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 1,
    판매비용: 656100,
    imgCode: 1072209,
  },
  {
    코드: 868,
    이름: "블루 바르슈즈",
    종류: 19,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 308,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 3,
    판매비용: 1000000,
    imgCode: 1072224,
  },
  {
    코드: 869,
    이름: "블루 엘리멘탈 슈즈",
    종류: 19,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 338,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 3,
    판매비용: 1464100,
    imgCode: 1072268,
  },
  {
    코드: 870,
    이름: "카바티나",
    종류: 19,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 368,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 1,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 4,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 871,
    이름: "반 레온 카바티나",
    종류: 19,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 400,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 6,
    LUK: 3,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 872,
    이름: "엘리고스 블러디부츠",
    종류: 19,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 420,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 6,
    LUK: 4,
    판매비용: 3841600,
    imgCode: 1072491,
  },
  {
    코드: 873,
    이름: "드래곤테일 메이지슈즈",
    종류: 19,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 450,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 7,
    LUK: 4,
    판매비용: 5062500,
    imgCode: 1072486,
  },
  {
    코드: 874,
    이름: "펜살리르 메이지부츠",
    종류: 19,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 480,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 8,
    LUK: 4,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 875,
    이름: "타일런트 헤르메스 부츠",
    종류: 19,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 510,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 9,
    LUK: 4,
    판매비용: 8352100,
    imgCode: 1072744,
  },
  {
    코드: 876,
    이름: "앱솔랩스 메이지슈즈",
    종류: 19,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 540,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 12,
    LUK: 4,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 877,
    이름: "아케인셰이드 메이지슈즈",
    종류: 19,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 570,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 12,
    LUK: 6,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 878,
    이름: "에테르넬 메이지슈즈",
    종류: 19,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 600,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 15,
    LUK: 6,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 879,
    이름: "브라운 기더슈즈",
    종류: 19,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1072562,
  },
  {
    코드: 880,
    이름: "흰색 닌자 샌들",
    종류: 19,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1072028,
  },
  {
    코드: 881,
    이름: "검은색 에나멜 부츠",
    종류: 19,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1072022,
  },
  {
    코드: 882,
    이름: "블랙 래피 부츠",
    종류: 19,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 3906,
    imgCode: 1072577,
  },
  {
    코드: 883,
    이름: "아이언 체인슈즈",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 8100,
    imgCode: 1072033,
  },
  {
    코드: 884,
    이름: "골드 체인슈즈",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072036,
  },
  {
    코드: 885,
    이름: "레드 라인부츠",
    종류: 19,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 15006,
    imgCode: 1072587,
  },
  {
    코드: 886,
    이름: "그린 라인부츠",
    종류: 19,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1072105,
  },
  {
    코드: 887,
    이름: "레드 하프슈즈",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 25600,
    imgCode: 1072592,
  },
  {
    코드: 888,
    이름: "그린 하프슈즈",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1072108,
  },
  {
    코드: 889,
    이름: "레드 고니슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1072597,
  },
  {
    코드: 890,
    이름: "블루 고니슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072128,
  },
  {
    코드: 891,
    이름: "그린 고니슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072129,
  },
  {
    코드: 892,
    이름: "다크 모스부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 129600,
    imgCode: 1072602,
  },
  {
    코드: 893,
    이름: "블러드 모스부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 129600,
    imgCode: 1072150,
  },
  {
    코드: 894,
    이름: "골드 모스부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1072151,
  },
  {
    코드: 895,
    이름: "레드 루티드슈즈",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 240100,
    imgCode: 1072607,
  },
  {
    코드: 896,
    이름: "퍼플 루티드슈즈",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 240100,
    imgCode: 1072161,
  },
  {
    코드: 897,
    이름: "블루 루티드슈즈",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1072162,
  },
  {
    코드: 898,
    이름: "다크 피레타부츠",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 3,
    판매비용: 409600,
    imgCode: 1072174,
  },
  {
    코드: 899,
    이름: "그린 피레타부츠",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 409600,
    imgCode: 1072172,
  },
  {
    코드: 900,
    이름: "레드 쉐도우부츠",
    종류: 19,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 3,
    판매비용: 656100,
    imgCode: 1072195,
  },
  {
    코드: 901,
    이름: "그린 쉐도우부츠",
    종류: 19,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 656100,
    imgCode: 1072192,
  },
  {
    코드: 902,
    이름: "다크 가티나부츠",
    종류: 19,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 4,
    판매비용: 1000000,
    imgCode: 1072216,
  },
  {
    코드: 903,
    이름: "그린 가티나부츠",
    종류: 19,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 1000000,
    imgCode: 1072213,
  },
  {
    코드: 904,
    이름: "블랙 가리나슈즈",
    종류: 19,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 280,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 4,
    판매비용: 1464100,
    imgCode: 1072272,
  },
  {
    코드: 905,
    이름: "문스티드",
    종류: 19,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 300,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 4,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 906,
    이름: "반 레온 문스티드",
    종류: 19,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 330,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 6,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 907,
    이름: "핼파스 블러디부츠",
    종류: 19,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 360,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 6,
    판매비용: 3841600,
    imgCode: 1072493,
  },
  {
    코드: 908,
    이름: "레이븐혼 체이서부츠",
    종류: 19,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 390,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 7,
    판매비용: 5062500,
    imgCode: 1072488,
  },
  {
    코드: 909,
    이름: "펜살리르 체이서부츠",
    종류: 19,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 420,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 8,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 910,
    이름: "타일런트 리카온 부츠",
    종류: 19,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 450,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 9,
    판매비용: 8352100,
    imgCode: 1072746,
  },
  {
    코드: 911,
    이름: "앱솔랩스 시프슈즈",
    종류: 19,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 480,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 12,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 912,
    이름: "아케인셰이드 시프슈즈",
    종류: 19,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 510,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 12,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 913,
    이름: "에테르넬 시프슈즈",
    종류: 19,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 540,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 15,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 914,
    이름: "브라운 하드래더 부츠",
    종류: 19,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1072561,
  },
  {
    코드: 915,
    이름: "그린 우드탑",
    종류: 19,
    "필요 레벨": 15,
    필요STR: 15,
    필요DEX: 35,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1072566,
  },
  {
    코드: 916,
    이름: "베어 헌터탑",
    종류: 19,
    "필요 레벨": 20,
    필요STR: 20,
    필요DEX: 45,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1072067,
  },
  {
    코드: 917,
    이름: "그린 잭부츠",
    종류: 19,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 55,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1072034,
  },
  {
    코드: 918,
    이름: "그린 헌터부츠",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 95,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072581,
  },
  {
    코드: 919,
    이름: "블루 헌터부츠",
    종류: 19,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 95,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1072080,
  },
  {
    코드: 920,
    이름: "그린 실키부츠",
    종류: 19,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1072102,
  },
  {
    코드: 921,
    이름: "블루 실키부츠",
    종류: 19,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1072101,
  },
  {
    코드: 922,
    이름: "블루 피에르슈즈",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1072591,
  },
  {
    코드: 923,
    이름: "레드 피에르슈즈",
    종류: 19,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1072118,
  },
  {
    코드: 924,
    이름: "퍼플 네일슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072596,
  },
  {
    코드: 925,
    이름: "브라운 네일슈즈",
    종류: 19,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1072122,
  },
  {
    코드: 926,
    이름: "그린 고어부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1072146,
  },
  {
    코드: 927,
    이름: "레드 고어부츠",
    종류: 19,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1072144,
  },
  {
    코드: 928,
    이름: "다크 리넥스슈즈",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 220,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1072606,
  },
  {
    코드: 929,
    이름: "블루 리넥스슈즈",
    종류: 19,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 220,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 3,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1072164,
  },
  {
    코드: 930,
    이름: "다크 윙부츠",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 250,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1072185,
  },
  {
    코드: 931,
    이름: "블루 윙부츠",
    종류: 19,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 250,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1072182,
  },
  {
    코드: 932,
    이름: "다크 아데스슈즈",
    종류: 19,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 280,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1072205,
  },
  {
    코드: 933,
    이름: "그린 아르나슈즈",
    종류: 19,
    "필요 레벨": 100,
    필요STR: 80,
    필요DEX: 310,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1072229,
  },
  {
    코드: 934,
    이름: "레드 헌터 슈즈",
    종류: 19,
    "필요 레벨": 110,
    필요STR: 80,
    필요DEX: 340,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 2,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1072269,
  },
  {
    코드: 935,
    이름: "론타노",
    종류: 19,
    "필요 레벨": 120,
    필요STR: 80,
    필요DEX: 370,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 3,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 936,
    이름: "반 레온 론타노",
    종류: 19,
    "필요 레벨": 130,
    필요STR: 80,
    필요DEX: 400,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 3,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 937,
    이름: "이포스 블러디부츠",
    종류: 19,
    "필요 레벨": 140,
    필요STR: 80,
    필요DEX: 430,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 5,
    STR: 4,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1072492,
  },
  {
    코드: 938,
    이름: "팔콘윙 센티널부츠",
    종류: 19,
    "필요 레벨": 150,
    필요STR: 80,
    필요DEX: 450,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 4,
    DEX: 7,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1072487,
  },
  {
    코드: 939,
    이름: "펜살리르 센티널부츠",
    종류: 19,
    "필요 레벨": 160,
    필요STR: 80,
    필요DEX: 480,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 5,
    STR: 4,
    DEX: 8,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 940,
    이름: "타일런트 케이론 부츠",
    종류: 19,
    "필요 레벨": 170,
    필요STR: 80,
    필요DEX: 500,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 4,
    DEX: 9,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 1072745,
  },
  {
    코드: 941,
    이름: "앱솔랩스 아처슈즈",
    종류: 19,
    "필요 레벨": 180,
    필요STR: 80,
    필요DEX: 530,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 5,
    이동속도: 10,
    STR: 4,
    DEX: 12,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 942,
    이름: "아케인셰이드 아처슈즈",
    종류: 19,
    "필요 레벨": 190,
    필요STR: 80,
    필요DEX: 560,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 6,
    DEX: 12,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 943,
    이름: "에테르넬 아처슈즈",
    종류: 19,
    "필요 레벨": 200,
    필요STR: 80,
    필요DEX: 590,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 10,
    STR: 6,
    DEX: 15,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 944,
    이름: "하얀 반팔 면티",
    종류: 20,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1040002,
  },
  {
    코드: 945,
    이름: "파란색 원라인 티셔츠",
    종류: 20,
    "필요 레벨": 11,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 146,
    imgCode: 1040013,
  },
  {
    코드: 946,
    이름: "브라운 로리카 아머",
    종류: 20,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1040015,
  },
  {
    코드: 947,
    이름: "브론즈 코퍼럴",
    종류: 20,
    "필요 레벨": 15,
    필요STR: 40,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1040037,
  },
  {
    코드: 948,
    이름: "블루 서전트",
    종류: 20,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1040166,
  },
  {
    코드: 949,
    이름: "레드 서전트",
    종류: 20,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1040039,
  },
  {
    코드: 950,
    이름: "다크 마스터 서전트",
    종류: 20,
    "필요 레벨": 25,
    필요STR: 770,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1040170,
  },
  {
    코드: 951,
    이름: "지천의",
    종류: 20,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1040026,
  },
  {
    코드: 952,
    이름: "창천의",
    종류: 20,
    "필요 레벨": 32,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10485,
    imgCode: 1040028,
  },
  {
    코드: 953,
    이름: "금룡의",
    종류: 20,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1040030,
  },
  {
    코드: 954,
    이름: "청룡의",
    종류: 20,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1040029,
  },
  {
    코드: 955,
    이름: "자진일갑주",
    종류: 20,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1040177,
  },
  {
    코드: 956,
    이름: "황진일갑주",
    종류: 20,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1040000,
  },
  {
    코드: 957,
    이름: "엄버 숄더메일",
    종류: 20,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1040180,
  },
  {
    코드: 958,
    이름: "오우커 숄더메일",
    종류: 20,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1040088,
  },
  {
    코드: 959,
    이름: "레드 오리엔타이칸",
    종류: 20,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1040182,
  },
  {
    코드: 960,
    이름: "블루 오리엔타이칸",
    종류: 20,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1040092,
  },
  {
    코드: 961,
    이름: "블러드 플라티나",
    종류: 20,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1041187,
  },
  {
    코드: 962,
    이름: "아쿠아 플라티나",
    종류: 20,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1041097,
  },
  {
    코드: 963,
    이름: "다크 제너럴",
    종류: 20,
    "필요 레벨": 90,
    필요STR: 300,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1040113,
  },
  {
    코드: 964,
    이름: "다크 엘소르",
    종류: 20,
    "필요 레벨": 100,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1041124,
  },
  {
    코드: 965,
    이름: "회갈색 수련복",
    종류: 20,
    "필요 레벨": 8,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 40,
    imgCode: 1040160,
  },
  {
    코드: 966,
    이름: "검은색 가람",
    종류: 20,
    "필요 레벨": 18,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 55,
    필요LUK: 20,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 1049,
    imgCode: 1041169,
  },
  {
    코드: 967,
    이름: "흑몽",
    종류: 20,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1041164,
  },
  {
    코드: 968,
    이름: "적야",
    종류: 20,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 506,
    imgCode: 1041167,
  },
  {
    코드: 969,
    이름: "청야",
    종류: 20,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1040035,
  },
  {
    코드: 970,
    이름: "검정색 파오",
    종류: 20,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 1600,
    imgCode: 1040169,
  },
  {
    코드: 971,
    이름: "빨간색 파오",
    종류: 20,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1040043,
  },
  {
    코드: 972,
    이름: "파란색 파오",
    종류: 20,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1040042,
  },
  {
    코드: 973,
    이름: "브라운 스니크",
    종류: 20,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 3906,
    imgCode: 1040172,
  },
  {
    코드: 974,
    이름: "블랙 스니크",
    종류: 20,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1040050,
  },
  {
    코드: 975,
    이름: "블루 스니크",
    종류: 20,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1040049,
  },
  {
    코드: 976,
    이름: "다크브라운 스틸러",
    종류: 20,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 8100,
    imgCode: 1040174,
  },
  {
    코드: 977,
    이름: "다크실버 스틸러",
    종류: 20,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1040058,
  },
  {
    코드: 978,
    이름: "그린 너클베스트",
    종류: 20,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 15006,
    imgCode: 1040176,
  },
  {
    코드: 979,
    이름: "블랙 너클베스트",
    종류: 20,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1040063,
  },
  {
    코드: 980,
    이름: "다크 쉐도우",
    종류: 20,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 25600,
    imgCode: 1041182,
  },
  {
    코드: 981,
    이름: "카키 쉐도우",
    종류: 20,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1040082,
  },
  {
    코드: 982,
    이름: "황일",
    종류: 20,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1040096,
  },
  {
    코드: 983,
    이름: "청일",
    종류: 20,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 62500,
    imgCode: 1040095,
  },
  {
    코드: 984,
    이름: "다크 스콜피오",
    종류: 20,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 4,
    판매비용: 129600,
    imgCode: 1040183,
  },
  {
    코드: 985,
    이름: "라이트 스콜피오",
    종류: 20,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 1,
    판매비용: 129600,
    imgCode: 1040098,
  },
  {
    코드: 986,
    이름: "다크 스타드",
    종류: 20,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 4,
    판매비용: 240100,
    imgCode: 1040185,
  },
  {
    코드: 987,
    이름: "브라운 스타드",
    종류: 20,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 80,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 2,
    판매비용: 240100,
    imgCode: 1040105,
  },
  {
    코드: 988,
    이름: "다크 피라테",
    종류: 20,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 5,
    판매비용: 409600,
    imgCode: 1040110,
  },
  {
    코드: 989,
    이름: "그린 피라테",
    종류: 20,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 1,
    판매비용: 409600,
    imgCode: 1040108,
  },
  {
    코드: 990,
    이름: "레드 미스트슈트",
    종류: 20,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 5,
    판매비용: 656100,
    imgCode: 1041118,
  },
  {
    코드: 991,
    이름: "그린 미스트슈트",
    종류: 20,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 656100,
    imgCode: 1041115,
  },
  {
    코드: 992,
    이름: "브라운 미스트슈트",
    종류: 20,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 2,
    판매비용: 656100,
    imgCode: 1041116,
  },
  {
    코드: 993,
    이름: "그린 아발란체",
    종류: 20,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1040161,
  },
  {
    코드: 994,
    이름: "초록색 래더 후드웨어",
    종류: 20,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1040164,
  },
  {
    코드: 995,
    이름: "은색 래더 후드웨어",
    종류: 20,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1040011,
  },
  {
    코드: 996,
    이름: "갈색 하드래더",
    종류: 20,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 65,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1040168,
  },
  {
    코드: 997,
    이름: "그린 이너스 체인메일",
    종류: 20,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1041173,
  },
  {
    코드: 998,
    이름: "블랙 이너스 체인메일",
    종류: 20,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1040023,
  },
  {
    코드: 999,
    이름: "그린 헌터스 아머",
    종류: 20,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1040067,
  },
  {
    코드: 1000,
    이름: "그린 레골리스",
    종류: 20,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1041067,
  },
  {
    코드: 1001,
    이름: "브라운 레골리스",
    종류: 20,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1041069,
  },
  {
    코드: 1002,
    이름: "브라운 피에뜨",
    종류: 20,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1040178,
  },
  {
    코드: 1003,
    이름: "브라운 피에르타",
    종류: 20,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1041082,
  },
  {
    코드: 1004,
    이름: "파란 청 반바지",
    종류: 21,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1060002,
  },
  {
    코드: 1005,
    이름: "회색 고무줄 바지",
    종류: 21,
    "필요 레벨": 11,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 146,
    imgCode: 1060004,
  },
  {
    코드: 1006,
    이름: "아이스 진",
    종류: 21,
    "필요 레벨": 16,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 15,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 655,
    imgCode: 1062000,
  },
  {
    코드: 1007,
    이름: "브라운 로리카 바지",
    종류: 21,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1060008,
  },
  {
    코드: 1008,
    이름: "브라운 코퍼럴 바지",
    종류: 21,
    "필요 레벨": 15,
    필요STR: 40,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 20,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1060027,
  },
  {
    코드: 1009,
    이름: "레드 서전트 치마",
    종류: 21,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1060029,
  },
  {
    코드: 1010,
    이름: "다크 마스터 서전트 치마",
    종류: 21,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1060030,
  },
  {
    코드: 1011,
    이름: "실버 마스터 서전트 치마",
    종류: 21,
    "필요 레벨": 25,
    필요STR: 70,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1060060,
  },
  {
    코드: 1012,
    이름: "흑무 바지",
    종류: 21,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1060016,
  },
  {
    코드: 1013,
    이름: "백무 바지",
    종류: 21,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1060017,
  },
  {
    코드: 1014,
    이름: "반 백무 바지",
    종류: 21,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1060020,
  },
  {
    코드: 1015,
    이름: "흑진일갑주 바지",
    종류: 21,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1060165,
  },
  {
    코드: 1016,
    이름: "황진일갑주 바지",
    종류: 21,
    "필요 레벨": 40,
    필요STR: 100,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1060075,
  },
  {
    코드: 1017,
    이름: "다크 숄더메일 바지",
    종류: 21,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1061192,
  },
  {
    코드: 1018,
    이름: "오우커 숄더메일 바지",
    종류: 21,
    "필요 레벨": 50,
    필요STR: 120,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1060077,
  },
  {
    코드: 1019,
    이름: "다크 오리엔타이칸 바지",
    종류: 21,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1060170,
  },
  {
    코드: 1020,
    이름: "그린 오리엔타이칸 바지",
    종류: 21,
    "필요 레벨": 60,
    필요STR: 140,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1060079,
  },
  {
    코드: 1021,
    이름: "오리할콘 플라틴 바지",
    종류: 21,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 2,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1060172,
  },
  {
    코드: 1022,
    이름: "브론즈 플라틴 바지",
    종류: 21,
    "필요 레벨": 70,
    필요STR: 160,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 2,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1060090,
  },
  {
    코드: 1023,
    이름: "다크 제너럴 바지",
    종류: 21,
    "필요 레벨": 90,
    필요STR: 300,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 2,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1060102,
  },
  {
    코드: 1024,
    이름: "그린 제너럴 바지",
    종류: 21,
    "필요 레벨": 90,
    필요STR: 300,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 2,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1060100,
  },
  {
    코드: 1025,
    이름: "블랙 네오스 바지",
    종류: 21,
    "필요 레벨": 100,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 3,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1060111,
  },
  {
    코드: 1026,
    이름: "그린 네오스 바지",
    종류: 21,
    "필요 레벨": 100,
    필요STR: 330,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 3,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1060109,
  },
  {
    코드: 1027,
    이름: "회갈색 수련복 바지",
    종류: 21,
    "필요 레벨": 8,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 40,
    imgCode: 1060150,
  },
  {
    코드: 1028,
    이름: "흰색 가람 바지",
    종류: 21,
    "필요 레벨": 18,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 55,
    필요LUK: 20,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 15,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1049,
    imgCode: 1060156,
  },
  {
    코드: 1029,
    이름: "흑몽 바지",
    종류: 21,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1061174,
  },
  {
    코드: 1030,
    이름: "청야 바지",
    종류: 21,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 30,
    필요INT: 0,
    필요LUK: 35,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 15,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1060025,
  },
  {
    코드: 1031,
    이름: "검정색 파오 바지",
    종류: 21,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 50,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 20,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1060033,
  },
  {
    코드: 1032,
    이름: "다크 스니클리스 바지",
    종류: 21,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 3906,
    imgCode: 1061183,
  },
  {
    코드: 1033,
    이름: "블랙 스니크 바지",
    종류: 21,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1060039,
  },
  {
    코드: 1034,
    이름: "브라운 스니크 바지",
    종류: 21,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 50,
    필요INT: 0,
    필요LUK: 65,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1060038,
  },
  {
    코드: 1035,
    이름: "블랙 스틸레스 바지",
    종류: 21,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 8100,
    imgCode: 1061186,
  },
  {
    코드: 1036,
    이름: "블루 스틸레스 바지",
    종류: 21,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1061045,
  },
  {
    코드: 1037,
    이름: "퍼플 스틸레스 바지",
    종류: 21,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 60,
    필요INT: 0,
    필요LUK: 80,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1061046,
  },
  {
    코드: 1038,
    이름: "블루 너클베스트 바지",
    종류: 21,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 25,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 15006,
    imgCode: 1060050,
  },
  {
    코드: 1039,
    이름: "레드 너클베스트 바지",
    종류: 21,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 70,
    필요INT: 0,
    필요LUK: 90,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 25,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1060051,
  },
  {
    코드: 1040,
    이름: "다크 쉐도우 바지",
    종류: 21,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 25600,
    imgCode: 1061191,
  },
  {
    코드: 1041,
    이름: "카키 쉐도우 바지",
    종류: 21,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 80,
    필요INT: 0,
    필요LUK: 100,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1060071,
  },
  {
    코드: 1042,
    이름: "청일 바지",
    종류: 21,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 120,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 1,
    판매비용: 62500,
    imgCode: 1060084,
  },
  {
    코드: 1043,
    이름: "다크 스콜피오 바지",
    종류: 21,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 129600,
    imgCode: 1060171,
  },
  {
    코드: 1044,
    이름: "라이트 스콜피오 바지",
    종류: 21,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 140,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1060087,
  },
  {
    코드: 1045,
    이름: "다크 스타드 바지",
    종류: 21,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 2,
    판매비용: 240100,
    imgCode: 1060173,
  },
  {
    코드: 1046,
    이름: "브라운 스타드 바지",
    종류: 21,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 110,
    필요INT: 0,
    필요LUK: 160,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1060093,
  },
  {
    코드: 1047,
    이름: "다크 피라테 바지",
    종류: 21,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 3,
    판매비용: 409600,
    imgCode: 1060099,
  },
  {
    코드: 1048,
    이름: "그린 피라테 바지",
    종류: 21,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 220,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 1,
    판매비용: 409600,
    imgCode: 1060097,
  },
  {
    코드: 1049,
    이름: "레드 쉐이드슈트 바지",
    종류: 21,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 2,
    INT: 0,
    LUK: 3,
    판매비용: 656100,
    imgCode: 1060107,
  },
  {
    코드: 1050,
    이름: "브라운 쉐이드슈트 바지",
    종류: 21,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 240,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 2,
    판매비용: 656100,
    imgCode: 1060105,
  },
  {
    코드: 1051,
    이름: "아발란체 바지",
    종류: 21,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1062004,
  },
  {
    코드: 1052,
    이름: "그린 아벨 가죽치마",
    종류: 21,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 40,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 15,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1061006,
  },
  {
    코드: 1053,
    이름: "갈색 하드래더 바지",
    종류: 21,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 65,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1062002,
  },
  {
    코드: 1054,
    이름: "이너스 체인바지",
    종류: 21,
    "필요 레벨": 25,
    필요STR: 25,
    필요DEX: 90,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1062146,
  },
  {
    코드: 1055,
    이름: "그린 헌터스 아머 바지",
    종류: 21,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 100,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1060161,
  },
  {
    코드: 1056,
    이름: "다크 레골러 바지",
    종류: 21,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 25,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1060064,
  },
  {
    코드: 1057,
    이름: "브라운 레골러 바지",
    종류: 21,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 115,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 25,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1060065,
  },
  {
    코드: 1058,
    이름: "브라운 피에뜨 바지",
    종류: 21,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 35,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1060069,
  },
  {
    코드: 1059,
    이름: "블루 피에뜨 바지",
    종류: 21,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 130,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 35,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1060070,
  },
  {
    코드: 1060,
    이름: "핑크빈 슈트",
    종류: 22,
    "필요 레벨": 13,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 285,
    imgCode: 1052434,
  },
  {
    코드: 1061,
    이름: "파란색 가운",
    종류: 22,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1050018,
  },
  {
    코드: 1062,
    이름: "메이플 베릴 리센느",
    종류: 22,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 4,
    INT: 4,
    LUK: 4,
    판매비용: 41006,
    imgCode: 1052357,
  },
  {
    코드: 1063,
    이름: "메이플 래티넘 리센느",
    종류: 22,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 5,
    INT: 5,
    LUK: 5,
    판매비용: 240100,
    imgCode: 1052358,
  },
  {
    코드: 1064,
    이름: "메이플 이올렛 수트",
    종류: 22,
    "필요 레벨": 79,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 350,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 6,
    INT: 6,
    LUK: 6,
    판매비용: 389500,
    imgCode: 1052457,
  },
  {
    코드: 1065,
    이름: "레전드 메이플 리센느",
    종류: 22,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 6,
    INT: 6,
    LUK: 6,
    판매비용: 409600,
    imgCode: 1052405,
  },
  {
    코드: 1066,
    이름: "파란색 검도복",
    종류: 22,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1050005,
  },
  {
    코드: 1067,
    이름: "흰색 검도복",
    종류: 22,
    "필요 레벨": 20,
    필요STR: 60,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 30,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1050007,
  },
  {
    코드: 1068,
    이름: "흑룡포",
    종류: 22,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1050196,
  },
  {
    코드: 1069,
    이름: "다크 잉그리트",
    종류: 22,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1051241,
  },
  {
    코드: 1070,
    이름: "옐로우 잉그리트",
    종류: 22,
    "필요 레벨": 30,
    필요STR: 80,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1051013,
  },
  {
    코드: 1071,
    이름: "다크 크로스 체인메일",
    종류: 22,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1050022,
  },
  {
    코드: 1072,
    이름: "블루 크로스 체인메일",
    종류: 22,
    "필요 레벨": 35,
    필요STR: 90,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1050021,
  },
  {
    코드: 1073,
    이름: "다크 배틀로드",
    종류: 22,
    "필요 레벨": 80,
    필요STR: 250,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 1,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1050083,
  },
  {
    코드: 1074,
    이름: "블루 드래곤 아머",
    종류: 22,
    "필요 레벨": 110,
    필요STR: 340,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 3,
    회피율: 0,
    이동속도: 0,
    STR: 8,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1052075,
  },
  {
    코드: 1075,
    이름: "타라곤",
    종류: 22,
    "필요 레벨": 120,
    필요STR: 370,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 3,
    회피율: 0,
    이동속도: 0,
    STR: 8,
    DEX: 7,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 1076,
    이름: "반 레온 타라곤",
    종류: 22,
    "필요 레벨": 130,
    필요STR: 400,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 600,
    MP: 0,
    명중률: 2,
    회피율: 2,
    이동속도: 0,
    STR: 12,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 1077,
    이름: "아가레스 블러디 메일",
    종류: 22,
    "필요 레벨": 140,
    필요STR: 430,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 700,
    MP: 0,
    명중률: 3,
    회피율: 3,
    이동속도: 0,
    STR: 14,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1052319,
  },
  {
    코드: 1078,
    이름: "라이온하트 배틀메일",
    종류: 22,
    "필요 레벨": 150,
    필요STR: 460,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 800,
    MP: 0,
    명중률: 4,
    회피율: 4,
    이동속도: 0,
    STR: 17,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1052314,
  },
  {
    코드: 1079,
    이름: "펜살리르 배틀메밀",
    종류: 22,
    "필요 레벨": 160,
    필요STR: 490,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 900,
    MP: 0,
    명중률: 5,
    회피율: 5,
    이동속도: 0,
    STR: 19,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 1080,
    이름: "이글아이 워리어아머",
    종류: 22,
    "필요 레벨": 170,
    필요STR: 520,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1000,
    MP: 0,
    명중률: 6,
    회피율: 6,
    이동속도: 0,
    STR: 22,
    DEX: 8,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 1081,
    이름: "앱솔랩스 나이트슈트",
    종류: 22,
    "필요 레벨": 180,
    필요STR: 550,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1100,
    MP: 0,
    명중률: 7,
    회피율: 7,
    이동속도: 0,
    STR: 24,
    DEX: 9,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 1082,
    이름: "아케인셰이드 나이트슈트",
    종류: 22,
    "필요 레벨": 190,
    필요STR: 580,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1200,
    MP: 0,
    명중률: 8,
    회피율: 8,
    이동속도: 0,
    STR: 26,
    DEX: 10,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 1083,
    이름: "에테르넬 나이트슈트",
    종류: 22,
    "필요 레벨": 200,
    필요STR: 600,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "전사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1300,
    MP: 0,
    명중률: 10,
    회피율: 10,
    이동속도: 0,
    STR: 30,
    DEX: 15,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 1084,
    이름: "베이지 플레로브",
    종류: 22,
    "필요 레벨": 13,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 40,
    필요LUK: 15,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 25,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 0,
    판매비용: 285,
    imgCode: 1050194,
  },
  {
    코드: 1085,
    이름: "화이트 도로스 로브",
    종류: 22,
    "필요 레벨": 23,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 70,
    필요LUK: 25,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 2798,
    imgCode: 1050025,
  },
  {
    코드: 1086,
    이름: "블루 도로스 로브",
    종류: 22,
    "필요 레벨": 23,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 70,
    필요LUK: 25,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 3,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2798,
    imgCode: 1050023,
  },
  {
    코드: 1087,
    이름: "검은색 사제의 로브",
    종류: 22,
    "필요 레벨": 28,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 85,
    필요LUK: 30,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 0,
    판매비용: 6146,
    imgCode: 1050197,
  },
  {
    코드: 1088,
    이름: "다크 데빌즈 로브",
    종류: 22,
    "필요 레벨": 33,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 100,
    필요LUK: 35,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 0,
    판매비용: 11859,
    imgCode: 1050029,
  },
  {
    코드: 1089,
    이름: "화이트 데빌즈 로브",
    종류: 22,
    "필요 레벨": 33,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 100,
    필요LUK: 35,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 40,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 4,
    판매비용: 11859,
    imgCode: 1050031,
  },
  {
    코드: 1090,
    이름: "다크 스타라이트",
    종류: 22,
    "필요 레벨": 38,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 115,
    필요LUK: 40,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 1,
    판매비용: 20851,
    imgCode: 1050200,
  },
  {
    코드: 1091,
    이름: "블루 스타라이트",
    종류: 22,
    "필요 레벨": 38,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 115,
    필요LUK: 40,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 4,
    판매비용: 20851,
    imgCode: 1050038,
  },
  {
    코드: 1092,
    이름: "다크 칼라스",
    종류: 22,
    "필요 레벨": 48,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 145,
    필요LUK: 50,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 4,
    LUK: 1,
    판매비용: 53084,
    imgCode: 1050049,
  },
  {
    코드: 1093,
    이름: "블루 칼라스",
    종류: 22,
    "필요 레벨": 48,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 145,
    필요LUK: 50,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 4,
    판매비용: 53084,
    imgCode: 1050045,
  },
  {
    코드: 1094,
    이름: "다크 아나카문",
    종류: 22,
    "필요 레벨": 58,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 175,
    필요LUK: 60,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 1,
    판매비용: 113164,
    imgCode: 1050056,
  },
  {
    코드: 1095,
    이름: "블루 아나카문",
    종류: 22,
    "필요 레벨": 58,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 175,
    필요LUK: 60,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 1,
    LUK: 5,
    판매비용: 113164,
    imgCode: 1050053,
  },
  {
    코드: 1096,
    이름: "다크 레퀴엠",
    종류: 22,
    "필요 레벨": 68,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 205,
    필요LUK: 70,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 5,
    LUK: 2,
    판매비용: 213813,
    imgCode: 1050205,
  },
  {
    코드: 1097,
    이름: "블루 레퀴엠",
    종류: 22,
    "필요 레벨": 68,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 205,
    필요LUK: 70,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 5,
    판매비용: 213813,
    imgCode: 1050067,
  },
  {
    코드: 1098,
    이름: "블루 크리시스",
    종류: 22,
    "필요 레벨": 78,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 235,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 250,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 6,
    LUK: 2,
    판매비용: 370150,
    imgCode: 1050073,
  },
  {
    코드: 1099,
    이름: "그린 크리시스",
    종류: 22,
    "필요 레벨": 78,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 235,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 250,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 2,
    LUK: 6,
    판매비용: 370150,
    imgCode: 1050072,
  },
  {
    코드: 1100,
    이름: "흑견랑포",
    종류: 22,
    "필요 레벨": 88,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 265,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 6,
    LUK: 3,
    판매비용: 599695,
    imgCode: 1051097,
  },
  {
    코드: 1101,
    이름: "녹견랑포",
    종류: 22,
    "필요 레벨": 88,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 265,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 300,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 6,
    판매비용: 599695,
    imgCode: 1051094,
  },
  {
    코드: 1102,
    이름: "다크 바루나",
    종류: 22,
    "필요 레벨": 98,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 295,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 7,
    LUK: 3,
    판매비용: 922368,
    imgCode: 1050105,
  },
  {
    코드: 1103,
    이름: "레드 바루나",
    종류: 22,
    "필요 레벨": 98,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 295,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 400,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 3,
    LUK: 7,
    판매비용: 922368,
    imgCode: 1050104,
  },
  {
    코드: 1104,
    이름: "블랙 로타네브",
    종류: 22,
    "필요 레벨": 108,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 325,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 500,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 8,
    LUK: 4,
    판매비용: 1360488,
    imgCode: 1052076,
  },
  {
    코드: 1105,
    이름: "미스트 블루",
    종류: 22,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 355,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 550,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 8,
    LUK: 4,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 1106,
    이름: "반 레온 미스트 블루",
    종류: 22,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 400,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 600,
    MP: 0,
    명중률: 2,
    회피율: 2,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 12,
    LUK: 3,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 1107,
    이름: "엘리고스 블러디 메일",
    종류: 22,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 430,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 700,
    MP: 0,
    명중률: 3,
    회피율: 3,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 14,
    LUK: 3,
    판매비용: 3841600,
    imgCode: 1052320,
  },
  {
    코드: 1108,
    이름: "드래곤테일 메이지로브",
    종류: 22,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 460,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 800,
    MP: 0,
    명중률: 4,
    회피율: 4,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 17,
    LUK: 5,
    판매비용: 5062500,
    imgCode: 1052315,
  },
  {
    코드: 1109,
    이름: "펜살리르 메이지로브",
    종류: 22,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 490,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 900,
    MP: 0,
    명중률: 5,
    회피율: 5,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 19,
    LUK: 6,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 1110,
    이름: "이글아이 던위치로브",
    종류: 22,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 520,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1000,
    MP: 0,
    명중률: 6,
    회피율: 6,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 22,
    LUK: 8,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 1111,
    이름: "앱솔랩스 메이지슈트",
    종류: 22,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 550,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1100,
    MP: 0,
    명중률: 7,
    회피율: 7,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 24,
    LUK: 9,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 1112,
    이름: "아케인셰이드 메이지슈트",
    종류: 22,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 580,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1200,
    MP: 0,
    명중률: 8,
    회피율: 8,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 26,
    LUK: 10,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 1113,
    이름: "에테르넬 메이지슈트",
    종류: 22,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 600,
    필요LUK: 80,
    직업: "마법사",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1300,
    MP: 0,
    명중률: 10,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 30,
    LUK: 15,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 1114,
    이름: "다크 카티나스",
    종류: 22,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 7,
    판매비용: 1000000,
    imgCode: 1050099,
  },
  {
    코드: 1115,
    이름: "그린 카티나스",
    종류: 22,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 260,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 7,
    DEX: 0,
    INT: 0,
    LUK: 3,
    판매비용: 1000000,
    imgCode: 1050096,
  },
  {
    코드: 1116,
    이름: "블랙 가리나",
    종류: 22,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 280,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 8,
    판매비용: 1464100,
    imgCode: 1052072,
  },
  {
    코드: 1117,
    이름: "프린지드",
    종류: 22,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 300,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 4,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 8,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 1118,
    이름: "반 레온 프린지드",
    종류: 22,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 400,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 600,
    MP: 0,
    명중률: 2,
    회피율: 2,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 12,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 1119,
    이름: "핼파스 블러디 메일",
    종류: 22,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 430,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 700,
    MP: 0,
    명중률: 3,
    회피율: 3,
    이동속도: 0,
    STR: 0,
    DEX: 3,
    INT: 0,
    LUK: 14,
    판매비용: 3841600,
    imgCode: 1052322,
  },
  {
    코드: 1120,
    이름: "레이븐혼 체이서아머",
    종류: 22,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 460,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 800,
    MP: 0,
    명중률: 4,
    회피율: 4,
    이동속도: 0,
    STR: 0,
    DEX: 5,
    INT: 0,
    LUK: 17,
    판매비용: 5062500,
    imgCode: 1052317,
  },
  {
    코드: 1121,
    이름: "펜살리르 체이서아머",
    종류: 22,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 490,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 900,
    MP: 0,
    명중률: 5,
    회피율: 5,
    이동속도: 0,
    STR: 0,
    DEX: 6,
    INT: 0,
    LUK: 19,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 1122,
    이름: "이글아이 어쌔신셔츠",
    종류: 22,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 520,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1000,
    MP: 0,
    명중률: 6,
    회피율: 6,
    이동속도: 0,
    STR: 0,
    DEX: 8,
    INT: 0,
    LUK: 22,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 1123,
    이름: "앱솔랩스 시프슈트",
    종류: 22,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 550,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1100,
    MP: 0,
    명중률: 7,
    회피율: 7,
    이동속도: 0,
    STR: 0,
    DEX: 9,
    INT: 0,
    LUK: 24,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 1124,
    이름: "아케인셰이드 시프슈트",
    종류: 22,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 580,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1200,
    MP: 0,
    명중률: 8,
    회피율: 8,
    이동속도: 0,
    STR: 0,
    DEX: 10,
    INT: 0,
    LUK: 26,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 1125,
    이름: "에테르넬 시프슈트",
    종류: 22,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 120,
    필요INT: 0,
    필요LUK: 600,
    직업: "도적",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1300,
    MP: 0,
    명중률: 10,
    회피율: 10,
    이동속도: 0,
    STR: 0,
    DEX: 15,
    INT: 0,
    LUK: 30,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 1126,
    이름: "그린 루이마리",
    종류: 22,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 0,
    DEX: 4,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1051038,
  },
  {
    코드: 1127,
    이름: "블루 루이마리",
    종류: 22,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 160,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 1,
    이동속도: 0,
    STR: 4,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1051037,
  },
  {
    코드: 1128,
    이름: "청자로",
    종류: 22,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1050059,
  },
  {
    코드: 1129,
    이름: "주자로",
    종류: 22,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 190,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 150,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 1,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1050058,
  },
  {
    코드: 1130,
    이름: "그린 리넥스",
    종류: 22,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 220,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 5,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1050063,
  },
  {
    코드: 1131,
    이름: "블루 리넥스",
    종류: 22,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 220,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 200,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1050061,
  },
  {
    코드: 1132,
    이름: "그린 프리아",
    종류: 22,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 250,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 250,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1051068,
  },
  {
    코드: 1133,
    이름: "블루 프리아",
    종류: 22,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 250,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 250,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 6,
    DEX: 2,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1051067,
  },
  {
    코드: 1134,
    이름: "그린 아데스",
    종류: 22,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 280,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 3,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1050090,
  },
  {
    코드: 1135,
    이름: "블루 아데스",
    종류: 22,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 280,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 6,
    DEX: 3,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1050089,
  },
  {
    코드: 1136,
    이름: "그린 아르주나",
    종류: 22,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 310,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 4,
    DEX: 6,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1050106,
  },
  {
    코드: 1137,
    이름: "레드 아르주나",
    종류: 22,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 310,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 2,
    이동속도: 0,
    STR: 6,
    DEX: 7,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1050108,
  },
  {
    코드: 1138,
    이름: "레드 맨틀",
    종류: 22,
    "필요 레벨": 110,
    필요STR: 0,
    필요DEX: 340,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 0,
    STR: 6,
    DEX: 8,
    INT: 0,
    LUK: 0,
    판매비용: 1464100,
    imgCode: 1052071,
  },
  {
    코드: 1139,
    이름: "에버뉴",
    종류: 22,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 370,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 0,
    STR: 6,
    DEX: 8,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 0,
  },
  {
    코드: 1140,
    이름: "반 레온 에버뉴",
    종류: 22,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 400,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 600,
    MP: 0,
    명중률: 2,
    회피율: 2,
    이동속도: 0,
    STR: 3,
    DEX: 12,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 0,
  },
  {
    코드: 1141,
    이름: "이포스 블러디 메일",
    종류: 22,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 430,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 700,
    MP: 0,
    명중률: 3,
    회피율: 3,
    이동속도: 0,
    STR: 3,
    DEX: 14,
    INT: 0,
    LUK: 0,
    판매비용: 3841600,
    imgCode: 1052321,
  },
  {
    코드: 1142,
    이름: "팔콘윙 센티널슈트",
    종류: 22,
    "필요 레벨": 150,
    필요STR: 0,
    필요DEX: 460,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 800,
    MP: 0,
    명중률: 4,
    회피율: 4,
    이동속도: 0,
    STR: 5,
    DEX: 17,
    INT: 0,
    LUK: 0,
    판매비용: 5062500,
    imgCode: 1052316,
  },
  {
    코드: 1143,
    이름: "펜살리르 센티널슈트",
    종류: 22,
    "필요 레벨": 160,
    필요STR: 0,
    필요DEX: 490,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 900,
    MP: 0,
    명중률: 5,
    회피율: 5,
    이동속도: 0,
    STR: 6,
    DEX: 19,
    INT: 0,
    LUK: 0,
    판매비용: 6553600,
    imgCode: 0,
  },
  {
    코드: 1144,
    이름: "이글아이 레인저후드",
    종류: 22,
    "필요 레벨": 170,
    필요STR: 0,
    필요DEX: 520,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1000,
    MP: 0,
    명중률: 6,
    회피율: 6,
    이동속도: 0,
    STR: 8,
    DEX: 22,
    INT: 0,
    LUK: 0,
    판매비용: 8352100,
    imgCode: 0,
  },
  {
    코드: 1145,
    이름: "앱솔랩스 아처슈트",
    종류: 22,
    "필요 레벨": 180,
    필요STR: 0,
    필요DEX: 550,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1100,
    MP: 0,
    명중률: 7,
    회피율: 7,
    이동속도: 0,
    STR: 9,
    DEX: 24,
    INT: 0,
    LUK: 0,
    판매비용: 10497600,
    imgCode: 0,
  },
  {
    코드: 1146,
    이름: "아케인셰이드 아처슈트",
    종류: 22,
    "필요 레벨": 190,
    필요STR: 0,
    필요DEX: 580,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1200,
    MP: 0,
    명중률: 8,
    회피율: 8,
    이동속도: 0,
    STR: 10,
    DEX: 26,
    INT: 0,
    LUK: 0,
    판매비용: 13032100,
    imgCode: 0,
  },
  {
    코드: 1147,
    이름: "에테르넬 아처슈트",
    종류: 22,
    "필요 레벨": 200,
    필요STR: 0,
    필요DEX: 600,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 1300,
    MP: 0,
    명중률: 10,
    회피율: 10,
    이동속도: 0,
    STR: 15,
    DEX: 30,
    INT: 0,
    LUK: 0,
    판매비용: 16000000,
    imgCode: 0,
  },
  {
    코드: 1148,
    이름: "한쪽 은 귀고리",
    종류: 23,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1032001,
  },
  {
    코드: 1149,
    이름: "자수정 귀고리",
    종류: 23,
    "필요 레벨": 15,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 506,
    imgCode: 1032003,
  },
  {
    코드: 1150,
    이름: "옐로우 스퀘어",
    종류: 23,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1032009,
  },
  {
    코드: 1151,
    이름: "추 귀고리",
    종류: 23,
    "필요 레벨": 20,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1600,
    imgCode: 1032000,
  },
  {
    코드: 1152,
    이름: "나뭇잎 귀고리",
    종류: 23,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 13,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1032025,
  },
  {
    코드: 1153,
    이름: "금 링 귀고리",
    종류: 23,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1032004,
  },
  {
    코드: 1154,
    이름: "레드 크로스링",
    종류: 23,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1032005,
  },
  {
    코드: 1155,
    이름: "별 귀고리",
    종류: 23,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1032010,
  },
  {
    코드: 1156,
    이름: "번개 귀고리",
    종류: 23,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1032006,
  },
  {
    코드: 1157,
    이름: "코-크 귀고리",
    종류: 23,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 15,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1032047,
  },
  {
    코드: 1158,
    이름: "사파이어 귀고리",
    종류: 23,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1032002,
  },
  {
    코드: 1159,
    이름: "캣츠 아이",
    종류: 23,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1032008,
  },
  {
    코드: 1160,
    이름: "블루문",
    종류: 23,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 1032011,
  },
  {
    코드: 1161,
    이름: "펜지 이어링",
    종류: 23,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 41006,
    imgCode: 1032018,
  },
  {
    코드: 1162,
    이름: "해골 귀고리",
    종류: 23,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1032012,
  },
  {
    코드: 1163,
    이름: "빨간하트 귀고리",
    종류: 23,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1032013,
  },
  {
    코드: 1164,
    이름: "크리스탈 플라워링",
    종류: 23,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1032019,
  },
  {
    코드: 1165,
    이름: "홀리 크로스링",
    종류: 23,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1032021,
  },
  {
    코드: 1166,
    이름: "분홍꽃 귀고리",
    종류: 23,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1032014,
  },
  {
    코드: 1167,
    이름: "골드드롭 이어링",
    종류: 23,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1032020,
  },
  {
    코드: 1168,
    이름: "메탈실버 이어링",
    종류: 23,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 1032015,
  },
  {
    코드: 1169,
    이름: "하프 이어링",
    종류: 23,
    "필요 레벨": 75,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 316406,
    imgCode: 1032022,
  },
  {
    코드: 1170,
    이름: "메탈하트 이어링",
    종류: 23,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 409600,
    imgCode: 1032016,
  },
  {
    코드: 1171,
    이름: "딸기 귀고리",
    종류: 23,
    "필요 레벨": 85,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 522006,
    imgCode: 1032023,
  },
  {
    코드: 1172,
    이름: "장미꽃 귀고리",
    종류: 23,
    "필요 레벨": 90,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 656100,
    imgCode: 1032017,
  },
  {
    코드: 1173,
    이름: "소드 이어링",
    종류: 23,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1032030,
  },
  {
    코드: 1174,
    이름: "허름한 망토",
    종류: 24,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 3906,
    imgCode: 1102053,
  },
  {
    코드: 1175,
    이름: "산소통",
    종류: 24,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 8100,
    imgCode: 1102061,
  },
  {
    코드: 1176,
    이름: "온화의 망토",
    종류: 24,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1102109,
  },
  {
    코드: 1177,
    이름: "제뉴미스트의 망토",
    종류: 24,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1102139,
  },
  {
    코드: 1178,
    이름: "알카드노의 망토",
    종류: 24,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 5,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 15006,
    imgCode: 1102140,
  },
  {
    코드: 1179,
    이름: "모험가의 망토",
    종류: 24,
    "필요 레벨": 50,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 62500,
    imgCode: 1102004,
  },
  {
    코드: 1180,
    이름: "수호의 망토",
    종류: 24,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 91506,
    imgCode: 1102013,
  },
  {
    코드: 1181,
    이름: "마법의 망토",
    종류: 24,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 129600,
    imgCode: 1102016,
  },
  {
    코드: 1182,
    이름: "가이아의 망토",
    종류: 24,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1102024,
  },
  {
    코드: 1183,
    이름: "도깨비 망토",
    종류: 24,
    "필요 레벨": 65,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 178506,
    imgCode: 1102064,
  },
  {
    코드: 1184,
    이름: "세라프의 망토",
    종류: 24,
    "필요 레벨": 75,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 316406,
    imgCode: 1102030,
  },
  {
    코드: 1185,
    이름: "자일즈의 망토",
    종류: 24,
    "필요 레벨": 85,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 522006,
    imgCode: 1102035,
  },
  {
    코드: 1186,
    이름: "실리스의 망토",
    종류: 24,
    "필요 레벨": 95,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 814506,
    imgCode: 1102046,
  },
  {
    코드: 1187,
    이름: "루디브리엄 망토",
    종류: 24,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 20,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 1102057,
  },
  {
    코드: 1188,
    이름: "문라이트",
    종류: 24,
    "필요 레벨": 120,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: null,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2073600,
    imgCode: 1051024,
  },
  {
    코드: 1189,
    이름: "루돌프의 빨간코",
    종류: 25,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 2,
    마력: 2,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 1012300,
  },
  {
    코드: 1190,
    이름: "메론맛 아이스바",
    종류: 25,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 10,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1012072,
  },
  {
    코드: 1191,
    이름: "피노키오 코",
    종류: 25,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 4,
    DEX: 4,
    INT: 4,
    LUK: 4,
    판매비용: 100,
    imgCode: 1012060,
  },
  {
    코드: 1192,
    이름: "딸기맛 아이스바",
    종류: 25,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 5,
    마력: 5,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100,
    imgCode: 1012070,
  },
  {
    코드: 1193,
    이름: "금이 간 안경",
    종류: 26,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 60,
    MP: 0,
    명중률: 20,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 41006,
    imgCode: 1022073,
  },
  {
    코드: 1194,
    이름: "미카엘의 안경",
    종류: 26,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 100,
    MP: 0,
    명중률: 0,
    회피율: 20,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 129600,
    imgCode: 1022114,
  },
  {
    코드: 1195,
    이름: "슈피겔만의 목걸이",
    종류: 27,
    "필요 레벨": 30,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 5,
    INT: 5,
    LUK: 5,
    판매비용: 8100,
    imgCode: 1122007,
  },
  {
    코드: 1196,
    이름: "슈피겔만의 혼돈 목걸이",
    종류: 27,
    "필요 레벨": 51,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 1,
    마력: 1,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 8,
    DEX: 8,
    INT: 8,
    LUK: 8,
    판매비용: 67652,
    imgCode: 1122058,
  },
  {
    코드: 1197,
    이름: "호루스의 눈",
    종류: 27,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 10,
    이동속도: 0,
    STR: 5,
    DEX: 5,
    INT: 5,
    LUK: 5,
    판매비용: 240100,
    imgCode: 1122010,
  },
  {
    코드: 1198,
    이름: "도미네이터 펜던트",
    종류: 27,
    "필요 레벨": 140,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 5,
    마력: 5,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 20,
    DEX: 20,
    INT: 20,
    LUK: 20,
    판매비용: 3841600,
    imgCode: 1122150,
  },
  {
    코드: 1199,
    이름: "따뜻한 복대",
    종류: 28,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 1,
    마력: 1,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 100,
    imgCode: 1132140,
  },
  {
    코드: 1200,
    이름: "흰색 허리띠",
    종류: 28,
    "필요 레벨": 25,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 3,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 3906,
    imgCode: 1132000,
  },
  {
    코드: 1201,
    이름: "노란색 허리띠",
    종류: 28,
    "필요 레벨": 35,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 6,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 15006,
    imgCode: 1132001,
  },
  {
    코드: 1202,
    이름: "파란색 허리띠",
    종류: 28,
    "필요 레벨": 45,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 9,
    이동속도: 0,
    STR: 3,
    DEX: 3,
    INT: 3,
    LUK: 3,
    판매비용: 41006,
    imgCode: 1132002,
  },
  {
    코드: 1203,
    이름: "슈피겔만의 벨트",
    종류: 28,
    "필요 레벨": 55,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 5,
    DEX: 5,
    INT: 5,
    LUK: 5,
    판매비용: 91506,
    imgCode: 1132145,
  },
  {
    코드: 1204,
    이름: "빨간색 허리띠",
    종류: 28,
    "필요 레벨": 60,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 12,
    이동속도: 0,
    STR: 4,
    DEX: 4,
    INT: 4,
    LUK: 4,
    판매비용: 129600,
    imgCode: 1132003,
  },
  {
    코드: 1205,
    이름: "검은색 허리띠",
    종류: 28,
    "필요 레벨": 75,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 15,
    이동속도: 0,
    STR: 5,
    DEX: 5,
    INT: 5,
    LUK: 5,
    판매비용: 316406,
    imgCode: 1132004,
  },
  {
    코드: 1206,
    이름: "불멸의 파라오의 벨트",
    종류: 28,
    "필요 레벨": 80,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 2,
    마력: 2,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 2,
    DEX: 2,
    INT: 2,
    LUK: 2,
    판매비용: 409600,
    imgCode: 1132013,
  },
  {
    코드: 1207,
    이름: "연금술사의 반지",
    종류: 29,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "공용",
    공격속도: 0,
    공격력: 0,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 1,
    DEX: 1,
    INT: 1,
    LUK: 1,
    판매비용: 100,
    imgCode: 1112400,
  },
  {
    코드: 1208,
    이름: "활전용 화살",
    종류: 30,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 1,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2060000,
  },
  {
    코드: 1209,
    이름: "석궁전용 화살",
    종류: 31,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 1,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2061000,
  },
  {
    코드: 1210,
    이름: "활전용 청동화살",
    종류: 30,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2060001,
  },
  {
    코드: 1211,
    이름: "석궁전용 청동화살",
    종류: 31,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2061001,
  },
  {
    코드: 1212,
    이름: "활전용 강철화살",
    종류: 30,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 5,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2060002,
  },
  {
    코드: 1213,
    이름: "석궁전용 강철화살",
    종류: 31,
    "필요 레벨": 0,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 5,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2061002,
  },
  {
    코드: 1214,
    이름: "활전용 좋은 화살",
    종류: 30,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 7,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 2060008,
  },
  {
    코드: 1215,
    이름: "석궁전용 좋은 화살",
    종류: 31,
    "필요 레벨": 40,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 7,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 25600,
    imgCode: 2061006,
  },
  {
    코드: 1216,
    이름: "활전용 강한 화살",
    종류: 30,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 9,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 2060009,
  },
  {
    코드: 1217,
    이름: "석궁전용 강한 화살",
    종류: 31,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 9,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 240100,
    imgCode: 2061007,
  },
  {
    코드: 1218,
    이름: "활전용 날카로운 화살",
    종류: 30,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 2060010,
  },
  {
    코드: 1219,
    이름: "석궁전용 날카로운 화살",
    종류: 31,
    "필요 레벨": 100,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 12,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 2061008,
  },
  {
    코드: 1220,
    이름: "활전용 티타늄 화살",
    종류: 30,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 2060011,
  },
  {
    코드: 1221,
    이름: "석궁전용 티타늄 화살",
    종류: 31,
    "필요 레벨": 130,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "궁수",
    공격속도: 0,
    공격력: 15,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 2856100,
    imgCode: 2061009,
  },
  {
    코드: 1222,
    이름: "수비 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 1,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000,
    imgCode: 2070000,
  },
  {
    코드: 1223,
    이름: "월비 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10000,
    imgCode: 2070001,
  },
  {
    코드: 1224,
    이름: "눈덩이",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 3,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 10000,
    imgCode: 2070008,
  },
  {
    코드: 1225,
    이름: "목비 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 6,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 60000,
    imgCode: 2070002,
  },
  {
    코드: 1226,
    이름: "나무팽이",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 6,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 60000,
    imgCode: 2070009,
  },
  {
    코드: 1227,
    이름: "금비 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100000,
    imgCode: 2070003,
  },
  {
    코드: 1228,
    이름: "고드름",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 10,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 100000,
    imgCode: 2070010,
  },
  {
    코드: 1229,
    이름: "메이플 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 9,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 0,
    imgCode: 2070011,
  },
  {
    코드: 1230,
    이름: "토비 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 14,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 200000,
    imgCode: 2070004,
  },
  {
    코드: 1231,
    이름: "뇌전 수리검",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 16,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 300000,
    imgCode: 2070005,
  },
  {
    코드: 1232,
    이름: "일비 표창",
    종류: 32,
    "필요 레벨": 10,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 19,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 600000,
    imgCode: 2070006,
  },
  {
    코드: 1233,
    이름: "화비 표창",
    종류: 32,
    "필요 레벨": 70,
    필요STR: 0,
    필요DEX: 0,
    필요INT: 0,
    필요LUK: 0,
    직업: "도적",
    공격속도: 0,
    공격력: 22,
    마력: 0,
    방어력: 0.0,
    HP: 0,
    MP: 0,
    명중률: 0,
    회피율: 0,
    이동속도: 0,
    STR: 0,
    DEX: 0,
    INT: 0,
    LUK: 0,
    판매비용: 1000000,
    imgCode: 2070007,
  },
];
export default item;
