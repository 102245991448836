const jobs = {
  0: "초보자",
  1: "전사",
  2: "마법사",
  3: "도적",
  4: "궁수",
  5: "해적",
};

export default jobs;
