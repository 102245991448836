const itemType = {
  1: "한손검",
  2: "두손검",
  3: "한손도끼",
  4: "두손도끼",
  5: "한손둔기",
  6: "두손둔기",
  7: "창",
  8: "폴암",
  9: "스태프",
  10: "완드",
  11: "아대",
  12: "럭단검",
  13: "힘단검",
  14: "활",
  15: "석궁",
  16: "방패",
  17: "모자",
  18: "장갑",
  19: "신발",
  20: "상의",
  21: "하의",
  22: "전신",
  23: "귀고리",
  24: "망토",
  25: "얼굴장식",
  26: "눈장식",
  27: "목걸이",
  28: "벨트",
  29: "반지",
  30: "활 화살",
  31: "석궁 화살",
  32: "표창",
};

export default itemType;
