const mobDropTable = [
  {
    이름: "달팽이",
    drop1: "빨간색 머리띠",
    probability1: 0.016,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파란 달팽이",
    drop1: "파란색 원라인 티셔츠",
    probability1: 0.016,
    drop2: "회갈색 수련복 바지",
    probability2: 0.002,
    drop3: "회갈색 수련복",
    probability3: 0.002,
    drop4: "회색 고무줄 바지",
    probability4: 0.002,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스포아",
    drop1: "필드 대거",
    probability1: 0.002,
    drop2: "파란 청 반바지",
    probability2: 0.002,
    drop3: "아발란체 바지",
    probability3: 0.002,
    drop4: "아이스 진",
    probability4: 0.002,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "빨간 달팽이",
    drop1: "자수정 귀고리",
    probability1: 0.002,
    drop2: "블랙 듀오",
    probability2: 0.002,
    drop3: "그린 우드탑",
    probability3: 0.002,
    drop4: "가죽 핸드백",
    probability4: 0.002,
    drop5: "메탈 완드",
    probability5: 0.002,
    drop6: "흑몽 바지",
    probability6: 0.002,
    drop7: "목검",
    probability7: 0.016,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스텀프",
    drop1: "브라운 미들 래더",
    probability1: 0.002,
    drop2: "합금 활",
    probability2: 0.002,
    drop3: "주노",
    probability3: 0.002,
    drop4: "삼각 자마다르",
    probability4: 0.002,
    drop5: "브라운 로리카 바지",
    probability5: 0.002,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "슬라임",
    drop1: "베이지 니티",
    probability1: 0.006,
    drop2: "포크 창",
    probability2: 0.002,
    drop3: "철제 도끼",
    probability3: 0.0018,
    drop4: "레모나",
    probability4: 0.004,
    drop5: "쇠 도끼",
    probability5: 0.016,
    drop6: "흰색 두건",
    probability6: 0.002,
    drop7: "가죽 핸드백",
    probability7: 0.002,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "돼지",
    drop1: "갈색 하드래더 바지",
    probability1: 0.002,
    drop2: "초록색 가죽 모자",
    probability2: 0.004,
    drop3: "적야",
    probability3: 0.002,
    drop4: "가니어",
    probability4: 0.01,
    drop5: "브라운 로리카 아머",
    probability5: 0.002,
    drop6: "흰색 가람 바지",
    probability6: 0.002,
    drop7: "나무 망치",
    probability7: 0.008,
    drop8: "환목검",
    probability8: 0.0008,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주황버섯",
    drop1: "아르",
    probability1: 0.002,
    drop2: "브론즈 코이프",
    probability2: 0.006,
    drop3: "브라운 하드래더 부츠",
    probability3: 0.002,
    drop4: "양날 도끼",
    probability4: 0.01,
    drop5: "흰색 닌자 샌들",
    probability5: 0.002,
    drop6: "창",
    probability6: 0.01,
    drop7: "검정색 고무신",
    probability7: 0.006,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "리본돼지",
    drop1: "자수정 귀고리",
    probability1: 0.0007,
    drop2: "청야",
    probability2: 0.0009,
    drop3: "청야 바지",
    probability3: 0.0018,
    drop4: "파란색 원라인 티셔츠",
    probability4: 0.002,
    drop5: "은색 래더 후드웨어",
    probability5: 0.002,
    drop6: "환목검",
    probability6: 0.0005,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 스텀프",
    drop1: "브론즈 캡",
    probability1: 0.006,
    drop2: "검은색 가람",
    probability2: 0.0016,
    drop3: "흰색 가람 바지",
    probability3: 0.0016,
    drop4: "폴암",
    probability4: 0.02,
    drop5: "한쪽 은 귀고리",
    probability5: 0.0006,
    drop6: "나무 스태프",
    probability6: 0.002,
    drop7: "그린 아발란체",
    probability7: 0.002,
    drop8: "아발란체 바지",
    probability8: 0.002,
    drop9: "넙치검",
    probability9: 0.002,
    drop10: "사각 나무 방패",
    probability10: 0.02,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "옥토퍼스",
    drop1: "배틀 실드",
    probability1: 0.0016,
    drop2: "브론즈 코퍼럴",
    probability2: 0.0012,
    drop3: "브라운 코퍼럴 바지",
    probability3: 0.0012,
    drop4: "군밤장수 모자",
    probability4: 0.0018,
    drop5: "한쪽 은 귀고리",
    probability5: 0.001,
    drop6: "강철 방패",
    probability6: 0.004,
    drop7: "나무 망치",
    probability7: 0.004,
    drop8: "검은색 가람",
    probability8: 0.0018,
    drop9: "흰색 가람 바지",
    probability9: 0.0018,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "초록버섯",
    drop1: "전투 석궁",
    probability1: 0.0012,
    drop2: "합금 활",
    probability2: 0.0008,
    drop3: "초록색 래더 후드웨어",
    probability3: 0.0016,
    drop4: "그린 아벨 가죽치마",
    probability4: 0.0016,
    drop5: "철제 도끼",
    probability5: 0.0009,
    drop6: "메이스",
    probability6: 0.01,
    drop7: "냄비 뚜껑",
    probability7: 0.0005,
    drop8: "검은색 가람",
    probability8: 0.0018,
    drop9: "흰색 가람 바지",
    probability9: 0.0018,
    drop10: "적야",
    probability10: 0.0018,
    drop11: "청야 바지",
    probability11: 0.0018,
    drop12: "제코",
    probability12: 0.0005,
    drop13: "군밤장수 모자",
    probability13: 0.002,
    drop14: "카알 대검",
    probability14: 0.002,
    drop15: "초록색 고급 가죽 모자",
    probability15: 0.002,
    drop16: "그린 매티",
    probability16: 0.0005,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "버블링",
    drop1: "블루 쥬얼리 슈즈",
    probability1: 0.001,
    drop2: "청야",
    probability2: 0.002,
    drop3: "청야 바지",
    probability3: 0.002,
    drop4: "그린 아벨 가죽치마",
    probability4: 0.001,
    drop5: "물고기 작살",
    probability5: 0.001,
    drop6: "철제 도끼",
    probability6: 0.0009,
    drop7: "갈색 낡은 고깔 모자",
    probability7: 0.016,
    drop8: "창",
    probability8: 0.005,
    drop9: "환목검",
    probability9: 0.0009,
    drop10: "은색 래더 후드웨어",
    probability10: 0.001,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "엑스텀프",
    drop1: "배틀 실드",
    probability1: 0.0016,
    drop2: "블랙 너클베스트",
    probability2: 0.0003,
    drop3: "레드 너클베스트 바지",
    probability3: 0.0003,
    drop4: "그린 페더윙햇",
    probability4: 0.001,
    drop5: "갈색 아로아 슈즈",
    probability5: 0.02,
    drop6: "청야",
    probability6: 0.002,
    drop7: "청야 바지",
    probability7: 0.002,
    drop8: "콘트라 엑스",
    probability8: 0.0016,
    drop9: "고목나무 스태프",
    probability9: 0.0008,
    drop10: "검은색 가람",
    probability10: 0.0016,
    drop11: "위저드 완드",
    probability11: 0.001,
    drop12: "목검",
    probability12: 0.002,
    drop13: "나무 야구 방망이",
    probability13: 0.0008,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "고스텀프",
    drop1: "검은색 가람",
    probability1: 0.0009,
    drop2: "흰색 가람 바지",
    probability2: 0.0009,
    drop3: "검은색 에나멜 부츠",
    probability3: 0.0008,
    drop4: "그린 페더햇",
    probability4: 0.001,
    drop5: "검은색 사제의 로브",
    probability5: 0.0007,
    drop6: "그린 아벨 가죽치마",
    probability6: 0.001,
    drop7: "다크 사바타",
    probability7: 0.0007,
    drop8: "베이지 플레로브",
    probability8: 0.001,
    drop9: "철제 도끼",
    probability9: 0.001,
    drop10: "브라운 미들 래더",
    probability10: 0.0008,
    drop11: "블랙 시프 후드",
    probability11: 0.001,
    drop12: "초록색 래더 후드웨어",
    probability12: 0.001,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파란버섯",
    drop1: "블루 서전트",
    probability1: 0.0008,
    drop2: "필드 대거",
    probability2: 0.001,
    drop3: "철제 도끼",
    probability3: 0.001,
    drop4: "미스릴 폴암",
    probability4: 0.0005,
    drop5: "블루 쥬얼리 슈즈",
    probability5: 0.001,
    drop6: "블랙 루즈캡",
    probability6: 0.0008,
    drop7: "갈색 하드래더",
    probability7: 0.0008,
    drop8: "갈색 하드래더 바지",
    probability8: 0.0008,
    drop9: "파란색 검도복",
    probability9: 0.002,
    drop10: "포크 창",
    probability10: 0.002,
    drop11: "다크 워 부츠",
    probability11: 0.0008,
    drop12: "반월 자마다르",
    probability12: 0.0004,
    drop13: "위저드 스태프",
    probability13: 0.0006,
    drop14: "그린 버글러",
    probability14: 0.0006,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스티지",
    drop1: "그린 페더윙햇",
    probability1: 0.0007,
    drop2: "빨간색 파오",
    probability2: 0.0008,
    drop3: "검정색 파오 바지",
    probability3: 0.0008,
    drop4: "라이덴",
    probability4: 0.0004,
    drop5: "브론즈 코퍼럴",
    probability5: 0.001,
    drop6: "브라운 코퍼럴 바지",
    probability6: 0.001,
    drop7: "노가다 목장갑",
    probability7: 0.001,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "흰모래 토끼",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 네키",
    drop1: "파란색 검도복",
    probability1: 0.0009,
    drop2: "블루 서전트",
    probability2: 0.0008,
    drop3: "레드 서전트 치마",
    probability3: 0.0008,
    drop4: "넙치검",
    probability4: 0.001,
    drop5: "검은색 가람",
    probability5: 0.001,
    drop6: "그린 잭부츠",
    probability6: 0.0006,
    drop7: "아이언 버거넷 헬름",
    probability7: 0.0007,
    drop8: "흰색 가람 바지",
    probability8: 0.001,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "갈색 모래 토끼",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "뿔버섯",
    drop1: "검정색 파오",
    probability1: 0.0009,
    drop2: "검정색 파오 바지",
    probability2: 0.0009,
    drop3: "그린 필퍼",
    probability3: 0.0007,
    drop4: "그린 핀터",
    probability4: 0.0008,
    drop5: "이글크로",
    probability5: 0.0004,
    drop6: "은색 래더 후드웨어",
    probability6: 0.001,
    drop7: "그린 아벨 가죽치마",
    probability7: 0.001,
    drop8: "아이언 버거넷 헬름",
    probability8: 0.0006,
    drop9: "미스릴 폴암",
    probability9: 0.0005,
    drop10: "흰색 검도복",
    probability10: 0.001,
    drop11: "강철 반장갑",
    probability11: 0.0008,
    drop12: "강철 방패",
    probability12: 0.002,
    drop13: "옐로우 윈드슈즈",
    probability13: 0.0005,
    drop14: "파란 청 반바지",
    probability14: 0.01,
    drop15: "커틀러스",
    probability15: 0.0004,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 엑스텀프",
    drop1: "스틸 이고르",
    probability1: 0.002,
    drop2: "블랙 래피 부츠",
    probability2: 0.001,
    drop3: "화이트 도로스 로브",
    probability3: 0.0016,
    drop4: "그린 아벨 가죽치마",
    probability4: 0.003,
    drop5: "스틸 가즈",
    probability5: 0.001,
    drop6: "갈색 하드래더",
    probability6: 0.002,
    drop7: "갈색 하드래더 바지",
    probability7: 0.002,
    drop8: "블랙 래피 부츠",
    probability8: 0.0012,
    drop9: "다크 가디언",
    probability9: 0.0004,
    drop10: "빨간색 별 두건",
    probability10: 0.0012,
    drop11: "나무팽이",
    probability11: 0.0008,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "핀붐",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 카투스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "우드 마스크",
    drop1: "블랙 래피 부츠",
    probability1: 0.0008,
    drop2: "흰색 가람 바지",
    probability2: 0.001,
    drop3: "검은색 가람",
    probability3: 0.001,
    drop4: "그린 페더윙햇",
    probability4: 0.001,
    drop5: "다크 워 부츠",
    probability5: 0.0008,
    drop6: "블랙 루즈캡",
    probability6: 0.001,
    drop7: "아이언 대거",
    probability7: 0.0008,
    drop8: "브론즈 바이킹 헬름",
    probability8: 0.001,
    drop9: "다크 메사나",
    probability9: 0.0009,
    drop10: "오리할콘 미셀",
    probability10: 0.0007,
    drop11: "골드 실비아",
    probability11: 0.0007,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 스톤볼",
    drop1: "다크브라운 스틸러",
    probability1: 0.001,
    drop2: "블랙 스틸레스 바지",
    probability2: 0.001,
    drop3: "블랙 루즈캡",
    probability3: 0.001,
    drop4: "퓨전 메이스",
    probability4: 0.0008,
    drop5: "강철 메이스",
    probability5: 0.001,
    drop6: "다크 마스터 서전트",
    probability6: 0.0008,
    drop7: "실버 마스터 서전트 치마",
    probability7: 0.0008,
    drop8: "라이덴",
    probability8: 0.0009,
    drop9: "수비 표창",
    probability9: 0.001,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "씨클",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "벨라모아",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "트릭스터",
    drop1: "발란쉐",
    probability1: 0.001,
    drop2: "블루 도로스 로브",
    probability2: 0.001,
    drop3: "일룬",
    probability3: 0.0008,
    drop4: "태극부채",
    probability4: 0.001,
    drop5: "미스릴 도끼",
    probability5: 0.0008,
    drop6: "다크 사바타",
    probability6: 0.0008,
    drop7: "빨간색 별 두건",
    probability7: 0.001,
    drop8: "수비 표창",
    probability8: 0.001,
    drop9: "알루미늄 야구 방망이",
    probability9: 0.0008,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "좀비버섯",
    drop1: "브라운 스니크",
    probability1: 0.0008,
    drop2: "브라운 스니크 바지",
    probability2: 0.0008,
    drop3: "금 링 귀고리",
    probability3: 0.001,
    drop4: "그린 헌트",
    probability4: 0.0007,
    drop5: "지천의",
    probability5: 0.0006,
    drop6: "사냥꾼의 활",
    probability6: 0.001,
    drop7: "강철 도끼",
    probability7: 0.001,
    drop8: "미스릴 완드",
    probability8: 0.0007,
    drop9: "다크 아르텐",
    probability9: 0.0008,
    drop10: "백무 바지",
    probability10: 0.0007,
    drop11: "추 귀고리",
    probability11: 0.001,
    drop12: "브론즈 코퍼럴",
    probability12: 0.002,
    drop13: "다크 데빌즈 로브",
    probability13: 0.0004,
    drop14: "화이트 데빌즈 로브",
    probability14: 0.0004,
    drop15: "그린 핀터",
    probability15: 0.0006,
    drop16: "나카마키",
    probability16: 0.0002,
    drop17: "지천의",
    probability17: 0.0004,
    drop18: "블루 카운터",
    probability18: 0.0002,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스톤 마스크",
    drop1: "파란색 파오",
    probability1: 0.001,
    drop2: "검정색 파오 바지",
    probability2: 0.001,
    drop3: "블랙 마커",
    probability3: 0.0007,
    drop4: "아이스 완드",
    probability4: 0.001,
    drop5: "블랙 루티아",
    probability5: 0.0007,
    drop6: "라이덴",
    probability6: 0.0008,
    drop7: "다크 마스터 서전트",
    probability7: 0.0009,
    drop8: "다크 마스터 서전트 치마",
    probability8: 0.0009,
    drop9: "바이킹소드",
    probability9: 0.001,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "크라피",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "귀마개 프릴드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "모래두더지",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "와일드보어",
    drop1: "손오공 금 머리띠",
    probability1: 0.0008,
    drop2: "브라운 피에뜨",
    probability2: 0.0003,
    drop3: "브라운 피에뜨 바지",
    probability3: 0.0003,
    drop4: "써클윈드 스태프",
    probability4: 0.0003,
    drop5: "갈색 하드래더",
    probability5: 0.001,
    drop6: "갈색 하드래더 바지",
    probability6: 0.001,
    drop7: "아이언 체인슈즈",
    probability7: 0.0007,
    drop8: "실버 배틀 그리브",
    probability8: 0.0007,
    drop9: "나카마키",
    probability9: 0.0002,
    drop10: "블루 도로스 로브",
    probability10: 0.001,
    drop11: "사파이어 귀고리",
    probability11: 0.0004,
    drop12: "추 귀고리",
    probability12: 0.01,
    drop13: "하이랜더",
    probability13: 0.0002,
    drop14: "블루 너클베스트 바지",
    probability14: 0.0004,
    drop15: "다크 길티언",
    probability15: 0.0002,
    drop16: "라이덴",
    probability16: 0.0004,
    drop17: "이글크로",
    probability17: 0.0004,
    drop18: "그린 크라페",
    probability18: 0.0002,
    drop19: "버드빌",
    probability19: 0.0002,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "씨코",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "카투스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "이블아이",
    drop1: "이너스 체인바지",
    probability1: 0.0009,
    drop2: "옐로우 잉그리트",
    probability2: 0.0007,
    drop3: "캣츠 아이",
    probability3: 0.0006,
    drop4: "커틀러스",
    probability4: 0.003,
    drop5: "브라운 스니크",
    probability5: 0.001,
    drop6: "브라운 스니크 바지",
    probability6: 0.001,
    drop7: "브라운 그레이트 헬멧",
    probability7: 0.0007,
    drop8: "그린 이너스 체인메일",
    probability8: 0.0009,
    drop9: "다크 크로스 체인메일",
    probability9: 0.001,
    drop10: "검은색 사제의 로브",
    probability10: 0.0008,
    drop11: "옐로우 스퀘어",
    probability11: 0.002,
    drop12: "삼지창",
    probability12: 0.0006,
    drop13: "지천의",
    probability13: 0.0008,
    drop14: "그린 아발란체",
    probability14: 0.01,
    drop15: "카키 쉐도우 바지",
    probability15: 0.0004,
    drop16: "미스릴 모울",
    probability16: 0.0006,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "목도리 프릴드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "버블피쉬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "로얄카투스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "그린 트릭스터",
    drop1: "브라운 네페르",
    probability1: 0.0008,
    drop2: "검은색 사제의 로브",
    probability2: 0.0009,
    drop3: "아이언 대거",
    probability3: 0.001,
    drop4: "목비 표창",
    probability4: 0.001,
    drop5: "골드 실비아",
    probability5: 0.0008,
    drop6: "위저드 스태프",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "플라워피쉬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "미요캐츠",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스콜피언",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스톤볼",
    drop1: "블루 너클베스트 바지",
    probability1: 0.0008,
    drop2: "다크 우드쉽",
    probability2: 0.001,
    drop3: "콘트라 엑스",
    probability3: 0.001,
    drop4: "파이어 잭",
    probability4: 0.001,
    drop5: "그린 너클베스트",
    probability5: 0.0007,
    drop6: "그린 폴레패더햇",
    probability6: 0.0006,
    drop7: "번개 귀고리",
    probability7: 0.0009,
    drop8: "페탈 스태프",
    probability8: 0.0006,
    drop9: "지천의",
    probability9: 0.0009,
    drop10: "백무 바지",
    probability10: 0.0009,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파이어 스톤볼",
    drop1: "블랙 루티아",
    probability1: 0.0008,
    drop2: "브라운 로리카 아머",
    probability2: 0.002,
    drop3: "그린 헌터스 아머",
    probability3: 0.0008,
    drop4: "그린 헌터스 아머 바지",
    probability4: 0.0008,
    drop5: "블랙 솔트슈즈",
    probability5: 0.0005,
    drop6: "레드 바이퍼",
    probability6: 0.0005,
    drop7: "블루 스니크",
    probability7: 0.001,
    drop8: "브라운 스니크 바지",
    probability8: 0.001,
    drop9: "다크 크로스 체인메일",
    probability9: 0.0005,
    drop10: "옐로우 스퀘어",
    probability10: 0.002,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "아이스 스톤볼",
    drop1: "도끼 폴암",
    probability1: 0.0006,
    drop2: "이너스 체인바지",
    probability2: 0.001,
    drop3: "그린 이너스 체인메일",
    probability3: 0.001,
    drop4: "그린 매티",
    probability4: 0.0006,
    drop5: "뚜러",
    probability5: 0.001,
    drop6: "다크실버 스틸러",
    probability6: 0.0008,
    drop7: "퍼플 스틸레스 바지",
    probability7: 0.0007,
    drop8: "검은색 사제의 로브",
    probability8: 0.0009,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "크립",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "키요",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "브라운 테니",
    drop1: "블루 스니크",
    probability1: 0.001,
    drop2: "브라운 스니크 바지",
    probability2: 0.001,
    drop3: "화이트 데빌즈 로브",
    probability3: 0.0007,
    drop4: "블루 실키부츠",
    probability4: 0.0008,
    drop5: "다크실버 스틸러",
    probability5: 0.0008,
    drop6: "퍼플 스틸레스 바지",
    probability6: 0.0008,
    drop7: "손오공 금 머리띠",
    probability7: 0.0007,
    drop8: "산양 석궁",
    probability8: 0.001,
    drop9: "골드 아르비욘",
    probability9: 0.0009,
    drop10: "워해머",
    probability10: 0.001,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "북치는 토끼",
    drop1: "이너스 체인바지",
    probability1: 0.001,
    drop2: "흑룡포",
    probability2: 0.0009,
    drop3: "블랙 루티아",
    probability3: 0.0009,
    drop4: "다크실버 스틸러",
    probability4: 0.001,
    drop5: "퍼플 스틸레스 바지",
    probability5: 0.001,
    drop6: "브라운 매티",
    probability6: 0.0008,
    drop7: "페어리 완드",
    probability7: 0.0006,
    drop8: "그린 레골리스",
    probability8: 0.0008,
    drop9: "다크 레골러 바지",
    probability9: 0.0008,
    drop10: "목비 표창",
    probability10: 0.0007,
    drop11: "냄비 뚜껑",
    probability11: 0.0007,
    drop12: "블랙 이너스 체인메일",
    probability12: 0.001,
    drop13: "붉은 채찍",
    probability13: 0.0007,
    drop14: "클러디",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "처프",
    drop1: "화이트 길티언",
    probability1: 0.0007,
    drop2: "레드 피에르슈즈",
    probability2: 0.0007,
    drop3: "커틀러스",
    probability3: 0.0007,
    drop4: "파이어 잭",
    probability4: 0.0009,
    drop5: "쌍지단도",
    probability5: 0.001,
    drop6: "그린 핀터",
    probability6: 0.001,
    drop7: "그린 필퍼",
    probability7: 0.0007,
    drop8: "다크 너클",
    probability8: 0.0007,
    drop9: "금비 표창",
    probability9: 0.0005,
    drop10: "블루 도로스 로브",
    probability10: 0.001,
    drop11: "그린 헌터스 아머",
    probability11: 0.001,
    drop12: "그린 헌터스 아머 바지",
    probability12: 0.001,
    drop13: "레드 라인부츠",
    probability13: 0.0008,
    drop14: "다크 브리건",
    probability14: 0.0008,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파이어보어",
    drop1: "블랙 솔트슈즈",
    probability1: 0.0007,
    drop2: "블랙 루티아",
    probability2: 0.0009,
    drop3: "백무 바지",
    probability3: 0.001,
    drop4: "크리스탈 완드",
    probability4: 0.0005,
    drop5: "골드 실비아",
    probability5: 0.001,
    drop6: "그린 버글러",
    probability6: 0.0007,
    drop7: "지천의",
    probability7: 0.001,
    drop8: "헤클러",
    probability8: 0.0005,
    drop9: "태극부채",
    probability9: 0.001,
    drop10: "그린 헌터부츠",
    probability10: 0.0009,
    drop11: "그린 실키부츠",
    probability11: 0.0007,
    drop12: "라이덴",
    probability12: 0.0009,
    drop13: "다크 가디언",
    probability13: 0.0008,
    drop14: "월비 표창",
    probability14: 0.0008,
    drop15: "미스릴 완드",
    probability15: 0.0008,
    drop16: "다크 매티",
    probability16: 0.0006,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "리게이터",
    drop1: "청일 바지",
    probability1: 0.0009,
    drop2: "게파트",
    probability2: 0.0005,
    drop3: "블랙 마커",
    probability3: 0.001,
    drop4: "미스릴 모울",
    probability4: 0.0009,
    drop5: "다크 티거",
    probability5: 0.0008,
    drop6: "뚜러",
    probability6: 0.001,
    drop7: "다크 데빌즈 로브",
    probability7: 0.0009,
    drop8: "골드 아르비욘",
    probability8: 0.0008,
    drop9: "미스릴 타워 실드",
    probability9: 0.0006,
    drop10: "빨간색 서핑보드",
    probability10: 0.0002,
    drop11: "옐로우 잉그리트",
    probability11: 0.001,
    drop12: "제코",
    probability12: 0.0006,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "리티",
    drop1: "다크브라운 스틸러",
    probability1: 0.0009,
    drop2: "블랙 스틸레스 바지",
    probability2: 0.0009,
    drop3: "다크 사바타",
    probability3: 0.001,
    drop4: "써클윈드 스태프",
    probability4: 0.0007,
    drop5: "그린 네페르",
    probability5: 0.0009,
    drop6: "금룡의",
    probability6: 0.0007,
    drop7: "그린 헌터부츠",
    probability7: 0.001,
    drop8: "그린 핀터",
    probability8: 0.001,
    drop9: "블랙 매직슈즈",
    probability9: 0.001,
    drop10: "다크 노엘",
    probability10: 0.0007,
    drop11: "사파이어 귀고리",
    probability11: 0.001,
    drop12: "반 백무 바지",
    probability12: 0.0007,
    drop13: "월비 표창",
    probability13: 0.001,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마스크피쉬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "모래난쟁이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "붉은 모래난쟁이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "큐브슬라임",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "라츠",
    drop1: "청룡의",
    probability1: 0.0008,
    drop2: "골드 스케일러",
    probability2: 0.0008,
    drop3: "브라운 네페르",
    probability3: 0.001,
    drop4: "카스",
    probability4: 0.0009,
    drop5: "당커",
    probability5: 0.0007,
    drop6: "다크 가이즈",
    probability6: 0.001,
    drop7: "젝커",
    probability7: 0.0005,
    drop8: "블랙 루티아",
    probability8: 0.001,
    drop9: "아이언 체인슈즈",
    probability9: 0.001,
    drop10: "반 백무 바지",
    probability10: 0.0008,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "핑크 테니",
    drop1: "배틀 실드",
    probability1: 0.0008,
    drop2: "그린 헌터스 아머",
    probability2: 0.0009,
    drop3: "그린 헌터스 아머 바지",
    probability3: 0.0009,
    drop4: "다크 데빌즈 로브",
    probability4: 0.0008,
    drop5: "오리할콘 미셀",
    probability5: 0.001,
    drop6: "이글크로",
    probability6: 0.0008,
    drop7: "레드 너클베스트 바지",
    probability7: 0.0007,
    drop8: "블랙 솔트슈즈",
    probability8: 0.0007,
    drop9: "아다만티움 보닌",
    probability9: 0.0005,
    drop10: "블랙 매직슈즈",
    probability10: 0.001,
    drop11: "다크 브리건",
    probability11: 0.0007,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 샐리온",
    drop1: "실버 배틀 그리브",
    probability1: 0.001,
    drop2: "버크",
    probability2: 0.0009,
    drop3: "브라운 레골리스",
    probability3: 0.0008,
    drop4: "브라운 레골러 바지",
    probability4: 0.0008,
    drop5: "다크 가이즈",
    probability5: 0.001,
    drop6: "그린 핀터",
    probability6: 0.001,
    drop7: "블루 스타라이트",
    probability7: 0.0006,
    drop8: "캣츠 아이",
    probability8: 0.001,
    drop9: "다크 매티",
    probability9: 0.0007,
    drop10: "게파트",
    probability10: 0.0008,
    drop11: "헤클러",
    probability11: 0.0006,
    drop12: "뚜러",
    probability12: 0.001,
    drop13: "골드 실비아",
    probability13: 0.001,
    drop14: "다크 티거",
    probability14: 0.0008,
    drop15: "월비 표창",
    probability15: 0.0006,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 라이오너",
    drop1: "블루 가이즈",
    probability1: 0.001,
    drop2: "블루 헌터부츠",
    probability2: 0.001,
    drop3: "다크 가디언",
    probability3: 0.0007,
    drop4: "골드 스케일러",
    probability4: 0.0008,
    drop5: "미스릴 폴암",
    probability5: 0.001,
    drop6: "블랙 솔트슈즈",
    probability6: 0.0008,
    drop7: "다크 스타라이트",
    probability7: 0.0006,
    drop8: "리프 크로",
    probability8: 0.001,
    drop9: "블루 크로스 체인메일",
    probability9: 0.0008,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 그류핀",
    drop1: "다크 데빌즈 로브",
    probability1: 0.0009,
    drop2: "그린 핀터",
    probability2: 0.0008,
    drop3: "브라운 레골리스",
    probability3: 0.0007,
    drop4: "브라운 레골러 바지",
    probability4: 0.0007,
    drop5: "블루문",
    probability5: 0.0007,
    drop6: "창천의",
    probability6: 0.001,
    drop7: "골드 아르비욘",
    probability7: 0.0007,
    drop8: "그린 네페르",
    probability8: 0.001,
    drop9: "반월 자마다르",
    probability9: 0.0009,
    drop10: "호스맨즈",
    probability10: 0.0007,
    drop11: "금비 표창",
    probability11: 0.0005,
    drop12: "하늘색 우산",
    probability12: 0.0006,
    drop13: "반 백무 바지",
    probability13: 0.0007,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 리티",
    drop1: "브라운 폴레패더햇",
    probability1: 0.0008,
    drop2: "다크 브리건",
    probability2: 0.0007,
    drop3: "다크 데빌즈 로브",
    probability3: 0.0009,
    drop4: "다크 가이즈",
    probability4: 0.0009,
    drop5: "그린 레골리스",
    probability5: 0.0007,
    drop6: "다크 레골러 바지",
    probability6: 0.0007,
    drop7: "게파트",
    probability7: 0.0008,
    drop8: "그린 라인부츠",
    probability8: 0.0008,
    drop9: "블루 스타라이트",
    probability9: 0.0006,
    drop10: "왕푸",
    probability10: 0.0009,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "더키패밀리",
    drop1: "다크 브레이스",
    probability1: 0.0007,
    drop2: "베즐러드",
    probability2: 0.0006,
    drop3: "블랙 루티아",
    probability3: 0.0009,
    drop4: "블루 크로스 체인메일",
    probability4: 0.0008,
    drop5: "그린 헌터스 아머",
    probability5: 0.001,
    drop6: "그린 헌터스 아머 바지",
    probability6: 0.001,
    drop7: "브라운 그레이트 헬멧",
    probability7: 0.0008,
    drop8: "다크브라운 스틸러",
    probability8: 0.001,
    drop9: "퍼플 스틸레스 바지",
    probability9: 0.001,
    drop10: "다크 티거",
    probability10: 0.0008,
    drop11: "화이트 데빌즈 로브",
    probability11: 0.0008,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "티키",
    drop1: "레드 하프슈즈",
    probability1: 0.0006,
    drop2: "블랙 매직슈즈",
    probability2: 0.001,
    drop3: "다크 노엘",
    probability3: 0.0008,
    drop4: "블루 스타라이트",
    probability4: 0.0008,
    drop5: "브라운 레골리스",
    probability5: 0.0008,
    drop6: "브라운 레골러 바지",
    probability6: 0.0008,
    drop7: "블랙 마커",
    probability7: 0.001,
    drop8: "그린 버글러",
    probability8: 0.0008,
    drop9: "브라운 폴레패더햇",
    probability9: 0.0008,
    drop10: "버드빌",
    probability10: 0.0006,
    drop11: "눈덩이",
    probability11: 0.0008,
    drop12: "위저드 완드",
    probability12: 0.0008,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블랙 라츠",
    drop1: "다크 크로스 체인메일",
    probability1: 0.0008,
    drop2: "검은색 사제의 로브",
    probability2: 0.001,
    drop3: "브라운 레골리스",
    probability3: 0.0008,
    drop4: "브라운 레골러 바지",
    probability4: 0.0008,
    drop5: "다크브라운 스틸러",
    probability5: 0.0009,
    drop6: "블랙 스틸레스 바지",
    probability6: 0.0009,
    drop7: "그린 폴레패더햇",
    probability7: 0.0007,
    drop8: "블루 그레이트 헬멧",
    probability8: 0.0008,
    drop9: "다크 스타라이트",
    probability9: 0.0007,
    drop10: "홀 스태프",
    probability10: 0.0005,
    drop11: "다크 클리브",
    probability11: 0.0006,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "커즈아이",
    drop1: "블랙 너클베스트",
    probability1: 0.0008,
    drop2: "블루 너클베스트 바지",
    probability2: 0.0008,
    drop3: "흑무 바지",
    probability3: 0.001,
    drop4: "블루 스타라이트",
    probability4: 0.0007,
    drop5: "브라운 피에뜨",
    probability5: 0.0006,
    drop6: "브라운 피에뜨 바지",
    probability6: 0.0006,
    drop7: "다크 버글러",
    probability7: 0.0008,
    drop8: "그린 매티",
    probability8: 0.0008,
    drop9: "막대 사탕",
    probability9: 0.0008,
    drop10: "나카마키",
    probability10: 0.0007,
    drop11: "레드 듀크",
    probability11: 0.0006,
    drop12: "지천의",
    probability12: 0.001,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 레이스",
    drop1: "아다만티움 보닌",
    probability1: 0.0006,
    drop2: "배틀 보우",
    probability2: 0.001,
    drop3: "블랙 너클베스트",
    probability3: 0.001,
    drop4: "레드 너클베스트 바지",
    probability4: 0.001,
    drop5: "다크 크로스 체인메일",
    probability5: 0.001,
    drop6: "다크 잉그리트",
    probability6: 0.001,
    drop7: "다크 노엘",
    probability7: 0.0008,
    drop8: "골드 스케일러",
    probability8: 0.001,
    drop9: "니암",
    probability9: 0.0008,
    drop10: "골드 아르비욘",
    probability10: 0.0008,
    drop11: "레드 문슈즈",
    probability11: 0.0006,
    drop12: "레드 피에르슈즈",
    probability12: 0.0006,
    drop13: "목비 표창",
    probability13: 0.001,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스타픽시",
    drop1: "배틀 실드",
    probability1: 0.0007,
    drop2: "블랙 마커",
    probability2: 0.001,
    drop3: "화이트 길티언",
    probability3: 0.0006,
    drop4: "그린 라인부츠",
    probability4: 0.0008,
    drop5: "다크 스타라이트",
    probability5: 0.0007,
    drop6: "물고기 작살",
    probability6: 0.001,
    drop7: "프라이팬",
    probability7: 0.0006,
    drop8: "그린 실키부츠",
    probability8: 0.0008,
    drop9: "냄비 뚜껑",
    probability9: 0.0008,
    drop10: "흰색 두건",
    probability10: 0.0006,
    drop11: "브라운 버글러",
    probability11: 0.0008,
    drop12: "그린 레골리스",
    probability12: 0.0008,
    drop13: "다크 레골러 바지",
    probability13: 0.0008,
    drop14: "하늘색 우산",
    probability14: 0.0008,
    drop15: "오리할콘 미셀",
    probability15: 0.001,
    drop16: "노란색 우산",
    probability16: 0.0005,
    drop17: "발터2000",
    probability17: 0.0005,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 페페",
    drop1: "그린 하프슈즈",
    probability1: 0.0006,
    drop2: "브라운 버글러",
    probability2: 0.001,
    drop3: "다크 크로스 체인메일",
    probability3: 0.0008,
    drop4: "물고기 작살",
    probability4: 0.001,
    drop5: "그린 레골리스",
    probability5: 0.001,
    drop6: "다크 레골러 바지",
    probability6: 0.001,
    drop7: "블루 스틸레스 바지",
    probability7: 0.001,
    drop8: "다크 아르텐",
    probability8: 0.0006,
    drop9: "다크실버 스틸러",
    probability9: 0.001,
    drop10: "빅해머",
    probability10: 0.0008,
    drop11: "블루 듀크",
    probability11: 0.0006,
    drop12: "타이탄",
    probability12: 0.0006,
    drop13: "목비 표창",
    probability13: 0.001,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "루모",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블록퍼스",
    drop1: "스틸 가즈",
    probability1: 0.0009,
    drop2: "블랙 매직슈즈",
    probability2: 0.0008,
    drop3: "크레센트",
    probability3: 0.0006,
    drop4: "그린 레골리스",
    probability4: 0.0008,
    drop5: "다크 레골러 바지",
    probability5: 0.0008,
    drop6: "카키 쉐도우",
    probability6: 0.0006,
    drop7: "카키 쉐도우 바지",
    probability7: 0.0006,
    drop8: "그린 너클베스트",
    probability8: 0.0008,
    drop9: "블루 너클베스트 바지",
    probability9: 0.0008,
    drop10: "브라운 매티",
    probability10: 0.0008,
    drop11: "실버 배틀 그리브",
    probability11: 0.001,
    drop12: "실버 크로우",
    probability12: 0.0006,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스쿠버 페페",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "팬더 테니",
    drop1: "블루 카운터",
    probability1: 0.0006,
    drop2: "블루 헌터부츠",
    probability2: 0.001,
    drop3: "레드 문슈즈",
    probability3: 0.0006,
    drop4: "그린 너클베스트",
    probability4: 0.0009,
    drop5: "블루 너클베스트 바지",
    probability5: 0.0009,
    drop6: "다크 너클",
    probability6: 0.0006,
    drop7: "다크 스타라이트",
    probability7: 0.0008,
    drop8: "드래곤 토네일",
    probability8: 0.0006,
    drop9: "청 진월장화",
    probability9: 0.0006,
    drop10: "브라운 크라페",
    probability10: 0.0007,
    drop11: "화이트 길티언",
    probability11: 0.0007,
    drop12: "지천의",
    probability12: 0.001,
    drop13: "백무 바지",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "치크세이버",
    drop1: "그린 헌터부츠",
    probability1: 0.0009,
    drop2: "흑진일갑주 바지",
    probability2: 0.0007,
    drop3: "블루 칼라스",
    probability3: 0.0005,
    drop4: "블루 피에뜨 바지",
    probability4: 0.0007,
    drop5: "브라운 버글러",
    probability5: 0.0008,
    drop6: "다크 스타라이트",
    probability6: 0.0007,
    drop7: "실버 배틀 그리브",
    probability7: 0.001,
    drop8: "에메랄드 힐던부츠",
    probability8: 0.0005,
    drop9: "골드 체인슈즈",
    probability9: 0.001,
    drop10: "그린 크라페",
    probability10: 0.0007,
    drop11: "태극부채",
    probability11: 0.001,
    drop12: "금비 표창",
    probability12: 0.001,
    drop13: "녹마로",
    probability13: 0.0005,
    drop14: "그린 실키부츠",
    probability14: 0.0008,
    drop15: "황 흑일모자",
    probability15: 0.0005,
    drop16: "브라운 피에뜨",
    probability16: 0.0007,
    drop17: "황진일갑주",
    probability17: 0.0007,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레츠",
    drop1: "화이트 데빌즈 로브",
    probability1: 0.001,
    drop2: "블랙 너클베스트",
    probability2: 0.0008,
    drop3: "레드 너클베스트 바지",
    probability3: 0.0008,
    drop4: "청 진월장화",
    probability4: 0.0006,
    drop5: "캣츠 아이",
    probability5: 0.0008,
    drop6: "다크 문슈즈",
    probability6: 0.0006,
    drop7: "다크 브레이스",
    probability7: 0.0006,
    drop8: "다크 클리브",
    probability8: 0.0006,
    drop9: "블루 그레이트 헬멧",
    probability9: 0.0008,
    drop10: "다크 가이즈",
    probability10: 0.001,
    drop11: "페탈 스태프",
    probability11: 0.0007,
    drop12: "그린 레골리스",
    probability12: 0.0008,
    drop13: "다크 레골러 바지",
    probability13: 0.0008,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "루팡",
    drop1: "그린 하프슈즈",
    probability1: 0.0006,
    drop2: "블루 가이즈",
    probability2: 0.001,
    drop3: "그린 핀터",
    probability3: 0.001,
    drop4: "아다만티움 보닌",
    probability4: 0.0004,
    drop5: "그린 레골리스",
    probability5: 0.0007,
    drop6: "다크 레골러 바지",
    probability6: 0.0007,
    drop7: "그린 폴레패더햇",
    probability7: 0.0007,
    drop8: "다크 문슈즈",
    probability8: 0.0006,
    drop9: "금비 표창",
    probability9: 0.0005,
    drop10: "미스릴 타워 실드",
    probability10: 0.0006,
    drop11: "다크실버 스틸러",
    probability11: 0.001,
    drop12: "퍼플 스틸레스 바지",
    probability12: 0.001,
    drop13: "블루 크로스 체인메일",
    probability13: 0.0008,
    drop14: "눈덩이",
    probability14: 0.0008,
    drop15: "흰색 두건",
    probability15: 0.0006,
    drop16: "알루미늄 야구 방망이",
    probability16: 0.0008,
    drop17: "일룬",
    probability17: 0.006,
    drop18: "라이트 스콜피오",
    probability18: 0.0002,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "로랑",
    drop1: "미스릴 타워 실드",
    probability1: 0.0006,
    drop2: "검은색 튜브",
    probability2: 0.0002,
    drop3: "블루 피에르슈즈",
    probability3: 0.0006,
    drop4: "블루 스타라이트",
    probability4: 0.0008,
    drop5: "써클윈드 스태프",
    probability5: 0.0007,
    drop6: "다크 클리브",
    probability6: 0.0006,
    drop7: "다크 노엘",
    probability7: 0.0009,
    drop8: "브라운 피에뜨",
    probability8: 0.0006,
    drop9: "브라운 피에뜨 바지",
    probability9: 0.0006,
    drop10: "다크 티거",
    probability10: 0.0009,
    drop11: "태극부채",
    probability11: 0.001,
    drop12: "미스릴 폴암",
    probability12: 0.0007,
    drop13: "다크 쉐도우",
    probability13: 0.0006,
    drop14: "다크 쉐도우 바지",
    probability14: 0.0006,
    drop15: "라이덴",
    probability15: 0.0007,
    drop16: "하늘색 우산",
    probability16: 0.0008,
    drop17: "다크 크로스 체인메일",
    probability17: 0.001,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "크로노스",
    drop1: "실버 배틀 그리브",
    probability1: 0.001,
    drop2: "그린 필퍼",
    probability2: 0.0007,
    drop3: "아이언 체인슈즈",
    probability3: 0.001,
    drop4: "브라운 레골리스",
    probability4: 0.0009,
    drop5: "브라운 레골러 바지",
    probability5: 0.0009,
    drop6: "그린 너클베스트",
    probability6: 0.0009,
    drop7: "블루 너클베스트 바지",
    probability7: 0.0009,
    drop8: "자진일갑주",
    probability8: 0.0007,
    drop9: "골드 스케일러",
    probability9: 0.0009,
    drop10: "미스틱 실드",
    probability10: 0.0008,
    drop11: "블랙 솔트슈즈",
    probability11: 0.0009,
    drop12: "화이트 길티언",
    probability12: 0.0007,
    drop13: "흑진일갑주 바지",
    probability13: 0.0007,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "핑크세이버",
    drop1: "블랙 솔트슈즈",
    probability1: 0.0009,
    drop2: "다크 매티",
    probability2: 0.0008,
    drop3: "다크 페넌스",
    probability3: 0.0005,
    drop4: "파이어 잭",
    probability4: 0.0008,
    drop5: "호검",
    probability5: 0.0005,
    drop6: "막대 사탕",
    probability6: 0.001,
    drop7: "미스릴 완드",
    probability7: 0.0008,
    drop8: "미스틱 실드",
    probability8: 0.0005,
    drop9: "황진일갑주",
    probability9: 0.0007,
    drop10: "황진일갑주 바지",
    probability10: 0.0007,
    drop11: "다크 필퍼",
    probability11: 0.0007,
    drop12: "레드 라인부츠",
    probability12: 0.001,
    drop13: "로우어",
    probability13: 0.0005,
    drop14: "골드 아르비욘",
    probability14: 0.0009,
    drop15: "다크 슬레인",
    probability15: 0.0005,
    drop16: "브라운 피에뜨",
    probability16: 0.0007,
    drop17: "브라운 피에뜨 바지",
    probability17: 0.0007,
    drop18: "레드 듀크",
    probability18: 0.0007,
    drop19: "알루미늄 야구 방망이",
    probability19: 0.001,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 씰",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "트리플 루모",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "킹 블록퍼스",
    drop1: "도끼 폴암",
    probability1: 0.0009,
    drop2: "블루 칼라스",
    probability2: 0.0005,
    drop3: "블루 듀크",
    probability3: 0.0007,
    drop4: "블루문",
    probability4: 0.0007,
    drop5: "다크 가디언",
    probability5: 0.0009,
    drop6: "브라운 피에르타",
    probability6: 0.0007,
    drop7: "블루 피에뜨 바지",
    probability7: 0.0007,
    drop8: "다크 길티언",
    probability8: 0.0007,
    drop9: "그린 라인부츠",
    probability9: 0.001,
    drop10: "다크 쉐도우",
    probability10: 0.0007,
    drop11: "다크 쉐도우 바지",
    probability11: 0.0007,
    drop12: "레드 바이퍼",
    probability12: 0.0009,
    drop13: "다크 브리건",
    probability13: 0.001,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스카이세이버",
    drop1: "아다만티움 보닌",
    probability1: 0.0006,
    drop2: "브라운 크라페",
    probability2: 0.0008,
    drop3: "자진일갑주",
    probability3: 0.0008,
    drop4: "다크 페넌스",
    probability4: 0.0006,
    drop5: "황진일갑주 바지",
    probability5: 0.0008,
    drop6: "황 진월장화",
    probability6: 0.0008,
    drop7: "다크 쉐도우",
    probability7: 0.0008,
    drop8: "다크 쉐도우 바지",
    probability8: 0.0008,
    drop9: "그린 고니슈즈",
    probability9: 0.0006,
    drop10: "그린 필퍼",
    probability10: 0.0008,
    drop11: "헤클러",
    probability11: 0.0008,
    drop12: "미스릴 브리스트",
    probability12: 0.0006,
    drop13: "나카마키",
    probability13: 0.0008,
    drop14: "펜지 이어링",
    probability14: 0.0007,
    drop15: "페탈 스태프",
    probability15: 0.0008,
    drop16: "다크 윌로우",
    probability16: 0.0006,
    drop17: "다크 가디언",
    probability17: 0.0008,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "장난감 목마",
    drop1: "청룡의",
    probability1: 0.001,
    drop2: "브라운 레골리스",
    probability2: 0.001,
    drop3: "브라운 레골러 바지",
    probability3: 0.001,
    drop4: "브라운 필퍼",
    probability4: 0.0008,
    drop5: "크레센트",
    probability5: 0.0007,
    drop6: "다크 아르텐",
    probability6: 0.0007,
    drop7: "레드 듀크",
    probability7: 0.0008,
    drop8: "블루 칼라스",
    probability8: 0.0006,
    drop9: "다크 클리브",
    probability9: 0.0008,
    drop10: "사파이어 귀고리",
    probability10: 0.001,
    drop11: "반 백무 바지",
    probability11: 0.001,
    drop12: "레드 피에르슈즈",
    probability12: 0.0008,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "트위터",
    drop1: "레드 하프슈즈",
    probability1: 0.0008,
    drop2: "레드 문슈즈",
    probability2: 0.0008,
    drop3: "자진일갑주",
    probability3: 0.0008,
    drop4: "흑진일갑주 바지",
    probability4: 0.0008,
    drop5: "레드 피에르슈즈",
    probability5: 0.0008,
    drop6: "다크 브레이스",
    probability6: 0.0008,
    drop7: "다크 윌로우",
    probability7: 0.0006,
    drop8: "페어리 완드",
    probability8: 0.0009,
    drop9: "막대 사탕",
    probability9: 0.001,
    drop10: "미스릴 브리스트",
    probability10: 0.0006,
    drop11: "블루 칼라스",
    probability11: 0.0006,
    drop12: "빅해머",
    probability12: 0.0009,
    drop13: "미스릴 타워 실드",
    probability13: 0.0008,
    drop14: "황월 장갑",
    probability14: 0.0006,
    drop15: "하이랜더",
    probability15: 0.0009,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "콜드아이",
    drop1: "브라운 크라페",
    probability1: 0.0008,
    drop2: "아다만티움 보닌",
    probability2: 0.0008,
    drop3: "다크 가디언",
    probability3: 0.0009,
    drop4: "이글크로",
    probability4: 0.001,
    drop5: "에메랄드 힐던부츠",
    probability5: 0.0006,
    drop6: "레드 듀크",
    probability6: 0.0008,
    drop7: "고드름",
    probability7: 0.0008,
    drop8: "니암",
    probability8: 0.0009,
    drop9: "핑크 골드윈드슈즈",
    probability9: 0.0006,
    drop10: "다크문 고깔모자",
    probability10: 0.008,
    drop11: "그린 크라페",
    probability11: 0.0008,
    drop12: "블랙 솔트슈즈",
    probability12: 0.001,
    drop13: "카키 쉐도우",
    probability13: 0.0008,
    drop14: "카키 쉐도우 바지",
    probability14: 0.0008,
    drop15: "황월 장갑",
    probability15: 0.0006,
    drop16: "캣츠 아이",
    probability16: 0.0008,
    drop17: "그린 헌터스 아머 바지",
    probability17: 0.0007,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "좀비루팡",
    drop1: "아크 스태프",
    probability1: 0.0006,
    drop2: "자진일갑주",
    probability2: 0.0007,
    drop3: "흑진일갑주 바지",
    probability3: 0.0007,
    drop4: "모험가의 망토",
    probability4: 0.0006,
    drop5: "아다만티움 보닌",
    probability5: 0.0006,
    drop6: "다크 길티언",
    probability6: 0.0007,
    drop7: "다크 필퍼",
    probability7: 0.0007,
    drop8: "다크 스타라이트",
    probability8: 0.0008,
    drop9: "다크 크로스 체인메일",
    probability9: 0.001,
    drop10: "그린 레골리스",
    probability10: 0.001,
    drop11: "다크 레골러 바지",
    probability11: 0.001,
    drop12: "그린 너클베스트",
    probability12: 0.001,
    drop13: "블루 너클베스트 바지",
    probability13: 0.001,
    drop14: "흰색 두건",
    probability14: 0.0006,
    drop15: "로우어",
    probability15: 0.0004,
    drop16: "신기타",
    probability16: 0.0004,
    drop17: "실버 크로우",
    probability17: 0.0003,
    drop18: "구룡도",
    probability18: 0.0004,
    drop19: "황진일갑주",
    probability19: 0.0004,
    drop20: "그린 레골리스",
    probability20: 0.0006,
  },
  {
    이름: "푸퍼",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "포이즌 푸퍼",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "별다람쥐",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "모래거인",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "틱톡",
    drop1: "다크 브레이스",
    probability1: 0.0007,
    drop2: "그린 하프슈즈",
    probability2: 0.0007,
    drop3: "블루 너클베스트 바지",
    probability3: 0.001,
    drop4: "다크 매티",
    probability4: 0.001,
    drop5: "블루 스타라이트",
    probability5: 0.0008,
    drop6: "크로미",
    probability6: 0.0005,
    drop7: "블루 그레이트 헬멧",
    probability7: 0.001,
    drop8: "홀 스태프",
    probability8: 0.0008,
    drop9: "다크 너클",
    probability9: 0.0007,
    drop10: "너클메이스",
    probability10: 0.0005,
    drop11: "펜지 이어링",
    probability11: 0.0007,
    drop12: "브라운 폴레패더햇",
    probability12: 0.001,
    drop13: "차",
    probability13: 0.0005,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "바나드 그레이",
    drop1: "배틀 실드",
    probability1: 0.0008,
    drop2: "그린 하프슈즈",
    probability2: 0.0007,
    drop3: "청마로",
    probability3: 0.0005,
    drop4: "레드 피에르슈즈",
    probability4: 0.0007,
    drop5: "다크 쉐도우",
    probability5: 0.0007,
    drop6: "다크 쉐도우 바지",
    probability6: 0.0007,
    drop7: "호스맨즈",
    probability7: 0.0008,
    drop8: "다크 칼라스",
    probability8: 0.0005,
    drop9: "다크 아르텐",
    probability9: 0.0007,
    drop10: "실버 크루세이더 헬름",
    probability10: 0.0005,
    drop11: "위저드 완드",
    probability11: 0.0008,
    drop12: "노란색 우산",
    probability12: 0.0008,
    drop13: "하이랜더",
    probability13: 0.0008,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "플라이아이",
    drop1: "다크 아르텐",
    probability1: 0.0008,
    drop2: "다크 스타라이트",
    probability2: 0.0009,
    drop3: "다크 칼라스",
    probability3: 0.0005,
    drop4: "다크 브레이스",
    probability4: 0.0008,
    drop5: "금룡의",
    probability5: 0.001,
    drop6: "그린 고니슈즈",
    probability6: 0.0005,
    drop7: "카키 쉐도우",
    probability7: 0.0008,
    drop8: "카키 쉐도우 바지",
    probability8: 0.0008,
    drop9: "태극부채",
    probability9: 0.001,
    drop10: "올림푸스",
    probability10: 0.0005,
    drop11: "에메랄드 힐던부츠",
    probability11: 0.0005,
    drop12: "그린 레골리스",
    probability12: 0.001,
    drop13: "다크 레골러 바지",
    probability13: 0.001,
    drop14: "반 백무 바지",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "호저",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마티안",
    drop1: "퍼플 네일슈즈",
    probability1: 0.0006,
    drop2: "골드 스케일러",
    probability2: 0.001,
    drop3: "블루 스타라이트",
    probability3: 0.0009,
    drop4: "다크 페넌스",
    probability4: 0.0006,
    drop5: "그린 버글러",
    probability5: 0.001,
    drop6: "님블 리스트",
    probability6: 0.0005,
    drop7: "황진일갑주",
    probability7: 0.0008,
    drop8: "황진일갑주 바지",
    probability8: 0.0008,
    drop9: "카키 쉐도우",
    probability9: 0.0008,
    drop10: "카키 쉐도우 바지",
    probability10: 0.0008,
    drop11: "청룡의",
    probability11: 0.001,
    drop12: "반 백무 바지",
    probability12: 0.001,
    drop13: "다크 티거",
    probability13: 0.001,
    drop14: "황월 장갑",
    probability14: 0.0006,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "로보 토이",
    drop1: "블루 크로스 체인메일",
    probability1: 0.001,
    drop2: "청 진월장화",
    probability2: 0.0008,
    drop3: "다크 문슈즈",
    probability3: 0.0008,
    drop4: "그린 크라페",
    probability4: 0.0008,
    drop5: "브라운 네일슈즈",
    probability5: 0.0006,
    drop6: "쥬얼 쿠아다라",
    probability6: 0.0006,
    drop7: "다크 데빌즈 로브",
    probability7: 0.001,
    drop8: "블루 크로스 체인메일",
    probability8: 0.001,
    drop9: "화이트 길티언",
    probability9: 0.0008,
    drop10: "블루 듀크",
    probability10: 0.0009,
    drop11: "다크 슬레인",
    probability11: 0.0005,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "플래툰 크로노스",
    drop1: "브라운 레골리스",
    probability1: 0.001,
    drop2: "브라운 레골러 바지",
    probability2: 0.001,
    drop3: "벅",
    probability3: 0.0005,
    drop4: "다크 클리브",
    probability4: 0.0008,
    drop5: "에스터 실드",
    probability5: 0.0008,
    drop6: "다크 문슈즈",
    probability6: 0.0008,
    drop7: "그린 매티",
    probability7: 0.001,
    drop8: "자진일갑주",
    probability8: 0.0008,
    drop9: "미스릴 타워 실드",
    probability9: 0.0008,
    drop10: "브라운 버글러",
    probability10: 0.001,
    drop11: "다크 스타라이트",
    probability11: 0.0009,
    drop12: "장팔사모",
    probability12: 0.0005,
    drop13: "흑진일갑주 바지",
    probability13: 0.0008,
    drop14: "쟈드",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "아이언 호그",
    drop1: "미스릴 타워 실드",
    probability1: 0.0008,
    drop2: "다크 슬레인",
    probability2: 0.0005,
    drop3: "블루 피에르슈즈",
    probability3: 0.0008,
    drop4: "브라운 버글러",
    probability4: 0.001,
    drop5: "화이트 길티언",
    probability5: 0.0008,
    drop6: "당커",
    probability6: 0.001,
    drop7: "다크 클리브",
    probability7: 0.0008,
    drop8: "반월 자마다르",
    probability8: 0.001,
    drop9: "너클메이스",
    probability9: 0.0005,
    drop10: "다크 칼라스",
    probability10: 0.0005,
    drop11: "레드 문슈즈",
    probability11: 0.0008,
    drop12: "블루 루이마리",
    probability12: 0.0005,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "네펜데스",
    drop1: "아다만티움 보닌",
    probability1: 0.0007,
    drop2: "그린 필퍼",
    probability2: 0.001,
    drop3: "브라운 피에뜨",
    probability3: 0.0009,
    drop4: "브라운 피에뜨 바지",
    probability4: 0.0009,
    drop5: "레드 듀크",
    probability5: 0.001,
    drop6: "그린 루이마리",
    probability6: 0.0005,
    drop7: "홀 스태프",
    probability7: 0.0007,
    drop8: "태극부채",
    probability8: 0.001,
    drop9: "다크 쉐도우",
    probability9: 0.0009,
    drop10: "다크 쉐도우 바지",
    probability10: 0.0009,
    drop11: "흰색 두건",
    probability11: 0.0006,
    drop12: "레드 고니슈즈",
    probability12: 0.0005,
    drop13: "모험가의 망토",
    probability13: 0.0006,
    drop14: "그린 실키부츠",
    probability14: 0.001,
    drop15: "다크 칼라스",
    probability15: 0.0007,
    drop16: "골드 브리스트",
    probability16: 0.0005,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "프리져",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "아이언 뮤테",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블록골렘",
    drop1: "브라운 크라페",
    probability1: 0.0009,
    drop2: "엄버 숄더메일",
    probability2: 0.0006,
    drop3: "다크 숄더메일 바지",
    probability3: 0.0006,
    drop4: "아다만티움 보닌",
    probability4: 0.0008,
    drop5: "다크 버글러",
    probability5: 0.001,
    drop6: "다크 길티언",
    probability6: 0.0009,
    drop7: "다크 문슈즈",
    probability7: 0.0008,
    drop8: "골드 스케일러",
    probability8: 0.001,
    drop9: "펜지 이어링",
    probability9: 0.0005,
    drop10: "다크 스타라이트",
    probability10: 0.0009,
    drop11: "로우어",
    probability11: 0.0006,
    drop12: "왕푸",
    probability12: 0.001,
    drop13: "해골 방패",
    probability13: 0.0006,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "제타 그레이",
    drop1: "녹 흑일모자",
    probability1: 0.0006,
    drop2: "자진일갑주",
    probability2: 0.0009,
    drop3: "흑진일갑주 바지",
    probability3: 0.0009,
    drop4: "벅",
    probability4: 0.0006,
    drop5: "다크 칼라스",
    probability5: 0.0006,
    drop6: "브라운 피에르타",
    probability6: 0.0008,
    drop7: "브라운 피에뜨 바지",
    probability7: 0.0008,
    drop8: "주르건 리스트",
    probability8: 0.0005,
    drop9: "목비 표창",
    probability9: 0.001,
    drop10: "오렌지 골든서클릿",
    probability10: 0.0006,
    drop11: "골드 아르비욘",
    probability11: 0.001,
    drop12: "녹마로",
    probability12: 0.0006,
    drop13: "신기타",
    probability13: 0.0006,
    drop14: "다크 브리건",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "이끼 달팽이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 불독",
    drop1: "그린 하프슈즈",
    probability1: 0.0009,
    drop2: "실버 크루세이더 헬름",
    probability2: 0.0006,
    drop3: "브라운 피에르타",
    probability3: 0.0009,
    drop4: "블루 피에뜨 바지",
    probability4: 0.0009,
    drop5: "다크 브레이스",
    probability5: 0.0009,
    drop6: "다크 골든서클릿",
    probability6: 0.0006,
    drop7: "다크 티거",
    probability7: 0.001,
    drop8: "물고기 작살",
    probability8: 0.001,
    drop9: "녹 흑일모자",
    probability9: 0.0006,
    drop10: "목비 표창",
    probability10: 0.001,
    drop11: "다크 슬레인",
    probability11: 0.0006,
    drop12: "구룡도",
    probability12: 0.0006,
    drop13: "타이탄",
    probability13: 0.0009,
    drop14: "화이트 데빌즈 로브",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스파커",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "흑저",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "월묘",
    drop1: "미스릴 타워 실드",
    probability1: 0.0005,
    drop2: "그린 하프슈즈",
    probability2: 0.001,
    drop3: "블루 카운터",
    probability3: 0.0008,
    drop4: "블루문",
    probability4: 0.001,
    drop5: "모험가의 망토",
    probability5: 0.0006,
    drop6: "다크 브레이스",
    probability6: 0.0009,
    drop7: "다크 티거",
    probability7: 0.0009,
    drop8: "목비 표창",
    probability8: 0.001,
    drop9: "노란색 우산",
    probability9: 0.0008,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스켈독",
    drop1: "그린 하프슈즈",
    probability1: 0.0008,
    drop2: "그린 루이마리",
    probability2: 0.0008,
    drop3: "브라운 매티",
    probability3: 0.001,
    drop4: "블루 그레이트 헬멧",
    probability4: 0.001,
    drop5: "그린 버글러",
    probability5: 0.001,
    drop6: "홀 스태프",
    probability6: 0.0007,
    drop7: "청 진월장화",
    probability7: 0.0008,
    drop8: "목비 표창",
    probability8: 0.001,
    drop9: "레드 피에르슈즈",
    probability9: 0.0008,
    drop10: "차",
    probability10: 0.0006,
    drop11: "타이탄",
    probability11: 0.0007,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마스터 로보",
    drop1: "그린 하프슈즈",
    probability1: 0.0009,
    drop2: "녹마로",
    probability2: 0.0006,
    drop3: "블루 실키부츠",
    probability3: 0.001,
    drop4: "브라운 크라페",
    probability4: 0.0009,
    drop5: "황 진월장화",
    probability5: 0.0009,
    drop6: "다크 너클",
    probability6: 0.0009,
    drop7: "다크 스타라이트",
    probability7: 0.0009,
    drop8: "청일",
    probability8: 0.0006,
    drop9: "청일 바지",
    probability9: 0.0006,
    drop10: "쥬얼 쿠아다라",
    probability10: 0.0008,
    drop11: "호검",
    probability11: 0.0006,
    drop12: "다크 슬레인",
    probability12: 0.0007,
    drop13: "라이징",
    probability13: 0.0006,
    drop14: "노란색 우산",
    probability14: 0.0008,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "플라티안",
    drop1: "자진일갑주",
    probability1: 0.001,
    drop2: "흑진일갑주 바지",
    probability2: 0.001,
    drop3: "다크 윌로우",
    probability3: 0.0006,
    drop4: "브라운 폴레패더햇",
    probability4: 0.001,
    drop5: "다크 칼라스",
    probability5: 0.0006,
    drop6: "다크 쉐도우",
    probability6: 0.0008,
    drop7: "다크 쉐도우 바지",
    probability7: 0.0008,
    drop8: "올림푸스",
    probability8: 0.0006,
    drop9: "블루 고니슈즈",
    probability9: 0.0006,
    drop10: "그린 루이마리",
    probability10: 0.0006,
    drop11: "다크 매티",
    probability11: 0.001,
    drop12: "장팔사모",
    probability12: 0.0006,
    drop13: "브라운 피에르타",
    probability13: 0.001,
    drop14: "브라운 피에뜨 바지",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "카파 드레이크",
    drop1: "아크 스태프",
    probability1: 0.0007,
    drop2: "본헬름",
    probability2: 0.0008,
    drop3: "브라운 폴레패더햇",
    probability3: 0.0,
    drop4: "브라운 피에뜨",
    probability4: 0.0009,
    drop5: "브라운 피에뜨 바지",
    probability5: 0.0009,
    drop6: "다크 아르텐",
    probability6: 0.0009,
    drop7: "다크 크로스 체인메일",
    probability7: 0.001,
    drop8: "젝커",
    probability8: 0.0009,
    drop9: "쥬얼 쿠아다라",
    probability9: 0.0006,
    drop10: "에메랄드 힐던부츠",
    probability10: 0.0006,
    drop11: "퍼플 네일슈즈",
    probability11: 0.0006,
    drop12: "블루 칼라스",
    probability12: 0.0007,
    drop13: "차",
    probability13: 0.0006,
    drop14: "환목검",
    probability14: 0.0007,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "루나픽시",
    drop1: "오렌지 골든서클릿",
    probability1: 0.0007,
    drop2: "흰색 두건",
    probability2: 0.0006,
    drop3: "블루 칼라스",
    probability3: 0.0007,
    drop4: "자진일갑주",
    probability4: 0.0009,
    drop5: "모험가의 망토",
    probability5: 0.0006,
    drop6: "블루 피에뜨 바지",
    probability6: 0.0009,
    drop7: "그린 루이마리",
    probability7: 0.0007,
    drop8: "황진일갑주 바지",
    probability8: 0.0009,
    drop9: "황 흑일모자",
    probability9: 0.0007,
    drop10: "다크 브리건",
    probability10: 0.0009,
    drop11: "골드 아르비욘",
    probability11: 0.001,
    drop12: "목비 표창",
    probability12: 0.001,
    drop13: "청일",
    probability13: 0.0007,
    drop14: "청일 바지",
    probability14: 0.0007,
    drop15: "레드 피에르슈즈",
    probability15: 0.0009,
    drop16: "브라운 피에뜨",
    probability16: 0.0009,
    drop17: "노란색 우산",
    probability17: 0.0008,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "청화사",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "홍화사",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "강화된 아이언 뮤테",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "킹 블록골렘",
    drop1: "브라운 매티",
    probability1: 0.001,
    drop2: "그린 크라페",
    probability2: 0.0008,
    drop3: "다크 윌로우",
    probability3: 0.0006,
    drop4: "골드 힐던부츠",
    probability4: 0.0006,
    drop5: "브라운 그레이트 헬멧",
    probability5: 0.001,
    drop6: "그린 레골리스",
    probability6: 0.001,
    drop7: "다크 레골러 바지",
    probability7: 0.001,
    drop8: "카키 쉐도우",
    probability8: 0.0008,
    drop9: "카키 쉐도우 바지",
    probability9: 0.0008,
    drop10: "오우커 숄더메일",
    probability10: 0.0006,
    drop11: "오우커 숄더메일 바지",
    probability11: 0.0006,
    drop12: "황진일갑주",
    probability12: 0.0008,
    drop13: "황진일갑주 바지",
    probability13: 0.0008,
    drop14: "다크 페넌스",
    probability14: 0.0006,
    drop15: "다크 필퍼",
    probability15: 0.0008,
    drop16: "붉은 채찍",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "울트라 그레이",
    drop1: "블루 스타라이트",
    probability1: 0.001,
    drop2: "황진일갑주 바지",
    probability2: 0.0008,
    drop3: "그린 레골리스",
    probability3: 0.001,
    drop4: "다크 레골러 바지",
    probability4: 0.001,
    drop5: "다크 슬레인",
    probability5: 0.0005,
    drop6: "다크 돔",
    probability6: 0.0007,
    drop7: "골든 모울",
    probability7: 0.0006,
    drop8: "그린 골드윈드슈즈",
    probability8: 0.0006,
    drop9: "카키 쉐도우",
    probability9: 0.0009,
    drop10: "카키 쉐도우 바지",
    probability10: 0.0009,
    drop11: "해골 귀고리",
    probability11: 0.0007,
    drop12: "토비 표창",
    probability12: 0.001,
    drop13: "화이트 길티언",
    probability13: 0.0009,
    drop14: "황진일갑주",
    probability14: 0.0008,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "엄티",
    drop1: "아다만티움 보닌",
    probability1: 0.001,
    drop2: "검은색 튜브",
    probability2: 0.0002,
    drop3: "화이트 길티언",
    probability3: 0.001,
    drop4: "브라운 네일슈즈",
    probability4: 0.0007,
    drop5: "황일",
    probability5: 0.0007,
    drop6: "청일 바지",
    probability6: 0.0007,
    drop7: "파노 튜브",
    probability7: 0.0002,
    drop8: "엄버 숄더메일",
    probability8: 0.0007,
    drop9: "다크 숄더메일 바지",
    probability9: 0.0007,
    drop10: "그린 필퍼",
    probability10: 0.001,
    drop11: "호검",
    probability11: 0.0007,
    drop12: "핑크 골드윈드슈즈",
    probability12: 0.0007,
    drop13: "해골 방패",
    probability13: 0.0007,
    drop14: "브라운 피에르타",
    probability14: 0.001,
    drop15: "브라운 피에뜨 바지",
    probability15: 0.001,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마스터 크로노스",
    drop1: "핑크 골드윈드슈즈",
    probability1: 0.0006,
    drop2: "황 진월장화",
    probability2: 0.001,
    drop3: "브라운 피에뜨",
    probability3: 0.0009,
    drop4: "브라운 피에뜨 바지",
    probability4: 0.0009,
    drop5: "다크 칼라스",
    probability5: 0.0007,
    drop6: "다크 슬레인",
    probability6: 0.0006,
    drop7: "골든 모울",
    probability7: 0.0006,
    drop8: "오우커 숄더메일",
    probability8: 0.0007,
    drop9: "오우커 숄더메일 바지",
    probability9: 0.0007,
    drop10: "골드 스케일러",
    probability10: 0.001,
    drop11: "레드 피에르슈즈",
    probability11: 0.001,
    drop12: "토비 표창",
    probability12: 0.0007,
    drop13: "황월 장갑",
    probability13: 0.0007,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "메카티안",
    drop1: "다크 데빌즈 로브",
    probability1: 0.001,
    drop2: "다크 크로스 체인메일",
    probability2: 0.001,
    drop3: "브라운 필퍼",
    probability3: 0.0009,
    drop4: "크로미",
    probability4: 0.0007,
    drop5: "다크 브레이스",
    probability5: 0.0009,
    drop6: "황일",
    probability6: 0.0007,
    drop7: "청일 바지",
    probability7: 0.0007,
    drop8: "골드 아르비욘",
    probability8: 0.001,
    drop9: "다크 스타라이트",
    probability9: 0.0009,
    drop10: "라이징",
    probability10: 0.0007,
    drop11: "엄버 숄더메일",
    probability11: 0.0007,
    drop12: "다크 숄더메일 바지",
    probability12: 0.0007,
    drop13: "화이트 길티언",
    probability13: 0.0009,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "트리로드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "머미독",
    drop1: "블루 칼라스",
    probability1: 0.0008,
    drop2: "다크 클랜치",
    probability2: 0.0007,
    drop3: "골드 파우",
    probability3: 0.0007,
    drop4: "황진일갑주 바지",
    probability4: 0.001,
    drop5: "브라운 피에뜨 바지",
    probability5: 0.0009,
    drop6: "브라운 피에르타",
    probability6: 0.0009,
    drop7: "미스릴 브리스트",
    probability7: 0.0008,
    drop8: "다크 가너",
    probability8: 0.0007,
    drop9: "황 진월장화",
    probability9: 0.001,
    drop10: "황월 장갑",
    probability10: 0.0008,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 네펜데스",
    drop1: "그린 하프슈즈",
    probability1: 0.0009,
    drop2: "다크 아르텐",
    probability2: 0.0009,
    drop3: "청일",
    probability3: 0.0007,
    drop4: "청일 바지",
    probability4: 0.0007,
    drop5: "다크 윌로우",
    probability5: 0.0006,
    drop6: "다크 스타라이트",
    probability6: 0.001,
    drop7: "브라운 피에뜨",
    probability7: 0.001,
    drop8: "브라운 피에뜨 바지",
    probability8: 0.001,
    drop9: "에메랄드 힐던부츠",
    probability9: 0.0006,
    drop10: "엄버 숄더메일",
    probability10: 0.0007,
    drop11: "다크 숄더메일 바지",
    probability11: 0.0007,
    drop12: "붉은 채찍",
    probability12: 0.0005,
    drop13: "레드 듀크",
    probability13: 0.001,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "단지",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "미스릴 뮤테",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "롬바드",
    drop1: "베이지 우산",
    probability1: 0.03,
    drop2: "호검",
    probability2: 0.03,
    drop3: "훌라후프",
    probability3: 0.03,
    drop4: "라이징",
    probability4: 0.03,
    drop5: "검은색 튜브",
    probability5: 0.03,
    drop6: "골든 모울",
    probability6: 0.03,
    drop7: "낚싯대",
    probability7: 0.03,
    drop8: "구룡도",
    probability8: 0.03,
    drop9: "쏜즈",
    probability9: 0.03,
    drop10: "우선",
    probability10: 0.03,
    drop11: "다크 슬레인",
    probability11: 0.03,
    drop12: "쌍고검",
    probability12: 0.03,
    drop13: "차",
    probability13: 0.03,
    drop14: "조가지궁",
    probability14: 0.03,
    drop15: "로우어",
    probability15: 0.03,
    drop16: "해골 방패",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레이스",
    drop1: "흰색 두건",
    probability1: 0.0006,
    drop2: "그린 골드윈드슈즈",
    probability2: 0.0006,
    drop3: "블랙 너클베스트",
    probability3: 0.001,
    drop4: "청마로",
    probability4: 0.0007,
    drop5: "캣츠 아이",
    probability5: 0.001,
    drop6: "오렌지 골든서클릿",
    probability6: 0.0007,
    drop7: "그린 너클베스트",
    probability7: 0.001,
    drop8: "자진일갑주",
    probability8: 0.0008,
    drop9: "레드 듀크",
    probability9: 0.0008,
    drop10: "녹마로",
    probability10: 0.0007,
    drop11: "황 흑일모자",
    probability11: 0.0007,
    drop12: "붉은 채찍",
    probability12: 0.0005,
    drop13: "황진일갑주 바지",
    probability13: 0.0008,
    drop14: "모험가의 망토",
    probability14: 0.0006,
    drop15: "황월 장갑",
    probability15: 0.0006,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "클랑",
    drop1: "아크 스태프",
    probability1: 0.0007,
    drop2: "청일",
    probability2: 0.0006,
    drop3: "청일 바지",
    probability3: 0.0006,
    drop4: "브라운 피에르타",
    probability4: 0.001,
    drop5: "블루 피에뜨 바지",
    probability5: 0.001,
    drop6: "다크 쉐도우",
    probability6: 0.001,
    drop7: "다크 쉐도우 바지",
    probability7: 0.001,
    drop8: "실버 크루세이더 헬름",
    probability8: 0.0008,
    drop9: "오렌지 골든서클릿",
    probability9: 0.0008,
    drop10: "레드 듀크",
    probability10: 0.001,
    drop11: "엄버 숄더메일",
    probability11: 0.0008,
    drop12: "다크 숄더메일 바지",
    probability12: 0.0008,
    drop13: "연두색 서핑보드",
    probability13: 0.0002,
    drop14: "라이징",
    probability14: 0.0006,
    drop15: "노란색 우산",
    probability15: 0.0005,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "삼단지",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "원로 그레이",
    drop1: "다크 골든서클릿",
    probability1: 0.0007,
    drop2: "브라운 네일슈즈",
    probability2: 0.0007,
    drop3: "다크 페넌스",
    probability3: 0.0007,
    drop4: "브라운 피에르타",
    probability4: 0.001,
    drop5: "브라운 피에뜨 바지",
    probability5: 0.001,
    drop6: "젝커",
    probability6: 0.001,
    drop7: "에메랄드 힐던부츠",
    probability7: 0.0007,
    drop8: "낡은 미스릴 노르만 헬름",
    probability8: 0.0007,
    drop9: "다크 쉐도우",
    probability9: 0.001,
    drop10: "다크 쉐도우 바지",
    probability10: 0.001,
    drop11: "퍼플 네일슈즈",
    probability11: 0.0007,
    drop12: "레드 고니슈즈",
    probability12: 0.0007,
    drop13: "마법의 망토",
    probability13: 0.0006,
    drop14: "신기타",
    probability14: 0.0007,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "드레이크",
    drop1: "그린 하프슈즈",
    probability1: 0.001,
    drop2: "블루 루이마리",
    probability2: 0.0007,
    drop3: "다크 칼라스",
    probability3: 0.0007,
    drop4: "다크 윌로우",
    probability4: 0.0007,
    drop5: "다크 문슈즈",
    probability5: 0.001,
    drop6: "금비 표창",
    probability6: 0.003,
    drop7: "황일",
    probability7: 0.0007,
    drop8: "청일 바지",
    probability8: 0.0007,
    drop9: "버드빌",
    probability9: 0.001,
    drop10: "쏜즈",
    probability10: 0.0007,
    drop11: "엄버 숄더메일",
    probability11: 0.0007,
    drop12: "다크 숄더메일 바지",
    probability12: 0.0007,
    drop13: "발터2000",
    probability13: 0.001,
    drop14: "제코",
    probability14: 0.001,
    drop15: "냄비 뚜껑",
    probability15: 0.0005,
    drop16: "이글크로",
    probability16: 0.0008,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 예티",
    drop1: "블루 아나카문",
    probability1: 0.0005,
    drop2: "블루 루이마리",
    probability2: 0.0005,
    drop3: "크레센트",
    probability3: 0.0005,
    drop4: "다크 우드쉽",
    probability4: 0.002,
    drop5: "블랙 솔트슈즈",
    probability5: 0.001,
    drop6: "레드 바이퍼",
    probability6: 0.0005,
    drop7: "다크 스콜피오",
    probability7: 0.0004,
    drop8: "다크 스콜피오 바지",
    probability8: 0.0004,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "훈련용 짚인형",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "강화된 미스릴 뮤테",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "호돌이",
    drop1: "베즐러드",
    probability1: 0.001,
    drop2: "벅",
    probability2: 0.0005,
    drop3: "그린 루이마리",
    probability3: 0.0005,
    drop4: "오우커 숄더메일",
    probability4: 0.0004,
    drop5: "너클메이스",
    probability5: 0.0005,
    drop6: "오우커 숄더메일 바지",
    probability6: 0.0004,
    drop7: "레드 피에르슈즈",
    probability7: 0.002,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파이어봄",
    drop1: "녹마로",
    probability1: 0.0006,
    drop2: "블러드 모스부츠",
    probability2: 0.0005,
    drop3: "다크 스타라이트",
    probability3: 0.001,
    drop4: "엄버 숄더메일",
    probability4: 0.0007,
    drop5: "다크 숄더메일 바지",
    probability5: 0.0007,
    drop6: "다크 쉐도우",
    probability6: 0.001,
    drop7: "다크 쉐도우 바지",
    probability7: 0.001,
    drop8: "녹 흑일모자",
    probability8: 0.0008,
    drop9: "너클메이스",
    probability9: 0.0006,
    drop10: "다크 세라피스",
    probability10: 0.0005,
    drop11: "차",
    probability11: 0.0006,
    drop12: "노란색 우산",
    probability12: 0.001,
    drop13: "금비 표창",
    probability13: 0.0005,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "훈련용 나무인형",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스톤버그",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "크로코",
    drop1: "골드 파우",
    probability1: 0.0006,
    drop2: "황일",
    probability2: 0.0008,
    drop3: "청일 바지",
    probability3: 0.0008,
    drop4: "황 흑일모자",
    probability4: 0.0007,
    drop5: "다크 골든서클릿",
    probability5: 0.0007,
    drop6: "드래곤 토네일",
    probability6: 0.0009,
    drop7: "그린 루이마리",
    probability7: 0.0008,
    drop8: "홀 스태프",
    probability8: 0.0009,
    drop9: "라이트 스콜피오",
    probability9: 0.0006,
    drop10: "라이트 스콜피오 바지",
    probability10: 0.0006,
    drop11: "블루 칼라스",
    probability11: 0.0008,
    drop12: "아다만티움 레전드 실드",
    probability12: 0.0005,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "러스터픽시",
    drop1: "배틀 실드",
    probability1: 0.001,
    drop2: "블루 고니슈즈",
    probability2: 0.0007,
    drop3: "블루 피에뜨 바지",
    probability3: 0.001,
    drop4: "실버 크루세이더 헬름",
    probability4: 0.0007,
    drop5: "다크 필퍼",
    probability5: 0.001,
    drop6: "목비 표창",
    probability6: 0.001,
    drop7: "오우커 숄더메일",
    probability7: 0.0007,
    drop8: "오우커 숄더메일 바지",
    probability8: 0.0007,
    drop9: "골드 힐던부츠",
    probability9: 0.0007,
    drop10: "블루 칼라스",
    probability10: 0.0008,
    drop11: "레드 고어부츠",
    probability11: 0.0006,
    drop12: "다크 마누트",
    probability12: 0.0006,
    drop13: "타이탄",
    probability13: 0.0009,
    drop14: "브라운 피에뜨",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "샐리온",
    drop1: "레드 문슈즈",
    probability1: 0.001,
    drop2: "다크 클리브",
    probability2: 0.0009,
    drop3: "엄버 숄더메일",
    probability3: 0.0006,
    drop4: "다크 숄더메일 바지",
    probability4: 0.0006,
    drop5: "청 진월장화",
    probability5: 0.001,
    drop6: "주자로",
    probability6: 0.0006,
    drop7: "블루 아나카문",
    probability7: 0.0006,
    drop8: "청일",
    probability8: 0.0008,
    drop9: "청일 바지",
    probability9: 0.0008,
    drop10: "빨간하트 귀고리",
    probability10: 0.0008,
    drop11: "실버 크루세이더 헬름",
    probability11: 0.0008,
    drop12: "쟈드",
    probability12: 0.0009,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "라이오너",
    drop1: "본헬름",
    probability1: 0.0007,
    drop2: "블루 카운터",
    probability2: 0.001,
    drop3: "다크 쉐도우",
    probability3: 0.0009,
    drop4: "다크 쉐도우 바지",
    probability4: 0.0009,
    drop5: "다크 돔",
    probability5: 0.0007,
    drop6: "브라운 네일슈즈",
    probability6: 0.0008,
    drop7: "쥬얼 쿠아다라",
    probability7: 0.0008,
    drop8: "레드 고니슈즈",
    probability8: 0.0008,
    drop9: "주자로",
    probability9: 0.0006,
    drop10: "다크 페넌스",
    probability10: 0.0008,
    drop11: "적궁우",
    probability11: 0.0006,
    drop12: "골드 브리스트",
    probability12: 0.0008,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "그류핀",
    drop1: "청일",
    probability1: 0.0008,
    drop2: "블루문",
    probability2: 0.001,
    drop3: "청일 바지",
    probability3: 0.0008,
    drop4: "다크 페넌스",
    probability4: 0.0007,
    drop5: "그린 루이마리",
    probability5: 0.0008,
    drop6: "자진일갑주",
    probability6: 0.001,
    drop7: "흑진일갑주 바지",
    probability7: 0.001,
    drop8: "크리스탈 플라워링",
    probability8: 0.0005,
    drop9: "게타",
    probability9: 0.0005,
    drop10: "골드 힐던부츠",
    probability10: 0.0008,
    drop11: "고드름",
    probability11: 0.0006,
    drop12: "다크 칼라스",
    probability12: 0.0008,
    drop13: "화이트 길티언",
    probability13: 0.001,
    drop14: "다크 필퍼",
    probability14: 0.001,
    drop15: "타무스",
    probability15: 0.0005,
    drop16: "토비 표창",
    probability16: 0.001,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "도라지",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "호걸",
    drop1: "청일",
    probability1: 0.0004,
    drop2: "청일 바지",
    probability2: 0.0004,
    drop3: "블루 아나카문",
    probability3: 0.0003,
    drop4: "타이탄",
    probability4: 0.001,
    drop5: "토비 표창",
    probability5: 0.001,
    drop6: "하이랜더",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "늙은 도라지",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "로이드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "머신 MT-09",
    drop1: "청마로",
    probability1: 0.0008,
    drop2: "황일",
    probability2: 0.0008,
    drop3: "청일 바지",
    probability3: 0.0008,
    drop4: "브라운 피에르타",
    probability4: 0.001,
    drop5: "블루 피에뜨 바지",
    probability5: 0.001,
    drop6: "다크 길티언",
    probability6: 0.001,
    drop7: "미스릴 브리스트",
    probability7: 0.0008,
    drop8: "분홍꽃 귀고리",
    probability8: 0.0006,
    drop9: "그린 골드윈드슈즈",
    probability9: 0.0008,
    drop10: "아다만티움 레전드 실드",
    probability10: 0.0006,
    drop11: "그리스",
    probability11: 0.0006,
    drop12: "샤이닝",
    probability12: 0.0006,
    drop13: "황월 장갑",
    probability13: 0.0008,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스톤골렘",
    drop1: "블루 피에르슈즈",
    probability1: 0.001,
    drop2: "실버 크루세이더 헬름",
    probability2: 0.0008,
    drop3: "브론즈 하이드후드",
    probability3: 0.0006,
    drop4: "크리스탈 완드",
    probability4: 0.0009,
    drop5: "다크 너클",
    probability5: 0.001,
    drop6: "다크 클리브",
    probability6: 0.001,
    drop7: "그린 골드윈드슈즈",
    probability7: 0.0008,
    drop8: "그린 오리엔타이칸 바지",
    probability8: 0.0006,
    drop9: "호크헤드",
    probability9: 0.0005,
    drop10: "금비 표창",
    probability10: 0.0006,
    drop11: "목비 표창",
    probability11: 0.0008,
    drop12: "레드 크로스 실드",
    probability12: 0.001,
    drop13: "청일",
    probability13: 0.0008,
    drop14: "청일 바지",
    probability14: 0.0008,
    drop15: "다크 칼라스",
    probability15: 0.0008,
    drop16: "라이덴",
    probability16: 0.0008,
    drop17: "사파이어 귀고리",
    probability17: 0.0008,
    drop18: "다크 쉐도우",
    probability18: 0.0007,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "멜러디",
    drop1: "봉황위궁",
    probability1: 0.0006,
    drop2: "흑진일갑주 바지",
    probability2: 0.001,
    drop3: "자진일갑주",
    probability3: 0.001,
    drop4: "크리스탈 플라워링",
    probability4: 0.0007,
    drop5: "그린 크라페",
    probability5: 0.001,
    drop6: "블루 앵클부츠",
    probability6: 0.0006,
    drop7: "그린 골드윈드슈즈",
    probability7: 0.0008,
    drop8: "블루 오리엔타이칸",
    probability8: 0.0006,
    drop9: "그린 오리엔타이칸 바지",
    probability9: 0.0006,
    drop10: "올림푸스",
    probability10: 0.0008,
    drop11: "그린 루이마리",
    probability11: 0.0008,
    drop12: "다크 슬레인",
    probability12: 0.0008,
    drop13: "신기타",
    probability13: 0.0008,
    drop14: "골드 파우",
    probability14: 0.0006,
    drop15: "다크 칼라스",
    probability15: 0.0008,
    drop16: "블루 듀크",
    probability16: 0.001,
    drop17: "골드 브리스트",
    probability17: 0.0008,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "헥터",
    drop1: "청 진월장화",
    probability1: 0.001,
    drop2: "그린 루이마리",
    probability2: 0.0007,
    drop3: "다크 아르텐",
    probability3: 0.001,
    drop4: "오렌지 골든서클릿",
    probability4: 0.0007,
    drop5: "골든 크로우",
    probability5: 0.0005,
    drop6: "청일",
    probability6: 0.0007,
    drop7: "청일 바지",
    probability7: 0.0007,
    drop8: "녹마로",
    probability8: 0.0007,
    drop9: "모험가의 망토",
    probability9: 0.006,
    drop10: "금비 표창",
    probability10: 0.002,
    drop11: "다크 클리브",
    probability11: 0.001,
    drop12: "스네이크",
    probability12: 0.0005,
    drop13: "플루튬",
    probability13: 0.0005,
    drop14: "레드 피에르슈즈",
    probability14: 0.001,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "비급",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "이끼버섯",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 주니어 예티",
    drop1: "다크 골든서클릿",
    probability1: 0.0009,
    drop2: "자진일갑주",
    probability2: 0.001,
    drop3: "흑진일갑주 바지",
    probability3: 0.001,
    drop4: "마법의 망토",
    probability4: 0.0007,
    drop5: "녹 흑일모자",
    probability5: 0.0009,
    drop6: "다크 클랜치",
    probability6: 0.0007,
    drop7: "아쿠아 플라티나",
    probability7: 0.0005,
    drop8: "브론즈 플라틴 바지",
    probability8: 0.0005,
    drop9: "브라운 피에르타",
    probability9: 0.001,
    drop10: "블루 피에뜨 바지",
    probability10: 0.001,
    drop11: "골드 모스부츠",
    probability11: 0.0007,
    drop12: "홀리 크로스링",
    probability12: 0.0009,
    drop13: "블루 앵클부츠",
    probability13: 0.0007,
    drop14: "다크 가너",
    probability14: 0.0007,
    drop15: "아다만티움 레전드 실드",
    probability15: 0.0007,
    drop16: "쏜즈",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "달곰",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "삼미호",
    drop1: "엄버 숄더메일",
    probability1: 0.0008,
    drop2: "다크 숄더메일 바지",
    probability2: 0.0008,
    drop3: "홀리 크로스링",
    probability3: 0.007,
    drop4: "십자창",
    probability4: 0.0004,
    drop5: "다크 스콜피오",
    probability5: 0.0005,
    drop6: "다크 스콜피오 바지",
    probability6: 0.0005,
    drop7: "주자로",
    probability7: 0.0005,
    drop8: "골드 힐던부츠",
    probability8: 0.001,
    drop9: "다크 페넌스",
    probability9: 0.001,
    drop10: "다크 아나카문",
    probability10: 0.0005,
    drop11: "황월 장갑",
    probability11: 0.001,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스켈레톤 사병",
    drop1: "베이지 웨어캡",
    probability1: 0.0007,
    drop2: "블루 고니슈즈",
    probability2: 0.0009,
    drop3: "블루 오리엔타이칸",
    probability3: 0.0008,
    drop4: "그린 오리엔타이칸 바지",
    probability4: 0.0008,
    drop5: "청자로",
    probability5: 0.0008,
    drop6: "브라운 루크",
    probability6: 0.0007,
    drop7: "브라운 네일슈즈",
    probability7: 0.0009,
    drop8: "골드 힐던부츠",
    probability8: 0.0009,
    drop9: "라이트 스콜피오",
    probability9: 0.0008,
    drop10: "라이트 스콜피오 바지",
    probability10: 0.0008,
    drop11: "핑크 골드윈드슈즈",
    probability11: 0.0009,
    drop12: "다크 아나카문",
    probability12: 0.0008,
    drop13: "블루 키튼서클렛",
    probability13: 0.0007,
    drop14: "실버 플래닛",
    probability14: 0.0007,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "쿨리좀비",
    drop1: "다크 브레이스",
    probability1: 0.001,
    drop2: "레드 하프슈즈",
    probability2: 0.001,
    drop3: "블루 오리엔타이칸",
    probability3: 0.0006,
    drop4: "그린 오리엔타이칸 바지",
    probability4: 0.0006,
    drop5: "브론즈 하이드후드",
    probability5: 0.0006,
    drop6: "브라운 스타드 바지",
    probability6: 0.0005,
    drop7: "브라운 스타드",
    probability7: 0.0005,
    drop8: "벅",
    probability8: 0.0007,
    drop9: "크로미",
    probability9: 0.0006,
    drop10: "크리스탈 플라워링",
    probability10: 0.0008,
    drop11: "다크 문슈즈",
    probability11: 0.0009,
    drop12: "다크 세라피스",
    probability12: 0.0006,
    drop13: "젝커",
    probability13: 0.0009,
    drop14: "다크 아르텐",
    probability14: 0.001,
    drop15: "황일",
    probability15: 0.0007,
    drop16: "청일 바지",
    probability16: 0.0007,
    drop17: "그린 루이마리",
    probability17: 0.0007,
    drop18: "마법의 망토",
    probability18: 0.0006,
    drop19: "흑궁우",
    probability19: 0.0006,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마이너 좀비",
    drop1: "아다만티움 레전드 실드",
    probability1: 0.0006,
    drop2: "베즐러드",
    probability2: 0.0009,
    drop3: "핑크 골드윈드슈즈",
    probability3: 0.0007,
    drop4: "블루 오리엔트 헬멧",
    probability4: 0.0006,
    drop5: "화이트 세라피스",
    probability5: 0.0006,
    drop6: "청자로",
    probability6: 0.0006,
    drop7: "브라운 크라페",
    probability7: 0.001,
    drop8: "골드 파우",
    probability8: 0.0006,
    drop9: "골드 힐던부츠",
    probability9: 0.0007,
    drop10: "펜지 이어링",
    probability10: 0.001,
    drop11: "브라운 스타드",
    probability11: 0.0005,
    drop12: "브라운 스타드 바지",
    probability12: 0.0005,
    drop13: "레드 고니슈즈",
    probability13: 0.0007,
    drop14: "황진일갑주",
    probability14: 0.001,
    drop15: "황진일갑주 바지",
    probability15: 0.001,
    drop16: "다크 아나카문",
    probability16: 0.0007,
    drop17: "화이트 길티언",
    probability17: 0.001,
    drop18: "마법의 망토",
    probability18: 0.0006,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "원시멧돼지",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 스톤골렘",
    drop1: "마법의 망토",
    probability1: 0.0007,
    drop2: "녹마로",
    probability2: 0.0008,
    drop3: "블루 앵클부츠",
    probability3: 0.0007,
    drop4: "청자로",
    probability4: 0.0007,
    drop5: "레드 피에르슈즈",
    probability5: 0.001,
    drop6: "다크 하이드후드",
    probability6: 0.0007,
    drop7: "다크 마누트",
    probability7: 0.0007,
    drop8: "다크 스타라이트",
    probability8: 0.001,
    drop9: "이블윙즈",
    probability9: 0.0006,
    drop10: "녹 흑일모자",
    probability10: 0.0008,
    drop11: "홀리 크로스링",
    probability11: 0.001,
    drop12: "자진일갑주",
    probability12: 0.001,
    drop13: "낡은 스틸 노르만 헬름",
    probability13: 0.0008,
    drop14: "다크 쉐도우",
    probability14: 0.001,
    drop15: "다크 쉐도우 바지",
    probability15: 0.001,
    drop16: "레드 오리엔타이칸",
    probability16: 0.0007,
    drop17: "다크 오리엔타이칸 바지",
    probability17: 0.0007,
    drop18: "골드 브리스트",
    probability18: 0.0008,
    drop19: "토비 표창",
    probability19: 0.001,
    drop20: "흑진일갑주 바지",
    probability20: 0.001,
  },
  {
    이름: "화이트팽",
    drop1: "아쿠아 플라티나",
    probability1: 0.0005,
    drop2: "브론즈 플라틴 바지",
    probability2: 0.0005,
    drop3: "블루 아나카문",
    probability3: 0.0007,
    drop4: "가이아의 망토",
    probability4: 0.0007,
    drop5: "청 진월장화",
    probability5: 0.001,
    drop6: "청자로",
    probability6: 0.0007,
    drop7: "다크 브레이스",
    probability7: 0.001,
    drop8: "골드 모스부츠",
    probability8: 0.0007,
    drop9: "황일",
    probability9: 0.0009,
    drop10: "청일 바지",
    probability10: 0.0009,
    drop11: "골드 힐던부츠",
    probability11: 0.0009,
    drop12: "분홍꽃 귀고리",
    probability12: 0.0009,
    drop13: "레드 문슈즈",
    probability13: 0.001,
    drop14: "레드 오리엔트 헬멧",
    probability14: 0.0007,
    drop15: "그린 루이마리",
    probability15: 0.0009,
    drop16: "빅해머",
    probability16: 0.001,
    drop17: "아다만티움 보닌",
    probability17: 0.001,
    drop18: "샤이닝",
    probability18: 0.0007,
    drop19: "토비 표창",
    probability19: 0.001,
    drop20: "황월 장갑",
    probability20: 0.0009,
  },
  {
    이름: "천록",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "네오 휴로이드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "믹스 골렘",
    drop1: "블루 루이마리",
    probability1: 0.001,
    drop2: "블루 오리엔타이칸",
    probability2: 0.0004,
    drop3: "그린 오리엔타이칸 바지",
    probability3: 0.0004,
    drop4: "이블테일러",
    probability4: 0.0005,
    drop5: "다크 마누트",
    probability5: 0.0005,
    drop6: "그린 고어부츠",
    probability6: 0.0004,
    drop7: "라이트 스콜피오",
    probability7: 0.0004,
    drop8: "라이트 스콜피오 바지",
    probability8: 0.0004,
    drop9: "청일",
    probability9: 0.0009,
    drop10: "청일 바지",
    probability10: 0.0009,
    drop11: "레드 오리엔트 헬멧",
    probability11: 0.0005,
    drop12: "실버 하이드후드",
    probability12: 0.0005,
    drop13: "흑궁우",
    probability13: 0.0005,
    drop14: "황월 장갑",
    probability14: 0.0009,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "선인 인형",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레드 드레이크",
    drop1: "다크 아이즈",
    probability1: 0.0005,
    drop2: "블루 루티드슈즈",
    probability2: 0.0005,
    drop3: "크리스탈 완드",
    probability3: 0.0007,
    drop4: "다크 아나카문",
    probability4: 0.0008,
    drop5: "브라운 피에뜨",
    probability5: 0.0009,
    drop6: "블루 피에뜨 바지",
    probability6: 0.0009,
    drop7: "다크 스콜피오",
    probability7: 0.0007,
    drop8: "다크 스콜피오 바지",
    probability8: 0.0007,
    drop9: "다크 세라피스",
    probability9: 0.0008,
    drop10: "이블테일러",
    probability10: 0.0006,
    drop11: "골드 라피스샌들",
    probability11: 0.0005,
    drop12: "그린 고어부츠",
    probability12: 0.0008,
    drop13: "다크 쉐도우",
    probability13: 0.0009,
    drop14: "다크 쉐도우 바지",
    probability14: 0.0009,
    drop15: "블러드 플라티나",
    probability15: 0.0005,
    drop16: "오리할콘 플라틴 바지",
    probability16: 0.0005,
    drop17: "블러드 카멜부츠",
    probability17: 0.0008,
    drop18: "해골 방패",
    probability18: 0.0007,
    drop19: "가이아의 망토",
    probability19: 0.0007,
    drop20: "뇌전 수리검",
    probability20: 0.0004,
  },
  {
    이름: "페페",
    drop1: "블루 고니슈즈",
    probability1: 0.0008,
    drop2: "브라운 루크",
    probability2: 0.0005,
    drop3: "퍼플 네일슈즈",
    probability3: 0.0008,
    drop4: "다크 기간틱",
    probability4: 0.0006,
    drop5: "다크 가너",
    probability5: 0.0007,
    drop6: "다크 필퍼",
    probability6: 0.0009,
    drop7: "블루 앵클부츠",
    probability7: 0.0007,
    drop8: "그린 리넥스",
    probability8: 0.0005,
    drop9: "십자창",
    probability9: 0.0006,
    drop10: "고드름",
    probability10: 0.0008,
    drop11: "미스릴 브리스트",
    probability11: 0.0008,
    drop12: "다크 칼라스",
    probability12: 0.0008,
    drop13: "다크 페넌스",
    probability13: 0.0008,
    drop14: "블루 레퀴엠",
    probability14: 0.0005,
    drop15: "실버 크루세이더 헬름",
    probability15: 0.0008,
    drop16: "실버 플래닛",
    probability16: 0.0005,
    drop17: "방천극",
    probability17: 0.0006,
    drop18: "가이아의 망토",
    probability18: 0.0009,
    drop19: "브라운 피에르타",
    probability19: 0.0009,
    drop20: "브라운 피에뜨 바지",
    probability20: 0.0009,
  },
  {
    이름: "판다곰",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "깨비(변신전)",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "깨비(변신후)",
    drop1: "마법의 망토",
    probability1: 0.0006,
    drop2: "크레센트",
    probability2: 0.001,
    drop3: "그린 오리엔타이칸 바지",
    probability3: 0.0004,
    drop4: "다크 스콜피오",
    probability4: 0.0006,
    drop5: "다크 스콜피오 바지",
    probability5: 0.0006,
    drop6: "올림푸스",
    probability6: 0.0008,
    drop7: "레드 고어부츠",
    probability7: 0.0004,
    drop8: "장팔사모",
    probability8: 0.001,
    drop9: "실버 크로우",
    probability9: 0.001,
    drop10: "골드 파우",
    probability10: 0.0005,
    drop11: "다크 마누트",
    probability11: 0.0006,
    drop12: "엄버 숄더메일",
    probability12: 0.0008,
    drop13: "다크 숄더메일 바지",
    probability13: 0.0008,
    drop14: "레드 오리엔타이칸",
    probability14: 0.0004,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "버피",
    drop1: "청자로",
    probability1: 0.0007,
    drop2: "블루 듀크",
    probability2: 0.0009,
    drop3: "마법의 망토",
    probability3: 0.0007,
    drop4: "그린 필퍼",
    probability4: 0.0009,
    drop5: "다크 로린",
    probability5: 0.0005,
    drop6: "브라운 네일슈즈",
    probability6: 0.0009,
    drop7: "다크 클랜치",
    probability7: 0.0007,
    drop8: "다크 모스부츠",
    probability8: 0.0006,
    drop9: "다크 마누트",
    probability9: 0.0007,
    drop10: "골드 파우",
    probability10: 0.0008,
    drop11: "미하일",
    probability11: 0.0005,
    drop12: "네오코라",
    probability12: 0.0006,
    drop13: "오우커 숄더메일",
    probability13: 0.0009,
    drop14: "오우커 숄더메일 바지",
    probability14: 0.0009,
    drop15: "블루 칼라스",
    probability15: 0.0009,
    drop16: "레드 힌켈",
    probability16: 0.0005,
    drop17: "레드 루티드슈즈",
    probability17: 0.0005,
    drop18: "해골 귀고리",
    probability18: 0.0009,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "와일드 카고",
    drop1: "다크 기간틱",
    probability1: 0.0006,
    drop2: "블루 카젠부츠",
    probability2: 0.0006,
    drop3: "다크 골든서클릿",
    probability3: 0.0009,
    drop4: "청일 바지",
    probability4: 0.0009,
    drop5: "다크 아이즈",
    probability5: 0.0006,
    drop6: "다크 레퀴엠",
    probability6: 0.0007,
    drop7: "다크 클랜치",
    probability7: 0.0007,
    drop8: "다크 칼라스",
    probability8: 0.0009,
    drop9: "사파이어 카멜부츠",
    probability9: 0.0008,
    drop10: "블루 아나카문",
    probability10: 0.0008,
    drop11: "브라운 크라페",
    probability11: 0.001,
    drop12: "마법의 망토",
    probability12: 0.0008,
    drop13: "다크 스타드",
    probability13: 0.0006,
    drop14: "다크 스타드 바지",
    probability14: 0.0006,
    drop15: "엄버 숄더메일",
    probability15: 0.0009,
    drop16: "다크 숄더메일 바지",
    probability16: 0.0009,
    drop17: "주자로",
    probability17: 0.0008,
    drop18: "쏜즈",
    probability18: 0.0005,
    drop19: "토비 표창",
    probability19: 0.0005,
    drop20: "골든 모울",
    probability20: 0.0008,
  },
  {
    이름: "원공",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스켈레톤 장교",
    drop1: "아다만티움 레전드 실드",
    probability1: 0.0006,
    drop2: "다크 기간틱",
    probability2: 0.0006,
    drop3: "다크 아이즈",
    probability3: 0.0007,
    drop4: "블루 오리엔트 헬멧",
    probability4: 0.0008,
    drop5: "다크 페넌스",
    probability5: 0.0009,
    drop6: "다크 허스크",
    probability6: 0.0007,
    drop7: "다크 로린",
    probability7: 0.0007,
    drop8: "다크 세라피스",
    probability8: 0.0008,
    drop9: "미스릴 브리스트",
    probability9: 0.0009,
    drop10: "네오코라",
    probability10: 0.0006,
    drop11: "다크 윌로우",
    probability11: 0.0009,
    drop12: "스네이크",
    probability12: 0.0006,
    drop13: "실버 하이드후드",
    probability13: 0.0008,
    drop14: "골드 와이어스",
    probability14: 0.0007,
    drop15: "흑궁우",
    probability15: 0.0008,
    drop16: "황월 장갑",
    probability16: 0.0009,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 루이넬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "소울 테니",
    drop1: "블러드 카멜부츠",
    probability1: 0.0008,
    drop2: "다크 로린",
    probability2: 0.0006,
    drop3: "블루 루이마리",
    probability3: 0.0009,
    drop4: "베이지 웨어캡",
    probability4: 0.0006,
    drop5: "황일",
    probability5: 0.0009,
    drop6: "청일 바지",
    probability6: 0.0009,
    drop7: "브라운 루크",
    probability7: 0.0006,
    drop8: "황 흑일모자",
    probability8: 0.0009,
    drop9: "다크 가너",
    probability9: 0.0007,
    drop10: "이블테일러",
    probability10: 0.0006,
    drop11: "골드드롭 이어링",
    probability11: 0.0009,
    drop12: "실버 크루세이더 헬름",
    probability12: 0.0009,
    drop13: "다크 칼라스",
    probability13: 0.0009,
    drop14: "분홍꽃 귀고리",
    probability14: 0.0009,
    drop15: "다크 기간틱",
    probability15: 0.0006,
    drop16: "골드 파우",
    probability16: 0.0008,
    drop17: "다크 마누트",
    probability17: 0.0008,
    drop18: "타무스",
    probability18: 0.0006,
    drop19: "블러드 플라티나",
    probability19: 0.0006,
    drop20: "오리할콘 플라틴 바지",
    probability20: 0.0006,
  },
  {
    이름: "아이스 드레이크",
    drop1: "골드 와이어스",
    probability1: 0.0005,
    drop2: "청자로",
    probability2: 0.0007,
    drop3: "황진일갑주",
    probability3: 0.002,
    drop4: "다크 로린",
    probability4: 0.0004,
    drop5: "골든 크로우",
    probability5: 0.0005,
    drop6: "그린 오리엔타이칸 바지",
    probability6: 0.0008,
    drop7: "황일",
    probability7: 0.001,
    drop8: "청일 바지",
    probability8: 0.001,
    drop9: "황진일갑주 바지",
    probability9: 0.002,
    drop10: "블루 라피스샌들",
    probability10: 0.0005,
    drop11: "레드 오리엔타이칸",
    probability11: 0.0008,
    drop12: "다크 오리엔타이칸 바지",
    probability12: 0.0008,
    drop13: "다크 기간틱",
    probability13: 0.0004,
    drop14: "다크 아나카문",
    probability14: 0.0007,
    drop15: "블루 오리엔타이칸",
    probability15: 0.0008,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 페페",
    drop1: "아다만티움 레전드 실드",
    probability1: 0.0008,
    drop2: "마법의 망토",
    probability2: 0.0008,
    drop3: "다크 기간틱",
    probability3: 0.0006,
    drop4: "블루 리넥스슈즈",
    probability4: 0.0005,
    drop5: "블루 레퀴엠",
    probability5: 0.0005,
    drop6: "적궁우",
    probability6: 0.0008,
    drop7: "다크 골든서클릿",
    probability7: 0.0009,
    drop8: "다크 마누트",
    probability8: 0.0007,
    drop9: "라이트 스콜피오",
    probability9: 0.0008,
    drop10: "라이트 스콜피오 바지",
    probability10: 0.0008,
    drop11: "낡은 스틸 노르만 헬름",
    probability11: 0.0009,
    drop12: "블루 카젠부츠",
    probability12: 0.0005,
    drop13: "퍼플 루티드슈즈",
    probability13: 0.0005,
    drop14: "다크 슬레인",
    probability14: 0.0008,
    drop15: "스네이크",
    probability15: 0.0006,
    drop16: "다크 아나카문",
    probability16: 0.0009,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "설산의 마녀",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "이블테일러",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "일비 표창",
    probability17: 0.03,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "게비알",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "예티",
    drop1: "블루 라피스샌들",
    probability1: 0.0005,
    drop2: "블루 리넥스",
    probability2: 0.0005,
    drop3: "청일",
    probability3: 0.0009,
    drop4: "흑갑충",
    probability4: 0.0005,
    drop5: "크리스탈 플라워링",
    probability5: 0.0009,
    drop6: "다크 아나카문",
    probability6: 0.0008,
    drop7: "엄버 숄더메일",
    probability7: 0.0009,
    drop8: "이블윙즈",
    probability8: 0.0006,
    drop9: "골드드롭 이어링",
    probability9: 0.0008,
    drop10: "다크 아이즈",
    probability10: 0.0005,
    drop11: "골든 크로우",
    probability11: 0.0006,
    drop12: "십자창",
    probability12: 0.0006,
    drop13: "다크 스콜피오",
    probability13: 0.0008,
    drop14: "다크 스콜피오 바지",
    probability14: 0.0008,
    drop15: "낡은 스틸 노르만 헬름",
    probability15: 0.0009,
    drop16: "블러드 플라티나",
    probability16: 0.0005,
    drop17: "오리할콘 플라틴 바지",
    probability17: 0.0005,
    drop18: "가이아의 망토",
    probability18: 0.0007,
    drop19: "다크 기간틱",
    probability19: 0.0006,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "호문",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "묘선",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레이지버피",
    drop1: "가이아의 망토",
    probability1: 0.0008,
    drop2: "청자로",
    probability2: 0.0008,
    drop3: "블루 리넥스",
    probability3: 0.0007,
    drop4: "메탈실버 이어링",
    probability4: 0.0008,
    drop5: "다크 스콜피오",
    probability5: 0.0008,
    drop6: "다크 스콜피오 바지",
    probability6: 0.0008,
    drop7: "낡은 미스릴 노르만 헬름",
    probability7: 0.0009,
    drop8: "퍼플 네일슈즈",
    probability8: 0.0009,
    drop9: "블루 라피스샌들",
    probability9: 0.0007,
    drop10: "화이트 세라피스",
    probability10: 0.0008,
    drop11: "스페판",
    probability11: 0.0007,
    drop12: "다크 클랜치",
    probability12: 0.0008,
    drop13: "골드 와이어스",
    probability13: 0.0007,
    drop14: "엄버 숄더메일",
    probability14: 0.0009,
    drop15: "다크 숄더메일 바지",
    probability15: 0.0009,
    drop16: "다크 칼라스",
    probability16: 0.0009,
    drop17: "황월 장갑",
    probability17: 0.0009,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마스터 소울 테니",
    drop1: "흑궁우",
    probability1: 0.0008,
    drop2: "블루 오리엔트 헬멧",
    probability2: 0.0008,
    drop3: "다크 허스크",
    probability3: 0.0006,
    drop4: "골드 라피스샌들",
    probability4: 0.0005,
    drop5: "다크 아나카문",
    probability5: 0.0008,
    drop6: "다크 칼라스",
    probability6: 0.0008,
    drop7: "다크 하이드후드",
    probability7: 0.0008,
    drop8: "금강저",
    probability8: 0.0005,
    drop9: "블루 리넥스슈즈",
    probability9: 0.0006,
    drop10: "다크 세라피스",
    probability10: 0.0008,
    drop11: "브라운 네일슈즈",
    probability11: 0.0009,
    drop12: "퍼플 루티드슈즈",
    probability12: 0.0006,
    drop13: "레드 오리엔타이칸",
    probability13: 0.0007,
    drop14: "다크 오리엔타이칸 바지",
    probability14: 0.0007,
    drop15: "다크 윌로우",
    probability15: 0.0009,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 드레이크",
    drop1: "엔젤윙즈",
    probability1: 0.0007,
    drop2: "아쿠아 플라티나",
    probability2: 0.0008,
    drop3: "브론즈 플라틴 바지",
    probability3: 0.0008,
    drop4: "브라운 스타드",
    probability4: 0.0008,
    drop5: "브라운 스타드 바지",
    probability5: 0.0008,
    drop6: "녹 흑일모자",
    probability6: 0.002,
    drop7: "브론즈 하이드후드",
    probability7: 0.001,
    drop8: "크로노",
    probability8: 0.0004,
    drop9: "그린 피레타부츠",
    probability9: 0.0004,
    drop10: "다크 코든",
    probability10: 0.0003,
    drop11: "화이트 세라피스",
    probability11: 0.001,
    drop12: "스페판",
    probability12: 0.0004,
    drop13: "방천극",
    probability13: 0.0005,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 예티",
    drop1: "아쿠아 플라티나",
    probability1: 0.0006,
    drop2: "브론즈 플라틴 바지",
    probability2: 0.0006,
    drop3: "다크 슬레인",
    probability3: 0.0008,
    drop4: "블루 오리엔타이칸",
    probability4: 0.0008,
    drop5: "그린 오리엔타이칸 바지",
    probability5: 0.0008,
    drop6: "다크 윌로우",
    probability6: 0.0009,
    drop7: "골드 와이어스",
    probability7: 0.0006,
    drop8: "블루 리넥스슈즈",
    probability8: 0.0006,
    drop9: "그린 크로스햇",
    probability9: 0.0005,
    drop10: "메탈실버 이어링",
    probability10: 0.0007,
    drop11: "핑크 골드윈드슈즈",
    probability11: 0.0009,
    drop12: "다크 아나카문",
    probability12: 0.0008,
    drop13: "레드 드래곤 바부타",
    probability13: 0.0005,
    drop14: "청자로",
    probability14: 0.0008,
    drop15: "실버 플래닛",
    probability15: 0.0006,
    drop16: "구룡도",
    probability16: 0.0008,
    drop17: "다크 스콜피오",
    probability17: 0.0007,
    drop18: "다크 스콜피오 바지",
    probability18: 0.0007,
    drop19: "화이트 세라피스",
    probability19: 0.0008,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "크루",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "사이티",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "타우로마시스",
    drop1: "베이지 웨어캡",
    probability1: 0.0007,
    drop2: "그린 크로스햇",
    probability2: 0.0006,
    drop3: "다크 오리엔타이칸 바지",
    probability3: 0.0009,
    drop4: "블루 레퀴엠",
    probability4: 0.0007,
    drop5: "세라프의 망토",
    probability5: 0.0006,
    drop6: "흑갑충",
    probability6: 0.0006,
    drop7: "다크 스콜피오",
    probability7: 0.0008,
    drop8: "다크 스콜피오 바지",
    probability8: 0.0008,
    drop9: "블러드 엠페러",
    probability9: 0.0006,
    drop10: "블러드 로버",
    probability10: 0.0006,
    drop11: "그린 리넥스",
    probability11: 0.0007,
    drop12: "다크 윙부츠",
    probability12: 0.0006,
    drop13: "미스릴 플래닛",
    probability13: 0.0007,
    drop14: "블러드 루크",
    probability14: 0.0007,
    drop15: "레드 힌켈",
    probability15: 0.0006,
    drop16: "레드 루티드슈즈",
    probability16: 0.0007,
    drop17: "뇌전 수리검",
    probability17: 0.0005,
    drop18: "스튬",
    probability18: 0.0006,
    drop19: "레드 오리엔타이칸",
    probability19: 0.0009,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레쉬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "캡틴",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "클라크",
    drop1: "흑궁우",
    probability1: 0.0009,
    drop2: "블루 크리시스",
    probability2: 0.0006,
    drop3: "블루 리넥스",
    probability3: 0.0007,
    drop4: "블루 루티드슈즈",
    probability4: 0.0007,
    drop5: "블루 레퀴엠",
    probability5: 0.0007,
    drop6: "블루 윙부츠",
    probability6: 0.0006,
    drop7: "다크 클랜치",
    probability7: 0.0008,
    drop8: "다크 기간틱",
    probability8: 0.0007,
    drop9: "골드 라피스샌들",
    probability9: 0.0007,
    drop10: "레드 오리엔타이칸",
    probability10: 0.0008,
    drop11: "다크 오리엔타이칸 바지",
    probability11: 0.0008,
    drop12: "하프 이어링",
    probability12: 0.0006,
    drop13: "메탈실버 이어링",
    probability13: 0.0007,
    drop14: "다크 허스크",
    probability14: 0.0007,
    drop15: "주자로",
    probability15: 0.0008,
    drop16: "블루 아나카문",
    probability16: 0.0009,
    drop17: "레드 고어부츠",
    probability17: 0.0008,
    drop18: "다크 엠페러",
    probability18: 0.0006,
    drop19: "스튬",
    probability19: 0.0006,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "불독",
    drop1: "엔젤윙즈",
    probability1: 0.0006,
    drop2: "다크 리넥스슈즈",
    probability2: 0.0007,
    drop3: "다크 스콜피오",
    probability3: 0.0008,
    drop4: "다크 스콜피오 바지",
    probability4: 0.0008,
    drop5: "다크 레퀴엠",
    probability5: 0.0007,
    drop6: "다크 아이즈",
    probability6: 0.0007,
    drop7: "다크 허스크",
    probability7: 0.0007,
    drop8: "다크 세라피스",
    probability8: 0.0008,
    drop9: "게타",
    probability9: 0.0008,
    drop10: "그린 고어부츠",
    probability10: 0.0008,
    drop11: "호크헤드",
    probability11: 0.0008,
    drop12: "메탈하트 이어링",
    probability12: 0.0006,
    drop13: "미스릴 플래닛",
    probability13: 0.0007,
    drop14: "피닉스 완드",
    probability14: 0.0005,
    drop15: "다크 스타드",
    probability15: 0.0007,
    drop16: "다크 스타드 바지",
    probability16: 0.0007,
    drop17: "분홍꽃 귀고리",
    probability17: 0.0008,
    drop18: "그리스",
    probability18: 0.0008,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "비틀",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "호브",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스켈레톤 지휘관",
    drop1: "블루 리넥스",
    probability1: 0.0008,
    drop2: "다크 스콜피오",
    probability2: 0.0009,
    drop3: "다크 스콜피오 바지",
    probability3: 0.0009,
    drop4: "블루 오리엔타이칸",
    probability4: 0.0009,
    drop5: "그린 오리엔타이칸 바지",
    probability5: 0.0009,
    drop6: "블루 윙부츠",
    probability6: 0.0007,
    drop7: "다크 레퀴엠",
    probability7: 0.0008,
    drop8: "그린 크리시아슈즈",
    probability8: 0.0007,
    drop9: "그린 피레타부츠",
    probability9: 0.0007,
    drop10: "다크 오리엔타이칸 바지",
    probability10: 0.0009,
    drop11: "레드 리버스부츠",
    probability11: 0.0007,
    drop12: "골드 아퀼라 실드",
    probability12: 0.0005,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "루이넬",
    drop1: "블러드 모스부츠",
    probability1: 0.0009,
    drop2: "블루 아나카문",
    probability2: 0.0009,
    drop3: "블루 골드윙캡",
    probability3: 0.0006,
    drop4: "레드 고어부츠",
    probability4: 0.0009,
    drop5: "블루 키튼서클렛",
    probability5: 0.0008,
    drop6: "블루 리넥스",
    probability6: 0.0008,
    drop7: "미스릴 플래닛",
    probability7: 0.0008,
    drop8: "브라운 스타드",
    probability8: 0.0008,
    drop9: "브라운 스타드 바지",
    probability9: 0.0008,
    drop10: "그륜힐",
    probability10: 0.0006,
    drop11: "그린 크리시아슈즈",
    probability11: 0.0006,
    drop12: "그린 피라테 바지",
    probability12: 0.0006,
    drop13: "그린 피라테",
    probability13: 0.0006,
    drop14: "칸디네",
    probability14: 0.0006,
    drop15: "주자로",
    probability15: 0.0009,
    drop16: "레드 오리엔트 헬멧",
    probability16: 0.0009,
    drop17: "블루 레퀴엠",
    probability17: 0.0008,
    drop18: "레드 리버스부츠",
    probability18: 0.0006,
    drop19: "사파이어 카멜부츠",
    probability19: 0.0009,
    drop20: "실버 하이드후드",
    probability20: 0.0009,
  },
  {
    이름: "호문쿨루",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 레쉬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "버푼",
    drop1: "다크 배틀로드",
    probability1: 0.0006,
    drop2: "다크 와이즈",
    probability2: 0.0006,
    drop3: "블루 앵클부츠",
    probability3: 0.0009,
    drop4: "레드 힌켈",
    probability4: 0.0006,
    drop5: "다크 오리엔타이칸 바지",
    probability5: 0.0009,
    drop6: "다크 스타드 바지",
    probability6: 0.0007,
    drop7: "다크 스타드",
    probability7: 0.0007,
    drop8: "다크 아이즈",
    probability8: 0.0007,
    drop9: "그린 리넥스",
    probability9: 0.0007,
    drop10: "골드 와이어스",
    probability10: 0.0007,
    drop11: "그린 피레타부츠",
    probability11: 0.0006,
    drop12: "흑갑충",
    probability12: 0.0006,
    drop13: "라이트 스콜피오",
    probability13: 0.0009,
    drop14: "라이트 스콜피오 바지",
    probability14: 0.0009,
    drop15: "다크 코든",
    probability15: 0.0006,
    drop16: "블루 라피스샌들",
    probability16: 0.0007,
    drop17: "황룡도",
    probability17: 0.0006,
    drop18: "블러드 플라티나",
    probability18: 0.0007,
    drop19: "오리할콘 플라틴 바지",
    probability19: 0.0007,
    drop20: "레드 오리엔타이칸",
    probability20: 0.0009,
  },
  {
    이름: "타우로스피어",
    drop1: "블루 리넥스",
    probability1: 0.0007,
    drop2: "다크 리버스부츠",
    probability2: 0.0006,
    drop3: "레드 피레타햇",
    probability3: 0.0006,
    drop4: "크로노",
    probability4: 0.0007,
    drop5: "다크 아나카문",
    probability5: 0.0009,
    drop6: "다크 리넥스슈즈",
    probability6: 0.0007,
    drop7: "다크 로린",
    probability7: 0.0007,
    drop8: "레이든 스태프",
    probability8: 0.0006,
    drop9: "호프만",
    probability9: 0.0007,
    drop10: "레드 힌켈",
    probability10: 0.0007,
    drop11: "그린 크리시스",
    probability11: 0.0006,
    drop12: "그린 오리엔타이칸 바지",
    probability12: 0.0009,
    drop13: "그린 프리아",
    probability13: 0.0006,
    drop14: "다크 허스크",
    probability14: 0.0007,
    drop15: "다크 스타드",
    probability15: 0.0007,
    drop16: "다크 스타드 바지",
    probability16: 0.0007,
    drop17: "세라프의 망토",
    probability17: 0.0007,
    drop18: "실버 에이션트 실드",
    probability18: 0.0007,
    drop19: "블러드 루크",
    probability19: 0.0007,
    drop20: "블루 오리엔타이칸",
    probability20: 0.0009,
  },
  {
    이름: "웨어울프",
    drop1: "모던스튬",
    probability1: 0.0006,
    drop2: "흑갑충",
    probability2: 0.0006,
    drop3: "블루 드래곤 바부타",
    probability3: 0.0006,
    drop4: "아쿠아 플라티나",
    probability4: 0.0008,
    drop5: "브론즈 플라틴 바지",
    probability5: 0.0008,
    drop6: "다크 레퀴엠",
    probability6: 0.0009,
    drop7: "다크 키튼서클렛",
    probability7: 0.0008,
    drop8: "다크 로린",
    probability8: 0.0008,
    drop9: "그륜힐",
    probability9: 0.0006,
    drop10: "다크 웨어캡",
    probability10: 0.0008,
    drop11: "그린 피라테",
    probability11: 0.0006,
    drop12: "그린 피라테 바지",
    probability12: 0.0006,
    drop13: "칸디네",
    probability13: 0.0006,
    drop14: "라이트 스콜피오",
    probability14: 0.0009,
    drop15: "라이트 스콜피오 바지",
    probability15: 0.0009,
    drop16: "다크 스타드",
    probability16: 0.0008,
    drop17: "다크 스타드 바지",
    probability17: 0.0008,
    drop18: "다크 크로스햇",
    probability18: 0.0006,
    drop19: "블루 프리아",
    probability19: 0.0006,
    drop20: "실버 에이션트 실드",
    probability20: 0.0007,
  },
  {
    이름: "D.로이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "듀얼 비틀",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "핀 호브",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 클라크",
    drop1: "모던스튬",
    probability1: 0.0006,
    drop2: "블러드 플라티나",
    probability2: 0.0007,
    drop3: "오리할콘 플라틴 바지",
    probability3: 0.0007,
    drop4: "청자로",
    probability4: 0.0009,
    drop5: "블루 드래곤 바부타",
    probability5: 0.0006,
    drop6: "다크 웨어캡",
    probability6: 0.0007,
    drop7: "다크 레퀴엠",
    probability7: 0.0007,
    drop8: "레이든 스태프",
    probability8: 0.0006,
    drop9: "다크 스타드 바지",
    probability9: 0.0007,
    drop10: "다크 스타드",
    probability10: 0.0007,
    drop11: "골드 라피스샌들",
    probability11: 0.0007,
    drop12: "다크 골드윙캡",
    probability12: 0.0006,
    drop13: "블루 오리엔타이칸",
    probability13: 0.0009,
    drop14: "그린 오리엔타이칸 바지",
    probability14: 0.0009,
    drop15: "흑갑충",
    probability15: 0.0006,
    drop16: "블루 크리시스",
    probability16: 0.0006,
    drop17: "블루 키튼서클렛",
    probability17: 0.0007,
    drop18: "다크 피라테",
    probability18: 0.0006,
    drop19: "다크 피라테 바지",
    probability19: 0.0006,
    drop20: "뇌전 수리검",
    probability20: 0.0004,
  },
  {
    이름: "딥 버푼",
    drop1: "흑갑충",
    probability1: 0.0007,
    drop2: "블러드 카멜부츠",
    probability2: 0.0009,
    drop3: "블러드 엠페러",
    probability3: 0.0007,
    drop4: "블러드 플라티나",
    probability4: 0.0009,
    drop5: "오리할콘 플라틴 바지",
    probability5: 0.0009,
    drop6: "블루 리넥스",
    probability6: 0.0009,
    drop7: "블러드 루크",
    probability7: 0.0009,
    drop8: "다크 스타드 바지",
    probability8: 0.0008,
    drop9: "다크 스타드",
    probability9: 0.0008,
    drop10: "다크 로린",
    probability10: 0.0009,
    drop11: "적궁우",
    probability11: 0.001,
    drop12: "크로노",
    probability12: 0.0007,
    drop13: "블루 크리시스",
    probability13: 0.0007,
    drop14: "다크 웨어캡",
    probability14: 0.0008,
    drop15: "골드 에이션트 실드",
    probability15: 0.0009,
    drop16: "그린 프리아",
    probability16: 0.0007,
    drop17: "하프 이어링",
    probability17: 0.0007,
    drop18: "블루 키튼서클렛",
    probability18: 0.0008,
    drop19: "그린 피레타부츠",
    probability19: 0.0007,
    drop20: "다크 아나카문",
    probability20: 0.001,
  },
  {
    이름: "예티와 페페",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "헹키",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "라이칸스로프",
    drop1: "블러드 루크",
    probability1: 0.0009,
    drop2: "블루 골드윙캡",
    probability2: 0.0007,
    drop3: "흑갑충",
    probability3: 0.0008,
    drop4: "청운검",
    probability4: 0.0007,
    drop5: "그린 쉐도우부츠",
    probability5: 0.0006,
    drop6: "그린 리넥스",
    probability6: 0.0009,
    drop7: "그린 크로스햇",
    probability7: 0.0007,
    drop8: "녹견랑포",
    probability8: 0.0006,
    drop9: "그린 피레타부츠",
    probability9: 0.0007,
    drop10: "일비 표창",
    probability10: 0.0008,
    drop11: "블러드 플라티나",
    probability11: 0.0009,
    drop12: "오리할콘 플라틴 바지",
    probability12: 0.0009,
    drop13: "그린 크리시아슈즈",
    probability13: 0.0007,
    drop14: "다크 페로치",
    probability14: 0.0006,
    drop15: "블루 아데스",
    probability15: 0.0006,
    drop16: "블루 레퀴엠",
    probability16: 0.0009,
    drop17: "레드 리버스부츠",
    probability17: 0.0007,
    drop18: "딸기 귀고리",
    probability18: 0.0008,
    drop19: "황룡도",
    probability19: 0.0008,
    drop20: "세라프의 망토",
    probability20: 0.0008,
  },
  {
    이름: "하프",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "호문스큘러",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "시간의 눈",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 예티와 페페",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블러드 하프",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파이렛",
    drop1: "베이지 웨어캡",
    probability1: 0.0009,
    drop2: "블루 아데스",
    probability2: 0.0007,
    drop3: "다크 배틀로드",
    probability3: 0.0008,
    drop4: "블루 윙부츠",
    probability4: 0.0008,
    drop5: "미스릴 플래닛",
    probability5: 0.0009,
    drop6: "다크 스타드 바지",
    probability6: 0.0009,
    drop7: "다크 스타드",
    probability7: 0.0009,
    drop8: "다크 샬리트",
    probability8: 0.0007,
    drop9: "골드 아퀼라 실드",
    probability9: 0.0008,
    drop10: "다크 골드윙캡",
    probability10: 0.0008,
    drop11: "다크 페로치",
    probability11: 0.0007,
    drop12: "다크 넬리슈즈",
    probability12: 0.0007,
    drop13: "그린 쉐도우부츠",
    probability13: 0.0007,
    drop14: "그린 피라테 바지",
    probability14: 0.0008,
    drop15: "그린 피라테",
    probability15: 0.0008,
    drop16: "호진공창",
    probability16: 0.0007,
    drop17: "다크 크로스햇",
    probability17: 0.0008,
    drop18: "레드 드래곤 바부타",
    probability18: 0.0008,
    drop19: "다크 엠페러",
    probability19: 0.0008,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "망둥이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "버크",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "데스테니",
    drop1: "카타르",
    probability1: 0.0007,
    drop2: "세라프의 망토",
    probability2: 0.0008,
    drop3: "다크 카젠부츠",
    probability3: 0.0009,
    drop4: "블루 크리시스",
    probability4: 0.0008,
    drop5: "레드 힌켈",
    probability5: 0.0008,
    drop6: "브라운 스타드",
    probability6: 0.0009,
    drop7: "브라운 스타드 바지",
    probability7: 0.0009,
    drop8: "다크 와이즈",
    probability8: 0.0007,
    drop9: "그린 리넥스",
    probability9: 0.0008,
    drop10: "다크 넬리슈즈",
    probability10: 0.0006,
    drop11: "다크 워 그리브",
    probability11: 0.0006,
    drop12: "블러드 로버",
    probability12: 0.0007,
    drop13: "다크 미스트라",
    probability13: 0.0006,
    drop14: "레드 피레타햇",
    probability14: 0.0007,
    drop15: "다크 윙부츠",
    probability15: 0.0007,
    drop16: "헬리오스",
    probability16: 0.0007,
    drop17: "피닉스 완드",
    probability17: 0.0008,
    drop18: "블루 아데스",
    probability18: 0.0006,
    drop19: "다크 배틀로드",
    probability19: 0.0007,
    drop20: "노란색 우산",
    probability20: 0.001,
  },
  {
    이름: "듀얼 파이렛",
    drop1: "블러드 엠페러",
    probability1: 0.0008,
    drop2: "다크 크로스햇",
    probability2: 0.0008,
    drop3: "다크 파쵸네",
    probability3: 0.0007,
    drop4: "다크 허스크",
    probability4: 0.0009,
    drop5: "다크 피레타햇",
    probability5: 0.0008,
    drop6: "다크 레퀴엠",
    probability6: 0.0009,
    drop7: "다크 스타드",
    probability7: 0.0009,
    drop8: "다크 스타드 바지",
    probability8: 0.0009,
    drop9: "레드 힌켈",
    probability9: 0.0009,
    drop10: "다크 와이즈",
    probability10: 0.0008,
    drop11: "브라운 쉐이드슈트 바지",
    probability11: 0.0007,
    drop12: "그린 미스트슈트",
    probability12: 0.0007,
    drop13: "그린 피라테",
    probability13: 0.0008,
    drop14: "그린 피라테 바지",
    probability14: 0.0008,
    drop15: "메탈하트 이어링",
    probability15: 0.0008,
    drop16: "다크 워 그리브",
    probability16: 0.0007,
    drop17: "블루 크리시스",
    probability17: 0.0008,
    drop18: "다크 코든",
    probability18: 0.0008,
    drop19: "다크 아데브",
    probability19: 0.0007,
    drop20: "뇌전 수리검",
    probability20: 0.0008,
  },
  {
    이름: "듀얼 버크",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "붉은 켄타우로스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "푸른 켄타우로스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "검은 켄타우로스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마스터 데스테니",
    drop1: "다크 제너럴 바지",
    probability1: 0.0007,
    drop2: "다크 코든",
    probability2: 0.0008,
    drop3: "블루 키튼서클렛",
    probability3: 0.0009,
    drop4: "다크 넬리슈즈",
    probability4: 0.0007,
    drop5: "다크 리넥스슈즈",
    probability5: 0.0009,
    drop6: "블루 크리시스",
    probability6: 0.0008,
    drop7: "다크 크로스햇",
    probability7: 0.0008,
    drop8: "다크 피라테",
    probability8: 0.0008,
    drop9: "다크 피라테 바지",
    probability9: 0.0008,
    drop10: "다크 리버스부츠",
    probability10: 0.0008,
    drop11: "다크 제너럴",
    probability11: 0.0007,
    drop12: "다크 배틀로드",
    probability12: 0.0008,
    drop13: "그린 프리아",
    probability13: 0.0008,
    drop14: "아츠",
    probability14: 0.0008,
    drop15: "블루 카젠부츠",
    probability15: 0.0009,
    drop16: "다크 미스트라",
    probability16: 0.0007,
    drop17: "다크 아데스슈즈",
    probability17: 0.0007,
    drop18: "다크 골드윙캡",
    probability18: 0.0008,
    drop19: "블러드 루크",
    probability19: 0.0009,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파이어독",
    drop1: "블루 리넥스슈즈",
    probability1: 0.0009,
    drop2: "다크 카젠부츠",
    probability2: 0.0009,
    drop3: "블루 드래곤 바부타",
    probability3: 0.0008,
    drop4: "다크 엠페러",
    probability4: 0.0008,
    drop5: "다크 피레타부츠",
    probability5: 0.0008,
    drop6: "그린 프리아",
    probability6: 0.0008,
    drop7: "다크 레퀴엠",
    probability7: 0.0009,
    drop8: "헬리오스",
    probability8: 0.0008,
    drop9: "골드 아룬드",
    probability9: 0.0008,
    drop10: "골드 와이어스",
    probability10: 0.0008,
    drop11: "다크 코든",
    probability11: 0.0008,
    drop12: "다크 아데브",
    probability12: 0.0007,
    drop13: "그린 미스트슈트",
    probability13: 0.0007,
    drop14: "브라운 쉐이드슈트 바지",
    probability14: 0.0007,
    drop15: "다크 넬리슈즈",
    probability15: 0.0007,
    drop16: "레드 쉐도우부츠",
    probability16: 0.001,
    drop17: "장미꽃 귀고리",
    probability17: 0.0008,
    drop18: "골드 아퀼라 실드",
    probability18: 0.0008,
    drop19: "골드 와이어스",
    probability19: 0.0009,
    drop20: "토비 표창",
    probability20: 0.001,
  },
  {
    이름: "폭렬 망둥이집",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블루 드래곤 터틀",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "추억의 사제",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "본 피쉬",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레드 드래곤 터틀",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "바이킹",
    drop1: "블루 리넥스",
    probability1: 0.0009,
    drop2: "블러드 루크",
    probability2: 0.0009,
    drop3: "다크 배틀로드",
    probability3: 0.0008,
    drop4: "다크 제너럴",
    probability4: 0.0007,
    drop5: "다크 제너럴 바지",
    probability5: 0.0007,
    drop6: "블루 프리아",
    probability6: 0.0008,
    drop7: "레드 피레타햇",
    probability7: 0.0008,
    drop8: "블루 크리시스",
    probability8: 0.0008,
    drop9: "다크 골드윙캡",
    probability9: 0.0008,
    drop10: "다크 키튼서클렛",
    probability10: 0.0009,
    drop11: "다크 엠페러",
    probability11: 0.0008,
    drop12: "호프만",
    probability12: 0.0009,
    drop13: "다크 파쵸네",
    probability13: 0.0007,
    drop14: "골든해머",
    probability14: 0.0008,
    drop15: "일비 표창",
    probability15: 0.0004,
    drop16: "그린 미스트슈트",
    probability16: 0.0007,
    drop17: "브라운 쉐이드슈트 바지",
    probability17: 0.0007,
    drop18: "다크 스타드",
    probability18: 0.0008,
    drop19: "다크 스타드 바지",
    probability19: 0.0008,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스퀴드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "추억의 신관",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "리스튼",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "팬텀워치",
    drop1: "블러드 엠페러",
    probability1: 0.0008,
    drop2: "블루 드래곤 바부타",
    probability2: 0.0008,
    drop3: "블루 프리아",
    probability3: 0.0008,
    drop4: "그린 아데스",
    probability4: 0.0007,
    drop5: "다크 코든",
    probability5: 0.0008,
    drop6: "다크 피라테 바지",
    probability6: 0.0008,
    drop7: "다크 피라테",
    probability7: 0.0008,
    drop8: "다크 리버스부츠",
    probability8: 0.0008,
    drop9: "골드 아룬드",
    probability9: 0.0008,
    drop10: "다크 아이즈",
    probability10: 0.0009,
    drop11: "다크 제너럴",
    probability11: 0.0007,
    drop12: "그린 제너럴 바지",
    probability12: 0.0007,
    drop13: "그린 크리시스",
    probability13: 0.0009,
    drop14: "다크 배틀로드",
    probability14: 0.0008,
    drop15: "흑견랑포",
    probability15: 0.0007,
    drop16: "다크 파쵸네",
    probability16: 0.0007,
    drop17: "스페판",
    probability17: 0.0009,
    drop18: "용천권",
    probability18: 0.0008,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "리셀 스퀴드",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레드 와이번",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "브레스튼",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "추억의 수호병",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "기간틱 바이킹",
    drop1: "블루 리넥스슈즈",
    probability1: 0.0009,
    drop2: "블루 아데스",
    probability2: 0.0007,
    drop3: "녹견랑포",
    probability3: 0.0007,
    drop4: "브라운 미스트슈트",
    probability4: 0.0007,
    drop5: "브라운 쉐이드슈트 바지",
    probability5: 0.0007,
    drop6: "다크 배틀로드",
    probability6: 0.0008,
    drop7: "블루 크리시스",
    probability7: 0.0009,
    drop8: "다크 허스크",
    probability8: 0.0009,
    drop9: "다크 파쵸네",
    probability9: 0.0007,
    drop10: "다크 윙부츠",
    probability10: 0.0008,
    drop11: "다크 샬리트",
    probability11: 0.0008,
    drop12: "그린 프리아",
    probability12: 0.0008,
    drop13: "그레이트 로헨",
    probability13: 0.0008,
    drop14: "블러드 로버",
    probability14: 0.0008,
    drop15: "레드 피레타햇",
    probability15: 0.0008,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "G.팬텀워치",
    drop1: "블러드 로버",
    probability1: 0.0008,
    drop2: "다크 와이즈",
    probability2: 0.0008,
    drop3: "브라운 쉐이드슈트 바지",
    probability3: 0.0007,
    drop4: "브라운 미스트슈트",
    probability4: 0.0007,
    drop5: "다크 엠페러",
    probability5: 0.0008,
    drop6: "다크 크리시아슈즈",
    probability6: 0.0008,
    drop7: "다크 페로치",
    probability7: 0.0007,
    drop8: "그린 리넥스",
    probability8: 0.0009,
    drop9: "그린 아데스",
    probability9: 0.0007,
    drop10: "녹견랑포",
    probability10: 0.0007,
    drop11: "레드 피레타햇",
    probability11: 0.0008,
    drop12: "다크 제너럴",
    probability12: 0.0007,
    drop13: "그린 제너럴 바지",
    probability13: 0.0007,
    drop14: "다크 샬리트",
    probability14: 0.0008,
    drop15: "브라운 루크",
    probability15: 0.0009,
    drop16: "레드 아데스햇",
    probability16: 0.0007,
    drop17: "다크 윙부츠",
    probability17: 0.0008,
    drop18: "실버 에이션트 실드",
    probability18: 0.0009,
    drop19: "크롬",
    probability19: 0.0008,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "샤크",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "그린 코니언",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블루 와이번",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "추억의 수호대장",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "콜드 샤크",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 와이번",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다크 코니언",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "뉴트 주니어",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "후회의 사제",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "게이트키퍼",
    drop1: "블루 리넥스",
    probability1: 0.0009,
    drop2: "코브라스티어",
    probability2: 0.0008,
    drop3: "다크 아데스햇",
    probability3: 0.0007,
    drop4: "다크 배틀로드",
    probability4: 0.0008,
    drop5: "녹견랑포",
    probability5: 0.0007,
    drop6: "흑갑충",
    probability6: 0.0009,
    drop7: "골드 아룬드",
    probability7: 0.0008,
    drop8: "다크 와이즈",
    probability8: 0.0008,
    drop9: "다크 크리시아슈즈",
    probability9: 0.0008,
    drop10: "월아산",
    probability10: 0.0008,
    drop11: "그린 가티나부츠",
    probability11: 0.0001,
    drop12: "그로스야거",
    probability12: 0.0009,
    drop13: "다크 제너럴",
    probability13: 0.0007,
    drop14: "다크 제너럴 바지",
    probability14: 0.0007,
    drop15: "레드 쉐이드슈트 바지",
    probability15: 0.0007,
    drop16: "레드 미스트슈트",
    probability16: 0.0007,
    drop17: "레드 피레타햇",
    probability17: 0.0008,
    drop18: "딸기 귀고리",
    probability18: 0.0007,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "타나토스",
    drop1: "흑견랑포",
    probability1: 0.0007,
    drop2: "다크 리버스부츠",
    probability2: 0.0008,
    drop3: "브라운 루크",
    probability3: 0.0009,
    drop4: "다크 배틀로드",
    probability4: 0.0008,
    drop5: "다크 제너럴",
    probability5: 0.0007,
    drop6: "다크 제너럴 바지",
    probability6: 0.0007,
    drop7: "다크 피라테",
    probability7: 0.0008,
    drop8: "다크 피라테 바지",
    probability8: 0.0008,
    drop9: "그린 프리아",
    probability9: 0.0008,
    drop10: "다크 샬리트",
    probability10: 0.0008,
    drop11: "골드 에이션트 실드",
    probability11: 0.0009,
    drop12: "금강저",
    probability12: 0.0009,
    drop13: "다크 아데스햇",
    probability13: 0.0007,
    drop14: "그린 크리시아슈즈",
    probability14: 0.0008,
    drop15: "그린 크리시스",
    probability15: 0.0008,
    drop16: "자일즈의 망토",
    probability16: 0.0007,
    drop17: "칸디네",
    probability17: 0.0009,
    drop18: "골드 아룬드",
    probability18: 0.0008,
    drop19: "그린 아르나슈즈",
    probability19: 0.0001,
    drop20: "골드 칼칸",
    probability20: 0.0007,
  },
  {
    이름: "후회의 신관",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "네스트 골렘",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스켈레곤",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스켈로스",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "후회의 수호병",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "후회의 수호대장",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "망각의 사제",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "망각의 신관",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "망각의 수호병",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "망각의 수호대장",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "아이언보어",
    drop1: "다크 브레이스",
    probability1: 0.001,
    drop2: "브라운 피에르타",
    probability2: 0.0009,
    drop3: "브라운 피에뜨 바지",
    probability3: 0.0008,
    drop4: "브라운 필퍼",
    probability4: 0.001,
    drop5: "레드 문슈즈",
    probability5: 0.0009,
    drop6: "청 진월장화",
    probability6: 0.0008,
    drop7: "다크 칼라스",
    probability7: 0.0006,
    drop8: "펜지 이어링",
    probability8: 0.0008,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마노",
    drop1: "바이킹소드",
    probability1: 0.03,
    drop2: "콘트라 엑스",
    probability2: 0.03,
    drop3: "강철 도끼",
    probability3: 0.03,
    drop4: "007 가방",
    probability4: 0.03,
    drop5: "곡괭이",
    probability5: 0.03,
    drop6: "죽창",
    probability6: 0.03,
    drop7: "팬텀",
    probability7: 0.03,
    drop8: "고목나무 스태프",
    probability8: 0.03,
    drop9: "아이스 완드",
    probability9: 0.03,
    drop10: "스틸 이고르",
    probability10: 0.03,
    drop11: "스팅어",
    probability11: 0.03,
    drop12: "넙치검",
    probability12: 0.03,
    drop13: "사냥꾼의 활",
    probability13: 0.03,
    drop14: "발란쉐",
    probability14: 0.03,
    drop15: "미스릴 버클러",
    probability15: 0.03,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스텀피",
    drop1: "커틀러스",
    probability1: 0.03,
    drop2: "왕푸",
    probability2: 0.03,
    drop3: "하이랜더",
    probability3: 0.03,
    drop4: "당커",
    probability4: 0.03,
    drop5: "니암",
    probability5: 0.03,
    drop6: "프라이팬",
    probability6: 0.03,
    drop7: "호스맨즈",
    probability7: 0.03,
    drop8: "빅해머",
    probability8: 0.03,
    drop9: "나카마키",
    probability9: 0.03,
    drop10: "도끼 폴암",
    probability10: 0.03,
    drop11: "페탈 스태프",
    probability11: 0.03,
    drop12: "크리스탈 완드",
    probability12: 0.03,
    drop13: "다크 가디언",
    probability13: 0.03,
    drop14: "태극부채",
    probability14: 0.03,
    drop15: "카스",
    probability15: 0.03,
    drop16: "레드 바이퍼",
    probability16: 0.03,
    drop17: "헤클러",
    probability17: 0.03,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "데우",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "세르프",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "파우스트",
    drop1: "쥬얼 쿠아다라",
    probability1: 0.03,
    drop2: "호검",
    probability2: 0.03,
    drop3: "벅",
    probability3: 0.03,
    drop4: "라이징",
    probability4: 0.03,
    drop5: "너클메이스",
    probability5: 0.03,
    drop6: "골든 모울",
    probability6: 0.03,
    drop7: "장팔사모",
    probability7: 0.03,
    drop8: "구룡도",
    probability8: 0.03,
    drop9: "아크 스태프",
    probability9: 0.03,
    drop10: "크로미",
    probability10: 0.03,
    drop11: "다크 슬레인",
    probability11: 0.03,
    drop12: "신기타",
    probability12: 0.03,
    drop13: "차",
    probability13: 0.03,
    drop14: "올림푸스",
    probability14: 0.03,
    drop15: "로우어",
    probability15: 0.03,
    drop16: "해골 방패",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "킹크랑",
    drop1: "쥬얼 쿠아다라",
    probability1: 0.03,
    drop2: "호검",
    probability2: 0.03,
    drop3: "벅",
    probability3: 0.03,
    drop4: "라이징",
    probability4: 0.03,
    drop5: "너클메이스",
    probability5: 0.03,
    drop6: "골든 모울",
    probability6: 0.03,
    drop7: "장팔사모",
    probability7: 0.03,
    drop8: "구룡도",
    probability8: 0.03,
    drop9: "아크 스태프",
    probability9: 0.03,
    drop10: "크로미",
    probability10: 0.03,
    drop11: "다크 슬레인",
    probability11: 0.03,
    drop12: "신기타",
    probability12: 0.03,
    drop13: "차",
    probability13: 0.03,
    drop14: "올림푸스",
    probability14: 0.03,
    drop15: "로우어",
    probability15: 0.03,
    drop16: "해골 방패",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "타이머",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "하트 완드",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "일비 표창",
    probability17: 0.03,
    drop18: "뇌전 수리검",
    probability18: 0.03,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "다일",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "이블테일러",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "제노",
    drop1: "광선채찍",
    probability1: 0.03,
    drop2: "그레이트 로헨",
    probability2: 0.03,
    drop3: "청운검",
    probability3: 0.03,
    drop4: "헬리오스",
    probability4: 0.03,
    drop5: "골든해머",
    probability5: 0.03,
    drop6: "크롬",
    probability6: 0.03,
    drop7: "호진공창",
    probability7: 0.03,
    drop8: "월아산",
    probability8: 0.03,
    drop9: "레이든 스태프",
    probability9: 0.03,
    drop10: "피닉스 완드",
    probability10: 0.03,
    drop11: "코브라스티어",
    probability11: 0.03,
    drop12: "용천권",
    probability12: 0.03,
    drop13: "카타르",
    probability13: 0.03,
    drop14: "골드 아룬드",
    probability14: 0.03,
    drop15: "다크 샬리트",
    probability15: 0.03,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "구미호",
    drop1: "레드 카타나",
    probability1: 0.03,
    drop2: "그륜힐",
    probability2: 0.03,
    drop3: "미하일",
    probability3: 0.03,
    drop4: "크로노",
    probability4: 0.03,
    drop5: "스튬",
    probability5: 0.03,
    drop6: "호프만",
    probability6: 0.03,
    drop7: "스페판",
    probability7: 0.03,
    drop8: "황룡도",
    probability8: 0.03,
    drop9: "이블윙즈",
    probability9: 0.03,
    drop10: "엔젤윙즈",
    probability10: 0.03,
    drop11: "흑갑충",
    probability11: 0.03,
    drop12: "칸디네",
    probability12: 0.03,
    drop13: "금강저",
    probability13: 0.03,
    drop14: "레드 힌켈",
    probability14: 0.03,
    drop15: "그로스야거",
    probability15: 0.03,
    drop16: "화비 표창",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "태륜",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "요괴선사",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "엘리쟈",
    drop1: "레드 카타나",
    probability1: 0.03,
    drop2: "그륜힐",
    probability2: 0.03,
    drop3: "미하일",
    probability3: 0.03,
    drop4: "크로노",
    probability4: 0.03,
    drop5: "스튬",
    probability5: 0.03,
    drop6: "호프만",
    probability6: 0.03,
    drop7: "스페판",
    probability7: 0.03,
    drop8: "황룡도",
    probability8: 0.03,
    drop9: "이블윙즈",
    probability9: 0.03,
    drop10: "엔젤윙즈",
    probability10: 0.03,
    drop11: "흑갑충",
    probability11: 0.03,
    drop12: "칸디네",
    probability12: 0.03,
    drop13: "금강저",
    probability13: 0.03,
    drop14: "레드 힌켈",
    probability14: 0.03,
    drop15: "그로스야거",
    probability15: 0.03,
    drop16: "화비 표창",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스노우맨",
    drop1: "아츠",
    probability1: 0.03,
    drop2: "그레이트 로헨",
    probability2: 0.03,
    drop3: "청운검",
    probability3: 0.03,
    drop4: "헬리오스",
    probability4: 0.03,
    drop5: "골든해머",
    probability5: 0.03,
    drop6: "크롬",
    probability6: 0.03,
    drop7: "호진공창",
    probability7: 0.03,
    drop8: "월아산",
    probability8: 0.03,
    drop9: "레이든 스태프",
    probability9: 0.03,
    drop10: "피닉스 완드",
    probability10: 0.03,
    drop11: "코브라스티어",
    probability11: 0.03,
    drop12: "용천권",
    probability12: 0.03,
    drop13: "카타르",
    probability13: 0.03,
    drop14: "골드 아룬드",
    probability14: 0.03,
    drop15: "다크 샬리트",
    probability15: 0.03,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "대왕지네",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "이블테일러",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "일비 표창",
    probability17: 0.03,
    drop18: "뇌전 수리검",
    probability18: 0.03,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "포장마차",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "이블테일러",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "일비 표창",
    probability17: 0.03,
    drop18: "낚싯대",
    probability18: 0.03,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "머쉬맘",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "이블테일러",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "일비 표창",
    probability17: 0.03,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "좀비 머쉬맘",
    drop1: "빨강 우산",
    probability1: 0.03,
    drop2: "네오코라",
    probability2: 0.03,
    drop3: "그리스",
    probability3: 0.03,
    drop4: "호크헤드",
    probability4: 0.03,
    drop5: "샤이닝",
    probability5: 0.03,
    drop6: "타무스",
    probability6: 0.03,
    drop7: "플루튬",
    probability7: 0.03,
    drop8: "십자창",
    probability8: 0.03,
    drop9: "방천극",
    probability9: 0.03,
    drop10: "쏜즈",
    probability10: 0.03,
    drop11: "이블테일러",
    probability11: 0.03,
    drop12: "다크 기간틱",
    probability12: 0.03,
    drop13: "게타",
    probability13: 0.03,
    drop14: "봉황위궁",
    probability14: 0.03,
    drop15: "골든 크로우",
    probability15: 0.03,
    drop16: "아다만티움 레전드 실드",
    probability16: 0.03,
    drop17: "일비 표창",
    probability17: 0.03,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "블루 머쉬맘",
    drop1: "검정 우산",
    probability1: 0.03,
    drop2: "그레이트 로헨",
    probability2: 0.03,
    drop3: "청운검",
    probability3: 0.03,
    drop4: "헬리오스",
    probability4: 0.03,
    drop5: "골든해머",
    probability5: 0.03,
    drop6: "크롬",
    probability6: 0.03,
    drop7: "호진공창",
    probability7: 0.03,
    drop8: "월아산",
    probability8: 0.03,
    drop9: "레이든 스태프",
    probability9: 0.03,
    drop10: "피닉스 완드",
    probability10: 0.03,
    drop11: "코브라스티어",
    probability11: 0.03,
    drop12: "용천권",
    probability12: 0.03,
    drop13: "카타르",
    probability13: 0.03,
    drop14: "골드 아룬드",
    probability14: 0.03,
    drop15: "다크 샬리트",
    probability15: 0.03,
    drop16: "일비 표창",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어 발록",
    drop1: "레드 카타나",
    probability1: 0.03,
    drop2: "그륜힐",
    probability2: 0.03,
    drop3: "미하일",
    probability3: 0.03,
    drop4: "크로노",
    probability4: 0.03,
    drop5: "스튬",
    probability5: 0.03,
    drop6: "호프만",
    probability6: 0.03,
    drop7: "스페판",
    probability7: 0.03,
    drop8: "황룡도",
    probability8: 0.03,
    drop9: "이블윙즈",
    probability9: 0.03,
    drop10: "엔젤윙즈",
    probability10: 0.03,
    drop11: "흑갑충",
    probability11: 0.03,
    drop12: "칸디네",
    probability12: 0.03,
    drop13: "금강저",
    probability13: 0.03,
    drop14: "레드 힌켈",
    probability14: 0.03,
    drop15: "그로스야거",
    probability15: 0.03,
    drop16: "화비 표창",
    probability16: 0.03,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "크림슨 발록",
    drop1: "화비 표창",
    probability1: 0.1,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "그리프",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "마뇽",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레비아탄",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "도도",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "릴리노흐",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "라이카",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크 달팽이",
    drop1: "스카이 스노우보드",
    probability1: 0.00004,
    drop2: "코-크 모자",
    probability2: 0.0001,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크 슬라임",
    drop1: "아쿠아 스노우보드",
    probability1: 0.00004,
    drop2: "코-크 모자",
    probability2: 0.0001,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크 버섯",
    drop1: "아쿠아 스노우보드",
    probability1: 0.00004,
    drop2: "코-크 모자",
    probability2: 0.0001,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크텀프",
    drop1: "실버 스노우보드",
    probability1: 0.00004,
    drop2: "코-크 모자",
    probability2: 0.0001,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크텀프 라이트",
    drop1: "실버 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크 돼지",
    drop1: "골든 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크 씰",
    drop1: "골든 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "플레이 씰",
    drop1: "골든 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "이글루 터틀",
    drop1: "다크 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "코-크 골렘",
    drop1: "슈퍼 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "아이스 골렘",
    drop1: "블러드 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "예티와 코-크텀프",
    drop1: "블러드 스노우보드",
    probability1: 0.00004,
    drop2: "코-크플레이 방패",
    probability2: 0.00001,
    drop3: "코-크 귀고리",
    probability3: 0.0001,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "들개",
    drop1: "빨간색 별 두건",
    probability1: 0.001,
    drop2: "흑룡포",
    probability2: 0.001,
    drop3: "그린 모리칸",
    probability3: 0.001,
    drop4: "그린 잭부츠",
    probability4: 0.001,
    drop5: "빨간 삼각 방패",
    probability5: 0.0008,
    drop6: "라이덴",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "멋쟁이 들개",
    drop1: "아이언 버거넷 헬름",
    probability1: 0.001,
    drop2: "검은색 사제의 로브",
    probability2: 0.001,
    drop3: "다크 사바타",
    probability3: 0.001,
    drop4: "아이언 체인슈즈",
    probability4: 0.001,
    drop5: "다크 가디언",
    probability5: 0.001,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "험악한 들개",
    drop1: "화이트 길티언",
    probability1: 0.001,
    drop2: "그린 루이마리",
    probability2: 0.001,
    drop3: "황월 장갑",
    probability3: 0.001,
    drop4: "황 진월장화",
    probability4: 0.001,
    drop5: "제코",
    probability5: 0.001,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "광대 원숭이",
    drop1: "그린 크라페",
    probability1: 0.001,
    drop2: "카키 쉐도우",
    probability2: 0.001,
    drop3: "카키 쉐도우 바지",
    probability3: 0.001,
    drop4: "다크 너클",
    probability4: 0.001,
    drop5: "태극부채",
    probability5: 0.001,
    drop6: "실버 스노우보드",
    probability6: 0.00001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "폭주족 원숭이",
    drop1: "녹 흑일모자",
    probability1: 0.001,
    drop2: "오우커 숄더메일",
    probability2: 0.001,
    drop3: "오우커 숄더메일 바지",
    probability3: 0.001,
    drop4: "다크 페넌스",
    probability4: 0.001,
    drop5: "브라운 네일슈즈",
    probability5: 0.001,
    drop6: "쏜즈",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레드 버블티",
    drop1: "파이어 잭",
    probability1: 0.0007,
    drop2: "커틀러스",
    probability2: 0.0007,
    drop3: "다크 쉐도우",
    probability3: 0.0007,
    drop4: "다크 쉐도우 바지",
    probability4: 0.0007,
    drop5: "노란색 우산",
    probability5: 0.0007,
    drop6: "쥬얼 쿠아다라",
    probability6: 0.0007,
    drop7: "해골 귀고리",
    probability7: 0.0007,
    drop8: "화이트 길티언",
    probability8: 0.0007,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "옐로우 버블티",
    drop1: "알루미늄 야구 방망이",
    probability1: 0.001,
    drop2: "하늘색 우산",
    probability2: 0.001,
    drop3: "목비 표창",
    probability3: 0.001,
    drop4: "흑룡포",
    probability4: 0.001,
    drop5: "청 진월장화",
    probability5: 0.001,
    drop6: "태극부채",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "그린 버블티",
    drop1: "그린 폴레패더햇",
    probability1: 0.0007,
    drop2: "눈덩이",
    probability2: 0.0007,
    drop3: "그린 핀터",
    probability3: 0.0007,
    drop4: "블루 가이즈",
    probability4: 0.0007,
    drop5: "다크실버 스틸러",
    probability5: 0.0007,
    drop6: "퍼플 스틸레스 바지",
    probability6: 0.0007,
    drop7: "블루 실키부츠",
    probability7: 0.0007,
    drop8: "그린 필퍼",
    probability8: 0.0007,
    drop9: "레드 듀크",
    probability9: 0.0007,
    drop10: "다크 쉐도우",
    probability10: 0.0006,
    drop11: "다크 쉐도우 바지",
    probability11: 0.0006,
    drop12: "아다만티움 보닌",
    probability12: 0.0007,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "예티 인형자판기",
    drop1: "펜지 이어링",
    probability1: 0.0007,
    drop2: "왕푸",
    probability2: 0.0007,
    drop3: "브라운 매티",
    probability3: 0.0007,
    drop4: "브라운 폴레패더햇",
    probability4: 0.0007,
    drop5: "자진일갑주",
    probability5: 0.0007,
    drop6: "흑진일갑주 바지",
    probability6: 0.0007,
    drop7: "다크 윌로우",
    probability7: 0.0007,
    drop8: "브라운 크라페",
    probability8: 0.0007,
    drop9: "호검",
    probability9: 0.0007,
    drop10: "아크 스태프",
    probability10: 0.0007,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "예티 인형",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어페페 인형자판기",
    drop1: "자진일갑주",
    probability1: 0.0006,
    drop2: "펜지 이어링",
    probability2: 0.0006,
    drop3: "크로미",
    probability3: 0.0006,
    drop4: "차",
    probability4: 0.0006,
    drop5: "이글크로",
    probability5: 0.0006,
    drop6: "다크 가디언",
    probability6: 0.0006,
    drop7: "니암",
    probability7: 0.0006,
    drop8: "블랙 솔트슈즈",
    probability8: 0.0006,
    drop9: "실버 크로우",
    probability9: 0.0006,
    drop10: "장팔사모",
    probability10: 0.0006,
    drop11: "캣츠 아이",
    probability11: 0.0006,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "주니어페페 인형",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "인형자판기",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "인형뽑기 기계",
    drop1: "레드 크로스 실드",
    probability1: 0.0007,
    drop2: "목비 표창",
    probability2: 0.0007,
    drop3: "마법의 망토",
    probability3: 0.0007,
    drop4: "젝커",
    probability4: 0.0007,
    drop5: "신기타",
    probability5: 0.0007,
    drop6: "블루 아나카문",
    probability6: 0.0007,
    drop7: "샤이닝",
    probability7: 0.0007,
    drop8: "금강저",
    probability8: 0.0004,
    drop9: "다크 기간틱",
    probability9: 0.0006,
    drop10: "홀리 크로스링",
    probability10: 0.0007,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "악마 슬라임",
    drop1: "다크 워 부츠",
    probability1: 0.0007,
    drop2: "갈색 하드래더 바지",
    probability2: 0.0007,
    drop3: "이너스 체인바지",
    probability3: 0.0007,
    drop4: "라이덴",
    probability4: 0.0007,
    drop5: "물고기 작살",
    probability5: 0.0007,
    drop6: "빨간색 가죽 구두",
    probability6: 0.0007,
    drop7: "헤클러",
    probability7: 0.0007,
    drop8: "갈색 하드래더",
    probability8: 0.0007,
    drop9: "그린 헌터스 아머",
    probability9: 0.0007,
    drop10: "아이언 체인슈즈",
    probability10: 0.0007,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "냉염 퍼퓸(변신전)",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "냉염 퍼퓸(변신후)",
    drop1: "호크헤드",
    probability1: 0.0007,
    drop2: "레드 오리엔트 헬멧",
    probability2: 0.0007,
    drop3: "실버 하이드후드",
    probability3: 0.0007,
    drop4: "다크 카젠부츠",
    probability4: 0.0007,
    drop5: "금강저",
    probability5: 0.0007,
    drop6: "다크 스타드 바지",
    probability6: 0.0007,
    drop7: "골드 에이션트 실드",
    probability7: 0.0007,
    drop8: "라이트 스콜피오",
    probability8: 0.0007,
    drop9: "블러드 모스부츠",
    probability9: 0.0007,
    drop10: "다크 웨어캡",
    probability10: 0.0007,
    drop11: "다크 마누트",
    probability11: 0.0007,
    drop12: "다크 스타드",
    probability12: 0.0007,
    drop13: "레드 카타나",
    probability13: 0.0007,
    drop14: "그린 고어부츠",
    probability14: 0.0007,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "백화 퍼퓸(변신전)",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "백화 퍼퓸(변신후)",
    drop1: "골드 라피스샌들",
    probability1: 0.0007,
    drop2: "미하일",
    probability2: 0.0007,
    drop3: "다크 아이즈",
    probability3: 0.0007,
    drop4: "그로스야거",
    probability4: 0.0007,
    drop5: "엔젤윙즈",
    probability5: 0.0007,
    drop6: "다크 클랜치",
    probability6: 0.0007,
    drop7: "그륜힐",
    probability7: 0.0007,
    drop8: "블루 리넥스슈즈",
    probability8: 0.0007,
    drop9: "골든 크로우",
    probability9: 0.0007,
    drop10: "샤이닝",
    probability10: 0.0007,
    drop11: "블루 키튼서클렛",
    probability11: 0.0007,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스위트하트 퍼퓸(변신전)",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "스위트하트 퍼퓸(변신후)",
    drop1: "골드 라피스샌들",
    probability1: 0.0007,
    drop2: "호프만",
    probability2: 0.0007,
    drop3: "블루 루티드슈즈",
    probability3: 0.0007,
    drop4: "다크 스콜피오",
    probability4: 0.0007,
    drop5: "그로스야거",
    probability5: 0.0007,
    drop6: "다크 카젠부츠",
    probability6: 0.0007,
    drop7: "분홍꽃 귀고리",
    probability7: 0.0007,
    drop8: "네오코라",
    probability8: 0.0007,
    drop9: "칸디네",
    probability9: 0.0007,
    drop10: "이블윙즈",
    probability10: 0.0007,
    drop11: "다크 오리엔타이칸 바지",
    probability11: 0.0007,
    drop12: "블루 앵클부츠",
    probability12: 0.0007,
    drop13: "그린 고어부츠",
    probability13: 0.0007,
    drop14: "블러드 카멜부츠",
    probability14: 0.0007,
    drop15: "브론즈 하이드후드",
    probability15: 0.0007,
    drop16: "다크 모스부츠",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "토끼귀신 인형",
    drop1: "흑궁우",
    probability1: 0.0007,
    drop2: "방천극",
    probability2: 0.0007,
    drop3: "오리할콘 플라틴 바지",
    probability3: 0.0007,
    drop4: "골든 크로우",
    probability4: 0.0007,
    drop5: "칸디네",
    probability5: 0.0002,
    drop6: "골드 라피스샌들",
    probability6: 0.0007,
    drop7: "핑크 앵클부츠",
    probability7: 0.0007,
    drop8: "골드 파우",
    probability8: 0.0007,
    drop9: "브론즈 하이드후드",
    probability9: 0.0007,
    drop10: "스페판",
    probability10: 0.0007,
    drop11: "블루 카젠부츠",
    probability11: 0.0007,
    drop12: "블러드 카멜부츠",
    probability12: 0.0007,
    drop13: "골드 와이어스",
    probability13: 0.0007,
    drop14: "레드 오리엔타이칸",
    probability14: 0.0007,
    drop15: "다크 허스크",
    probability15: 0.0007,
    drop16: "엔젤윙즈",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "인형 로즈",
    drop1: "타무스",
    probability1: 0.0007,
    drop2: "골든 크로우",
    probability2: 0.0007,
    drop3: "스네이크",
    probability3: 0.0007,
    drop4: "스튬",
    probability4: 0.0007,
    drop5: "흑갑충",
    probability5: 0.0007,
    drop6: "마법의 망토",
    probability6: 0.0007,
    drop7: "브론즈 하이드후드",
    probability7: 0.0007,
    drop8: "블루 라피스샌들",
    probability8: 0.0007,
    drop9: "그리스",
    probability9: 0.0007,
    drop10: "메탈실버 이어링",
    probability10: 0.0007,
    drop11: "다크 세라피스",
    probability11: 0.0007,
    drop12: "오리할콘 플라틴 바지",
    probability12: 0.0007,
    drop13: "브라운 스타드 바지",
    probability13: 0.0007,
    drop14: "라이트 스콜피오",
    probability14: 0.0007,
    drop15: "분홍꽃 귀고리",
    probability15: 0.0007,
    drop16: "황룡도",
    probability16: 0.0007,
    drop17: "화이트 세라피스",
    probability17: 0.0007,
    drop18: "블루 오리엔타이칸",
    probability18: 0.0007,
    drop19: "다크 스타드 바지",
    probability19: 0.0007,
    drop20: "흑궁우",
    probability20: 0.0007,
  },
  {
    이름: "인형 잭",
    drop1: "다크 웨어캡",
    probability1: 0.0007,
    drop2: "미스릴 플래닛",
    probability2: 0.0007,
    drop3: "그로스야거",
    probability3: 0.0007,
    drop4: "브라운 스타드",
    probability4: 0.0007,
    drop5: "흑갑충",
    probability5: 0.0007,
    drop6: "브론즈 하이드후드",
    probability6: 0.0007,
    drop7: "레드 오리엔트 헬멧",
    probability7: 0.0007,
    drop8: "봉황위궁",
    probability8: 0.0,
    drop9: "실버 에이션트 실드",
    probability9: 0.0007,
    drop10: "스페판",
    probability10: 0.0007,
    drop11: "핑크 앵클부츠",
    probability11: 0.0007,
    drop12: "그리스",
    probability12: 0.0007,
    drop13: "사파이어 카멜부츠",
    probability13: 0.0007,
    drop14: "적궁우",
    probability14: 0.0007,
    drop15: "퍼플 루티드슈즈",
    probability15: 0.0007,
    drop16: "게타",
    probability16: 0.0007,
    drop17: "엔젤윙즈",
    probability17: 0.0007,
    drop18: "다크 세라피스",
    probability18: 0.0007,
    drop19: "다크 레퀴엠",
    probability19: 0.0007,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "매직 앰프",
    drop1: "파노 튜브",
    probability1: 0.0001,
    drop2: "브론즈 플라틴 바지",
    probability2: 0.0007,
    drop3: "다크 스타드",
    probability3: 0.0007,
    drop4: "라이트 스콜피오 바지",
    probability4: 0.0007,
    drop5: "분홍꽃 귀고리",
    probability5: 0.0007,
    drop6: "레드 힌켈",
    probability6: 0.0007,
    drop7: "이블윙즈",
    probability7: 0.0007,
    drop8: "게타",
    probability8: 0.0007,
    drop9: "레드 오리엔타이칸",
    probability9: 0.0007,
    drop10: "블러드 플라티나",
    probability10: 0.0007,
    drop11: "네오코라",
    probability11: 0.0007,
    drop12: "가이아의 망토",
    probability12: 0.0007,
    drop13: "레드 고어부츠",
    probability13: 0.0007,
    drop14: "블루 레퀴엠",
    probability14: 0.0007,
    drop15: "적궁우",
    probability15: 0.0007,
    drop16: "주자로",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "매직 스피커",
    drop1: "호크헤드",
    probability1: 0.0007,
    drop2: "골드 모스부츠",
    probability2: 0.0007,
    drop3: "플루튬",
    probability3: 0.0007,
    drop4: "이블윙즈",
    probability4: 0.0007,
    drop5: "아쿠아 플라티나",
    probability5: 0.0007,
    drop6: "호프만",
    probability6: 0.0007,
    drop7: "골드 와이어스",
    probability7: 0.0007,
    drop8: "다크 로린",
    probability8: 0.0007,
    drop9: "스튬",
    probability9: 0.0007,
    drop10: "미하일",
    probability10: 0.0007,
    drop11: "블루 오리엔타이칸",
    probability11: 0.0007,
    drop12: "그린 리넥스",
    probability12: 0.0007,
    drop13: "다크 리넥스슈즈",
    probability13: 0.0007,
    drop14: "다크 마누트",
    probability14: 0.0007,
    drop15: "레드 힌켈",
    probability15: 0.0007,
    drop16: "십자창",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "CD 모음집",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "시비꾼 CD",
    drop1: "네오코라",
    probability1: 0.0007,
    drop2: "블루 앵클부츠",
    probability2: 0.0007,
    drop3: "블루 리넥스",
    probability3: 0.0007,
    drop4: "레드 오리엔타이칸",
    probability4: 0.0007,
    drop5: "브라운 스타드",
    probability5: 0.0007,
    drop6: "그린 리넥스",
    probability6: 0.0007,
    drop7: "블루 오리엔트 헬멧",
    probability7: 0.0007,
    drop8: "블러드 플라티나",
    probability8: 0.0007,
    drop9: "호프만",
    probability9: 0.0007,
    drop10: "실버 플래닛",
    probability10: 0.0007,
    drop11: "다크 웨어캡",
    probability11: 0.0007,
    drop12: "다크 스콜피오 바지",
    probability12: 0.0007,
    drop13: "그리스",
    probability13: 0.0007,
    drop14: "다크 스타드 바지",
    probability14: 0.0007,
    drop15: "브론즈 플라틴 바지",
    probability15: 0.0007,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "닭",
    drop1: "브론즈 풀 헬름",
    probability1: 0.001,
    drop2: "검은색 가람",
    probability2: 0.001,
    drop3: "흰색 가람 바지",
    probability3: 0.001,
    drop4: "다크 사바타",
    probability4: 0.001,
    drop5: "갈색 도로시 구두",
    probability5: 0.001,
    drop6: "미스릴 도끼",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "오리",
    drop1: "다크문 고깔모자",
    probability1: 0.001,
    drop2: "블루 도로스 로브",
    probability2: 0.001,
    drop3: "다크 우드쉽",
    probability3: 0.001,
    drop4: "다크 워 부츠",
    probability4: 0.001,
    drop5: "바이킹소드",
    probability5: 0.001,
    drop6: "미스릴 폴암",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "양",
    drop1: "그린 페더윙햇",
    probability1: 0.001,
    drop2: "블랙 스니크",
    probability2: 0.001,
    drop3: "브라운 스니크 바지",
    probability3: 0.001,
    drop4: "오리할콘 미셀",
    probability4: 0.001,
    drop5: "옐로우 윈드슈즈",
    probability5: 0.001,
    drop6: "막대 사탕",
    probability6: 0.001,
    drop7: "삼지창",
    probability7: 0.001,
    drop8: "목비 표창",
    probability8: 0.001,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "염소",
    drop1: "다크 티베리안",
    probability1: 0.001,
    drop2: "옐로우 잉그리트",
    probability2: 0.001,
    drop3: "블랙 루티아",
    probability3: 0.001,
    drop4: "그린 실키부츠",
    probability4: 0.001,
    drop5: "위저드 완드",
    probability5: 0.001,
    drop6: "빅해머",
    probability6: 0.001,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "소",
    drop1: "죽도",
    probability1: 0.0007,
    drop2: "일룬",
    probability2: 0.0007,
    drop3: "연보라 우산",
    probability3: 0.0007,
    drop4: "글라디우스",
    probability4: 0.0007,
    drop5: "커틀러스",
    probability5: 0.0007,
    drop6: "페탈 스태프",
    probability6: 0.0007,
    drop7: "호스맨즈",
    probability7: 0.0007,
    drop8: "청룡의",
    probability8: 0.0007,
    drop9: "다크 가디언",
    probability9: 0.0007,
    drop10: "스틸 가즈",
    probability10: 0.0007,
    drop11: "막대 사탕",
    probability11: 0.0007,
    drop12: "라이덴",
    probability12: 0.0007,
    drop13: "써클윈드 스태프",
    probability13: 0.0007,
    drop14: "삼지창",
    probability14: 0.0007,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "흑염소",
    drop1: "주스팅",
    probability1: 0.0012,
    drop2: "블루 스타라이트",
    probability2: 0.0012,
    drop3: "금 링 귀고리",
    probability3: 0.0012,
    drop4: "다크 브레이스",
    probability4: 0.0012,
    drop5: "그린 하프슈즈",
    probability5: 0.0012,
    drop6: "버드빌",
    probability6: 0.0012,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "검은 양",
    drop1: "다크 가이즈",
    probability1: 0.001,
    drop2: "자진일갑주",
    probability2: 0.001,
    drop3: "흑진일갑주 바지",
    probability3: 0.001,
    drop4: "다크 아르텐",
    probability4: 0.001,
    drop5: "레드 피에르슈즈",
    probability5: 0.001,
    drop6: "아다만티움 보닌",
    probability6: 0.001,
    drop7: "당커",
    probability7: 0.001,
    drop8: "목비 표창",
    probability8: 0.001,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "쟁기소",
    drop1: "그린 네페르",
    probability1: 0.001,
    drop2: "그린 너클베스트",
    probability2: 0.001,
    drop3: "블루 너클베스트 바지",
    probability3: 0.001,
    drop4: "캣츠 아이",
    probability4: 0.001,
    drop5: "다크 너클",
    probability5: 0.001,
    drop6: "다크 문슈즈",
    probability6: 0.001,
    drop7: "발터2000",
    probability7: 0.001,
    drop8: "게파트",
    probability8: 0.001,
    drop9: "골든 스노우보드",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "흑곰 검사",
    drop1: "다크 아르텐",
    probability1: 0.0007,
    drop2: "화이트 길티언",
    probability2: 0.0007,
    drop3: "레드 듀크",
    probability3: 0.0007,
    drop4: "블루 피에르슈즈",
    probability4: 0.0007,
    drop5: "다크 너클",
    probability5: 0.0007,
    drop6: "그린 너클베스트",
    probability6: 0.0007,
    drop7: "다크 브리건",
    probability7: 0.0007,
    drop8: "브라운 폴레패더햇",
    probability8: 0.0007,
    drop9: "브라운 레골리스",
    probability9: 0.0007,
    drop10: "타이탄",
    probability10: 0.0007,
    drop11: "황진일갑주",
    probability11: 0.0007,
    drop12: "그린 매티",
    probability12: 0.0007,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "백호 검사",
    drop1: "블루 고니슈즈",
    probability1: 0.0007,
    drop2: "연두색 서핑보드",
    probability2: 0.0002,
    drop3: "엄버 숄더메일",
    probability3: 0.0007,
    drop4: "조가지궁",
    probability4: 0.0007,
    drop5: "벅",
    probability5: 0.0007,
    drop6: "해골 방패",
    probability6: 0.0007,
    drop7: "펜지 이어링",
    probability7: 0.0007,
    drop8: "녹마로",
    probability8: 0.0007,
    drop9: "블루 칼라스",
    probability9: 0.0007,
    drop10: "다크 돔",
    probability10: 0.0007,
    drop11: "라이징",
    probability11: 0.0007,
    drop12: "쥬얼 쿠아다라",
    probability12: 0.0007,
    drop13: "차",
    probability13: 0.0007,
    drop14: "핑크 골드윈드슈즈",
    probability14: 0.0007,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "매 격투가",
    drop1: "낡은 스틸 노르만 헬름",
    probability1: 0.0007,
    drop2: "다크 골든서클릿",
    probability2: 0.0007,
    drop3: "호검",
    probability3: 0.0007,
    drop4: "브론즈 하이드후드",
    probability4: 0.0007,
    drop5: "이블테일러",
    probability5: 0.0007,
    drop6: "다크 마누트",
    probability6: 0.0007,
    drop7: "그린 골드윈드슈즈",
    probability7: 0.0007,
    drop8: "너클메이스",
    probability8: 0.0007,
    drop9: "아다만티움 레전드 실드",
    probability9: 0.0007,
    drop10: "주자로",
    probability10: 0.0007,
    drop11: "구룡도",
    probability11: 0.0007,
    drop12: "다크 클랜치",
    probability12: 0.0007,
    drop13: "검은색 튜브",
    probability13: 0.0002,
    drop14: "봉황위궁",
    probability14: 0.0007,
    drop15: "레드 오리엔타이칸",
    probability15: 0.0007,
    drop16: "황일",
    probability16: 0.0007,
    drop17: "빨간하트 귀고리",
    probability17: 0.0007,
    drop18: "코라",
    probability18: 0.0007,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "남자 산적",
    drop1: "레드 오리엔트 헬멧",
    probability1: 0.0007,
    drop2: "다크 클랜치",
    probability2: 0.0007,
    drop3: "실버 하이드후드",
    probability3: 0.0007,
    drop4: "그린 고어부츠",
    probability4: 0.0007,
    drop5: "방천극",
    probability5: 0.0007,
    drop6: "구명 튜브",
    probability6: 0.0002,
    drop7: "쏜즈",
    probability7: 0.0007,
    drop8: "봉황위궁",
    probability8: 0.0007,
    drop9: "낡은 미스릴 노르만 헬름",
    probability9: 0.0007,
    drop10: "분홍꽃 귀고리",
    probability10: 0.0007,
    drop11: "골드드롭 이어링",
    probability11: 0.0007,
    drop12: "주자로",
    probability12: 0.0007,
    drop13: "다크 모스부츠",
    probability13: 0.0007,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "여자 산적",
    drop1: "플루튬",
    probability1: 0.0008,
    drop2: "흑궁우",
    probability2: 0.0008,
    drop3: "라이트 스콜피오",
    probability3: 0.0008,
    drop4: "골든 크로우",
    probability4: 0.0008,
    drop5: "십자창",
    probability5: 0.0008,
    drop6: "다크 마누트",
    probability6: 0.0008,
    drop7: "크리스탈 플라워링",
    probability7: 0.0008,
    drop8: "레드 고어부츠",
    probability8: 0.0008,
    drop9: "브론즈 하이드후드",
    probability9: 0.0008,
    drop10: "호크헤드",
    probability10: 0.0008,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "요괴 향로",
    drop1: "실버 에이션트 실드",
    probability1: 0.0007,
    drop2: "다크 가너",
    probability2: 0.0007,
    drop3: "브론즈 하이드후드",
    probability3: 0.0007,
    drop4: "블루 레퀴엠",
    probability4: 0.0007,
    drop5: "블루 리넥스",
    probability5: 0.0007,
    drop6: "구명 튜브",
    probability6: 0.0002,
    drop7: "다크 레퀴엠",
    probability7: 0.0007,
    drop8: "브론즈 플라틴 바지",
    probability8: 0.0007,
    drop9: "블러드 모스부츠",
    probability9: 0.0007,
    drop10: "호크헤드",
    probability10: 0.0007,
    drop11: "스페판",
    probability11: 0.0007,
    drop12: "그린 오리엔타이칸 바지",
    probability12: 0.0007,
    drop13: "다크 리넥스슈즈",
    probability13: 0.0007,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "까마귀",
    drop1: "다크 스틸라인",
    probability1: 0.0007,
    drop2: "냄비 뚜껑",
    probability2: 0.0004,
    drop3: "빨간색 가죽 구두",
    probability3: 0.0007,
    drop4: "목비 표창",
    probability4: 0.0007,
    drop5: "미스릴 완드",
    probability5: 0.0007,
    drop6: "파란색 검도복",
    probability6: 0.0007,
    drop7: "옐로우 잉그리트",
    probability7: 0.0007,
    drop8: "블루 가이즈",
    probability8: 0.0007,
    drop9: "라이덴",
    probability9: 0.0007,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "구름여우",
    drop1: "당커",
    probability1: 0.0007,
    drop2: "이글크로",
    probability2: 0.0007,
    drop3: "브라운 폴레패더햇",
    probability3: 0.0007,
    drop4: "브라운 레골리스",
    probability4: 0.0007,
    drop5: "캣츠 아이",
    probability5: 0.0007,
    drop6: "청룡의",
    probability6: 0.0007,
    drop7: "실버 배틀 그리브",
    probability7: 0.0007,
    drop8: "하늘색 우산",
    probability8: 0.0007,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "불너구리",
    drop1: "태극부채",
    probability1: 0.0007,
    drop2: "금 링 귀고리",
    probability2: 0.0007,
    drop3: "스키_오렌지",
    probability3: 0.0004,
    drop4: "노란 대걸레",
    probability4: 0.0004,
    drop5: "호스맨즈",
    probability5: 0.0007,
    drop6: "사각 강철 야삽",
    probability6: 0.0005,
    drop7: "블랙 매직슈즈",
    probability7: 0.0007,
    drop8: "그린 레골리스",
    probability8: 0.0007,
    drop9: "다크 레골러 바지",
    probability9: 0.0007,
    drop10: "청룡의",
    probability10: 0.0007,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "제등귀신",
    drop1: "님블 리스트",
    probability1: 0.0007,
    drop2: "노란색 우산",
    probability2: 0.0004,
    drop3: "캣츠 아이",
    probability3: 0.0007,
    drop4: "황진일갑주 바지",
    probability4: 0.0007,
    drop5: "다크 쉐도우 바지",
    probability5: 0.0007,
    drop6: "다크 칼라스",
    probability6: 0.0007,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "물도깨비",
    drop1: "흑궁우",
    probability1: 0.0007,
    drop2: "블루 오리엔트 헬멧",
    probability2: 0.0007,
    drop3: "블루 오리엔타이칸",
    probability3: 0.0007,
    drop4: "다크 스콜피오",
    probability4: 0.0007,
    drop5: "골든 크로우",
    probability5: 0.0007,
    drop6: "샤이닝",
    probability6: 0.0007,
    drop7: "스네이크",
    probability7: 0.0007,
    drop8: "쏜즈",
    probability8: 0.0007,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "큰 구름여우",
    drop1: "토비 표창",
    probability1: 0.0007,
    drop2: "블루문",
    probability2: 0.0007,
    drop3: "청일 바지",
    probability3: 0.0007,
    drop4: "브라운 피에뜨 바지",
    probability4: 0.0007,
    drop5: "아크 스태프",
    probability5: 0.0007,
    drop6: "오우커 숄더메일 바지",
    probability6: 0.0007,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "망령",
    drop1: "뇌전 수리검",
    probability1: 0.0007,
    drop2: "주르건 리스트",
    probability2: 0.0007,
    drop3: "다크 세라피스",
    probability3: 0.0007,
    drop4: "브론즈 하이드후드",
    probability4: 0.0007,
    drop5: "스네이크",
    probability5: 0.0007,
    drop6: "레드 카타나",
    probability6: 0.0007,
    drop7: "다크 스콜피오",
    probability7: 0.0007,
    drop8: "블루 오리엔타이칸",
    probability8: 0.0007,
    drop9: "청자로",
    probability9: 0.0007,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "수하 A",
    drop1: "골드 힐던부츠",
    probability1: 0.0007,
    drop2: "황월 장갑",
    probability2: 0.0007,
    drop3: "그린 골드윈드슈즈",
    probability3: 0.0007,
    drop4: "황 진월장화",
    probability4: 0.0007,
    drop5: "화이트 길티언",
    probability5: 0.0007,
    drop6: "미스릴 브리스트",
    probability6: 0.0007,
    drop7: "칠성검",
    probability7: 0.0007,
    drop8: "다크 페넌스",
    probability8: 0.0007,
    drop9: "벅",
    probability9: 0.0007,
    drop10: "발터2000",
    probability10: 0.0007,
    drop11: "미스릴 타워 실드",
    probability11: 0.0007,
    drop12: "연두색 서핑보드",
    probability12: 0.0003,
    drop13: "오우커 숄더메일",
    probability13: 0.0007,
    drop14: "브라운 크라페",
    probability14: 0.0007,
    drop15: "장팔사모",
    probability15: 0.0007,
    drop16: "다크 쉐도우 바지",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "수하 B",
    drop1: "블루 루이마리",
    probability1: 0.0007,
    drop2: "다크 숄더메일 바지",
    probability2: 0.0007,
    drop3: "벅",
    probability3: 0.0007,
    drop4: "크레센트",
    probability4: 0.0007,
    drop5: "블루 듀크",
    probability5: 0.0007,
    drop6: "차",
    probability6: 0.0007,
    drop7: "구룡도",
    probability7: 0.0007,
    drop8: "본헬름",
    probability8: 0.0007,
    drop9: "아크 스태프",
    probability9: 0.0007,
    drop10: "블루 고니슈즈",
    probability10: 0.0007,
    drop11: "황진일갑주",
    probability11: 0.0007,
    drop12: "다크 길티언",
    probability12: 0.0007,
    drop13: "크로미",
    probability13: 0.0007,
    drop14: "녹마로",
    probability14: 0.0007,
    drop15: "레드 문슈즈",
    probability15: 0.0007,
    drop16: "브라운 필퍼",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "수하 C",
    drop1: "청 진월장화",
    probability1: 0.0007,
    drop2: "그린 루이마리",
    probability2: 0.0007,
    drop3: "다크 길티언",
    probability3: 0.0007,
    drop4: "레드 고니슈즈",
    probability4: 0.0007,
    drop5: "골든 모울",
    probability5: 0.0007,
    drop6: "로우어",
    probability6: 0.0007,
    drop7: "드래곤 토네일",
    probability7: 0.0007,
    drop8: "다크 윌로우",
    probability8: 0.0007,
    drop9: "그린 고니슈즈",
    probability9: 0.0007,
    drop10: "해골 귀고리",
    probability10: 0.0007,
    drop11: "브라운 피에뜨",
    probability11: 0.0007,
    drop12: "다크 필퍼",
    probability12: 0.0007,
    drop13: "레드 문슈즈",
    probability13: 0.0007,
    drop14: "황일",
    probability14: 0.0007,
    drop15: "그린 필퍼",
    probability15: 0.0007,
    drop16: "황 흑일모자",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "수하 D",
    drop1: "다크 스콜피오",
    probability1: 0.0007,
    drop2: "골드 라피스샌들",
    probability2: 0.0007,
    drop3: "게타",
    probability3: 0.0007,
    drop4: "다크 가너",
    probability4: 0.0007,
    drop5: "라이트 스콜피오",
    probability5: 0.0007,
    drop6: "호프만",
    probability6: 0.0007,
    drop7: "블루 오리엔타이칸",
    probability7: 0.0007,
    drop8: "브론즈 플라틴 바지",
    probability8: 0.0007,
    drop9: "블루 키튼서클렛",
    probability9: 0.0007,
    drop10: "다크 스타드",
    probability10: 0.0007,
    drop11: "브론즈 하이드후드",
    probability11: 0.0007,
    drop12: "실버 에이션트 실드",
    probability12: 0.0007,
    drop13: "주자로",
    probability13: 0.0007,
    drop14: "다크 키튼서클렛",
    probability14: 0.0007,
    drop15: "라이트 스콜피오 바지",
    probability15: 0.0007,
    drop16: "분홍꽃 귀고리",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "간부 A",
    drop1: "녹 흑일모자",
    probability1: 0.0007,
    drop2: "차",
    probability2: 0.0007,
    drop3: "퍼플 네일슈즈",
    probability3: 0.0007,
    drop4: "구명 튜브",
    probability4: 0.0002,
    drop5: "십자창",
    probability5: 0.0007,
    drop6: "골드 브리스트",
    probability6: 0.0007,
    drop7: "그린 고니슈즈",
    probability7: 0.0007,
    drop8: "낡은 미스릴 노르만 헬름",
    probability8: 0.0007,
    drop9: "골드 힐던부츠",
    probability9: 0.0007,
    drop10: "골든 모울",
    probability10: 0.0007,
    drop11: "블루 루이마리",
    probability11: 0.0007,
    drop12: "수호의 망토",
    probability12: 0.0007,
    drop13: "골드 모스부츠",
    probability13: 0.0007,
    drop14: "골드 파우",
    probability14: 0.0007,
    drop15: "벅",
    probability15: 0.0007,
    drop16: "구룡도",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "간부 B",
    drop1: "다크 가너",
    probability1: 0.0007,
    drop2: "황월 장갑",
    probability2: 0.0007,
    drop3: "블러드 모스부츠",
    probability3: 0.0007,
    drop4: "오우커 숄더메일",
    probability4: 0.0007,
    drop5: "네오코라",
    probability5: 0.0007,
    drop6: "블루 루이마리",
    probability6: 0.0007,
    drop7: "블루 앵클부츠",
    probability7: 0.0007,
    drop8: "다크 골든서클릿",
    probability8: 0.0007,
    drop9: "장팔사모",
    probability9: 0.0007,
    drop10: "올림푸스",
    probability10: 0.0007,
    drop11: "타무스",
    probability11: 0.0007,
    drop12: "골든 크로우",
    probability12: 0.0007,
    drop13: "골드 모스부츠",
    probability13: 0.0007,
    drop14: "레드 오리엔트 헬멧",
    probability14: 0.0007,
    drop15: "너클메이스",
    probability15: 0.0007,
    drop16: "다크 슬레인",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "레드 슬라임",
    drop1: "쟈드",
    probability1: 0.0007,
    drop2: "제코",
    probability2: 0.0007,
    drop3: "흑진일갑주 바지",
    probability3: 0.0007,
    drop4: "그린 골드윈드슈즈",
    probability4: 0.0007,
    drop5: "다크 쉐도우 바지",
    probability5: 0.0007,
    drop6: "다크 아르텐",
    probability6: 0.0007,
    drop7: "그린 하프슈즈",
    probability7: 0.0007,
    drop8: "올림푸스",
    probability8: 0.0007,
    drop9: "블루 카운터",
    probability9: 0.0007,
    drop10: "라이징",
    probability10: 0.0007,
    drop11: "빨간하트 귀고리",
    probability11: 0.0007,
    drop12: "미스릴 브리스트",
    probability12: 0.0007,
    drop13: "다크 칼라스",
    probability13: 0.0007,
    drop14: "홀 스태프",
    probability14: 0.0007,
    drop15: "아크 스태프",
    probability15: 0.0007,
    drop16: "레드 고니슈즈",
    probability16: 0.0007,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "개구리",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "흰 닭",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "노란 도마뱀",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "두꺼비",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "빨간 도마뱀",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "구렁이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "사나운 원숭이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "어미 원숭이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "아기 원숭이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "두목 원숭이",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "붉은 도깨비",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "푸른 도깨비",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
  {
    이름: "힘센 돌도깨비",
    drop1: "-",
    probability1: 0.0,
    drop2: "-",
    probability2: 0.0,
    drop3: "-",
    probability3: 0.0,
    drop4: "-",
    probability4: 0.0,
    drop5: "-",
    probability5: 0.0,
    drop6: "-",
    probability6: 0.0,
    drop7: "-",
    probability7: 0.0,
    drop8: "-",
    probability8: 0.0,
    drop9: "-",
    probability9: 0.0,
    drop10: "-",
    probability10: 0.0,
    drop11: "-",
    probability11: 0.0,
    drop12: "-",
    probability12: 0.0,
    drop13: "-",
    probability13: 0.0,
    drop14: "-",
    probability14: 0.0,
    drop15: "-",
    probability15: 0.0,
    drop16: "-",
    probability16: 0.0,
    drop17: "-",
    probability17: 0.0,
    drop18: "-",
    probability18: 0.0,
    drop19: "-",
    probability19: 0.0,
    drop20: "-",
    probability20: 0.0,
  },
];
export default mobDropTable;
