const scrollType = {
  한손검: "한손검 공격력",
  두손검: "두손검 공격력",
  한손도끼: "한손도끼 공격력",
  두손도끼: "두손도끼 공격력",
  한손둔기: "한손둔기 공격력",
  두손둔기: "두손둔기 공격력",
  창: "창 공격력",
  창공: "창 공격력",
  폴공: "폴암 공격력",
  폴암: "폴암 공격력",
  스태프: "스태프 마력",
  스마: "스태프 마력",
  완드: "완드 마력",
  완마: "완드 마력",
  아대: "아대 공격력",
  아공: "아대 공격력",
  단검: "단검 공격력",
  단공: "단검 공격력",
  활: "활 공격력",
  활공: "활 공격력",
  석궁: "석궁 공격력",
  석공: "석궁 공격력",
  표창: "표창 공격력",
  표공: "표창 공격력",
  장공: "장갑 공격력",
  장마: "장갑 마력",
  방방: "방패 방어력",
  방행: "방패 행운",

  상힘: "상의 힘",
  하행: "하의 행운",
  전힘: "전신 힘",
  전민: "전신 민첩",
  전지: "전신 지력",
  전행: "전신 행운",

  투민: "투구 민첩",
  투지: "투구 지력",
  신민: "신발 민첩",

  귀민: "귀고리 민첩",
  귀지: "귀고리 지력",
  귀행: "귀고리 행운",

  망힘: "망토 힘",
  망민: "망토 민첩",
  망지: "망토 지력",
  망행: "망토 행운",

  혼돈: "혼돈의 주문서",
  백: "백의 주문서",
  혼줌: "혼돈의 주문서",
  백줌: "백의 주문서",

  X: "X",
  x: "X",
};

export default scrollType;
