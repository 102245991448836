const badWords = [
  { badWords: "씨퐁" },
  { badWords: "떼끼" },
  { badWords: "쉬탱" },
  { badWords: "개섹" },
  { badWords: "닥쳐" },
  { badWords: "게부럴" },
  { badWords: "버어어지" },
  { badWords: "시박쉑히" },
  { badWords: "유두핧어" },
  { badWords: "운디네" },
  { badWords: "옘병" },
  { badWords: "유깝" },
  { badWords: "촌씨브랑이" },
  { badWords: "시팔년" },
  { badWords: "대에가리" },
  { badWords: "조온니" },
  { badWords: "항문" },
  { badWords: "존나게" },
  { badWords: "벌창" },
  { badWords: "띠풀" },
  { badWords: "롬들" },
  { badWords: "씨불알" },
  { badWords: "빠가십새" },
  { badWords: "십자슥" },
  { badWords: "개고치" },
  { badWords: "빠가냐" },
  { badWords: "지럴" },
  { badWords: "엄창" },
  { badWords: "니아비" },
  { badWords: "니미랄" },
  { badWords: "젓가튼" },
  { badWords: "지랼" },
  { badWords: "때끼" },
  { badWords: "왕털보지" },
  { badWords: "썅년" },
  { badWords: "드응신" },
  { badWords: "개같" },
  { badWords: "구씹" },
  { badWords: "ㅄ" },
  { badWords: "딴년" },
  { badWords: "걸래년" },
  { badWords: "어미강간" },
  { badWords: "앰창" },
  { badWords: "쓰파" },
  { badWords: "존내" },
  { badWords: "사까아시" },
  { badWords: "빠구리" },
  { badWords: "창남" },
  { badWords: "씌팔" },
  { badWords: "조오웃" },
  { badWords: "에애무" },
  { badWords: "십탱" },
  { badWords: "좆까" },
  { badWords: "좆도" },
  { badWords: "족같내" },
  { badWords: "졸웃" },
  { badWords: "조가튼" },
  { badWords: "조개보지" },
  { badWords: "시빡" },
  { badWords: "돌으년" },
  { badWords: "십세이" },
  { badWords: "뒤질" },
  { badWords: "씨발년" },
  { badWords: "개애걸래" },
  { badWords: "빙신" },
  { badWords: "젓나" },
  { badWords: "엠생" },
  { badWords: "개씨발자슥" },
  { badWords: "돌은새끼" },
  { badWords: "개후라" },
  { badWords: "떠라이" },
  { badWords: "mi틴" },
  { badWords: "개쓰레기" },
  { badWords: "좆" },
  { badWords: "니미" },
  { badWords: "뷰웅시인" },
  { badWords: "sex" },
  { badWords: "시발" },
  { badWords: "세끼" },
  { badWords: "존낙" },
  { badWords: "니할애비" },
  { badWords: "따먹었지" },
  { badWords: "왜저럼" },
  { badWords: "씨팔" },
  { badWords: "뒤지길" },
  { badWords: "쥰니" },
  { badWords: "보지" },
  { badWords: "개잡년" },
  { badWords: "개붕알" },
  { badWords: "애비" },
  { badWords: "젓물냄새" },
  { badWords: "덜은새끼" },
  { badWords: "가슴쪼물락" },
  { badWords: "사까시이" },
  { badWords: "쉬방새" },
  { badWords: "빠가씹새" },
  { badWords: "쉬발" },
  { badWords: "짱께" },
  { badWords: "쒸8" },
  { badWords: "호냥년" },
  { badWords: "개걸래" },
  { badWords: "개쓰래기" },
  { badWords: "디지고" },
  { badWords: "정자먹어" },
  { badWords: "돈새끼" },
  { badWords: "버지따먹기" },
  { badWords: "쌔리" },
  { badWords: "凸" },
  { badWords: "개련" },
  { badWords: "조오올라" },
  { badWords: "버지썰어" },
  { badWords: "썅놈" },
  { badWords: "허졉" },
  { badWords: "촌씨브랭이" },
  { badWords: "시팔" },
  { badWords: "씨벌탱" },
  { badWords: "시발새끼" },
  { badWords: "에비" },
  { badWords: "쓰브랄쉽세" },
  { badWords: "맛이간년" },
  { badWords: "병신셰리" },
  { badWords: "시이풀" },
  { badWords: "쪼다" },
  { badWords: "취좃" },
  { badWords: "애에비" },
  { badWords: "쥐랄" },
  { badWords: "좃빠네" },
  { badWords: "뇬" },
  { badWords: "개자식" },
  { badWords: "대가리" },
  { badWords: "뻐큐" },
  { badWords: "딸달이" },
  { badWords: "개씁년" },
  { badWords: "개 걸레" },
  { badWords: "호루자슥" },
  { badWords: "jot같" },
  { badWords: "개걸레" },
  { badWords: "씌팔" },
  { badWords: "젓같내" },
  { badWords: "계새끼" },
  { badWords: "애애무" },
  { badWords: "조오또" },
  { badWords: "보지" },
  { badWords: "젖까" },
  { badWords: "씹팔" },
  { badWords: "빠구리" },
  { badWords: "돈년" },
  { badWords: "시뷰" },
  { badWords: "공알" },
  { badWords: "씨불알" },
  { badWords: "젓까" },
  { badWords: "걔잡년" },
  { badWords: "새끼" },
  { badWords: "따먹" },
  { badWords: "빡새끼" },
  { badWords: "젼나" },
  { badWords: "쉬이바" },
  { badWords: "색희" },
  { badWords: "씨븡" },
  { badWords: "쉬빨" },
  { badWords: "유방만져" },
  { badWords: "쌍놈" },
  { badWords: "씨빠빠" },
  { badWords: "걸래년" },
  { badWords: "씨섹끼" },
  { badWords: "개1새" },
  { badWords: "ㅌㅓㄹㅐㄱㅣ" },
  { badWords: "십탱굴이" },
  { badWords: "18놈" },
  { badWords: "쉬이풀" },
  { badWords: "씨가랭넘" },
  { badWords: "좁빠라라" },
  { badWords: "엠창" },
  { badWords: "엿먹어라" },
  { badWords: "빠가새" },
  { badWords: "새ㄲㅣ" },
  { badWords: "좃빠라라" },
  { badWords: "좃넘" },
  { badWords: "ㅆㅂ" },
  { badWords: "씨뻘" },
  { badWords: "엿같" },
  { badWords: "지랄" },
  { badWords: "맛없는년" },
  { badWords: "시밸" },
  { badWords: "닥쳐라" },
  { badWords: "쎄엑스" },
  { badWords: "병신세리" },
  { badWords: "씨뎅" },
  { badWords: "섹히" },
  { badWords: "에미" },
  { badWords: "지껄이" },
  { badWords: "게부럴" },
  { badWords: "닳은" },
  { badWords: "깨쌔끼" },
  { badWords: "시팔놈" },
  { badWords: "싸물어" },
  { badWords: "덜은새끼" },
  { badWords: "허버리년" },
  { badWords: "즤랄" },
  { badWords: "쓰래기같" },
  { badWords: "개자지" },
  { badWords: "괘새끼" },
  { badWords: "쌕스" },
  { badWords: "시이벌" },
  { badWords: "새끼" },
  { badWords: "쉽쌔" },
  { badWords: "쳐마" },
  { badWords: "씨부랄" },
  { badWords: "시팔새끼" },
  { badWords: "d쥐고" },
  { badWords: "쌔엑스" },
  { badWords: "괴가튼" },
  { badWords: "괴에가튼?" },
  { badWords: "미띤" },
  { badWords: "시발" },
  { badWords: "십지랄" },
  { badWords: "잠지" },
  { badWords: "사까시" },
  { badWords: "빠구울" },
  { badWords: "니미" },
  { badWords: "쌍놈" },
  { badWords: "세엑쓰" },
  { badWords: "개후라년" },
  { badWords: "미친년" },
  { badWords: "엠플레버" },
  { badWords: "조오까튼" },
  { badWords: "젓물냄새" },
  { badWords: "유두빨어" },
  { badWords: "쉬이빨" },
  { badWords: "시이팔" },
  { badWords: "썅" },
  { badWords: "허벌" },
  { badWords: "가슴조물락" },
  { badWords: "씹탱" },
  { badWords: "내씨발" },
  { badWords: "씨팍세끼" },
  { badWords: "작은보지" },
  { badWords: "시친발미" },
  { badWords: "에비" },
  { badWords: "핥아" },
  { badWords: "후라덜" },
  { badWords: "쉐끼" },
  { badWords: "띠부울" },
  { badWords: "ㅂ크" },
  { badWords: "니미기" },
  { badWords: "항문수셔" },
  { badWords: "18새끼" },
  { badWords: "t^^t" },
  { badWords: "개 가튼" },
  { badWords: "쪼녜" },
  { badWords: "병50" },
  { badWords: "빠큐" },
  { badWords: "저년" },
  { badWords: "씨밸" },
  { badWords: "미칭럼" },
  { badWords: "시이펄" },
  { badWords: "ㅇㅍㅊㅌ" },
  { badWords: "개잡년" },
  { badWords: "씨브럴" },
  { badWords: "썅늠" },
  { badWords: "씹년" },
  { badWords: "미틴" },
  { badWords: "ㅅ루" },
  { badWords: "씨븡새끼" },
  { badWords: "좆만한" },
  { badWords: "더러운년" },
  { badWords: "18num" },
  { badWords: "시팔" },
  { badWords: "ㅅㄲ들" },
  { badWords: "씹탱이" },
  { badWords: "졸멋" },
  { badWords: "씌벨" },
  { badWords: "엔플레버" },
  { badWords: "뷩딱" },
  { badWords: "니뽕" },
  { badWords: "디져라" },
  { badWords: "씨바라" },
  { badWords: "세꺄" },
  { badWords: "쇡끼" },
  { badWords: "씨발" },
  { badWords: "유우까압" },
  { badWords: "쪼다새끼" },
  { badWords: "미티넘" },
  { badWords: "새끼" },
  { badWords: "띠펄" },
  { badWords: "시발놈" },
  { badWords: "쳐받는" },
  { badWords: "호모" },
  { badWords: "18새끼" },
  { badWords: "ㅂㅊ" },
  { badWords: "씨퐁" },
  { badWords: "ㅈㄹ" },
  { badWords: "년" },
  { badWords: "버따리자지" },
  { badWords: "막대핧아줘" },
  { badWords: "게새끼" },
  { badWords: "사까시" },
  { badWords: "똥구녁" },
  { badWords: "븅쉰" },
  { badWords: "개후라들놈" },
  { badWords: "죵나" },
  { badWords: "유발조물락" },
  { badWords: "시이발" },
  { badWords: "띠부우울" },
  { badWords: "오르가즘" },
  { badWords: "띠바" },
  { badWords: "니기미" },
  { badWords: "미티넘" },
  { badWords: "노네들" },
  { badWords: "쉬빡" },
  { badWords: "세엑스" },
  { badWords: "쉽알넘" },
  { badWords: "죤내" },
  { badWords: "따먹어" },
  { badWords: "미튄" },
  { badWords: "자지" },
  { badWords: "병신" },
  { badWords: "조개벌려조?" },
  { badWords: "지롤" },
  { badWords: "쇄기" },
  { badWords: "조오온니" },
  { badWords: "닝기리" },
  { badWords: "맛간년" },
  { badWords: "버지뜨더" },
  { badWords: "씨이발" },
  { badWords: "좋만한" },
  { badWords: "돌으년" },
  { badWords: "유방" },
  { badWords: "씨부" },
  { badWords: "게지랄놈" },
  { badWords: "걸레년" },
  { badWords: "왕잠지" },
  { badWords: "쉬펄" },
  { badWords: "병딱" },
  { badWords: "빙신" },
  { badWords: "에무" },
  { badWords: "게세끼" },
  { badWords: "ㅆㅣ팍넘" },
  { badWords: "슨상님" },
  { badWords: "벵신" },
  { badWords: "십지랄" },
  { badWords: "쒸댕" },
  { badWords: "쇅끼" },
  { badWords: "si발" },
  { badWords: "시빨" },
  { badWords: "미시친발" },
  { badWords: "뼈큐" },
  { badWords: "씹새" },
  { badWords: "씨바알" },
  { badWords: "사까쉬" },
  { badWords: "ㅆㅣ8" },
  { badWords: "버짓물마셔" },
  { badWords: "찌랄" },
  { badWords: "개씨" },
  { badWords: "성괴" },
  { badWords: "씨펄" },
  { badWords: "봉알" },
  { badWords: "빠라" },
  { badWords: "쓰렉" },
  { badWords: "개씁블" },
  { badWords: "개붕알" },
  { badWords: "sex" },
  { badWords: "정액발사" },
  { badWords: "어미쑤시자" },
  { badWords: "씝창" },
  { badWords: "따먹자" },
  { badWords: "fuckyou" },
  { badWords: "아닥" },
  { badWords: "졸맛" },
  { badWords: "니뿡" },
  { badWords: "싀발" },
  { badWords: "니애비" },
  { badWords: "띠벌" },
  { badWords: "유방주물럭" },
  { badWords: "jaji" },
  { badWords: "젖까" },
  { badWords: "씨새발끼" },
  { badWords: "니앰" },
  { badWords: "애미" },
  { badWords: "젓대가리" },
  { badWords: "니아범?" },
  { badWords: "뼝신" },
  { badWords: "조온마니" },
  { badWords: "니기미" },
  { badWords: "씨댕이" },
  { badWords: "미칀" },
  { badWords: "쉬풀" },
  { badWords: "창녀버지" },
  { badWords: "새끼" },
  { badWords: "mi친" },
  { badWords: "음경" },
  { badWords: "씨불" },
  { badWords: "니씨브랄" },
  { badWords: "젓물" },
  { badWords: "존쎼" },
  { badWords: "염병" },
  { badWords: "버지쑤셔" },
  { badWords: "버지벌려" },
  { badWords: "ㅆ1발" },
  { badWords: "은새끼" },
  { badWords: "색끼" },
  { badWords: "미친" },
  { badWords: "씹창녀" },
  { badWords: "좆년" },
  { badWords: "시팍" },
  { badWords: "빠아구리" },
  { badWords: "니애미" },
  { badWords: "좃대가리" },
  { badWords: "개마이" },
  { badWords: "젓같은" },
  { badWords: "시이발" },
  { badWords: "좃대가리" },
  { badWords: "ㅆㅣ" },
  { badWords: "십자슥" },
  { badWords: "개걸레" },
  { badWords: "시벌" },
  { badWords: "강간" },
  { badWords: "촌씨브랭이" },
  { badWords: "내꺼빨아" },
  { badWords: "찐따" },
  { badWords: "십세이" },
  { badWords: "뒷잇치기" },
  { badWords: "좃털" },
  { badWords: "쌕스" },
  { badWords: "니애비" },
  { badWords: "존.나" },
  { badWords: "십세리" },
  { badWords: "미친쇠리" },
  { badWords: "조개속물" },
  { badWords: "쥰트" },
  { badWords: "띠벌" },
  { badWords: "쓰바새끼" },
  { badWords: "씨퐁보지" },
  { badWords: "미친눔" },
  { badWords: "강간" },
  { badWords: "쌕쓰" },
  { badWords: "후라덜넘" },
  { badWords: "거지같은" },
  { badWords: "가슴빨어" },
  { badWords: "개씨발" },
  { badWords: "애에무" },
  { badWords: "화낭년" },
  { badWords: "걸레핀년" },
  { badWords: "유방쪼물딱" },
  { badWords: "호좁" },
  { badWords: "갈보" },
  { badWords: "스벌" },
  { badWords: "개년" },
  { badWords: "화냥년" },
  { badWords: "조개쑤셔줘?" },
  { badWords: "띠이이벌" },
  { badWords: "암캐년" },
  { badWords: "동생강간" },
  { badWords: "씨벌" },
  { badWords: "샤발" },
  { badWords: "창놈" },
  { badWords: "잡것" },
  { badWords: "응애" },
  { badWords: "정액" },
  { badWords: "시벌" },
  { badWords: "항문쑤셔" },
  { badWords: "처먹을" },
  { badWords: "개부달" },
  { badWords: "십쉐" },
  { badWords: "쓰루" },
  { badWords: "유방핧어" },
  { badWords: "개넷" },
  { badWords: "씨파넘" },
  { badWords: "조오지" },
  { badWords: "맛간년" },
  { badWords: "따먹기" },
  { badWords: "똘아이" },
  { badWords: "버지물마셔" },
  { badWords: "내자지" },
  { badWords: "처먹" },
  { badWords: "씨발병신" },
  { badWords: "뒤진다" },
  { badWords: "빠가씹새" },
  { badWords: "뒤져라" },
  { badWords: "좆물" },
  { badWords: "쎅쓰" },
  { badWords: "18놈" },
  { badWords: "쉬이방" },
  { badWords: "개부랄" },
  { badWords: "보짓" },
  { badWords: "병크" },
  { badWords: "씨팍새끼" },
  { badWords: "주글년" },
  { badWords: "뒤져야지" },
  { badWords: "족까" },
  { badWords: "쪼까튼" },
  { badWords: "주둥아리" },
  { badWords: "조온만" },
  { badWords: "따먹는다" },
  { badWords: "조옴마니" },
  { badWords: "18ㅅㅐ끼" },
  { badWords: "게지랄놈" },
  { badWords: "미췬" },
  { badWords: "ㅂㄹ" },
  { badWords: "섀키" },
  { badWords: "섹끼" },
  { badWords: "쒸펄" },
  { badWords: "젼낰" },
  { badWords: "가슴만져" },
  { badWords: "조개핧아줘?" },
  { badWords: "유방핧아" },
  { badWords: "노옴" },
  { badWords: "ㅆㅣㅂㅏ" },
  { badWords: "개라슥" },
  { badWords: "붕알" },
  { badWords: "십셰리" },
  { badWords: "떠어라이" },
  { badWords: "친년" },
  { badWords: "젖밥" },
  { badWords: "졸잼" },
  { badWords: "개불랄" },
  { badWords: "ㅁ친" },
  { badWords: "씨발롬" },
  { badWords: "뒷치기" },
  { badWords: "미쳤나" },
  { badWords: "쳐먹고" },
  { badWords: "세끼" },
  { badWords: "브랄" },
  { badWords: "크리토리스" },
  { badWords: "좀마니" },
  { badWords: "좆빨아" },
  { badWords: "개씁" },
  { badWords: "니미럴" },
  { badWords: "개에가튼" },
  { badWords: "애비" },
  { badWords: "좃만한쉐이" },
  { badWords: "띠발뇬" },
  { badWords: "씹" },
  { badWords: "잠지" },
  { badWords: "개쓰래기" },
  { badWords: "새1끼" },
  { badWords: "육갑" },
  { badWords: "좃빠구리" },
  { badWords: "개지랄놈" },
  { badWords: "십8" },
  { badWords: "샊히" },
  { badWords: "쉬이불" },
  { badWords: "붕신" },
  { badWords: "좃깟네" },
  { badWords: "십부랄" },
  { badWords: "씨방새" },
  { badWords: "캐럿닷컴" },
  { badWords: "씨뷰렬" },
  { badWords: "늑음" },
  { badWords: "빠간가" },
  { badWords: "게가튼" },
  { badWords: "존귀" },
  { badWords: "걔잡년" },
  { badWords: "걸레같은년" },
  { badWords: "졌같은" },
  { badWords: "돌은새끼" },
  { badWords: "벌창" },
  { badWords: "에에미" },
  { badWords: "개념빠가" },
  { badWords: "짬지" },
  { badWords: "애자" },
  { badWords: "존맛" },
  { badWords: "호로자식" },
  { badWords: "걸레핀년" },
  { badWords: "싸가지" },
  { badWords: "섹쓰" },
  { badWords: "좃까리" },
  { badWords: "개너미" },
  { badWords: "큰보지" },
  { badWords: "시탱" },
  { badWords: "쎄리" },
  { badWords: "왕버지" },
  { badWords: "조깟" },
  { badWords: "왕따" },
  { badWords: "씨박색희" },
  { badWords: "유두" },
  { badWords: "걸레보지" },
  { badWords: "존버" },
  { badWords: "쳐먹" },
  { badWords: "씨방세" },
  { badWords: "개쓰레기" },
  { badWords: "저엇" },
  { badWords: "개거얼래" },
  { badWords: "유우깝" },
  { badWords: "십녀" },
  { badWords: "뻑큐" },
  { badWords: "조또" },
  { badWords: "개지랄" },
  { badWords: "개늠" },
  { badWords: "지뢀" },
  { badWords: "써글년" },
  { badWords: "18nom" },
  { badWords: "자슥" },
  { badWords: "맛없는년" },
  { badWords: "쪽발" },
  { badWords: "허벌자지" },
  { badWords: "보쥐" },
  { badWords: "씹탱굴이" },
  { badWords: "잡년" },
  { badWords: "흐접" },
  { badWords: "왕털버지" },
  { badWords: "개보지년" },
  { badWords: "김대중" },
  { badWords: "내꺼핧아" },
  { badWords: "조온나" },
  { badWords: "좃도" },
  { badWords: "씨ㅂㅏ" },
  { badWords: "쉑쓰" },
  { badWords: "씨뱅" },
  { badWords: "졸귀" },
  { badWords: "쓰뎅" },
  { badWords: "호졉" },
  { badWords: "염뵹" },
  { badWords: "허벌년" },
  { badWords: "ㅈ리" },
  { badWords: "미친놈" },
  { badWords: "쓰브랄쉽세" },
  { badWords: "잡놈" },
  { badWords: "십부랄" },
  { badWords: "쳐쑤셔박어" },
  { badWords: "드으응신" },
  { badWords: "개자식" },
  { badWords: "병신" },
  { badWords: "달딸이" },
  { badWords: "쑤셔" },
  { badWords: "me췬" },
  { badWords: "늬믜" },
  { badWords: "시이불" },
  { badWords: "흐젚" },
  { badWords: "게자식" },
  { badWords: "슈우벌" },
  { badWords: "지이랄" },
  { badWords: "좃만한것" },
  { badWords: "후려" },
  { badWords: "외퀴" },
  { badWords: "씌뎅" },
  { badWords: "호로짜식" },
  { badWords: "염병할" },
  { badWords: "족까내" },
  { badWords: "씨박쉑히" },
  { badWords: "더엉신" },
  { badWords: "니엄마" },
  { badWords: "젓가튼쉐이" },
  { badWords: "쌍년" },
  { badWords: "대애가리" },
  { badWords: "막대쑤셔줘?" },
  { badWords: "돌은넘" },
  { badWords: "좀마니" },
  { badWords: "좆만한놈" },
  { badWords: "아가리" },
  { badWords: "쓰벨" },
  { badWords: "시미친발" },
  { badWords: "ㅅ1발" },
  { badWords: "씹" },
  { badWords: "pussy" },
  { badWords: "빠아가" },
  { badWords: "친노마" },
  { badWords: "닌기미" },
  { badWords: "ㅈ같" },
  { badWords: "씨방세" },
  { badWords: "십새" },
  { badWords: "좃부랄" },
  { badWords: "시입세" },
  { badWords: "좃간년" },
  { badWords: "ㅅㅡ루" },
  { badWords: "염병할" },
  { badWords: "ㅅㅐㄲㅣ" },
  { badWords: "허접" },
  { badWords: "호냥년" },
  { badWords: "처먹고" },
  { badWords: "씨가랭놈" },
  { badWords: "씨이팔" },
  { badWords: "버지빨어" },
  { badWords: "좃만한쉐이" },
  { badWords: "또오라이" },
  { badWords: "tlqkf" },
  { badWords: "딸딸이" },
  { badWords: "개씁자지" },
  { badWords: "bitch" },
  { badWords: "너거애비" },
  { badWords: "지뢰" },
  { badWords: "개부랄" },
  { badWords: "젖같은" },
  { badWords: "아가리" },
  { badWords: "씨버럼" },
  { badWords: "뇌텅" },
  { badWords: "씨빨" },
  { badWords: "촌씨브라리" },
  { badWords: "요년" },
  { badWords: "왕버지" },
  { badWords: "믜친" },
  { badWords: "허젚" },
  { badWords: "me친" },
  { badWords: "막간년" },
  { badWords: "가슴핧어" },
  { badWords: "ㅆㅣ댕" },
  { badWords: "쌍눔" },
  { badWords: "쌉쳐" },
  { badWords: "왕자지" },
  { badWords: "개^ㅐ77ㅣ" },
  { badWords: "지랄" },
  { badWords: "좃" },
  { badWords: "씨블" },
  { badWords: "핥" },
  { badWords: "등신" },
  { badWords: "존나아" },
  { badWords: "좃가튼뇬" },
  { badWords: "졏같" },
  { badWords: "씌댕" },
  { badWords: "존귘" },
  { badWords: "쉬이벌" },
  { badWords: "슈ㅣ발" },
  { badWords: "왕털자지" },
  { badWords: "쉬팍" },
  { badWords: "뻐규" },
  { badWords: "씌발" },
  { badWords: "뒤져요" },
  { badWords: "조개마셔줘?" },
  { badWords: "쉬불" },
  { badWords: "조오우까튼" },
  { badWords: "개색뀌" },
  { badWords: "수셔" },
  { badWords: "ㅆㅣ" },
  { badWords: "개새" },
  { badWords: "쌍쌍보지" },
  { badWords: "내미랄" },
  { badWords: "개후라년" },
  { badWords: "좃간년" },
  { badWords: "애미" },
  { badWords: "존멋" },
  { badWords: "딸딸이" },
  { badWords: "따알따리" },
  { badWords: "개에거얼래" },
  { badWords: "젓만이" },
  { badWords: "섹스" },
  { badWords: "존똑" },
  { badWords: "시펄" },
  { badWords: "색키" },
  { badWords: "정액핧아" },
  { badWords: "뒤지겠" },
  { badWords: "조옷만" },
  { badWords: "펑글" },
  { badWords: "대음순" },
  { badWords: "개고치" },
  { badWords: "씨가랭년" },
  { badWords: "버짓" },
  { badWords: "줬같은" },
  { badWords: "창년" },
  { badWords: "굿보지" },
  { badWords: "띠발뇬" },
  { badWords: "후장" },
  { badWords: "머갈" },
  { badWords: "개씨블" },
  { badWords: "돌았네" },
  { badWords: "귀두" },
  { badWords: "ㄴㄱㅁ" },
  { badWords: "창년" },
  { badWords: "개가튼년" },
  { badWords: "조우까튼" },
  { badWords: "좃만아" },
  { badWords: "쓰벌" },
  { badWords: "걔섀" },
  { badWords: "좆밥" },
  { badWords: "개애거얼래" },
  { badWords: "좁밥" },
  { badWords: "새킈" },
  { badWords: "디질" },
  { badWords: "좆새끼" },
  { badWords: "젓떠" },
  { badWords: "부럴" },
  { badWords: "개같은" },
  { badWords: "시박색히" },
  { badWords: "색갸" },
  { badWords: "뒤지고싶" },
  { badWords: "씨빡" },
  { badWords: "조온" },
  { badWords: "좀물" },
  { badWords: "씨부럴" },
  { badWords: "jazi" },
  { badWords: "페니스" },
  { badWords: "뽕알" },
  { badWords: "조개넓은년" },
  { badWords: "좃물" },
  { badWords: "빠굴이" },
  { badWords: "섹스" },
  { badWords: "븅신" },
  { badWords: "꼭지" },
  { badWords: "좃까" },
  { badWords: "써글" },
  { badWords: "젓가튼쉐이" },
  { badWords: "잠짓물마셔" },
  { badWords: "ㅂㅅ" },
  { badWords: "병닥" },
  { badWords: "ㅇㅒ쁜" },
  { badWords: "젓마무리" },
  { badWords: "내잠지" },
  { badWords: "젓떠" },
  { badWords: "띠이벌" },
  { badWords: "주글년" },
  { badWords: "실프" },
  { badWords: "씨브럴" },
  { badWords: "여자ㄸㅏ묵기" },
  { badWords: "씨부럴" },
  { badWords: "좀쓰레기" },
  { badWords: "페니스" },
  { badWords: "시1발" },
  { badWords: "이새끼" },
  { badWords: "띠브울" },
  { badWords: "시키가" },
  { badWords: "난자마셔" },
  { badWords: "애무" },
  { badWords: "븅신" },
  { badWords: "씨바" },
  { badWords: "조올라" },
  { badWords: "따먹을까" },
  { badWords: "버지벌료" },
  { badWords: "방점뱅" },
  { badWords: "보짓" },
  { badWords: "개씨블" },
  { badWords: "쉬이팔" },
  { badWords: "18ㅅㅐㄲㅣ" },
  { badWords: "빙띤" },
  { badWords: "게에가튼" },
  { badWords: "좃도" },
  { badWords: "ㅈㄴ" },
  { badWords: "미틴" },
  { badWords: "개.웃" },
  { badWords: "성교" },
  { badWords: "존나" },
  { badWords: "조오가튼" },
  { badWords: "빠아아라" },
  { badWords: "쥐랄" },
  { badWords: "좃물냄새" },
  { badWords: "달달이" },
  { badWords: "짱깨" },
  { badWords: "버지빨아" },
  { badWords: "씌발" },
  { badWords: "여자따먹기" },
  { badWords: "ㅅ.ㅂ" },
  { badWords: "조개따조?" },
  { badWords: "니아범" },
  { badWords: "유우방" },
  { badWords: "미친" },
  { badWords: "개라슥" },
  { badWords: "파친" },
  { badWords: "쉬밸년" },
  { badWords: "색스" },
  { badWords: "개색휘" },
  { badWords: "개저씨" },
  { badWords: "좃빠라라" },
  { badWords: "쌔끼" },
  { badWords: "왕털잠지" },
  { badWords: "자위" },
  { badWords: "노무노무" },
  { badWords: "시입세에" },
  { badWords: "좃만이" },
  { badWords: "개 같은" },
  { badWords: "내버지" },
  { badWords: "너거애비" },
  { badWords: "에미" },
  { badWords: "구녕" },
  { badWords: "좃냄새" },
  { badWords: "잡년" },
  { badWords: "좆까라" },
  { badWords: "또라인" },
  { badWords: "시발년" },
  { badWords: "사까아시이" },
  { badWords: "버지구녕" },
  { badWords: "늬미" },
  { badWords: "ㅈ1랄" },
  { badWords: "개지랄넘" },
  { badWords: "지이라알" },
  { badWords: "버어지이" },
  { badWords: "쉬버" },
  { badWords: "붜지" },
  { badWords: "절라" },
  { badWords: "허벌보지" },
  { badWords: "십창" },
  { badWords: "개거얼레" },
  { badWords: "뇬" },
  { badWords: "me틴" },
  { badWords: "정액먹어" },
  { badWords: "지뢀" },
  { badWords: "뻨큐" },
  { badWords: "쉬벌" },
  { badWords: "개불랄" },
  { badWords: "자압것" },
  { badWords: "18ㅅㅔ키" },
  { badWords: "니할애비" },
  { badWords: "ㅆㅂㄻ" },
  { badWords: "애비" },
  { badWords: "쳐발라" },
  { badWords: "젖같" },
  { badWords: "니애뷔" },
  { badWords: "씨벌년" },
  { badWords: "뒤져버" },
  { badWords: "씨이벌" },
  { badWords: "난자먹어" },
  { badWords: "따먹어야지" },
  { badWords: "난자핧아" },
  { badWords: "개좆" },
  { badWords: "썅뇬" },
  { badWords: "좃까" },
  { badWords: "쌍년" },
  { badWords: "십쉐끼" },
  { badWords: "zazi" },
  { badWords: "씨파넘" },
  { badWords: "띠빌" },
  { badWords: "씹세" },
  { badWords: "bozi" },
  { badWords: "penis" },
  { badWords: "허버리년" },
  { badWords: "개가튼" },
  { badWords: "십새기" },
  { badWords: "뒤졌" },
  { badWords: "슈벌" },
  { badWords: "십세" },
  { badWords: "지롤" },
  { badWords: "쪽바리" },
  { badWords: "18세키" },
  { badWords: "씨벌" },
  { badWords: "씨발병신" },
  { badWords: "ㅆㄹㄱ" },
  { badWords: "십버지" },
  { badWords: "쓰레기새" },
  { badWords: "시볼" },
  { badWords: "개저엇" },
  { badWords: "유우우방것" },
  { badWords: "나쁜새끼" },
  { badWords: "시부" },
  { badWords: "씨이불" },
  { badWords: "시방" },
  { badWords: "개후라새끼" },
  { badWords: "성교" },
  { badWords: "저엊" },
  { badWords: "촌씨브라리" },
  { badWords: "ㅈㅏ위" },
  { badWords: "존웃" },
  { badWords: "개씁년" },
  { badWords: "빨치산" },
  { badWords: "유방빨아" },
  { badWords: "정액마셔" },
  { badWords: "싹스" },
  { badWords: "후우자앙" },
  { badWords: "니아빠" },
  { badWords: "씨이발" },
  { badWords: "핥기" },
  { badWords: "띠벨" },
  { badWords: "취ㅈ" },
  { badWords: "불알" },
  { badWords: "성폭행" },
  { badWords: "허벌자식" },
  { badWords: "뷰웅신" },
  { badWords: "십팔새끼" },
  { badWords: "씨가랭년" },
  { badWords: "미쳤니" },
  { badWords: "씨발" },
  { badWords: "및힌" },
  { badWords: "종나" },
  { badWords: "미친넘" },
  { badWords: "씨팔" },
  { badWords: "빠아구우리" },
  { badWords: "샹년" },
  { badWords: "혀로보지핧기" },
  { badWords: "ㅆㅣ뎅" },
  { badWords: "더어엉신" },
  { badWords: "조오오조" },
  { badWords: "돈새끼" },
  { badWords: "개젓가튼넘" },
  { badWords: "버따리자지" },
  { badWords: "시팔넘" },
  { badWords: "ㅅㅋ" },
  { badWords: "개보지" },
  { badWords: "ㅉ질한" },
  { badWords: "씨팍" },
  { badWords: "shit" },
  { badWords: "ㅅㅂ" },
  { badWords: "거시기" },
  { badWords: "개보지" },
  { badWords: "시새발끼" },
  { badWords: "십탱" },
  { badWords: "백보지" },
  { badWords: "색히" },
  { badWords: "쫓같" },
  { badWords: "닳은년" },
  { badWords: "좆나" },
  { badWords: "ㅈ.ㄴ" },
  { badWords: "여자ㄸㅏ먹기" },
  { badWords: "씹창" },
  { badWords: "씨댕" },
  { badWords: "뒤치기" },
  { badWords: "가슴빨아" },
  { badWords: "십새" },
  { badWords: "또라이" },
  { badWords: "호로자슥" },
  { badWords: "존니" },
  { badWords: "빠굴이" },
  { badWords: "빠가니" },
  { badWords: "믜칀" },
  { badWords: "개가튼뇬" },
  { badWords: "잡년" },
  { badWords: "새뀌" },
  { badWords: "오랄" },
  { badWords: "엿먹어라" },
  { badWords: "빙신쉐이" },
  { badWords: "여어엄" },
  { badWords: "버지핧아" },
  { badWords: "씨박색히" },
  { badWords: "쉬이펄" },
  { badWords: "개저가튼" },
  { badWords: "좆먹어" },
  { badWords: "뇨온" },
  { badWords: "미친씨부랄" },
  { badWords: "뒈져" },
  { badWords: "쉐끼" },
  { badWords: "개작두넘" },
  { badWords: "개좆" },
  { badWords: "젓냄새" },
  { badWords: "씹" },
  { badWords: "어미" },
  { badWords: "주둥이" },
  { badWords: "엄창" },
  { badWords: "씨볼탱" },
  { badWords: "ㅗ" },
  { badWords: "개독" },
  { badWords: "싑창" },
  { badWords: "여엄병" },
  { badWords: "띠이바알" },
  { badWords: "애미랄" },
  { badWords: "쎄끼" },
  { badWords: "좃깟네" },
  { badWords: "쎅스" },
  { badWords: "새77ㅣ" },
  { badWords: "존마니" },
  { badWords: "걸레년" },
  { badWords: "후우장" },
  { badWords: "존니" },
  { badWords: "쉬방" },
  { badWords: "개후라새끼" },
  { badWords: "정자핧아" },
  { badWords: "시발" },
  { badWords: "병딱" },
  { badWords: "존나" },
  { badWords: "좆만아" },
  { badWords: "버지뚫어" },
  { badWords: "젖탱이" },
  { badWords: "깨쌔끼" },
  { badWords: "시불" },
  { badWords: "졸귘" },
  { badWords: "후장" },
  { badWords: "병맛" },
  { badWords: "젓밥" },
  { badWords: "개작두년" },
  { badWords: "붕알" },
  { badWords: "씨블년" },
  { badWords: "잠지뚫어" },
  { badWords: "띠블넘" },
  { badWords: "씨뷰럴" },
  { badWords: "ㅁㅣ췬" },
  { badWords: "ㄱㅐㅅㅐㄲl" },
  { badWords: "띠블" },
  { badWords: "벌창같은년" },
  { badWords: "조까" },
  { badWords: "개넘" },
  { badWords: "개젓" },
  { badWords: "크리토리스" },
  { badWords: "존예" },
  { badWords: "젓까" },
  { badWords: "개셈" },
  { badWords: "애에미" },
  { badWords: "또오라아이" },
  { badWords: "개젓" },
  { badWords: "존잘" },
  { badWords: "좋만" },
  { badWords: "쒸발" },
  { badWords: "씹새기" },
  { badWords: "쌍보지" },
  { badWords: "니애미" },
  { badWords: "쉬박" },
  { badWords: "쥰내" },
  { badWords: "부랄" },
  { badWords: "zaji" },
  { badWords: "시봘" },
  { badWords: "느금" },
  { badWords: "뻑큐" },
  { badWords: "느금" },
  { badWords: "존ㄴ나" },
  { badWords: "쌕쓰" },
  { badWords: "빠가야로" },
  { badWords: "씨이붕" },
  { badWords: "존쎄" },
  { badWords: "띠불" },
  { badWords: "게이" },
  { badWords: "불알" },
  { badWords: "빻은" },
  { badWords: "ㄱㅐㅈㅏ" },
  { badWords: "게젓" },
  { badWords: "염뵹" },
  { badWords: "쓰댕" },
  { badWords: "ㅅㅂ" },
  { badWords: "뒤져야" },
  { badWords: "유방" },
  { badWords: "쉬펄" },
  { badWords: "니아범" },
  { badWords: "이년" },
  { badWords: "nflavor" },
  { badWords: "십버지" },
  { badWords: "정액짜" },
  { badWords: "맛이간년" },
  { badWords: "좋오웃" },
  { badWords: "럼들" },
  { badWords: "씨가랭넘" },
  { badWords: "pennis" },
  { badWords: "멜리스" },
  { badWords: "십부럴" },
  { badWords: "씨봉" },
  { badWords: "미쳣네" },
  { badWords: "창녀" },
  { badWords: "내미럴" },
  { badWords: "첫빠" },
  { badWords: "야발" },
  { badWords: "ya동" },
  { badWords: "개부달" },
  { badWords: "니씨브랄" },
  { badWords: "걸레같은년" },
  { badWords: "바주카자지" },
  { badWords: "새퀴" },
  { badWords: "유방쪼물럭" },
  { badWords: "찝째끼" },
  { badWords: "아아가리" },
  { badWords: "병신씨발" },
  { badWords: "ㅅㄲ네" },
  { badWords: "쉬이붕" },
  { badWords: "sibal" },
  { badWords: "벵신" },
  { badWords: "띠벨" },
  { badWords: "좃또" },
  { badWords: "버지" },
  { badWords: "염병" },
  { badWords: "쬲" },
  { badWords: "저년" },
  { badWords: "호로" },
  { badWords: "노알라" },
  { badWords: "개잡지랄" },
  { badWords: "시댕이" },
  { badWords: "애미" },
  { badWords: "터래기터래기" },
  { badWords: "호루자슥" },
  { badWords: "ㄱㅅㄲ" },
  { badWords: "니믜" },
  { badWords: "찌질이" },
  { badWords: "빠네" },
  { badWords: "어미따먹자" },
  { badWords: "젓같내" },
  { badWords: "개새기" },
  { badWords: "미친쉐이" },
  { badWords: "십창녀" },
  { badWords: "개에걸래" },
  { badWords: "촌씨브랑이" },
  { badWords: "성폭행" },
  { badWords: "십셰리" },
  { badWords: "yadong" },
  { badWords: "정자마셔" },
  { badWords: "좀물" },
  { badWords: "아오시바" },
  { badWords: "가슴핧아" },
  { badWords: "ㅅ발" },
  { badWords: "빠가" },
  { badWords: "ㅆㅂㄹㅁ" },
  { badWords: "쉑갸" },
  { badWords: "존트" },
  { badWords: "닝기미" },
  { badWords: "잠지물마셔" },
  { badWords: "허어벌" },
  { badWords: "쬰잘" },
  { badWords: "쓰바새끼" },
  { badWords: "샊기" },
  { badWords: "은년" },
  { badWords: "띠이버얼" },
  { badWords: "쓉새" },
  { badWords: "씨블" },
  { badWords: "시붕" },
  { badWords: "씨븡새끼" },
  { badWords: "띠발" },
  { badWords: "좃만한것" },
  { badWords: "친구년" },
  { badWords: "꽃휴" },
  { badWords: "18년" },
  { badWords: "쌔끼" },
  { badWords: "걸래" },
  { badWords: "잡놈" },
  { badWords: "따아알따아리" },
  { badWords: "에에무" },
  { badWords: "졸라" },
  { badWords: "개노맛" },
  { badWords: "씨빨" },
  { badWords: "시팍새끼" },
  { badWords: "띠팔" },
  { badWords: "십창" },
  { badWords: "김치녀" },
  { badWords: "거시기" },
  { badWords: "좃까리" },
  { badWords: "띠이이발" },
  { badWords: "십창녀" },
  { badWords: "뽀지" },
  { badWords: "니 엄마" },
  { badWords: "개새기" },
  { badWords: "씨1발" },
  { badWords: "버지냄새" },
  { badWords: "fuck" },
  { badWords: "개놈" },
  { badWords: "뽀지" },
  { badWords: "쓰바" },
  { badWords: "족같내" },
  { badWords: "유두" },
  { badWords: "시불" },
  { badWords: "창년벼지" },
  { badWords: "강간한다" },
  { badWords: "주길년" },
  { badWords: "미치인" },
  { badWords: "상년" },
  { badWords: "쉬발" },
  { badWords: "죠낸" },
  { badWords: "세키" },
  { badWords: "젓대가리" },
  { badWords: "ㅈ같네" },
  { badWords: "병닥" },
  { badWords: "^^ㅣ발" },
  { badWords: "클리토리스" },
  { badWords: "개너미" },
  { badWords: "졸싫" },
  { badWords: "미친새끼" },
  { badWords: "좃물" },
  { badWords: "ㄷㅊ" },
  { badWords: "좃냄새" },
  { badWords: "아오ㅅㅂ" },
  { badWords: "내조지" },
  { badWords: "쉬붕" },
  { badWords: "젓냄새" },
  { badWords: "ㅆㄺ" },
  { badWords: "죠온나" },
  { badWords: "가슴쪼물딱" },
  { badWords: "좃만이" },
  { badWords: "씨퐁자지" },
  { badWords: "씨벌쉐이" },
  { badWords: "양아치" },
  { badWords: "엿이나" },
  { badWords: "좃물" },
  { badWords: "씨발롬" },
  { badWords: "개놈" },
  { badWords: "허벌레" },
  { badWords: "ㅅㅍ" },
  { badWords: "좃" },
  { badWords: "따 먹" },
  { badWords: "시펄" },
  { badWords: "젓만이" },
  { badWords: "씨벨" },
  { badWords: "여자따묵기" },
  { badWords: "십팔새끼" },
  { badWords: "슈발" },
  { badWords: "boji" },
  { badWords: "그나물에" },
  { badWords: "씹쌔끼" },
  { badWords: "버어어지이" },
  { badWords: "창렬" },
  { badWords: "앰창" },
  { badWords: "가슴주물럭" },
  { badWords: "졸라" },
  { badWords: "씨8" },
  { badWords: "점물" },
  { badWords: "적까" },
  { badWords: "씨부렬" },
  { badWords: "지럴" },
  { badWords: "개쉐뀌" },
  { badWords: "클리토리스" },
  { badWords: "똥구뇽" },
  { badWords: "주길년" },
  { badWords: "닥치세" },
  { badWords: "잠지털" },
  { badWords: "오르가즘" },
  { badWords: "d지고" },
  { badWords: "개아기" },
  { badWords: "호로" },
  { badWords: "시방새" },
  { badWords: "육갑" },
  { badWords: "쥰나" },
  { badWords: "느그매" },
  { badWords: "소음순" },
  { badWords: "맘충" },
  { badWords: "썌끼" },
  { badWords: "빻았" },
  { badWords: "씨붕" },
  { badWords: "개샛기" },
  { badWords: "좆" },
  { badWords: "존잼" },
  { badWords: "좃또" },
  { badWords: "후려" },
  { badWords: "친놈" },
  { badWords: "시미발친" },
  { badWords: "십부럴" },
  { badWords: "개간" },
  { badWords: "따먹었어" },
  { badWords: "십자석" },
  { badWords: "점물" },
  { badWords: "ㅆㅂ" },
  { badWords: "쇄리" },
  { badWords: "좀쓰레기" },
  { badWords: "부랄" },
  { badWords: "버짓물" },
  { badWords: "씨뱅가리" },
  { badWords: "슴가" },
  { badWords: "애무" },
  { badWords: "젓물" },
  { badWords: "씨뱅가리" },
  { badWords: "런년" },
  { badWords: "게늠" },
  { badWords: "내버지" },
  { badWords: "막간년" },
  { badWords: "발놈" },
  { badWords: "fuck" },
  { badWords: "뒤이치기" },
  { badWords: "쌍넘" },
  { badWords: "창남" },
  { badWords: "좃마무리" },
  { badWords: "씨걸" },
  { badWords: "젗같" },
  { badWords: "젖탱이" },
  { badWords: "개년" },
  { badWords: "십팔넘" },
  { badWords: "죤나" },
  { badWords: "쉬이발" },
  { badWords: "개세" },
  { badWords: "새키" },
  { badWords: "족까내" },
  { badWords: "졸좋" },
  { badWords: "귀두" },
  { badWords: "쉬팔" },
  { badWords: "느그" },
  { badWords: "dogbaby" },
  { badWords: "개가튼" },
  { badWords: "버지구멍" },
  { badWords: "년놈" },
  { badWords: "졸예" },
  { badWords: "왕자지" },
  { badWords: "야동" },
  { badWords: "햝" },
  { badWords: "쪼다새끼" },
  { badWords: "쒸팔" },
  { badWords: "떠어라아이" },
  { badWords: "개지랄" },
  { badWords: "디졌" },
  { badWords: "새꺄" },
  { badWords: "좃가튼뇬" },
  { badWords: "18년" },
  { badWords: "왕잠지" },
  { badWords: "쪼다" },
  { badWords: "십탱구리" },
  { badWords: "씨벌쉐이" },
  { badWords: "미친씨부랄" },
  { badWords: "별창" },
  { badWords: "좃빠네" },
  { badWords: "자지" },
  { badWords: "좃넘" },
  { badWords: "개후라들놈" },
  { badWords: "씨블년" },
  { badWords: "좃만아" },
  { badWords: "씌8" },
  { badWords: "개씨발넘" },
  { badWords: "쓰발" },
  { badWords: "대갈" },
  { badWords: "존좋" },
  { badWords: "띠이발" },
  { badWords: "누나강간" },
  { badWords: "엠창" },
  { badWords: "상년" },
  { badWords: "버러지" },
  { badWords: "씨발" },
  { badWords: "고환" },
  { badWords: "씨방새" },
  { badWords: "개비싸" },
  { badWords: "조개" },
  { badWords: "헐렁보지" },
  { badWords: "시댕이" },
  { badWords: "골빈" },
  { badWords: "18ㅅㅔㅋㅣ" },
  { badWords: "노무현" },
  { badWords: "개가튼년" },
  { badWords: "조또" },
  { badWords: "씨비" },
  { badWords: "개같이" },
  { badWords: "쫂" },
  { badWords: "족까" },
  { badWords: "버지털" },
  { badWords: "사새끼" },
  { badWords: "병신" },
  { badWords: "개소리" },
  { badWords: "니년" },
  { badWords: "좃보지" },
  { badWords: "걔잡지랄" },
  { badWords: "나빼썅" },
  { badWords: "뷰우웅신" },
  { badWords: "죶" },
  { badWords: "씨부랄" },
  { badWords: "따아알따리" },
  { badWords: "창녀" },
  { badWords: "존싫" },
  { badWords: "18세ㅋㅣ" },
  { badWords: "좁빠라라" },
  { badWords: "개씁블" },
  { badWords: "게저엇" },
  { badWords: "같은새끼" },
  { badWords: "이년" },
  { badWords: "빠굴" },
  { badWords: "젓밥" },
  { badWords: "개쩌" },
  { badWords: "버어지" },
  { badWords: "야dong" },
  { badWords: "ㅆㅣ발" },
  { badWords: "ㅁㅊ" },
  { badWords: "자기핧아" },
  { badWords: "존나" },
  { badWords: "ㅆㅣ바" },
  { badWords: "십팔" },
  { badWords: "ㅂㅁㄱ" },
  { badWords: "뻑유" },
  { badWords: "애미" },
  { badWords: "에에비" },
  { badWords: "난자" },
  { badWords: "씨가랭놈" },
  { badWords: "쉽세" },
  { badWords: "시파" },
  { badWords: "개같은년" },
  { badWords: "십자석" },
  { badWords: "흐졉" },
  { badWords: "좆털" },
  { badWords: "벼엉신" },
  { badWords: "섹" },
  { badWords: "장애인" },
  { badWords: "틱장애" },
  { badWords: "장애" },
  { badWords: "cex" },
  { badWords: "S.E.X" },
  { badWords: "C.E.X" },
  { badWords: "s.e.x" },
  { badWords: "c.e.x" },
  { badWords: "s e x" },
  { badWords: "se x" },
  { badWords: "se x" },
];
export default badWords;
